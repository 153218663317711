import '../Settings.css'
import axios from 'axios';
import { useState, useEffect } from 'react';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from 'react-toastify';
import { Tooltip } from 'antd';

export function UserForm({ display }) {
  const user = localStorage.getItem("user");
  const businessID = JSON.parse(user)[0].business.id;
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    password: '',
    designation: '',
    roles: '',
  });
  const [passtype, setPassType] = useState('password');
  const [errors, setErrors] = useState({});
  const [errorsMessage, setErrorsMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};
    const errorMessage = {};

    for (const [key, value] of Object.entries(formData)) {
      var name;
      if(key === "first_name"){name = "First name"};
      if(key === "last_name"){name = "Last name"};
      if(key === "email"){name = "Email"};
      if(key === "password"){name = "Password"};
      if(key === "designation"){name = "Designation"};
      if(key === "roles"){name = "Role"};
      if (!String(value).trim()) {
        errors[key] = true;
        errorMessage[key] = `${name} is required`;
      } 
      else if (key === "first_name" && !/^[a-zA-Z]{3,25}$/.test(value)) {
        errors[key] = true;
        errorMessage[key] = "First name must be 3-25 letters";
      }
      else if (key === "last_name" && !/^[a-zA-Z\s]{1,25}$/.test(value)) {
        errors[key] = true;
        errorMessage[key] = "Last name must be 1-25 letters";
      }
      else if (key === "email" && !/\S+@\S+\.\S+/.test(value)) {
        errors[key] = true;
        errorMessage[key] = `${name} is invalid`
      }
      else if (key === "password" && !/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-_+=~`[\]{}|:;"'<>,.?/]{8,}$/.test(value)) {
        errors[key] = true;
        errorMessage[key] = `Password must be one letter, digit, special character and at least 8 characters long`
      }
    }
    
    setErrors(errors);
    setErrorsMessage(errorMessage);
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      axios.post("https://wavedemo.cydeztechnologies.com/user-register", {
        business_id: businessID,
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        password: formData.password,
        designation: formData.designation,
        role: parseInt(formData.roles)
      })
        .then((res) => {
          if (res.data === "User has been created") {
            toast.success("User has been created successfully", {toastId:"adduser"});
            display(false);
            setIsSubmitting(false);
          }
          else {
            toast.error(res.data.trim(), {toastId:"adduser"});
          }
        })
        .catch((e) => {
          console.log("Error in /user-register:", e);
          toast.error(e.message, {toastId:"err-api-1"});
        });
    }
    else{
      toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error-1"});
  }
  }

  //password hide & seek toggle
  const handleToggle = () => {
    passtype === 'password' ? setPassType('text') : setPassType('password')
  }

  const styleName = "ms-2 rounded border border-2 border-primary py-1 w-100";
  const styleNameError = "ms-2 rounded border border-2 border-danger py-1 w-100";
  return (
    <section>
      <div className="formCard">
        <form>
          <div className="text-start fw-bold fs-4 mt-2 mb-3">Create New User</div>
          <div className="form-group row mb-2">
            <label className="col-sm-4 fw-bold text-start">Name <span className="importent">*</span> </label>
            <div className="col-sm-4">
              <Tooltip
                title={errorsMessage.first_name}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.first_name}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
              >
                <input
                  className={`${errors.first_name ? styleNameError : styleName}`}
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && <><br /><br /></>}
              </Tooltip>
            </div>
            <div className="col-sm-4">
              <Tooltip
                title={errorsMessage.last_name}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.last_name}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
              >
                <input
                  className={`${errors.last_name ? styleNameError : styleName}`}
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                />
                {errors.last_name && <><br /><br /></>}
              </Tooltip>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-4 fw-bold text-start">Email <span className="importent">*</span> </label>
            <div className="col-sm-8">
              <Tooltip
                title={errorsMessage.email}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.email}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
              >
                <input
                  className={`${errors.email ? styleNameError : styleName}`}
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
                {errors.email && <><br /><br /></>}
              </Tooltip>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-4 fw-bold text-start">Password <span className="importent">*</span> </label>
            <div className="col-sm-8" >
              <Tooltip
                title={errorsMessage.password}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.password}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
              >
                <div style={{ position: 'relative' }}>
                  <input
                    type={passtype}
                    className={`${errors.password ? styleNameError : styleName}`}
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                  />
                  <i
                    className={
                      passtype === "password" ? "fa fa-eye-slash" : "fa fa-eye"
                    }
                    style={{ position: "absolute", top: "30%", right: "5%", color: "#d5cdcd", cursor: "pointer" }}
                    onClick={handleToggle}
                  ></i>
                </div>
                {errors.password && <><br /><br /></>}
              </Tooltip>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-4 fw-bold text-start">Position <span className="importent">*</span> </label>
            <div className="col-sm-8">
              <Tooltip
                title={errorsMessage.designation}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.designation}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
              >
                <select
                  className={`${errors.designation ? styleNameError : styleName}`}
                  name='designation'
                  value={formData.designation}
                  onChange={handleChange}
                >
                  <option value="">Position at the business</option>
                  <option value="Partner/Co-owner">Partner/Co-owner</option>
                  <option value="Accountant/Bookkeeper">Accountant/Bookkeeper</option>
                  <option value="Family member">Family member</option>
                  <option value="Salesperson">Salesperson</option>
                  <option value="Assistant">Assistant</option>
                  <option value="Block Advisors tax pro">Block Advisors Tax Pro</option>
                  <option value="Other">Other</option>
                </select>
                {errors.designation && <><br /><br /></>}
              </Tooltip>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-4 fw-bold text-start">Role <span className="importent">*</span> </label>
            <div className="col-sm-8">
              <Tooltip
                title={errorsMessage.roles}
                placement="bottomLeft"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.roles}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
              >
                <div className="text-start">
                  <div>
                    <input
                      type="radio"
                      className="ms-2 rounded"
                      name="roles"
                      value={2}
                      onChange={handleChange}
                    /> <span className="fw-bold">Admin</span>
                  </div>
                  <div>
                    <input
                      type="radio"
                      className="ms-2 rounded"
                      name="roles"
                      value={3}
                      onChange={handleChange}
                    /> <span className="fw-bold">Editor</span>
                  </div>
                </div>
                {errors.roles && <><br /></>}
              </Tooltip>
            </div>
          </div>
          <div className="form-group mt-5 text-end">
            <button type="button" onClick={() => display(false)} className="btn btn-white border rounded-5 border-primary mx-2">Cancel</button>
            <button type="submit" disabled={isSubmitting} onClick={handleSubmit} className="btn btn-primary rounded-5">{isSubmitting ? 'Uploading...' : 'Create User'}</button>
          </div>
        </form>
      </div>
    </section>
  )
}

export function UserEditBox(props) {
  const { displayEdit, editUserData, isOpen, onClose } = props;
  const [_isOpen, setOpen] = useState(isOpen);
  const user = localStorage.getItem("user");
  const businessID = JSON.parse(user)[0].business.id;
  const [editformData, setEditFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    // password:"",
    designation: "",
    role: "",
  });
  const [passtype, setPassType] = useState('password');
  const [errors, setErrors] = useState({});
  const [errorsMessage, setErrorsMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
    setErrors(false);
  };

  useEffect(() => {
    setOpen(isOpen);
    if (editUserData !== undefined) {
      setEditFormData(editUserData)
    }
  }, [isOpen]);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors(false)
  };

  // update checked state of radio buttons
  document.querySelectorAll('input[name="role"]').forEach((el) => {
    el.checked = parseInt(el.value) === parseInt(editformData.role);
  });

  const handleSubmit = (event) => {
    console.log('editformData: ', editformData)
    event.preventDefault();
    const errors = {};
    const errorMessage = {};
    for (const [key, value] of Object.entries(editformData)) {
      var name;
      if(key === "first_name"){name = "First name"};
      if(key === "last_name"){name = "Last name"};
      if(key === "email"){name = "Email"};
      if(key === "designation"){name = "Designation"};
      if(key === "role"){name = "Role"};
      if (!String(value).trim()) {
        errors[key] = true;
        errorMessage[key] = `${name} is required`;
      } 
      else if (key === "first_name" && !/^[a-zA-Z]{3,25}$/.test(value)) {
        errors[key] = true;
        errorMessage[key] = "First name must be 3-25 letters";
      }
      else if (key === "last_name" && !/^[a-zA-Z\s]{1,25}$/.test(value)) {
        errors[key] = true;
        errorMessage[key] = "Last name must be 1-25 letters";
      }
      else if (key === "email" && !/\S+@\S+\.\S+/.test(value)) {
        errors[key] = true;
        errorMessage[key] = `${name} is invalid`
      }
      // else if (key === "password" && ! /^(?=.*[a-zA-Z]).{8,}$/.test(value)) {
      //   errors[key] = true;
      //   errorMessage[key] = `Password must be eight characters long`
      // }
      // else if (key === "password" && !/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/.test(value)) {
      //   errors[key] = true;
      //   errorMessage[key] = `Must be one uppercase letter, one special character and alphanumeric characters`
      // }
    }
    
    setErrors(errors);
    setErrorsMessage(errorMessage);
    if (Object.keys(errors).length === 0) {
      setIsSubmitting(true);
      axios.put(`https://wavedemo.cydeztechnologies.com/user-updation/${editUserData.id}`, {
        business_id: businessID,
        first_name: editformData.first_name,
        last_name: editformData.last_name,
        email: editformData.email,
        // password: editUserData.password,
        designation: editformData.designation,
        role: parseInt(editformData.role)
      })
        .then((res) => {
          if (res.data) {
            toast.success(res.data, {toastId:"edituser"});
            setOpen(false);
            if (onClose) onClose();
            setIsSubmitting(false);
          }
          else {
            toast.error(res.data.trim(), {toastId:"edituser"});
          }
        })
        .catch((e) => {
          console.log("Error in user-updation:", e);
          toast.error(e.message, {toastId:"err-api-2"});
        });
    }
    else{
      toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error-2"});
  }
  }

  const styleName = "ms-2 rounded border border-2 border-primary py-1 w-100";
  const styleNameError = "ms-2 rounded border border-2 border-danger py-1 w-100";

  return (
    <Dialog
      open={displayEdit}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll={'body'}
      disablebackdropclick="true"
      disableEscapeKeyDown={true}
      style={{ height: "auto", maxHeight: "900px" }}
    >
      {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Edit</DialogTitle> */}
      <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center ">
        Edit User
        <i
          className="bi bi-x-lg"
          style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
          onClick={handleClose}
        ></i>
      </DialogTitle>
      <hr />
      <DialogContent className="px-5 mt-2">
        <>
          <div className="form-group row mb-2">
            <label className="col-sm-4 fw-bold text-sm-end text-dark">Name <span className="importent">*</span> </label>
            <div className="col-sm-4">
            <Tooltip
                title={errorsMessage.first_name}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.first_name}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
                zIndex={9999}
              >
                <input
                  className={`${errors.first_name ? styleNameError : styleName}`}
                  type="text"
                  name="first_name"
                  value={editformData.first_name}
                  onChange={handleChange}
                />
                {errors.first_name && <><br /><br /></>}
              </Tooltip>
            </div>
            <div className="col-sm-4">
            <Tooltip
                title={errorsMessage.last_name}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.last_name}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
                zIndex={9999}
              >
                <input
                  className={`${errors.last_name ? styleNameError : styleName}`}
                  type="text"
                  name="last_name"
                  value={editformData.last_name}
                  onChange={handleChange}
                />
                {errors.last_name && <><br /><br /></>}
              </Tooltip>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-4 fw-bold text-sm-end text-dark">Email <span className="importent">*</span> </label>
            <div className="col-sm-8">
            <Tooltip
                title={errorsMessage.email}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.email}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
                zIndex={9999}
              >
                <input
                  className={`${errors.email ? styleNameError : styleName}`}
                  type="text"
                  name="email"
                  value={editformData.email}
                  onChange={handleChange}
                />
                {errors.email && <><br /><br /></>}
              </Tooltip>
            </div>
          </div>
          {/* <div className="form-group row mb-2">
            <label className="col-sm-4 fw-bold text-sm-end text-dark">Password <span className="importent">*</span> </label>
            <div className="col-sm-8" >
              <div style={{position:'relative'}}>
              <input
                type={passtype}
                className="ms-2 rounded border border-2 border-primary py-1 w-100"
                name="password"
                // value={editUserData.password || ''}
                onChange={handleChange}
              />
              <i
                className={
                  passtype === "password" ? "fa fa-eye-slash" : "fa fa-eye"
                }
                style={{position: "absolute", top: "30%", right: "5%", color: "#d5cdcd", cursor: "pointer"}}
                onClick={handleToggle}
              ></i>
              </div>
              {errors.password && <span className='ms-2 text-danger'>{errors.password}</span>}
            </div>
          </div> */}
          <div className="form-group row mb-2">
            <label className="col-sm-4 fw-bold text-sm-end text-dark">Position <span className="importent">*</span> </label>
            <div className="col-sm-8">
            <Tooltip
                title={errorsMessage.designation}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.designation}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
                zIndex={9999}
              >
                <select
                className={`${errors.designation ? styleNameError : styleName}`}
                name='designation'
                value={editformData.designation || ""}
                onChange={handleChange}
              >
                <option value="">Position at the business</option>
                <option value="Partner/Co-owner">Partner/Co-owner</option>
                <option value="Accountant/Bookkeeper">Accountant/Bookkeeper</option>
                <option value="Family member">Family member</option>
                <option value="Salesperson">Salesperson</option>
                <option value="Assistant">Assistant</option>
                <option value="Block Advisors tax pro">Block Advisors Tax Pro</option>
                <option value="Other">Other</option>
              </select>
                {errors.designation && <><br /><br /></>}
              </Tooltip>
            </div>
          </div>
          <div className="form-group row mb-2">
            <label className="col-sm-4 fw-bold text-sm-end text-dark">Role <span className="importent">*</span> </label>
            <div className="col-sm-8">
              {editformData.role === 1 ?
                <div className='fw-bold px-2 text-dark'>Owner</div> :
                <>
                  <div className="text-start">
                    <div>
                      <input
                        type="radio"
                        className="ms-2 rounded"
                        name="role"
                        value={2}
                        onChange={handleChange}
                      /> <span className="fw-bold text-dark">Admin</span>
                    </div>
                    <div>
                      <input
                        type="radio"
                        className="ms-2 rounded"
                        name="role"
                        value={3}
                        onChange={handleChange}
                      /> <span className="fw-bold text-dark">Editor</span>
                    </div>
                  </div>
                  {errors.role && <span className='text-danger'>{errors.role}</span>}
                </>}
            </div>
          </div>
        </>
      </DialogContent>
      <hr />
      <DialogActions className='mb-4'>
        <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
          Cancel
        </button>
        <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
          {isSubmitting ? 'Uploading...' : 'Save'}
        </button>
      </DialogActions>
    </Dialog>
  );
}