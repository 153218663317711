import { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import Setting from '../Setting.sidebar';
import '../Settings.css'
import { UserForm, UserEditBox } from "./User_Form";
import UserDetails from "./User_Details";
import Sidebar from "../../Sidebar/Sidebar";
import axios from "axios";
import { toast } from 'react-toastify';
import LoadingSpinner from '../../Utils/Loader'
import { useNavigate } from "react-router-dom";

export default function User() {
  const navigate = useNavigate();
  const [displayForm, setDisplayForm] = useState(false);
  const [editBox, setEditBox] = useState(false);
  const [details, setDetails] = useState(false);
  const [editUserData, setEditUserData] = useState();
  const [loading, setLoading] = useState(false);
  const [admin, setAdmin] = useState({});
  const [users, setUsers] = useState([{
    id: null,
    business_id: null,
    first_name: null,
    last_name: null,
    email: null,
    designation: null,
    role: null,
    status: null,
    created_at: null
  }]);

  const display = (display) => {
    setDisplayForm(display);
  };

  const onClickDisplay = () => {
    setDisplayForm(true)
  }

  const handleEdit = (user) => {
    setEditBox(true);
    setEditUserData(user);
  }

  useEffect(() => {
    const user = localStorage.getItem("user");
    const userID = user && JSON.parse(user)[0].id
    const businessID = user && JSON.parse(user)[0].business.id;

    if(user === null){
      localStorage.removeItem("user");
      navigate('/error-page');
      }


    const userData = [];
    axios.get(`https://wavedemo.cydeztechnologies.com/list-user/${businessID}`).then((response) => {
      if(response.data !== "No data available"){
        response.data.map((item) => {
          if (item.id === userID) {
            setAdmin(item);
          }
          else {
            userData.push(item)
          }
        })
      }
      setUsers(userData);
      setLoading(true);
    }).catch((e) => {
      console.log("Error in /user-profile:", e);
      toast.error(e.message, {toastId:"error"});
    });
  }, [displayForm, editBox]);


  return (
    <section>
      <Sidebar />
      <Setting />
      {loading ?
        <div className="column2 container my-5">
          <div className="row">
            <div className="col-9 text-start fw-bold fs-3 text-capitalize">
              Users
            </div>
            <div className="col-3 text-end">
              <button className="btn btn-primary" onClick={onClickDisplay}>Create User</button>
            </div>
          </div>
          <br />
          <div className="fs-6 text-start">
            You can create a new user to access your Wave account. Only give access to people you trust, since users can see your
            transactions and other business information.
            <span> <Link to='#' onClick={()=> setDetails(true)} >Learn more about all user types</Link> </span>
            and what they can do.
          </div>
          <br />
          {displayForm ? <UserForm display={display} /> : <></>}
          <>
            <ul style={{ listStyleType: "none", display: "initial" }}>
              <li className="card p-3 my-1 mx-5">
                <div className="row">
                  <div className="col-9 text-start">
                    <span className="text-start fw-bold text-capitalize">{admin.first_name}</span> <br />
                    <span className="text-center fw-normal">{admin.role === 1 ? "Owner" : admin.role === 2 ? "Admin" : "Editor"}</span>
                  </div>
                  <div className="col-3 text-end pt-2 pe-4">
                    <button onClick={() => handleEdit(admin)} className="btn btn-sm btn-primary">Edit</button>
                  </div>
                </div>
              </li>
            </ul>
          </>
          <div>
            <ul style={{ listStyleType: "none", display: "initial" }}>
              {users.map((data, index) => {
                return (
                  <li className="card px-3 my-1 mx-5" key={index}>
                    <div className="row">
                      <div className="col-9 text-start">
                        <span className="text-start fw-bold text-capitalize">{data.first_name}</span> <br />
                        <span className="text-center fw-normal">{data.role === 1 ? "Owner" : data.role === 2 ? "Admin" : "Editor"}</span>
                      </div>
                      <div className="col-3 text-end pt-2 pe-4">
                        <button onClick={() => handleEdit(data)} className="btn btn-sm btn-primary">Edit</button>
                      </div>
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
        :
        <LoadingSpinner />}
      <UserEditBox
        displayEdit={editBox}
        editUserData={editUserData}
        isOpen={editBox}
        onClose={() => {
          setEditBox(false);
        }}
      />
      <UserDetails
        displayDetails={details}
        isOpen={details}
        onClose={() => {
          setDetails(false);
        }}
      />
    </section>
  );
};