export default function Business_Profile_Validation(input) {
    let errors = {}
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    var status = true;

   console.log(input);
    if (!input.business_name) {
        status = false;
        errors.business_name = "Please enter business name.";
    }

    if (!input.business_email) {
        status = false;
        errors.business_email = "Please enter email address."
    }

    if (input.business_email && !email_pattern.test(input.business_email)) {
        status = false;
        errors.business_email = "Please enter a valid email address."
    }

    if (!input.business_mobile) {
        status = false;
        errors.business_mobile = "Please enter phone number."
    }

    if (!input.business_weburl) {
        status = false;
        errors.business_weburl = "Please  enter  website"
    }

    if (!input.business_address) {
        status = false;
        errors.business_address = "Please enter address"
    }
    if (!input.business_city) {
        status = false;
        errors.business_city = "Please enter city"
    }
    if (!input.business_state) {
        status = false;
        errors.business_state = "Please select state"
    }
    if (!input.business_country) {
        status = false;
        errors.business_country = "Please select country"
    }
    if (!input.business_pincode) {
        status = false;
        errors.business_pincode = "Please enter pincode"
    }


    return {'errors':errors,'status':status};
}