import React from 'react';
import './Settings.css';
import { NavLink } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';

export default function Setting() {
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setScreenWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <>{
            screenWidth > 900 ?
                <section className="column1 container-fluid" style={{ borderRight: "1px solid lightgray", textAlign: "start" }}>
                    <div className="d-flex flex-column align-items-start align-items-sm-start pt-2 min-vh-100">
                        <div className='fs-5 fw-bold my-3'>Settings</div>
                        <div className="subtitle">
                            User management
                        </div>
                        <NavLink to="/settings/users" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                            Users
                        </NavLink>
                        <div className="subtitle">
                            Sales & Payments
                        </div>
                        <NavLink to="/settings/invoice-customization" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                            Invoice Customization
                        </NavLink>
                        <div className="subtitle">
                            Accounting
                        </div>
                        <NavLink to="/settings/currency" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                            Currency
                        </NavLink>
                        <NavLink to="/settings/sales-taxes" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                            Sales Taxes
                        </NavLink>
                        <div className="subtitle">
                            Payment
                        </div>
                        <NavLink to="/settings/payment-method" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                            Payment Method
                        </NavLink>
                        <NavLink to="/settings/payment-account" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                            Payment Account
                        </NavLink>
                        {/* <div className="subtitle">
                            Others
                        </div>
                        <NavLink to="/settings/export-your-data" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                            Export your data
                        </NavLink> */}
                        <NavLink to="/settings/edit-business-profile" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                            Business 
                        </NavLink>

                    </div>
                </section>
                :
                <div className='container my-5'>
                    <Navbar bg="light" expand="lg" className='border border-primary rounded p-3'>
                        <Navbar.Brand>Settings</Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler"><i className="bi bi-gear"></i></Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="ml-auto">
                                <div className="subtitle mt-3">
                                    User management
                                </div>
                                <NavLink to="/settings/users" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                                    Users
                                </NavLink>
                                <div className="subtitle">
                                    Sales & Payments
                                </div>
                                <NavLink to="/settings/invoice-customization" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                                    Invoice Customization
                                </NavLink>
                                <div className="subtitle">
                                    Accounting
                                </div>
                                <NavLink to="/settings/currency" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                                    Currency
                                </NavLink>
                                <NavLink to="/settings/sales-taxes" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                                    Sales Taxes
                                </NavLink>
                                <div className="subtitle">
                                    Payment
                                </div>
                                <NavLink to="/settings/payment-method" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                                    Payment Method
                                </NavLink>
                                <NavLink to="/settings/payment-account" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                                    Payment Account
                                </NavLink>
                                {/* <div className="subtitle">
                                    Others
                                </div>
                                <NavLink to="/settings/export-your-data" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                                    Export your data
                                </NavLink> */}
                                <NavLink to="/settings/edit-business-profile" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                                    Business 
                                </NavLink>
                            </Nav>
                        </Navbar.Collapse>
                    </Navbar>
                </div>
        }
        </>
    );
};

