import { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from 'react-toastify';
import './Estimate.css'

import { AddSalesTax } from "../../Settings/Accounting/Sales Taxes/Add_Tax";
import { Tooltip } from 'antd';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

function AddProductPopup(props) {
    const { displayPopup, isOpen, onClose, onUpdateproductList,index } = props;
    const [_isOpen, setOpen] = useState(isOpen);
    const user = localStorage.getItem("user");
    const businessID = JSON.parse(user)[0].business.id;
    const [formData, setFormData] = useState({
        product_name: "",
        description: "",
        price: "",
        tax_id: {},
    });
    const [taxData, setTaxData] = useState([]);
    const [allTaxData, setAllTaxData] = useState([]);
    const [errors, setErrors] = useState({});
    const [errorsMessage, setErrorsMessage] = useState({});
    const [addTaxBox, setAddTaxBox] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        axios.get(`https://wavedemo.cydeztechnologies.com/list-tax-by-businessid/${businessID}`).then((response) => {
            if (response.data !== 'No data found') {
                setTaxData(response.data.filter(item => item.status === 1));
                setAllTaxData(response.data)
            }
        }).catch((e) => {
            console.log("Error in /list-tax-by-businessid:", e);
            toast.error(e.message);
        });
    }, [!addTaxBox]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(false);
    };

    const updateTaxListfromPopup  = (newvalue) =>{
        setFormData({ ...formData, tax_id: newvalue });
      }  

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};
        for (const [key, value] of Object.entries(formData)) {
            var name;
      if(key === "product_name"){name = "Product name"};
      if(key === "price"){name = "Price"};
            if (key !== 'description') {
                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`;
                } else if (key === "price" && !/^0*[1-9]\d*(\.\d+)?$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = "Price is invalid";
                }
                else if (key === "product_name" && !/^[\w\d\s-]{3,150}(?![\w\d\s-]*\d{2})[\w\d\s-]*$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = "product name must be 3-150 letters";
                }
            }
        }
        if (!formData.tax_id || Object.keys(formData.tax_id).length === 0) {
            errors.tax_id = true;
            errorMessage.tax_id = "Tax is required";
          }

        setErrors(errors);
        setErrorsMessage(errorMessage);
        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
            axios.post("https://wavedemo.cydeztechnologies.com/add-products", {
                business_id: businessID,
                product_name: formData.product_name,
                description: formData.description,
                price: Number(formData.price),
                tax_id: formData.tax_id['id'],
            })
                .then((res) => {
                    if (res.data) {
                        onUpdateproductList(res.data.allProducts,taxData,res.data.product,index)
                        toast.success("Product has been created", {toastId:"add-pro"});
                        setOpen(false);
                        if (onClose) onClose();
                        setErrors({});
                        setFormData({product_name: "",description: "",price: "",tax_id: {}});
                        setIsSubmitting(false);
                    }
                })
                .catch((e) => {
                    console.log("Error in /add-products:", e);
                    toast.error(e.message, {toastId:"add-pro"});
                    setIsSubmitting(false);
                });
        }
        else{
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error"});
            setIsSubmitting(false);
        }
    }

    const styleName = "form-control w-75 fs-13 ";
    const styleNameError = "form-control w-75 fs-13 border border-danger";

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
        setErrors({});
        setFormData({
            product_name: "",
            description: "",
            price: "",
            tax_id: {},
        });
    };




    return (
        <section>
            <Dialog
                open={displayPopup}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll={'paper'}
                disablebackdropclick="true"
                disableEscapeKeyDown={true}
                fullWidth={true}
                maxWidth={false}
                PaperProps={{
                style: {
                    maxWidth: "600px",
                    width: "100%",
                    }
                }}
            >
                {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Edit</DialogTitle> */}
                <DialogTitle sx={{ position: 'relative', height: '50px' }} id="alert-dialog-title" className="align-content-center ">
                    <p style={{ fontWeight: '500' }}>Add Product</p>
                    <i
                        className="bi bi-x-lg"
                        style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                        onClick={handleClose}
                    ></i>
                </DialogTitle>
                <hr />
                <DialogContent className="m-4">
                    <>
                        <div className="form-group row mb-4">
                            <label className="col-sm-4 fw-bold text-md-end py-1">Name <span className="importent">*</span></label>
                            <div className="col-sm-8 text-center">
                                <Tooltip
                                    title={errorsMessage.product_name}
                                    placement="bottomLeft"
                                    color="#990000"
                                    trigger={"contextMenu"}
                                    open={errors.product_name}
                                    overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                    autoAdjustOverflow={false}
                                    zIndex={1300}
                                >
                                    <input
                                        className={`${errors.product_name ? styleNameError : styleName}`}
                                        type="text"
                                        name="product_name"
                                        value={formData.product_name}
                                        onChange={handleChange}
                                    />
                                    {errors.product_name && <div className="mb-4"></div>}
                                </Tooltip>
                                {/* {errors.product_name && <span className="mx-2 text-danger">{errors.product_name}</span>} */}
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-4 fw-bold text-md-end py-1">Description</label>
                            <div className="col-sm-8">
                                <textarea
                                    className="form-control w-75 fs-13"
                                    name="description"
                                    value={formData.description}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-4 fw-bold text-md-end py-1">Price <span className="importent">*</span></label>
                            <div className="col-sm-8">
                                <Tooltip
                                    title={errorsMessage.price}
                                    placement="bottomLeft"
                                    color="#990000"
                                    trigger={"contextMenu"}
                                    open={errors.price}
                                    overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                    autoAdjustOverflow={false}
                                    zIndex={1300}
                                >
                                    <input
                                        className={`${errors.price ? styleNameError : styleName}`}
                                        type="text"
                                        name="price"
                                        value={formData.price}
                                        onChange={handleChange}
                                    />
                                    {errors.price && <div className="mb-4"></div>}
                                </Tooltip>
                                {/* {errors.price && <span className="mx-2 text-danger">{errors.price}</span>} */}
                            </div>
                        </div>
                        {errors.price && <div className="mt-4"></div>}
                        <div className="form-group row mb-2">
                            <label className="col-sm-4 fw-bold text-md-end py-1">Sales Tax <span className="importent">*</span></label>
                            <div className="col-sm-8">
                                <Tooltip
                                    title={<span>tax is required</span>}
                                    placement="bottomLeft"
                                    color="#990000"
                                    trigger={"contextMenu"}
                                    open={errors.tax_id}
                                    overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                    autoAdjustOverflow={false}
                                    zIndex={1300}
                                >
                                    <Autocomplete
                                        // disablePortal
                                        sx={{
                                            '& input': {
                                                height: 0,
                                            },
                                        }}
                                        className={errors.tax_id ? "border rounded border-danger w-75 fs-13" : "w-75 fs-13"}
                                        ListboxProps={{ style: { maxHeight: 100 } }}
                                        value={formData.tax_id}
                                        onChange={(event, newValue) => {
                                            setFormData({ ...formData, tax_id: newValue });
                                            setErrors(false);
                                        }}
                                        options={taxData}
                                        getOptionLabel={(option) => option.tax_name || ""}
                                        // options={taxData.map((item) => item.tax_name)}
                                        renderOption={(props, option) => {
                                            return (
                                                <li {...props} key={option.id}>
                                                    {option.tax_name}
                                                </li>
                                            );
                                        }}
                                        renderInput={(params) => <TextField {...params} />}
                                        PaperComponent={({ children }) => {
                                            return (
                                                <Paper>
                                                    {children}
                                                    <Button
                                                        color="primary"
                                                        fullWidth
                                                        style={{ textTransform: 'none' }}
                                                        sx={{ mx: 'auto', py: '8px', mt: '2px', fontSize: 12, borderTop: 1, borderColor: 'grey.500', borderRadius: 0 }}
                                                        onMouseDown={() => {
                                                            setAddTaxBox(true)
                                                        }}
                                                    >
                                                        <i className="bi bi-plus-circle me-1" ></i> Add New Sales Tax
                                                    </Button>
                                                </Paper>
                                            );
                                        }}
                                    />
                                    {errors.tax_id && <div className="mb-2"></div>}
                                </Tooltip>
                            </div>
                        </div>
                    </>
                </DialogContent>
                <hr />
                <DialogActions className='mb-3' sx={{ height: '30px' }}>
                    <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary popup-add-btn me-2">
                        {isSubmitting ? 'Uploading...' : 'Add Product'}
                    </button> or
                    <span onClick={handleClose} className="mx-2 popup-cancel-btn">
                        Cancel
                    </span>

                </DialogActions>
            </Dialog>
            <AddSalesTax
                onUpdateselectedtax={updateTaxListfromPopup}
                onUpdateTaxList={allTaxData}
                displayAddTax={addTaxBox}
                isOpen={addTaxBox}
                onClose={() => {
                    setAddTaxBox(false);
                }}
            />
        </section>
    )
}

export default AddProductPopup