import Sidebar from "../Sidebar/Sidebar"
import './Export_Data.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileCsv } from '@fortawesome/free-solid-svg-icons'
import DataTable from 'react-data-table-component';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';
import axios from 'axios';
import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import LoadingSpinner from '../Utils/Loader'

import dayjs from 'dayjs';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import numeral from 'numeral';


export default function Export_Data() {
  const [transaction, setTransaction] = useState([]);
  const [expenses, setExpenses] = useState([]);
  const user = localStorage.getItem("user");
  const businessID = user && JSON.parse(user)[0].business.id;
  const business_name = user && JSON.parse(user)[0].business.business_name;
  const businessCurrency = user && JSON.parse(user)[0].business_currency === null ? "$" : JSON.parse(user)[0].business_currency.toString().split(" - ")[2];
  const [filterFromDate, setFilterFromDate] = useState();
  const [filterToDate, setFilterToDate] = useState();
  const [isOpenFromDate, setIsOpenFromDate] = useState(false);
  const [isOpenToDate, setIsOpenToDate] = useState(false);
  const [filterHide, setFilterHide] = useState(false);
  const [loading, setLoading] = useState(false);

  // Fetch data from the API
  const fetchDataFromAPI = async () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-record-payment-by-business/${businessID}`).then((res) => {
      if (res.data !== "No data found") {
        setTransaction(res.data);
        setLoading(true);
      }
      else{
        setLoading(true);
      }
    });

    axios.get(`https://wavedemo.cydeztechnologies.com/listExpenseByBusinessId/${businessID}`).then((res) => {
      if (res.data !== "No data found") {
        setExpenses(res.data);
      }
    });
  };

  const onFilterToggleChange = () => {
    setFilterHide((prevState) => !prevState);
    setFilterFromDate(null);
  }


  let filteredData = transaction;

  if (filterFromDate) {
    filteredData = filteredData.filter(
      item =>
        item.date && 
        (dayjs(item.date).isSame(dayjs(filterFromDate), 'day') ||
          dayjs(item.date).isAfter(dayjs(filterFromDate), 'day'))
        // :
        // item.created_at &&
        // (dayjs(item.created_at).isSame(dayjs(filterFromDate), 'day') ||
        //   dayjs(item.created_at).isAfter(dayjs(filterFromDate), 'day'))
    );
  }

  if (filterToDate) {
    filteredData = filteredData.filter(
      item =>
        item.date && 
        (dayjs(item.date).isSame(dayjs(filterToDate), 'day') ||
          dayjs(item.date).isBefore(dayjs(filterToDate), 'day'))
        // :
        // item.created_at &&
        // (dayjs(item.created_at).isSame(dayjs(filterToDate), 'day') ||
        //   dayjs(item.created_at).isBefore(dayjs(filterToDate), 'day'))
    );
  }

  let filteredExpensesData = expenses;

  if (filterFromDate) {
    filteredExpensesData = filteredExpensesData.filter(
      item =>
        item.created_at &&
        (dayjs(item.created_at).isSame(dayjs(filterFromDate), 'day') ||
          dayjs(item.created_at).isAfter(dayjs(filterFromDate), 'day'))
    );
  }

  if (filterToDate) {
    filteredExpensesData = filteredExpensesData.filter(
      item =>
        item.created_at &&
        (dayjs(item.created_at).isSame(dayjs(filterToDate), 'day') ||
          dayjs(item.created_at).isBefore(dayjs(filterToDate), 'day'))
    );
  }



  const exportToExcel = () => {
    if(Array.isArray(transaction) && transaction.length === 0 && Array.isArray(expenses) && expenses.length === 0){
      toast.warning("Transaction and expenses data is empty!");
    }
    else if (transaction && expenses) {
      const workbook = new ExcelJS.Workbook();
      const transactionWorksheet = workbook.addWorksheet('Transaction');
      const expenseWorksheet = workbook.addWorksheet('Expenses');

      // Columns for the Transaction sheet
      const transactionColumns = [
        { apiName: 'date', excelName: 'Date' }, //: 'created_at'
        { apiName: ['invoice_prefix', 'invoice_transaction_number'], excelName: 'Income Number' },
        { apiName: 'customer_name', excelName: 'Customer Name' },
        { apiName: 'payment_method', excelName: 'Payment method' },
        { apiName: 'description', excelName: 'Description' },
        { apiName: 'amount', excelName: 'Amount' },
      ];

      // Columns for the Expense sheet
      const expenseColumns = [
        { apiName: 'expense_date', excelName: 'Date' },
        { apiName: 'expense_name', excelName: 'Expenses Name' },
        { apiName: 'description', excelName: 'Description' },
        { apiName: 'amount', excelName: 'Amount' },
      ];

      const addColumnsToSheet = (worksheet, columns) => {
        const sheetColumns = columns.map((column) => ({
          header: column.excelName,
          key: column.apiName,
          width: 15,
        }));
        worksheet.columns = sheetColumns;
      };

      addColumnsToSheet(transactionWorksheet, transactionColumns);
      addColumnsToSheet(expenseWorksheet, expenseColumns);

      // Add data to the Transaction sheet
      filteredData.forEach((item, index) => {
        const row = {};
        transactionColumns.forEach((column) => {
          if (Array.isArray(column.apiName)) {
            const values = column.apiName.map((name) => item[name]);
            row[column.apiName] = values.join('');
          }
          // else if (column.apiName == "created_at") {
          //   row[column.apiName] = dayjs(item[column.apiName]).format('DD/MM/YYYY');
          // }
          else if (column.apiName == "date") {
            row[column.apiName] = dayjs(item[column.apiName]).format('DD/MM/YYYY');
          }
          else if (column.apiName == "amount") {
            const formatValue = item[column.apiName].toFixed(2)
            row[column.apiName] = numeral(formatValue).value();
          }
          else {
            row[column.apiName] = item[column.apiName];
          }
        });
        transactionWorksheet.addRow(row);
      });


      // Add data to the Expense sheet
      filteredExpensesData.forEach((item) => {
        const row = {};
        expenseColumns.forEach((col) => {
          if (col.apiName == "expense_date") {
            row[col.apiName] = dayjs(item[col.apiName]).format('DD/MM/YYYY');
          }
          else if (col.apiName == "amount") {
            const formatValue = item[col.apiName].toFixed(2)
            row[col.apiName] = numeral(formatValue).value();
          }
          else {
            row[col.apiName] = item[col.apiName];
          }
        });
        expenseWorksheet.addRow(row);
      });
      

      const setCellAlignment = (worksheet, startRow, endRow, startCol, endCol, horizontal, vertical) => {
        for (let row = startRow; row <= endRow; row++) {
          for (let col = startCol; col <= endCol; col++) {
            const cell = worksheet.getCell(row, col);
            cell.alignment = { horizontal, vertical };
          }
        }
      };

      // Align headers (first row) in both worksheets to center horizontally and vertically
      setCellAlignment(transactionWorksheet, 1, 1, 1, transactionColumns.length, 'center', 'center');
      setCellAlignment(expenseWorksheet, 1, 1, 1, expenseColumns.length, 'center', 'center');

      // Align data rows to left horizontally and center vertically
      // setCellAlignment(transactionWorksheet, 2, filteredData.length + 1, 1, transactionColumns.length, 'left', 'center');
      // setCellAlignment(expenseWorksheet, 2, filteredExpensesData.length + 1, 1, expenseColumns.length, 'left', 'center');

      const transactionTotalAmount = filteredData.reduce((sum, item) => sum + Number(item.amount), 0);

    // Calculate total amount for expenses
    const expenseTotalAmount = filteredExpensesData.reduce((sum, item) => sum + Number(item.amount), 0);

    // Add total row to the Transaction sheet
    const lastRowIndexTransaction = filteredData.length + 3; // Add 3 for the header row, total sum row, and 1-based indexing
    transactionWorksheet.getCell(lastRowIndexTransaction, 5).value = 'Total Amount:';
    transactionWorksheet.getCell(lastRowIndexTransaction, 5).alignment = { horizontal: 'left', vertical: 'middle' };
    // transactionWorksheet.getCell(lastRowIndexTransaction, 6).value = transactionTotalAmount;
    // transactionWorksheet.getCell(lastRowIndexTransaction, 6).numFmt = `${businessCurrency}0.00`; // Format the cell as currency
    transactionWorksheet.getCell(lastRowIndexTransaction, 6).value = `${businessCurrency} ${transactionTotalAmount}.00`;
    transactionWorksheet.getCell(lastRowIndexTransaction, 6).alignment = { horizontal: 'right', vertical: 'middle' };

    // Add total row to the Expense sheet
    const lastRowIndexExpense = filteredExpensesData.length + 3; // Add 3 for the header row, total sum row, and 1-based indexing
    expenseWorksheet.getCell(lastRowIndexExpense, 3).value = 'Total Amount:';
    expenseWorksheet.getCell(lastRowIndexExpense, 3).alignment = { horizontal: 'left', vertical: 'middle' };
    // expenseWorksheet.getCell(lastRowIndexExpense, 4).value = expenseTotalAmount;
    // expenseWorksheet.getCell(lastRowIndexExpense, 4).numFmt = `${businessCurrency}0.00`; // Format the cell as currency
    expenseWorksheet.getCell(lastRowIndexExpense, 4).value = `${businessCurrency} ${expenseTotalAmount}.00`;
    expenseWorksheet.getCell(lastRowIndexExpense, 4).alignment = { horizontal: 'right', vertical: 'middle' };

    // Define column widths for the Transaction sheet
    const transactionColumnWidths = [
      { col: 1, width: 12 }, // Date
      { col: 2, width: 20 }, // Income Number
      { col: 3, width: 20 }, // Customer Name
      { col: 4, width: 20 }, // Payment method
      { col: 5, width: 30 }, // Description
      { col: 6, width: 15 }, // Amount
    ];

    // Define column widths for the Expense sheet
    const expenseColumnWidths = [
      { col: 1, width: 12 }, // Date
      { col: 2, width: 20 }, // Expenses Name
      { col: 3, width: 30 }, // Description
      { col: 4, width: 15 }, // Amount
    ];

    // Set column widths for the Transaction sheet
    transactionColumnWidths.forEach((column) => {
      transactionWorksheet.getColumn(column.col).width = column.width;
    });

    // Set column widths for the Expense sheet
    expenseColumnWidths.forEach((column) => {
      expenseWorksheet.getColumn(column.col).width = column.width;
    });


      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer]), `Transaction and expenses data from ${business_name}.xlsx`);
      });
    }
  };


  useEffect(() => {
    fetchDataFromAPI();
  }, [])


  return (
    <div>
      <Sidebar />
      { loading ?
      <div className="container main-coloum1 my-5 px-5">
        <div className="row">
          <div className="col-12 text-start fw-bold fs-4 text-capitalize">
            Export Your Data
          </div>
          <br />
          <div className="p1">
            <p>Export and download data from <strong>{business_name}</strong>.</p>
          </div>
        </div>
        <div className="container">
          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <div>
                <h5>Accounting</h5>
              </div>
              <div className="text-end">
                <button className="btn btn-outline-primary px-4 py-1 wv-outline-btn mb-3" onClick={() => { onFilterToggleChange() }}>Filter<i className={filterHide ? 'bi bi-chevron-up ps-2' : 'bi bi-chevron-down ps-2'}></i> </button>
              </div>
            </div>
            <div className='filter-body' style={{ display: filterHide ? 'block' : 'none' }}>
              <div className="row my-2">
                <div className='filter-input-box col-sm-6'>
                  <p className="filter-input-lable">Date: </p>
                  <div className="w-50" style={{ padding: "0 0 0 8px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div style={{ position: 'relative' }}>
                        <DatePicker
                          format='DD/MM/YYYY'
                          value={filterFromDate}
                          onChange={(date) => setFilterFromDate(date)}
                          open={isOpenFromDate}
                          onOpen={() => setIsOpenFromDate(true)}
                          onClose={() => setIsOpenFromDate(false)}
                          slotProps={{ textField: { size: 'small', placeholder: 'From', type: 'text' } }}
                        />
                      </div>
                    </LocalizationProvider>
                  </div>
                  <div className="w-50" style={{ padding: "0 0 0 22px" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <div style={{ position: 'relative' }}>
                        <DatePicker
                          format='DD/MM/YYYY'
                          value={filterToDate}
                          onChange={(date) => setFilterToDate(date)}
                          open={isOpenToDate}
                          onOpen={() => setIsOpenToDate(true)}
                          onClose={() => setIsOpenToDate(false)}
                          slotProps={{ textField: { size: 'small', placeholder: "To" } }}
                        />
                      </div>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mt-2" style={{ display: "flex", flexDirection: "row", }}> */}
          <div className="mt-2 w-50" style={{ margin: "0 auto" }} onClick={exportToExcel}>
            <div className="card card-1 fs-5">
              <strong className="d-flex justify-content-center align-items-center">
                <i className="bi bi-file-earmark-spreadsheet me-2" style={{ display: 'inline-block', color: "green" }}></i>
                <p style={{ display: 'inline-block', margin: 0 }}>
                  Export {filterHide ? 'filter data' : 'all data'} transactions for Excel
                </p>
              </strong>
            </div>
            {/* </div> */}
            {/* <div className="col-md-4">
              <div className="card card-2">
                <strong><FontAwesomeIcon icon={faFileCsv} style={{ color: "#1b63de", }} /><p>Export all transactions for CSV</p></strong>
              </div>
            </div>
            <br />
            <div className="col-md-4">
              <div className="card card-3">
                <strong><p>Export all receipts as a ZIP file</p></strong>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      : <LoadingSpinner /> }
    </div>
  )
}