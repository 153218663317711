import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";


export default function Delete(props) {
  const { title1, title2, isOpen, onClose, confirm } = props;
  const [_isOpen, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleDelete = () => {
    confirm(true);
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Dialog
      open={_isOpen}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll={'body'}
      disablebackdropclick="true"
      disableEscapeKeyDown={true}
      fullWidth={true}
      maxWidth={false}
      PaperProps={{
        style: {
          maxWidth: "500px",
          width: "100%",
          height: "auto",
          maxHeight: "900px"
        }
      }}
    >
      <DialogContent className="px-4 my-2 text-center">
        <i className="bi bi-x-circle text-danger" style={{ fontSize: '75px' }}> </i>
        <div className='fw-bold mt-3'>
        <h5 style={{color: "#685e5e" }}>{title1}</h5>
        <h5>{title2}</h5>
        </div>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center", marginBottom: "18px" }} >
        <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 px-4 text-capitalize fw-bold`}>
          Cancel
        </button>
        <button onClick={handleDelete} className="btn btn-danger border rounded-5 border-danger mx-2 px-4 text-capitalize fw-bold">
          Delete
        </button>
      </DialogActions>
    </Dialog>
  );
}
