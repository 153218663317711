import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { Tooltip } from 'antd';
// import forgot from '../../../assets/forgot-password.jpg'

export default function Changepassword() {

    const location = useLocation();
    const navigate = useNavigate();
    let email = location.state.email;
    const [input, setInput] = useState({
        otp: "",
        password: "",
        confirmPassword: "",
    })
    const [type1, setType1] = useState('password');
    const [type2, setType2] = useState('password');
    const [errors, setErrors] = useState({});
    const [errorsMessage, setErrorsMessage] = useState({});

    const styles = {
        backgroundColor: "#ffffff",
        borderRadius: "18px",
        padding: "20px 50px",
        boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxHeight: "auto",
        width: "450px",
        maxWidth: "auto",
        margin: "auto",
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setInput((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(false);
    };



    //prevent form submitting
    function handleSubmit(e) {
        e.preventDefault();

        const errors = {};
        const errorMessage = {};

        for (const [key, value] of Object.entries(input)) {
            var name;
            if (key === "otp") { name = "OTP" };
            if (key === "password") { name = "Password" };
            if (key === "confirmPassword") { name = "Confirm password" };
            if (!String(value).trim()) {
                errors[key] = true;
                errorMessage[key] = `${name} is required`;
            }
            else if (key === 'password' && !/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/.test(value)) {
                errors[key] = true;
                errorMessage[key] = "Must be one letter, digit, special character and at least 8 characters long";
            }
        }

        if (input.confirmPassword && String(input.confirmPassword) !== String(input.password)) {
            errors['confirmPassword'] = true;
            errorMessage['confirmPassword'] = "Please make sure your passwords match"
        }

        setErrors(errors);
        setErrorsMessage(errorMessage);

        if (Object.keys(errors).length === 0) {
            axios.put('https://wavedemo.cydeztechnologies.com/forgot_password_reset', {
                otp: input.otp,
                password: input.confirmPassword,
                email: email
            }).then(res => {
                if (res.data === "Password Updated... ") {
                    navigate('/')
                    toast.success("Password  reset successfully", {toastId:"change-updated"})
                }
                else {
                    toast.error("Invalid OTP", {toastId:"err-otp"})
                }
            })
        }
    }

    const handleToggle1 = () => {
        type1 === 'password' ? setType1('text') : setType1('password')
    }


    const handleToggle2 = () => {
        type2 === 'password' ? setType2('text') : setType2('password')
    }

    const styleName = "ms-2 rounded border border-2 border-primary p-1 w-100";
    const styleNameError = "ms-2 rounded border border-2 border-danger p-1 w-100";

    return (
        <div className=" p-5 container">
            <div>
                <div style={styles}>
                    <h4 className="card-title text-center ">Reset Password</h4>
                    <form className='form flex'>
                        <div className=" m-3">
                            <h6 className="card-text text-center" style={{ color: "#001b66" }}>
                                Please enter the OTP sent to <br /> {email}</h6>
                            <div className="form-group row">
                                <label htmlFor="otp" className="col col-form-label fw-bold" style={{ display: "flex", color: "#685e5e" }}>OTP</label>
                                <div className="input-group1" >
                                    <Tooltip
                                        title={errorsMessage.otp}
                                        placement="bottom"
                                        color="#8B0000"
                                        trigger={"contextMenu"}
                                        open={errors.otp}
                                        overlayStyle={{ fontSize: "12px" }}
                                        autoAdjustOverflow={false}
                                        getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    >
                                        <input
                                            className={`${errors.otp ? styleNameError : styleName}`}
                                            type="number"
                                            name="otp"
                                            onChange={handleChange}
                                        />
                                        {errors.otp && <><br /><br /></>}
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className=" m-3">
                            <h6 className="card-text text-center p-2" style={{ color: "#001b66" }}>Please Change your Password!</h6>
                            <div className="form-group chngpswd-form-group">
                                <label htmlFor="newpassword" style={{ color: "#685e5e" }} className="col col-form-label fw-bold text-start">New Password</label>
                                <Tooltip
                                    title={errorsMessage.password}
                                    placement="bottom"
                                    color="#8B0000"
                                    trigger={"contextMenu"}
                                    open={errors.password}
                                    overlayStyle={{ fontSize: "12px" }}
                                    autoAdjustOverflow={false}
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    <div style={{ position: 'relative' }}>
                                        <input
                                            type={type1}
                                            className={`${errors.password ? styleNameError : styleName}`}
                                            name="password"
                                            placeholder="Enter New Password"
                                            onChange={handleChange}
                                            onCopy={(event) => event.preventDefault()}
                                            onPaste={(event) => event.preventDefault()}
                                        />
                                        <i
                                            className={type1 === 'password' ? "fa fa-eye-slash" : "fa fa-eye"}
                                            style={{ position: "absolute", top: "30%", right: "5%", color: "#d5cdcd", cursor: "pointer" }}
                                            onClick={handleToggle1}
                                        ></i>
                                    </div>
                                    {errors.password && <><br /><br /><br /></>}
                                </Tooltip>
                            </div>

                            <div className="form-group chngpswd-form-group">
                                <label htmlFor="newpassword" style={{ color: "#685e5e" }} className="col col-form-label fw-bold text-start">Confirm Password</label>
                                <Tooltip
                                    title={errorsMessage.confirmPassword}
                                    placement="bottom"
                                    color="#8B0000"
                                    trigger={"contextMenu"}
                                    open={errors.confirmPassword}
                                    overlayStyle={{ fontSize: "12px" }}
                                    autoAdjustOverflow={false}
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    <div style={{ position: 'relative' }}>
                                        <input
                                            type={type2}
                                            className={`${errors.confirmPassword ? styleNameError : styleName}`}
                                            name="confirmPassword"
                                            placeholder="Enter Confirm Password"
                                            onChange={handleChange}
                                            onCopy={(event) => event.preventDefault()}
                                            onPaste={(event) => event.preventDefault()}
                                        />
                                        <i
                                            className={type2 === 'password' ? "fa fa-eye-slash" : "fa fa-eye"}
                                            style={{ position: "absolute", top: "30%", right: "5%", color: "#d5cdcd", cursor: "pointer" }}
                                            onClick={handleToggle2}
                                        ></i>
                                    </div>
                                    {errors.confirmPassword && <><br /><br /></>}
                                </Tooltip>
                            </div>
                        </div>
                        <div className="text-center mb-4">
                            <button type="button"
                                onClick={handleSubmit}
                                className="btn btn-primary w-50 " id="chngpswd-btn">Submit</button>
                        </div>
                        <div className="text-center ">
                            <p style={{ fontWeight: "500" }} >
                                Back to
                                <span className="ms-1 text-decoration-underline text-primary fw-bold"
                                    onClick={
                                        () => {
                                            navigate('/')
                                        }
                                    }
                                >Login</span></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};