import '../Accounting.css'
import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import { Tooltip } from 'antd';


export function AddSalesTax(props) {
  const { displayAddTax, isOpen, onClose, onUpdateselectedtax, onUpdateTaxList } = props;
  const [_isOpen, setOpen] = useState(isOpen);
  const user = localStorage.getItem("user");
  const businessID = JSON.parse(user)[0].business.id;
  const [formData, setFormData] = useState({
    tax_name: '',
    description: '',
    tax_rate: '',
  });
  const [errors, setErrors] = useState({});
  const [errorsMessage, setErrorsMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
    setFormData({ tax_name: '', description: '', tax_rate: '', })
    setErrors(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors(false);
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};
    const errorMessage = {};
    for (const [key, value] of Object.entries(formData)) {
      var name;
      if(key === "tax_name"){name = "Tax name"};
      if(key === "tax_rate"){name = "Tax rate"};
      if (key !== 'description') {
        if (!String(value).trim()) {
          errors[key] = true;
          errorMessage[key] = `${name} is required`;
        }
        else if (key === "tax_name" && !/^(?![\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$).+$/.test(value)) {
          errors[key] = true;
          errorMessage[key] = `${name} is invaild`;
        }
        else if (key === "tax_rate" && !/^-?\d*(?:\.\d+)?$/.test(value)) {
          errors[key] = true;
          errorMessage[key] = `${name} is invaild`;
        }
      }
    }

    setErrors(errors);
    setErrorsMessage(errorMessage);
    if (Object.keys(errors).length === 0) {
      const isTaxExisted = onUpdateTaxList && onUpdateTaxList.some((item) => {
        return (item.tax_name === formData.tax_name && item.tax_rate == formData.tax_rate);
      });
      if (isTaxExisted) {
        toast.error("Sales tax already exists!", { toastId: "addTax-1" });
      } else {
        setIsSubmitting(true);
    
        axios
          .post("https://wavedemo.cydeztechnologies.com/tax-registration", {
            business_id: businessID,
            tax_name: formData.tax_name,
            description: formData.description,
            tax_rate: formData.tax_rate,
            status: 1
          })
          .then((res) => {
            if (res.data) {
              toast.success("Tax has been created", { toastId: "addTax-1" });
              onUpdateselectedtax(res.data.Tax);
              setOpen(false);
              if (onClose) onClose();
              setFormData({ tax_name: "", description: "", tax_rate: "" });
              setErrors(false);
              setIsSubmitting(false);
            } else {
              toast.error("Failed, please try again", { toastId: "addTax-1" });
            }
          })
          .catch((e) => {
            toast.error(e.message, { toastId: "err-api" });
            setIsSubmitting(false);
          });
      }
    } else {
      toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error"});
    }    
  };

  const styleName = "ms-2 rounded border border-2 border-primary py-1 w-100";
  const styleNameError = "ms-2 rounded border border-2 border-danger py-1 w-100";

  return (
    <Dialog
      open={displayAddTax}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll={'body'}
      disablebackdropclick="true"
      disableEscapeKeyDown={true}
      style={{ height: "auto", maxHeight: "900px" }}
    >
      <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center ">
        Create a Sales Tax
        <i
          className="bi bi-x-lg"
          style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
          onClick={handleClose}
        ></i>
      </DialogTitle>
      <hr />
      <DialogContent className="px-5 mt-2">
        <div className="my-4">
          <div className="form-group row m-4">
            <label className="col-sm-4 fw-bold py-1 text-sm-start text-dark">Tax Name <span className="importent">*</span></label>
            <div className="col-sm-8">
              <Tooltip
                title={errorsMessage.tax_name}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.tax_name}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
                zIndex={9999}
              >
                <input
                  type="text"
                  className={`${errors.tax_name ? styleNameError : styleName}`}
                  name='tax_name'
                  value={formData.tax_name}
                  onChange={handleChange}
                />
                {errors.tax_name && <div className='mb-2'></div>}
              </Tooltip>
            </div>
          </div>
          <div className="form-group row m-4">
            <label className="col-sm-4 fw-bold py-1 text-sm-start text-dark">Description</label>
            <div className="col-sm-8">
              <textarea
                className="ms-2 rounded border border-2 border-primary py-1 w-100"
                name="description"
                value={formData.description}
                onChange={handleChange}
              ></textarea>
            </div>
          </div>
          <div className="form-group row m-4">
            <label className="col-sm-4 fw-bold py-1 text-sm-start text-dark">Tax Rate (%) <span className="importent">*</span></label>
            <div className="col-sm-8">
              <Tooltip
                title={errorsMessage.tax_rate}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.tax_rate}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
                zIndex={9999}
              >
                <input
                  type="text"
                  className={`${errors.tax_rate ? styleNameError : styleName}`}
                  name='tax_rate'
                  value={formData.tax_rate}
                  onChange={handleChange}
                />
                {errors.tax_rate && <div className='mb-1'></div>}
              </Tooltip>
              <br/>
              <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">Please provide a numerical value without including the percentage sign.</label>
            </div>
          </div>
        </div>
      </DialogContent>
      <hr />
      <DialogActions className='mb-3'>
        <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
          Cancel
        </button>
        <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
          {isSubmitting ? 'Uploading...' : 'Ok'}
        </button>
      </DialogActions>
    </Dialog>

  );
}