import Setting from "../Setting.sidebar";
import Sidebar from "../../Sidebar/Sidebar";
import { useState, useEffect } from 'react';
import '../Settings.css'
import ImageUploader from './ImageUploader';
import Invoice from '../../../assets/Invoice.jpg'
import axios from "axios";
import { toast } from 'react-toastify';
import County_Currency from "../../Utils/County_Currency.json"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import LoadingSpinner from '../../Utils/Loader'
import { Tooltip } from 'antd';
import { useNavigate } from "react-router-dom";
import { ChromePicker } from 'react-color';

export default function InvoiceCustomization() {
    const navigate = useNavigate();
    const user = localStorage.getItem("user");
    const businessID = user && JSON.parse(user)[0].business.id;
    const [selectedImage, setSelectedImage] = useState(null);
    const [findData, setFindData] = useState(false);
    const [cancelBtn, setCancelBtn] = useState(false);
    const [data, setData] = useState({
        business_id: "",
        prefix: "",
        transaction_number: "",
        logo: "",
        invoice_payment_term: "",
        invoice_default_title: "",
        invoice_default_subheading: "",
        invoice_default_footer: "",
        invoice_default_notes: "",
        estimate_default_title: "",
        estimate_default_subheading: "",
        estimate_payment_term: "",
        estimate_default_footer: "",
        estimate_default_notes: "",
    });
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorsMessage, setErrorsMessage] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isWarning, setIsWarning] = useState(false);
    const [month, setMonth] = useState('');
    const [date, setDate] = useState('');
    const [businessCurrency, setBusinessCurrency] = useState('');
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const d = new Date();
    let year = d.getFullYear();
    const daysInMonth = new Date(year, months.indexOf(month) + 1, 0).getDate();
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
    const currency = County_Currency.map((item) => (`${item.cc} - ${item.name} - ${item.symbol}`));

    const [selectedColor, setSelectedColor] = useState('#1E4C88');

    const handleColorChange = (color) => {
      setSelectedColor(color.hex);
    };

    const handleImageSelect = (imageFile) => {
        setSelectedImage(imageFile);
        setCancelBtn(true);
        setErrors(false);
    };

    const removeInputFields = () => {
        setSelectedImage(null);
        setCancelBtn(true);
        setErrors(false);
    };

    const handleConvert = async (imageLink) => {
        try {
          const response = await fetch(imageLink);
          const blobData = await response.blob();
    
          const convertedFile = new File([blobData], 'converted_image.png', {
            type: 'image/png',
          });
    
          setSelectedImage(convertedFile);
        } catch (error) {
            toast.error('Error converting image:', error);
        }
      };

    useEffect(() => {
        if(user === null){
            localStorage.removeItem("user");
            navigate('/error-page');
            }


        axios.get(`https://wavedemo.cydeztechnologies.com/list-invoice-by-id/${businessID}`)
            .then((res) => {
                if(res.data === "No data found"){
                    setLoading(true);
                }
                else if (res.data[0]) {
                    res.data[0] && setData(res.data[0]);
                    // res.data[0].logo && setSelectedImage(res.data[0].logo);
                    res.data[0].logo && handleConvert(`https://wavedemo.cydeztechnologies.com/${res.data[0].logo}`)
                    res.data[0].row_bgcolor && setSelectedColor(res.data[0].row_bgcolor);
                    res.data[0].business_currency && setBusinessCurrency(res.data[0].business_currency);
                    if (res.data[0].fiscal_year_end && res.data[0].fiscal_year_end !== null) {
                        const year = res.data[0].fiscal_year_end;
                        const substrings = year.split(" - ");
                        setMonth(substrings[0]);
                        setDate(substrings[1]);
                    }
                    setFindData(true);
                    setLoading(true);
                    setErrors(false);
                    if(res.data[0].transaction_number === 0){
                        setIsWarning(true);
                    }
                }
            })
            .catch((e) => {
                toast.error(e.message, {toastId:"error"});
            })


            // axios.get(`https://wavedemo.cydeztechnologies.com/color-customization/${businessID}`)
            // .then((res) => {
            //     if(res.data !== "No data found" && findLogo){
            //         res.data.logo && setSelectedImage(res.data.logo);
            //         console.log('logo api: ', res.data.logo)
            //     }
            // })
            // .catch((e) => {
            //     toast.error(e.message, {toastId:"error"});
            // })
    }, [!loading])

    function updateUser (businessCurrency) {
        const user = JSON.parse(localStorage.getItem('user'));
        user[0]['business_currency'] = businessCurrency
        localStorage.setItem('user', JSON.stringify(user));
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setCancelBtn(true);
        setErrors(false)
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('business_id', businessID);
        formData.append('prefix', data.prefix);
        formData.append('transaction_number', data.transaction_number);
        formData.append('logo', selectedImage !== null ? selectedImage : '');
        formData.append('row_bgcolor', selectedColor);
        formData.append('invoice_payment_term', data.invoice_payment_term);
        formData.append('invoice_default_title', data.invoice_default_title);
        formData.append('invoice_default_subheading', data.invoice_default_subheading ? data.invoice_default_subheading : "");
        formData.append('invoice_default_footer', data.invoice_default_footer ? data.invoice_default_footer : "");
        formData.append('invoice_default_notes', data.invoice_default_notes ? data.invoice_default_notes : "");
        formData.append('estimate_default_title', data.estimate_default_title);
        formData.append('estimate_default_subheading', data.estimate_default_subheading ? data.estimate_default_subheading : "");
        formData.append('estimate_payment_term', data.estimate_payment_term)
        formData.append('estimate_default_footer', data.estimate_default_footer ? data.estimate_default_footer : "");
        formData.append('estimate_default_notes', data.estimate_default_notes ? data.estimate_default_notes : "");
        formData.append('fiscal_year_end', `${month} - ${date}`);
        formData.append('business_currency', businessCurrency);

        const formLogo = new FormData();
        formLogo.append('logo', selectedImage !== null ? selectedImage : '');
        formLogo.append('color', selectedColor);


        const errors = {};
        const errorMessage = {};
        // key !== 'logo' &&
        for (const [key, value] of Object.entries(data)) {
            var name;
      if(key === "prefix"){name = "Prefix name"};
      if(key === "transaction_number"){name = "Starting invoice number"};
      if(key === "invoice_payment_term"){name = "Payment due period"};
      if(key === "invoice_default_title"){name = "Invoice title"};
      if(key === "estimate_default_title"){name = "Estimate title"};
      if(key === "estimate_payment_term"){name = "Estimate due period"}
            if (key !== 'logo' &&
                key !== 'row_bgcolor' &&
                key !== 'business_id' &&
                key !== 'invoice_default_subheading' &&
                key !== 'invoice_default_footer' &&
                key !== 'invoice_default_notes' &&
                key !== 'estimate_default_subheading' &&
                key !== 'estimate_default_footer' &&
                key !== 'estimate_default_notes' && 
                key !== 'fiscal_year_end') {
                if (!String(value).trim() || value === null) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`;
                }
                else if (key === "prefix" && !/^[A-Za-z]{2,5}$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = "Prefix must be 3-5 letters only accepted";
                }
                else if (key === "transaction_number" && !/^-?\d{1,5}(\.\d+)?$/.test(value) || value === 0) {
                    errors[key] = true;
                    errorMessage[key] = "Starting invoice number must be 1-5 Numbers only accepted";
                }
                else if (key === "invoice_default_title" && !/^[a-zA-Z\s]{3,150}$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = "Invoice title must be 3-150 letters";
                }
                else if (key === "estimate_default_title" && !/^[a-zA-Z\s]{3,150}$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = "Estimate title must be 3-150 letters";
                }
            }
        }
        // if(data.prefix && !/^[A-Za-z]+$/.test(data.prefix)){
        //     errors['prefix'] = true;
        // }
        // if(data.transaction_number && !/^-?\d+(\.\d+)?$/.test(data.transaction_number)){
        //     errors['transaction_number'] = true;
        // }
        if (month === '') {
            errors['month'] = true;
            errorMessage['month'] = "Month is required";
        }
        if (date === '') {
            errors['date'] = true;
            errorMessage['date'] = "Date is required";
        }
        if (businessCurrency === '' || businessCurrency === null) {
            errors['businessCurrency'] = true;
            errorMessage['businessCurrency'] = "Business currency is required";
        }
        // if (selectedImage === null) {
        //     errors['imagelogo'] = true;
        //     errorMessage['imagelogo'] = 'Logo is required.';
        // }

        if (selectedImage && selectedImage.size > 5 * 1024 * 1024) {
            errors['imagelogo'] = true;
            errorMessage['imagelogo'] = 'Image size must be at least 5MB.';
        } 

        const validateImage = (file) => {
            const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.img)$/i;
            return selectedImage && allowedExtensions.test(file.name);
          };

          if (selectedImage && !validateImage(selectedImage)) {
            errors['imagelogo'] = true;
            errorMessage['imagelogo'] = 'Invalid image format. Only image files are accepted.';
          }
        
        setErrors(errors);
        setErrorsMessage(errorMessage)

        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
            if (findData) {
                axios.put(`https://wavedemo.cydeztechnologies.com/edit-invoice-cutomization/${data.id}`, formData)
                    .then((res) => {
                        if (res.data) {
                            toast.success(res.data, {toastId:"ino-cus-1"});
                            updateUser(businessCurrency);
                            // window.location.reload();
                            setLoading(false);
                            setIsSubmitting(false);
                        }
                        else {
                            toast.error(res.data.trim(), {toastId:"ino-cus-1"});
                            setIsSubmitting(false);
                        }
                    })
                    .catch((e) => {
                        toast.error(e.message, {toastId:"err-api"});
                        setIsSubmitting(false);
                    });

                axios.put(`https://wavedemo.cydeztechnologies.com/color-customization/${businessID}`,formLogo)
                .catch((e) => {
                    toast.error(e.message, {toastId:"err-api"});
                    setIsSubmitting(false);
                });
            }
            // else {
            //     axios.post(`https://wavedemo.cydeztechnologies.com/add-invoice-cutomization`, formData)
            //         .then((res) => {
            //             if (res.data) {
            //                 toast.success(res.data);
            //                 updateUser(businessCurrency);
            //                 window.location.reload();
            //                 setIsSubmitting(false);
            //             }
            //             else {
            //                 toast.error(res.data.trim());
            //             }
            //         })
            //         .catch((e) => {
            //             console.log("Error in /list-invoice-by-id:", e);
            //             toast.error(e.message);
            //         });
            // };
            // console.log("testing")
        }
        else{
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error1"});
        }
    }

    const styleName = "ms-2 rounded border border-2 border-primary p-1 w-50";
    const styleNameError = "ms-2 rounded border border-2 border-danger p-1 w-50";

    const styles = {
        default: {
          picker: {
            width: '65%',
          },
          body: {
            padding: '12px',
          },
        },
      };

    const tooltipstyle = {fontSize: "10px", fontWeight:"bold"};
    const tooltipstyleerror = {fontSize: "10px", fontWeight:"bold",display:'relative' };
      

    return (
        <div>
            <Sidebar />
            <Setting />
            {loading ?
                <div className="column2 container my-5">
                    <div className="text-start fw-bold fs-3 text-capitalize">
                        Invoice Customization
                    </div>
                    <div>
                        <div className="text-start fw-bold fs-5 text-capitalize mt-3">
                            General settings
                        </div>
                        <div className="row">
                            <div className="col-4 text-end fw-bold me-5 mt-3"> Logo
                                {/* Logo <span className="importent">*</span> */}
                            </div>
                            <div className="col-6 mt-3">
                                <div>
                                    <Tooltip
                                        title={errorsMessage.imagelogo}
                                        placement="bottomLeft"
                                        color="#990000"
                                        trigger={"contextMenu"}
                                        open={errors.imagelogo} //selectedImage !== null ? false : true
                                        overlayStyle={errors.imagelogo ? tooltipstyleerror : tooltipstyle}
                                        autoAdjustOverflow={false}
                                    >
                                        {selectedImage === null ? <ImageUploader onImageSelect={handleImageSelect} /> : <></>}
                                        {!selectedImage && <><br /><br /></>}
                                    </Tooltip>
                                    {selectedImage && (
                                        <img src={typeof selectedImage === 'object' ? URL.createObjectURL(selectedImage) : `https://wavedemo.cydeztechnologies.com/${selectedImage}`} alt="Selected" style={{ maxWidth: "300px", maxHeight: "200px" }} />
                                    )}
                                    {selectedImage != null ?
                                        <div className="mt-2" onClick={removeInputFields}>
                                            <p className="remove-logo">Remove logo</p>
                                        </div> : <></>}
                                    {/* {errors.imagelogo && <span className="m-1 text-nowrap text-danger">{errors.imagelogo}<br /></span>} */}
                                </div>
                            </div>
                        </div>
                        <div className="row mb-5">
                            <div className="col-4 text-end fw-bold me-5 mt-3"> 
                                Color Picker
                            </div>
                            <div className="col-6 mt-3">
                                <ChromePicker
                                color={selectedColor}
                                onChange={handleColorChange}
                                disableAlpha={true}
                                styles={styles}
                                />
                            </div>
                        </div>
                        {Invoice && <div className="row">
                            <div className="col-4 text-end fw-bold me-5 mt-3">
                                Template
                            </div>
                            <div className="col-4 mt-3">
                                <div className="image-container" style={{ position: "relative", display: "inline-block" }}>
                                    {selectedImage && <img className="logo" src={typeof selectedImage === 'object' ? URL.createObjectURL(selectedImage) : `https://wavedemo.cydeztechnologies.com/${selectedImage}`} alt="logo" style={{ maxWidth: "60px", maxHeight: "60px", position: "absolute", top: "25px", left: "30px", zIndex: "1" }} />}
                                    <img src={Invoice} alt="invoice" style={{ width: "400px", height: "500px" }} />
                                    <div style={{display:"flex", alignItems:"center" ,backgroundColor: selectedColor, width: "100%", height: "15px", position: "absolute", top: "183.5px"}}>
                                        <div className="template-table-name" style={{marginLeft:"13px"}}>
                                            Items
                                        </div>
                                        <div className="template-table-name" style={{marginLeft:"173px"}}>
                                            Quantity
                                        </div>
                                        <div className="template-table-name" style={{marginLeft:"45px"}}>
                                            Price
                                        </div>
                                        <div className="template-table-name" style={{marginLeft:"47px"}}>
                                            Amount
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {/* <div className="form-group mt-5 text-center">
                        <button type="submit" className="btn btn-primary rounded-5">Save</button>
                        <button type="button" className="btn btn-white border rounded-5 border-primary mx-2">Cencel</button>
                    </div> */}
                    </div>
                    <hr />
                    <div>
                        <div className="text-start fw-bold fs-5 text-capitalize mt-3">
                            Invoice settings
                        </div>
                        <div className="my-4">
                            <div className="form-group row mb-2">
                                <label className="col-4 fw-bold text-end">Prefix <span className="importent">*</span></label>
                                <div className="col-8">
                                    <Tooltip
                                        title={errorsMessage.prefix}
                                        placement="bottom"
                                        color="#990000"
                                        trigger={"contextMenu"}
                                        open={errors.prefix}
                                        overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                        autoAdjustOverflow={false}
                                    >
                                        <input
                                            className={`${errors.prefix ? styleNameError : styleName}`}
                                            placeholder="example abc..."
                                            type="text"
                                            name="prefix"
                                            value={data.prefix}
                                            onChange={handleChange}
                                        />
                                        {errors.prefix && <div className="mb-1"></div>}
                                    </Tooltip>
                                    <br />
                                    <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">This will be displayed as a prefix in invoice number of each invoice.</label>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-4 fw-bold text-end">Starting Invoice No <span className="importent">*</span></label>
                                <div className="col-8">
                                    <Tooltip
                                        title={errorsMessage.transaction_number}
                                        placement="bottom"
                                        color="#990000"
                                        trigger={"contextMenu"}
                                        open={errors.transaction_number}
                                        overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                        autoAdjustOverflow={false}
                                    >
                                        <input
                                            className={`${errors.transaction_number ? styleNameError : styleName}`}
                                            placeholder="example 123..."
                                            type="text"
                                            name="transaction_number" value={data.transaction_number}
                                            // value={data.transaction_number == 0 ? "" : data.transaction_number}
                                            onChange={handleChange}
                                            // disabled={isWarning || data.transaction_number !== null}
                                        />
                                        {errors.transaction_number && <div className="mb-1"></div>}
                                    </Tooltip>
                                    <br />
                                    {isWarning && <div style={{ fontSize: "13px" }} className="ms-2 fw-bold text-warning">
                                        <i className="bi bi-exclamation-triangle-fill text-warning fs-5 me-2"></i> 
                                        Please note that the starting invoice number can only be<br />
                                        used once and cannot be edited or updated afterwards.</div>}
                                    <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">This will be displayed as a starting number in invoice number of each invoice.</label>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-4 fw-bold text-end">Default Payment Terms <span className="importent">*</span></label>
                                <div className="col-8">
                                    {/* <input type="text" className="ms-2 rounded border border-2 border-primary p-1 w-50"/>  */}
                                    <Tooltip
                                        title={errorsMessage.invoice_payment_term}
                                        placement="bottom"
                                        color="#990000"
                                        trigger={"contextMenu"}
                                        open={errors.invoice_payment_term}
                                        overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                        autoAdjustOverflow={false}
                                    >
                                        <select
                                            className={`${errors.invoice_payment_term ? styleNameError : styleName}`}
                                            name="invoice_payment_term"
                                            value={data.invoice_payment_term}
                                            onChange={handleChange}
                                        >
                                            <option name="" value="" style={{ display: "none" }}> Select Due Date </option>
                                            {/* <option value="Due upon receipt">Due upon receipt</option> */}
                                            <option value="15">Due within 15 Days</option>
                                            <option value="30">Due within 30 Days</option>
                                            <option value="45">Due within 45 Days</option>
                                            <option value="60">Due within 60 Days</option>
                                            <option value="90">Due within 90 Days</option>
                                        </select>
                                        {errors.invoice_payment_term && <div className="mb-1"></div>}
                                    </Tooltip>
                                    <br />
                                    <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">Does not apply to recurring invoices.</label>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-4 fw-bold text-end">Default Title <span className="importent">*</span></label>
                                <div className="col-8">
                                    <Tooltip
                                        title={errorsMessage.invoice_default_title}
                                        placement="bottom"
                                        color="#990000"
                                        trigger={"contextMenu"}
                                        open={errors.invoice_default_title}
                                        overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                        autoAdjustOverflow={false}
                                    >
                                        <input
                                            className={`${errors.invoice_default_title ? styleNameError : styleName}`}
                                            type="text"
                                            name="invoice_default_title"
                                            value={data.invoice_default_title}
                                            onChange={handleChange}
                                        />
                                        {errors.invoice_default_title && <div className="mb-1"></div>}
                                    </Tooltip>
                                    <br />
                                    <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">The default title for all invoices. You can change this on each invoice.</label>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-4 fw-bold text-end">Default Subheading</label>
                                <div className="col-8">
                                    <input
                                        type="text"
                                        className="ms-2 rounded border border-2 border-primary p-1 w-50"
                                        name="invoice_default_subheading"
                                        value={data.invoice_default_subheading}
                                        onChange={handleChange}
                                    /> <br />
                                    <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">This will be displayed below the title of each invoice. Useful for things like ABN numbers.</label>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-4 fw-bold text-end">Default Footer</label>
                                <div className="col-8">
                                    <input
                                        type="text"
                                        className="ms-2 rounded border border-2 border-primary p-1 w-50"
                                        name="invoice_default_footer"
                                        value={data.invoice_default_footer}
                                        onChange={handleChange}
                                    /> <br />
                                    <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">This will be displayed at the bottom of each invoice.</label>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-4 fw-bold text-end">Default Notes / Terms</label>
                                <div className="col-8">
                                    <textarea
                                        type="text"
                                        className="ms-2 rounded border border-2 border-primary p-1 w-50"
                                        label="invoice notes and terms"
                                        name="invoice_default_notes"
                                        value={data.invoice_default_notes}
                                        onChange={handleChange}
                                    ></textarea> <br />
                                    <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">Appears on each invoice. You can choose to override it when you create an invoice.</label>
                                </div>
                            </div>
                            {/* <div className="form-group mt-5 text-center">
                            <button type="submit" className="btn btn-primary rounded-5">Save</button>
                            <button type="button" className="btn btn-white border rounded-5 border-primary mx-2">Cencel</button>
                        </div> */}
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className="text-start fw-bold fs-5 text-capitalize mt-3">
                            Estimate settings
                        </div>
                        <div className="my-4">
                            <div className="form-group row mb-2">
                                <label className="col-4 fw-bold text-end">Default Title <span className="importent">*</span></label>
                                <div className="col-8">
                                    <Tooltip
                                        title={errorsMessage.estimate_default_title}
                                        placement="bottom"
                                        color="#990000"
                                        trigger={"contextMenu"}
                                        open={errors.estimate_default_title}
                                        overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                        autoAdjustOverflow={false}
                                    >
                                        <input
                                            className={`${errors.estimate_default_title ? styleNameError : styleName}`}
                                            type="text"
                                            name="estimate_default_title"
                                            value={data.estimate_default_title}
                                            onChange={handleChange}
                                        />
                                        {errors.estimate_default_title && <div className="mb-1"></div>}
                                    </Tooltip>
                                    <br />
                                    <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">The default title for all estimates. You can change this on each estimate.</label>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-4 fw-bold text-end">Default Subheading</label>
                                <div className="col-8">
                                    <input
                                        type="text"
                                        className="ms-2 rounded border border-2 border-primary p-1 w-50"
                                        name="estimate_default_subheading"
                                        value={data.estimate_default_subheading}
                                        onChange={handleChange}
                                    /> <br />
                                    <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">This will be displayed below the title of each estimate. Useful for things like ABN numbers.</label>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-4 fw-bold text-end">Default Estimate due Terms <span className="importent">*</span></label>
                                <div className="col-8">
                                    {/* <input type="text" className="ms-2 rounded border border-2 border-primary p-1 w-50"/>  */}
                                    <Tooltip
                                        title={errorsMessage.estimate_payment_term}
                                        placement="bottom"
                                        color="#990000"
                                        trigger={"contextMenu"}
                                        open={errors.estimate_payment_term}
                                        overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                        autoAdjustOverflow={false}
                                    >
                                        <select
                                            className={`${errors.estimate_payment_term ? styleNameError : styleName}`}
                                            name="estimate_payment_term"
                                            value={data.estimate_payment_term}
                                            onChange={handleChange}
                                        >
                                            <option name="" value="" style={{ display: "none" }}> Select Due Date </option>
                                            {/* <option value="Due upon receipt">Due upon receipt</option> */}
                                            <option value="15">Due within 15 Days</option>
                                            <option value="30">Due within 30 Days</option>
                                            <option value="45">Due within 45 Days</option>
                                            <option value="60">Due within 60 Days</option>
                                            <option value="90">Due within 90 Days</option>
                                        </select>
                                        {errors.estimate_payment_term && <div className="mb-1"></div>}
                                    </Tooltip>
                                    <br />
                                    <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">Does not apply to recurring estimate.</label>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-4 fw-bold text-end">Default Footer</label>
                                <div className="col-8">
                                    <input
                                        type="text"
                                        className="ms-2 rounded border border-2 border-primary p-1 w-50"
                                        name="estimate_default_footer"
                                        value={data.estimate_default_footer}
                                        onChange={handleChange}
                                    /> <br />
                                    <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">This will be displayed at the bottom of each estimate.</label>
                                </div>
                            </div>
                            <div className="form-group row mb-2">
                                <label className="col-4 fw-bold text-end">Default Notes / Terms</label>
                                <div className="col-8">
                                    <textarea
                                        type="text"
                                        className="ms-2 rounded border border-2 border-primary p-1 w-50"
                                        name="estimate_default_notes"
                                        value={data.estimate_default_notes}
                                        onChange={handleChange}
                                    ></textarea> <br />
                                    <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">Appears on each estimate. You can choose to override it when you create an estimate.</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <div className="text-start fw-bold fs-5 text-capitalize mt-3">
                            Dates & Currency
                        </div>
                        <div className="my-4">
                            <div className="form-group row my-4">
                                <label className="col-4 fw-bold text-end">Fiscal Year End <span className="importent">*</span> </label>
                                <div className="col-8">
                                    <div className="input-group">
                                        <>
                                            <Tooltip
                                                title={errorsMessage.month}
                                                placement="bottom"
                                                color="#990000"
                                                trigger={"contextMenu"}
                                                open={errors.month}
                                                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                                autoAdjustOverflow={false}
                                            >
                                                <select
                                                    className={`${errors.month ? "ms-2 rounded border border-2 border-danger p-1" : "ms-2 rounded border border-2 border-primary p-1"}`}
                                                    name="month"
                                                    value={month}
                                                    onChange={(e) => { setMonth(e.target.value) }}
                                                >
                                                    <option name="" value="" > Month </option>
                                                    {months.map((month) => (
                                                        <option key={month} value={month}>
                                                            {month}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Tooltip>
                                        </>
                                        <>
                                            <Tooltip
                                                title={errorsMessage.date}
                                                placement="bottom"
                                                color="#990000"
                                                trigger={"contextMenu"}
                                                open={errors.date}
                                                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                                autoAdjustOverflow={false}
                                            >
                                                <select
                                                    className={`${errors.date ? "ms-2 rounded border border-2 border-danger p-1" : "ms-2 rounded border border-2 border-primary p-1"}`}
                                                    name="date"
                                                    value={date}
                                                    onChange={(e) => { setDate(e.target.value) }}
                                                >
                                                    <option name="" value="" > Day </option>
                                                    {days.map((day) => (
                                                        <option key={day} value={day}>
                                                            {day}
                                                        </option>
                                                    ))}
                                                </select>
                                            </Tooltip>
                                        </>
                                    </div>
                                </div>
                            </div>
                            {(errors.month || errors.date) && <div className="mb-5"></div>}
                            {/* {errors.date && <><br /></>} */}
                            <div className="form-group row my-4">
                                <label className="col-4 fw-bold text-end text-nowrap">Business Currency <span className="importent">*</span> </label>
                                <div className="col-8">
                                    {/* <input type="text" className="ms-2 rounded border border-2 border-primary py-1 w-100"/> */}
                                    <div className="">
                                        <Tooltip
                                            title={errorsMessage.businessCurrency}
                                            placement="bottomLeft"
                                            color="#990000"
                                            trigger={"contextMenu"}
                                            open={errors.businessCurrency}
                                            overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                            autoAdjustOverflow={false}
                                        >
                                            <Autocomplete
                                                disablePortal
                                                sx={{
                                                    '& input': {
                                                        height: 0,
                                                    },
                                                }}
                                                className={`${errors.businessCurrency ? "border border-2 rounded border-danger ms-2 w-50" : "border border-2 rounded border-primary ms-2 w-50"}`}
                                                value={businessCurrency}
                                                onChange={(event, newValue) => {
                                                    setBusinessCurrency(newValue);
                                                }}
                                                options={currency}
                                                disabled={true}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            {errors.businessCurrency && <div className="mb-1"></div>}
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group mt-5 text-center">
                        <button onClick={handleSubmit} disabled={isSubmitting} type="submit" className="btn btn-primary rounded-5">{isSubmitting ? 'Uploading...' : findData ? "Update" : "Save"}</button>
                        {cancelBtn ? <button onClick={() => setLoading(false)} type="button" className="btn btn-white border rounded-5 border-primary mx-2">Cancel</button> :
                            <button disabled type="button" className="btn btn-white border rounded-5 border-primary mx-2">Cancel</button>}
                    </div>
                </div>
                :
                <LoadingSpinner />}
        </div>
    )
}