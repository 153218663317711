import React, { useState } from 'react'
import './User_Register.css'
import { Link } from 'react-router-dom';
import RegIllus from '../../assets/reg-illus.svg'
import logo from '../../assets/wave-logo.svg'
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import County_Currency from "../Utils/County_Currency.json"
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Tooltip } from 'antd';
import Home_Navbar from '../Home/Home_Navbar';

function User_Register() {
  const navigate = useNavigate();
  const location = useLocation();
  const [passtype, setPassType] = useState('password');

  const homeEmail = location.state ? location.state.email : "";
  const homePassword = location.state ? location.state.password : "";


  const [input, setInput] = useState({
    first_name: "",
    last_name: "",
    email: homeEmail,
    business_name: "",
    businessCurrency: "",
    password: homePassword,
    role: 1,
  });
  const currency = County_Currency.map((item) => (`${item.cc} - ${item.name} - ${item.symbol}`));

  const [errors, setErrors] = useState({});
  const [errorsMessage, setErrorsMessage] = useState({});


  //prevent form submitting
  function handleSubmit(e) {
    e.preventDefault();
  }
  const handleInput = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: false });
    //    setErrors(false);
  };


  const doRegister = () => {
    const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    const errors = {};
    const errorMessage = {};

    for (const [key, value] of Object.entries(input)) {
      var name;
      if (key === "first_name") { name = "First name" };
      if (key === "last_name") { name = "Last name" };
      if (key === "email") { name = "Email" };
      if (key === "password") { name = "Password" };
      if (key === "business_name") { name = "Business name" };
      if (key === "businessCurrency") { name = "Business currency" };
      if (!String(value).trim()) {
        errors[key] = true;
        errorMessage[key] = `${name} is required`;
      }
      else if (key === "first_name" && !/^[a-zA-Z]{3,25}$/.test(value)) {
        errors[key] = true;
        errorMessage[key] = "First name must be 3-25 letters";
      }
      else if (key === "last_name" && !/^[a-zA-Z\s]{1,25}$/.test(value)) {
        errors[key] = true;
        errorMessage[key] = "Last name must be 1-25 letters";
      }
      else if (key === "email" && !/\S+@\S+\.\S+/.test(value)) {
        errors[key] = true;
        errorMessage[key] = `${name} is invalid`
      }
      else if (key === "business_name" && !/^[a-zA-Z\s]+$/.test(value)) {
        errors[key] = true;
        errorMessage[key] = "Business name must only contain letters and spaces";
      }
      else if (key === "business_name" && !/^[a-zA-Z\s]{1,25}$/.test(value)) {
        errors[key] = true;
        errorMessage[key] = "Business name must be 1-25 characters";
      }
      else if (key === "password" && !/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-_+=~`[\]{}|:;"'<>,.?/]{8,}$/.test(value)) {
        errors[key] = true;
        errorMessage[key] = `Password must be one letter, digit, special character and at least 8 characters long`
      }
    }

    setErrors(errors);
    setErrorsMessage(errorMessage);

    if (Object.keys(errors).length === 0) {

      //business registration
      axios.post('https://wavedemo.cydeztechnologies.com/business-registration', {
        business_name: input.business_name
      }).then((res) => {
        if (res.data.id) {
          newUserRegistration(res.data.id); // new user registration with bussiness id
        } else {
          toast.error('Business Registration failed', { toastId: 'reg1', })
        }
      }).catch(error => {
        toast.error('Something went wrong', { toastId: 'reg2', })
      })
    }
    else {
      toast.error(`Please check ${errorMessage && Object.entries(errorMessage)[0][1]}`, { toastId: "error-1" });
    }

  }
  //user registration
  const newUserRegistration = (business_id) => {
    axios.post('https://wavedemo.cydeztechnologies.com/user-register', {
      business_id: business_id,
      first_name: input.first_name,
      last_name: input.last_name,
      email: input.email,
      password: input.password,
      role: input.role,
      business_currency: input.businessCurrency,
    }).then((res) => {

      if (res.data == "User has been created") {
        toast.success("User has been registered successfully", { toastId: "adduser" });
        navigate('/user-login', {
          state: {
            email: input.email,
          }
        })
      } else {
        toast.error(res.data, { toastId: 'reg3', });
        axios.delete(`https://wavedemo.cydeztechnologies.com/business-delete/${business_id}`);
      }
    }).catch(error => {
      toast.error('Something went wrong', { toastId: 'reg4', });
      axios.delete(`https://wavedemo.cydeztechnologies.com/business-delete/${business_id}`);
    })

  }

  //password hide & seek toggle
  const handleToggle = () => {
    passtype === 'password' ? setPassType('text') : setPassType('password')
  }

//https://wavedemo.cydeztechnologies.com/business-delete/4(business
  return (
    <section className='w-100' style={{backgroundColor:"#f9f4f1"}}>
      <Home_Navbar/>
      <div className="container-fluid vh-100 " >
        <div className="row px-0 px-sm-4">
          <div className="col -12 col-md-6 d-none d-sm-block" >
            <div className="register-content-section m-5 "  >
              <div className="reg-content-title ">
                <h1 className='mb-3'>Rule your billing <br />domain</h1> {/*Be the boss of your <br />billing */}
                <p className='text-muted' >Streamline your billing effortlessly with our robust solution.</p> {/*Experience hassle-free billing with our powerful solution */}
              </div>
              <div className="reg-content-img  mb-2 text-center">
                <img src={RegIllus} alt="Registration" width="350px" height="350px" />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="register-input-section  mx-2 my-4 m-sm-5">
              <div className="reg-input-container ">
                {/* <div className="reg-header  mb-4 text-center ">
                  <img src={logo} alt="Logo" width="150px" height="35px" />
                </div> */}
                <div className="reg-form">
                  <h3 className=' mb-4 reg-form-title'>Sign up</h3>
                  <form onSubmit={handleSubmit}>
                    <div class="row  mb-3 gap-3 gap-sm-0 ">
                      <div className="form-group col-md-6 ">
                        <label className="labels">First Name</label> <span className="importent">*</span>
                        <Tooltip
                          title={errorsMessage.first_name}
                          placement="bottom"
                          color="#990000"
                          trigger={"contextMenu"}
                          open={errors.first_name}
                          overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                          autoAdjustOverflow={false}
                        >
                          <input
                            type="text"
                            name="first_name"
                            className={`form-control sgn-input ${errors.first_name && 'border border-danger'}`}
                            placeholder="Your First Name"
                            id="name"
                            // required
                            onChange={handleInput} />
                        </Tooltip>
                      </div>
                      <div className="form-group col-md-6 ">
                        <label className="labels">Last Name</label> <span className="importent">*</span>
                        <Tooltip
                          title={errorsMessage.last_name}
                          placement="bottom"
                          color="#990000"
                          trigger={"contextMenu"}
                          open={errors.last_name}
                          overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                          autoAdjustOverflow={false}
                        >
                          <input
                            type="text"
                            name="last_name"
                            className={`form-control sgn-input ${errors.last_name && 'border border-danger'}`}
                            placeholder="Your Last Name"
                            id="last_name"
                            // required
                            onChange={handleInput} />
                        </Tooltip>
                      </div>

                    </div>
                    <div className="form-group   mb-3">
                      <label className="labels">Email</label> <span className="importent">*</span>
                      <Tooltip
                        title={errorsMessage.email}
                        placement="bottom"
                        color="#990000"
                        trigger={"contextMenu"}
                        open={errors.email}
                        overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                        autoAdjustOverflow={false}
                      >
                        <input
                          type="email"
                          name="email"
                          className={`form-control sgn-input ${errors.email && 'border border-danger'}`}
                          placeholder="Your Email"
                          id="email"
                          defaultValue={homeEmail ? homeEmail : ''}
                          // required
                          onChange={handleInput} />
                      </Tooltip>
                    </div>

                    <div className="form-group mb-3">
                      <label className="labels">Business Name</label> <span className="importent">*</span>
                      <Tooltip
                        title={errorsMessage.business_name}
                        placement="bottom"
                        color="#990000"
                        trigger={"contextMenu"}
                        open={errors.business_name}
                        overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                        autoAdjustOverflow={false}
                      >
                        <input
                          type="text"
                          name="business_name"
                          className={`form-control sgn-input ${errors.business_name && 'border border-danger'}`}
                          placeholder="Your Business Name"
                          id="business_name"
                          // required
                          onChange={handleInput} />
                      </Tooltip>
                    </div>
                    <div className="form-group mb-3">
                      <label className="labels">Business Currency</label> <span className="importent">*</span>
                      <Tooltip
                        title={errorsMessage.businessCurrency}
                        placement="bottom"
                        color="#990000"
                        trigger={"contextMenu"}
                        open={errors.businessCurrency}
                        overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                        autoAdjustOverflow={false}
                      >
                        <Autocomplete
                          className={`sgn-input-autocomplete ${errors.businessCurrency && 'border border-danger'}`}

                          disablePortal
                          sx={{
                            '& input': {
                              height: 8,

                            },
                            "&.Mui-focused .MuiInputLabel-outlined": {
                              color: "purple"
                            },
                            '& .MuiAutocomplete-option': {
                              '&:hover': {
                                backgroundColor: 'transparent',
                              },
                            },
                            '& .MuiAutocomplete-input:focus': {
                              backgroundColor: 'transparent',
                              boxShadow: 'none',
                            },

                          }}
                          value={input.businessCurrency}
                          onChange={(event, newValue) => {
                            setInput({ ...input, businessCurrency: newValue });
                            setErrors({ ...errors, businessCurrency: false });
                          }}
                          options={currency}
                          renderInput={(params) => <TextField {...params}
                            placeholder="Your Business's Curreny"
                            // required
                            InputProps={{
                              ...params.InputProps,
                              style: {
                                fontFamily: 'Nunito Sans, sans-serif',
                                fontSize: '18px'// Adjust the desired font family
                              },
                            }} />}
                        />
                      </Tooltip>
                    </div>
                    <div className="form-group mb-3" style={{ position: 'relative' }}>
                      <label className="labels">Password</label> <span className="importent">*</span>
                      <Tooltip
                        title={errorsMessage.password}
                        placement="bottom"
                        color="#990000"
                        trigger={"contextMenu"}
                        open={errors.password}
                        overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                        autoAdjustOverflow={false}
                      >
                        <div style={{ position: 'relative' }}>
                          <input
                            type={passtype}
                            name="password"
                            className={`form-control sgn-input ${errors.password && 'border border-danger'}`}
                            placeholder="Your Password"
                            id="password"
                            // required
                            defaultValue={homePassword ? homePassword : ''}
                            onChange={handleInput} />
                          <i
                            className={
                              passtype === "password" ? "fa fa-eye-slash" : "fa fa-eye"
                            }
                            style={{ position: "absolute", top: "35%", right: "2.5%", color: "#d5cdcd", cursor: "pointer" }}
                            id="sgn-pass-tog"
                            onClick={handleToggle}
                          ></i>
                        </div>
                        {errors.password && <><br /></>}
                      </Tooltip>
                    </div>
                    <div className="form-group mb-3 ">
                      <button className='btn btn-primary w-100 reg-btn'
                        onClick={() => doRegister()}
                      >Get Started</button>
                    </div>
                    <div className="form-group mb-3 text-center" >

                      <span className="text-black me-2 text-muted" style={{ fontSize: '14px' }} >Already have an account?
                        <Link className="text-decoration-none" to="/user-login">
                          <span className="signLink">Sign in now</span>
                        </Link>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default User_Register