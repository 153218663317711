import SidebarItem from "./SidebarItem"
import items from "./SidebarData.json"
import LogoutPopup from './logout';
import { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export default function Sidebar() {
  const [open, setOpen] = useState(false);
  const user = localStorage.getItem("user");
  const userName = user && JSON.parse(user)[0].first_name;
  const role = user && JSON.parse(user)[0].role;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setScreenWidth(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  // localStorage.setItem('screenWidth',screenWidth)

  let userSidebar;

  if (role === 3) {
    // userSidebar = items.filter((item) => {
    //   if (item.title === "Dashboard" || item.title === "Sales & Payments" || item.title === "Accounting") {
    //     if (item.title === "Sales & Payments") {
    //       item.childrens = item.childrens.filter((child) => child.title !== "Product & Services");
    //       return true;
    //     } else {
    //       return true;
    //     }
    //   }
    //   return false;
    // });
    userSidebar = [
      {
        "title": "Dashboard",
        "icon": "bi-speedometer2",
        "path": "/dashboard"
      },
      {
        "title": "Sales & Payments",
        "icon": "bi-credit-card",
        "childrens": [
          {
            "title": "Estimates",
            "path": "/estimate",
            "parent": true
          },
          {
            "title": "Invoices",
            "path": "/invoices",
            "parent": true
          },
          {
            "title": "Customers",
            "path": "/customers",
            "parent": true
          }
        ]
      },
      {
        "title": "Accounting",
        "icon": "bi bi-wallet",
        "accounting_childrens": [
          {
            "title": "Transaction",
            "path": "/accounting-payment-transaction",
            "parent": true
          },
          {
            "title": "Expenses",
            "path": "/accounting-expenses",
            "parent": true
          },
          {
            "title": "Export Data",
            "path": "/accounting-export-your-data",
            "parent": true
          }
        ]
      },
    ];
  }
  else {
    userSidebar = items;
  }

  return (
    <>
      {role === 3 ?
        screenWidth > 900 ?
          <div className="sidebar">
            <div className="sidebar-header-title">
              <h5>{userName}</h5>
            </div >
            <div className="ps-1">
              {/* <div >
                <NavLink to="/dashboard" className="nav-link  sidebar-item plain">
                  <i className="bi-speedometer2"></i>
                  Dashboard
                </NavLink>
                <NavLink to="/estimate" className="nav-link  sidebar-item plain">
                  <i className="bi bi-card-text"></i>
                  Estimates
                </NavLink>
                <NavLink to="/invoices" className="nav-link  sidebar-item plain">
                  <i className="bi bi-receipt-cutoff"></i>
                  Invoices
                </NavLink>
                <NavLink to="/accounting-payment-transaction" className="nav-link  sidebar-item plain">
                  <i className="bi bi-cash-stack"></i>
                  Transaction
                </NavLink>
                <NavLink to="/accounting-expenses" className="nav-link  sidebar-item plain">
                  <i className="bi bi-calculator"></i>
                  Expenses
                </NavLink>
                <NavLink to="/accounting-export-your-data" className="nav-link  sidebar-item plain">
                  <i className="bi bi-calculator"></i>
                  Export Data
                </NavLink>
                <Link onClick={() => setOpen(true)} style={{ cursor: "pointer", textDecoration: "none" }} className="sidebar-item plain">
                  <i className="bi bi-box-arrow-right"></i>
                  Logout
                </Link>
                <LogoutPopup isOpen={open} onClose={() => { setOpen(false) }} />
              </div> */}
              <div className="ps-1"> {userSidebar.map((item, index) => <SidebarItem key={index} item={item} />)}
              </div>
              <div className="ps-1">
                <div >
                  <span onClick={() => setOpen(true)} style={{ cursor: "pointer", textDecoration: "none" }} className="sidebar-item plain">
                    <i className="bi bi-box-arrow-right"></i>
                    Logout
                  </span>
                  <LogoutPopup isOpen={open} onClose={() => { setOpen(false) }} />
                </div>
              </div>
            </div>
          </div>
          :
          <div style={{ borderBottom: "1px solid #aba7a7" }}>
            <Navbar bg="light" expand="lg" className='sticky-top px-3'>
              <Navbar.Toggle aria-controls="basic-navbar-nav" className="text-start toggle-custom"><i className="bi bi-list fs-1"></i></Navbar.Toggle>
              <Navbar.Brand style={{ marginRight: "auto", marginLeft: "15px", paddingLeft: "15px", borderLeft: "1px solid #aba7a7", fontWeight: "bold" }}>{userName}</Navbar.Brand>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto px-3">
                  <NavLink to="/dashboard" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                    Dashboard
                  </NavLink>

                  <NavDropdown title="Sales & Payments" id="sidebar-dropdown" >
                    <NavDropdown.Item as={NavLink} to="/estimate" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Estimates</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/invoices" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Invoices</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/customers" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Customers</NavDropdown.Item>
                  </NavDropdown>

                  <NavDropdown title="Accounting" id="sidebar-dropdown" >
                    <NavDropdown.Item as={NavLink} to="/accounting-payment-transaction" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Transaction</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/accounting-expenses" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Expenses</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/accounting-export-your-data" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Export Data</NavDropdown.Item>
                  </NavDropdown>

                  <span onClick={() => setOpen(true)} className={'sidebarLink'}>
                    Logout
                  </span>
                  <LogoutPopup isOpen={open} onClose={() => { setOpen(false) }} />
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
        :
        screenWidth > 900 ?
          <div className="sidebar">
            <div className="sidebar-header-title">
              <h5>{userName}</h5>
            </div >
            <div className="ps-1"> {userSidebar.map((item, index) => <SidebarItem key={index} item={item} />)}
            </div>
            <div className="ps-1">
              <div >
                <span onClick={() => setOpen(true)} style={{ cursor: "pointer", textDecoration: "none" }} className="sidebar-item plain">
                  <i className="bi bi-box-arrow-right"></i>
                  Logout
                </span>
                <LogoutPopup isOpen={open} onClose={() => { setOpen(false) }} />
              </div>
            </div>
          </div>
          :
          <div style={{ borderBottom: "1px solid #aba7a7" }}>
            <Navbar bg="light" expand="lg" className='sticky-top px-3'>
              <Navbar.Toggle aria-controls="basic-navbar-nav" className="text-start toggle-custom"><i className="bi bi-list fs-1"></i></Navbar.Toggle>
              <Navbar.Brand style={{ marginRight: "auto", marginLeft: "15px", paddingLeft: "15px", borderLeft: "1px solid #aba7a7", fontWeight: "bold" }}>{userName}</Navbar.Brand>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="mr-auto px-3">
                  <NavLink to="/dashboard" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                    Dashboard
                  </NavLink>
                  <NavDropdown title="Sales & Payments" id="sidebar-dropdown" >
                    <NavDropdown.Item as={NavLink} to="/estimate" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Estimates</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/invoices" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Invoices</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/customers" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Customers</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/product-&-services" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Product & Services</NavDropdown.Item>
                  </NavDropdown>
                  <NavDropdown title="Accounting" id="sidebar-dropdown" >
                    <NavDropdown.Item as={NavLink} to="/accounting-payment-transaction" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Transaction</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/accounting-expenses" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Expenses</NavDropdown.Item>
                    <NavDropdown.Item as={NavLink} to="/accounting-export-your-data" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>Export Data</NavDropdown.Item>
                  </NavDropdown>
                  <NavLink to="/settings/users" className={({ isActive }) => isActive ? 'activeSidebarLink' : 'sidebarLink'}>
                    Settings
                  </NavLink>
                  <span onClick={() => setOpen(true)} className={'sidebarLink'}>
                    Logout
                  </span>
                  <LogoutPopup isOpen={open} onClose={() => { setOpen(false) }} />
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </div>
      }
    </>
  )
}