import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import County_Currency from "../../../Utils/County_Currency.json";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Tooltip } from 'antd';

export default function AddCurrency(props) {
    const { displayAddCurrency, waveCurrency,isOpen, onClose } = props;
    const [_isOpen, setOpen] = useState(isOpen);
    const user = localStorage.getItem("user");
    const businessID = JSON.parse(user)[0].business.id;
    const businessCurrency = JSON.parse(user)[0].business_currency.toString().split(" - ")[1];
    const [errors, setErrors] = useState('');
    const [errorsMessage, setErrorsMessage] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [currencyList, setCurrencyList] = useState('');    
    const allcurrency = County_Currency.filter((item1) => !waveCurrency.some((item2) => item2.currency_name === item1.name));
    const currency = allcurrency.map((item) => (`${item.cc} - ${item.name}`));

    const [addCurrency, setAddCurrency] = useState({
        business_id: "",
        currency_name: "",
        symbol: "",
        exchange_rate: "",
        code: ""
    });

    useEffect(() => {
        County_Currency.forEach((item) => {
            if (currencyList && currencyList.includes(item.name)) {
                setAddCurrency({ ...addCurrency, currency_name: item.name, symbol: item.symbol, code: item.cc })
            }
            else if (currencyList === '' || currencyList === null) {
                setAddCurrency({ ...addCurrency, currency_name: '', symbol: '', code: '' })
            }
        });
    }, [currencyList])


    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
        setErrors(false);
        setAddCurrency({business_id: "",
        currency_name: "",
        symbol: "",
        exchange_rate: "",
        code: ""});
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setAddCurrency((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};

        for (const [key, value] of Object.entries(addCurrency)) {
            var name;
      if(key === "currency_name"){name = "Currency name"};
      if(key === "exchange_rate"){name = "Exchange rate"};
            if (key !== 'business_id') {
                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`
                }
                else if (key === "exchange_rate" && isNaN(value)) {
                    errors[key] = true;
                    errorMessage[key] = `Exchange rate is invalid. Must be a number`
                  }
                else if (key === "exchange_rate" && !/^-?\d*(?:\.\d{1,5})?$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = `Exchange rate is invalid must be less then 5 decimal only accepted`
                  }
            }
        }
        

        if (!addCurrency.currency_name || addCurrency.currency_name === null) {
            errors['currency_name'] = true;
            errorMessage['currency_name'] = `Currency name is required`
        }


        setErrors(errors);
        setErrorsMessage(errorMessage);
        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
            axios.post(`https://wavedemo.cydeztechnologies.com/currencies/create`, {
                business_id: businessID,
                currency_name: addCurrency.currency_name,
                symbol: addCurrency.symbol,
                exchange_rate: addCurrency.exchange_rate,
                code: addCurrency.code
            })
                .then((res) => {
                    if (res.data !== "Currency Already Exists!") {
                        toast.success("Currency added successfully", {toastId:"addcus"});
                        setOpen(false);
                        if (onClose) onClose();
                        setErrors(false);
                        setIsSubmitting(false);
                        setAddCurrency({});
                    }
                    else if(res.data === "Currency Already Exists!"){
                        toast.warning("Currency Already Exists!", {toastId:"addcus-1"});
                        setIsSubmitting(false);
                    }
                    else {
                        toast.error(res.data.trim(), {toastId:"addcus-1"});
                    }
                })
                .catch((e) => {
                    toast.error(e.message, {toastId:"err-api"});
                    setIsSubmitting(false);
                });
        }
        else{
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error"});
        }
    }

    const styleName = "ms-2 rounded border border-2 border-primary w-100";
    const styleNameError = "ms-2 rounded border border-2 border-danger w-100";

    return (
        <Dialog
            open={displayAddCurrency}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll={'body'}
            disablebackdropclick="true"
            disableEscapeKeyDown={true}
            fullWidth={true}
            maxWidth={'sm'}
            style={{ height: "auto", maxHeight: "900px" }}
        >
            {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Add Payment Method</DialogTitle> */}
            <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center ">
                Add New Currency
                <i
                    className="bi bi-x-lg"
                    style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                    onClick={handleClose}
                ></i>
            </DialogTitle>
            <hr />
            <DialogContent className="px-5 mt-2" >
                <div className="form-group row m-4">
                    <label className="col-sm-4 fw-bold pe-5 text-start text-nowrap">Currency Name <span className="importent">*</span> </label>
                    <div className="col-sm-8">
                        <div className="">
                            <Tooltip
                                title={errorsMessage.currency_name}
                                placement="bottom"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.currency_name}
                                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                autoAdjustOverflow={false}
                                zIndex={9999}
                            >
                                <Autocomplete
                                    disablePortal
                                    sx={{
                                        '& input': {
                                            height: 0,
                                        },
                                    }}
                                    className={`${errors.currency_name ? styleNameError : styleName}`}
                                    ListboxProps={{ style: { maxHeight: 150 } }}
                                    value={addCurrency.currency_name}
                                    onChange={(event, newValue) => {
                                        setCurrencyList(newValue);
                                        setErrors(false);
                                    }}
                                    options={currency}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                {errors.currency_name && <div className="mb-3"></div>}
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="form-group row m-4">
                    <label className="col-sm-4 fw-bold text-start text-nowrap">Currency Symbol</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"
                            name='symbol'
                            value={addCurrency.symbol}
                            onChange={handleChange}
                            disabled
                        />
                    </div>
                </div>
                <div className="form-group row m-4">
                    <label className="col-sm-4 fw-bold text-start text-nowrap">Currency Code</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"
                            name='code'
                            value={addCurrency.code}
                            onChange={handleChange}
                            disabled
                        />
                    </div>
                </div>
                <div className="form-group row m-4">
                    <label className="col-sm-4 fw-bold text-start text-nowrap">Exchange Rate <span className="importent">*</span></label>
                    {false ? //businessCurrency && addCurrency.currency_name && businessCurrency.toLowerCase() === addCurrency.currency_name.toLowerCase()
                    <div className="col-sm-8">
                        <input
                    type="text"
                    className={`${errors.exchange_rate ? "ms-2 rounded border border-2 border-danger py-1 w-100 ps-2" : "ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"}`}
                    name='exchange_rate'
                    value={1}
                    onChange={() => {setAddCurrency({...addCurrency, exchange_rate: 1})}}
                    disabled
                />
                </div>:
                    <div className="col-sm-8">
                    <Tooltip
                        title={errorsMessage.exchange_rate}
                        placement="bottom"
                        color="#990000"
                        trigger={"contextMenu"}
                        open={errors.exchange_rate}
                        overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                        autoAdjustOverflow={false}
                        zIndex={9999}
                    >
                        <input
                            type="text"
                            className={`${errors.exchange_rate ? "ms-2 rounded border border-2 border-danger py-1 w-100 ps-2" : "ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"}`}
                            name='exchange_rate'
                            value={addCurrency.exchange_rate}
                            onChange={handleChange}
                        />
                        {errors.exchange_rate && <><br /><br /></>}
                    </Tooltip>
                </div>
                    }
                   
                </div>
            </DialogContent>
            <hr />
            <DialogActions className='mb-4'>
                <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
                    Cancel
                </button>
                <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
                    {isSubmitting ? 'Uploading...' : 'Ok'}
                </button>
            </DialogActions>
        </Dialog>
    )
}