import { useNavigate } from "react-router-dom";
import './Customer.css'
import { useState, useEffect } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import axios from "axios";
import { toast } from 'react-toastify';
// import { Tooltip as ReactTooltip } from 'react-tooltip'
import { Tooltip } from 'antd';


export default function AddCustomers() {
    const navigate = useNavigate();
    const user = localStorage.getItem("user");
    const businessID = user && JSON.parse(user)[0].business.id;
    const [customerForm, getCustomerForm] = useState({
        customer_company_name: "",
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
        note: null
    })
    const [errors, setErrors] = useState({});
    const [errorsMessage, setErrorsMessage] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if(user === null){
          localStorage.removeItem("user");
          navigate('/error-page');
          }
        }, []);

    const handleChange = (event) => {
        const { name, value } = event.target;
        getCustomerForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        // setErrors((prevState) => ({
        //     ...prevState,
        //     [name]: false
        // }));
        setErrors(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const errors = {};
        const errorMessage = {};

        // for (const [key, value] of Object.entries(customerForm)) {
        //     if (key === 'customer_company_name'|| key === 'email') {
        //         if (!String(value).trim()) {
        //             errors[key] = true;
        //             errorMessage[key] = `${key} is required`;
        //         }
        //     }
        // }

        if (customerForm.customer_company_name === "") {
            errors["customer_company_name"] = true;
            errorMessage['customer_company_name'] = `Name is required`;
        }
        else if (customerForm.customer_company_name &&  !/^[\w\d\s-]{3,150}(?![\w\d\s-]*\d{2})[\w\d\s-]*$/.test(customerForm.customer_company_name)) {
            errors["customer_company_name"] = true;
            errorMessage['customer_company_name'] = "Name must be 3-150 letters";
        }

        // if (customerForm.email === "") {
        //     errors["email"] = true;
        //     errorMessage['email'] = `Email is required`;
        // }
        // else
         if (customerForm.email && !/\S+@\S+\.\S+/.test(customerForm.email)) {
            errors["email"] = true;
            errorMessage['email'] = `Email is invalid`;
        }
        if (customerForm.phone && !/^\d{10}$/.test(customerForm.phone)) {
            errors["phone"] = true;
            errorMessage['phone'] = `Phone is invalid`;
        }
        if (customerForm.postal_code && !/^-?\d+(\.\d+)?$/.test(customerForm.postal_code)) {
            errors["postal_code"] = true;
            errorMessage['postal_code'] = `Postal is invalid`;
        }

        setErrors(errors);
        setErrorsMessage(errorMessage);

        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
            axios.post(`https://wavedemo.cydeztechnologies.com/add-customers`, {
                business_id: businessID,
                customer_company_name: customerForm.customer_company_name,
                first_name: customerForm.first_name,
                last_name: customerForm.last_name,
                email: customerForm.email,
                phone: customerForm.phone,
                address: customerForm.address1 && customerForm.address2 ? customerForm.address1 + ", " + customerForm.address2 : customerForm.address1 ? customerForm.address1 : customerForm.address2 || null,
                line_one: customerForm.address1,
                line_two: customerForm.address2,
                country: customerForm.country,
                state: customerForm.state,
                city: customerForm.city,
                postal_code: customerForm.postal_code,
                note: customerForm.note
            })
                .then((res) => {
                    if(res.data === "Email already exists"){
                        toast.error(res.data, {toastId:"add-cus3"});
                        setIsSubmitting(false);
                    }
                    else{
                        toast.success("New Customer added!", {toastId:"add-cus1"});
                        navigate("/customers")
                        setIsSubmitting(false);
                    }
                })
                .catch((e) => {
                    console.log("Error in /add-customers:", e);
                    toast.error(e.message, {toastId:"add-cus2"});
                });
        }
        else{
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error"});
        }
    }

    return (
        <section>
            <Sidebar />
            <div className="container p-5" style={{ width: "83%", float: "right" }}>
                <div className="fw-bold fs-3 text-capitalize">
                    New customer
                    <hr />
                </div>
                <div className="add_customer">
                    <div className="mt-2 fs-5 fw-bold">
                        Basic information
                    </div>
                    <div className="add_customer_formCard">
                        <div className="fw-bold fs-5 text-center mb-4 ">
                            Business / Person Name
                        </div>
                        <div className="form-group row mb-2 position-relative">
                            <label className="col-sm-4 fw-bold text-start">Customer <span className="importent">*</span></label>
                            <div className="col-sm-8 text-center">
                                <Tooltip
                                    title={errorsMessage.customer_company_name}
                                    placement="bottom"
                                    color="#990000"
                                    trigger={"contextMenu"}
                                    open={errors.customer_company_name}
                                    overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                    // autoAdjustOverflow={false}
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    <input
                                        className={`${errors.customer_company_name ? 'addCustomer_input_error' : 'addCustomer_input'}`}
                                        type="text"
                                        name="customer_company_name"
                                        value={customerForm.customer_company_name}
                                        onChange={handleChange}
                                    />
                                    {errors.customer_company_name && <><br /><br /></>}
                                </Tooltip>
                                {/* {errors.customer_company_name && <Tooltip title={"name is requried"} placement="bottom" color="red" open={errors.customer_company_name} />} */}
                            </div>
                        </div>
                    </div>
                    <div className="add_customer_formCard mt-2 position-relative">
                        <div className="fw-bold fs-5 text-center mb-4 ">
                            Primary Contact
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-4 fw-bold text-start">First Name</label>
                            <div className="col-sm-8">
                                <input
                                    className="addCustomer_input"
                                    type="text"
                                    name="first_name"
                                    value={customerForm.first_name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-4 fw-bold text-start">Last Name</label>
                            <div className="col-sm-8">
                                <input
                                    className="addCustomer_input"
                                    type="text"
                                    name="last_name"
                                    value={customerForm.last_name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-4 fw-bold text-start">Email</label>
                            <div className="col-sm-8 text-center">
                                <Tooltip
                                    title={errorsMessage.email}
                                    placement="bottom"
                                    color="#990000"
                                    trigger={"contextMenu"}
                                    open={errors.email}
                                    overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                    autoAdjustOverflow={false}
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    <input
                                        className={`${errors.email ? 'addCustomer_input_error' : 'addCustomer_input'}`}
                                        type="text"
                                        name="email"
                                        value={customerForm.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <><br /><br /></>}
                                </Tooltip>
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-4 fw-bold text-start">Phone</label>
                            <div className="col-sm-8 text-center">
                                <Tooltip
                                    title={errorsMessage.phone}
                                    placement="bottom"
                                    color="#990000"
                                    trigger={"contextMenu"}
                                    open={errors.phone}
                                    overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                    autoAdjustOverflow={false}
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    <input
                                        className={`${errors.phone ? 'addCustomer_input_error' : 'addCustomer_input'}`}
                                        type="text"
                                        name="phone"
                                        value={customerForm.phone}
                                        onChange={handleChange}
                                    />
                                    {errors.phone && <><br /><br /></>}
                                </Tooltip>
                            </div>
                        </div>
                    </div>
                    {/* {addContact?
                <div className="add_customer_formCard mt-2">
                    <div className="fw-bold fs-5 text-center mb-4 ">
                        Secondary contact
                    </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-4 fw-bold text-start">Name</label>
                            <div className="col-sm-8">
                                <input className="addCustomer_input" type="text"/>
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-4 fw-bold text-start">Emai</label>
                            <div className="col-sm-8">
                                <input className="addCustomer_input" type="text"/>
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-4 fw-bold text-start">Phone</label>
                            <div className="col-sm-8">
                                <input className="addCustomer_input" type="text"/>
                            </div>
                        </div>
                        <div className="text-center mt-2">
                            <button className="btn-addcontact text-danger mx-2" onClick={() => setAddContact(false)}>
                                <i className="bi bi-trash-fill me-2"></i>
                                remove
                            </button>
                        </div>
                </div>
                :
                <div className="text-center mt-2">
                    <button className="btn border rounded-5 border-info mx-2" onClick={() => setAddContact(true)}>
                        <i className="bi bi-person-fill-add me-2"></i>
                        add contact
                    </button>
                </div>
                } */}
                    <div className="add_customer_formCard mt-2">
                        <div className="fw-bold fs-5 text-center mb-4 ">
                            Other Details
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-4 fw-bold text-start">Notes</label>
                            <div className="col-sm-8">
                                <textarea
                                    type="text"
                                    name="note"
                                    value={customerForm.note}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                    <div className="add_customer_formCard mt-2">
                        <div className="fw-bold fs-5 text-center mb-4 ">
                            Address
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-3 fw-bold text-start">Address line 1 </label>
                            <div className="col-sm-9">
                                <input
                                    className="addCustomer_input w-100"
                                    type="text"
                                    name="address1"
                                    value={customerForm.address1}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-3 fw-bold text-start">Address line 2 </label>
                            <div className="col-sm-9">
                                <input
                                    className="addCustomer_input w-100"
                                    type="text"
                                    name="address2"
                                    value={customerForm.address2}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-2 fw-bold text-start">City </label>
                            <div className="col-sm-4">
                                <input
                                    className="addCustomer_input w-100"
                                    type="text"
                                    name="city"
                                    value={customerForm.city}
                                    onChange={handleChange}
                                />
                            </div>
                            <label className="col-sm-2 fw-bold text-start">Postal </label>
                            <div className="col-sm-4">
                                <Tooltip
                                    title={errorsMessage.postal_code}
                                    placement="bottom"
                                    color="#990000"
                                    trigger={"contextMenu"}
                                    open={errors.postal_code}
                                    overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                    autoAdjustOverflow={false}
                                >
                                    <input
                                        className={`${errors.postal_code ? 'addCustomer_input_error w-100' : 'addCustomer_input w-100'}`}
                                        type="text"
                                        name="postal_code"
                                        value={customerForm.postal_code}
                                        onChange={handleChange}
                                    />
                                    {errors.postal_code && <><br /><br /></>}
                                </Tooltip>
                                {/* {errors.postal_code && <Tooltip title={"Invalid postal number"} placement="bottom" color="red" open={errors.postal_code} />} */}
                            </div>
                        </div>
                        <div className="form-group row mb-2">
                            <label className="col-sm-2 fw-bold text-start">State </label>
                            <div className="col-sm-4">
                                <input
                                    className="addCustomer_input w-100"
                                    type="text"
                                    name="state"
                                    value={customerForm.state}
                                    onChange={handleChange}
                                />
                            </div>
                            <label className="col-sm-2 fw-bold text-start">Country </label>
                            <div className="col-sm-4">
                                <input
                                    className="addCustomer_input w-100"
                                    type="text"
                                    name="country"
                                    value={customerForm.country}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="form-group mt-5 mb-4 text-center">
                        <button type="button" onClick={() => navigate(-1)} className="btn btn-white border rounded-5 border-primary mx-2">Cancel</button>
                        <button type="submit" disabled={isSubmitting} onClick={handleSubmit} className="btn btn-primary rounded-5">{isSubmitting ? 'Uploading...' : 'Save'}</button>
                    </div>
                </div>
            </div>
        </section>
    )
}