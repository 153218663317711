import Home_Navbar from "./Home_Navbar"
import "./Home_Style.css"
import { useState, useEffect } from "react";
import { useNavigate } from "react-router";
// import sample_video from '../../assets/Sample-video.mp4'
import account_gif from'../../assets/Audit.gif'
import invoice_gif from '../../assets/Invoice.gif'
import payment_gif from '../../assets/Plain credit card.gif'
import Consulting_gif from '../../assets/Consulting.gif'
import FreeLancer_gif from '../../assets/Telecommuting.gif'

import sign_in_group from '../../assets/sign_in_group.svg'
import gear from '../../assets/gear.svg'
import graph from '../../assets/graph.svg'
import idea from '../../assets/idea-bulb.svg'
import logo from '../../assets/logo transparent.png'

export default function Home_page() {

    const navigate = useNavigate();

    const [input, setInput] = useState({
        email: "",
        password: ""
    })

    const handleChange = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
    }

    const onClickSubmit = () => {
        navigate('user-register', {
            state: {
              email: input.email,
              password: input.password
            }
          })
    }

    return (
        <section>
            <Home_Navbar />
            <div>
                <div style={{ backgroundColor: '#f9f4f1' }}>
                    <div className="container">
                        <div className="row py-5">
                            <div className="col-6">
                                <h1 className="home_title">
                                    Handle your finances like a pro!
                                </h1>
                                <p className="home_subText">
                                    Create beautiful invoices, accept online payments, and make accounting easy—all in one place—with Bill Beam’s suite of money tools.
                                </p>
                                <form className="pt-5 pb-3" style={{ maxWidth: "25rem" }}>
                                    <label for="email-5" className="home_label_field" >Email Address</label> <br />
                                    <input type="email" className="home_input_field" maxlength="256" name="email" data-name="email" placeholder="Enter your email" id="email-5" onChange={handleChange}/>
                                    <label for="password-5" className="home_label_field">Password</label> <br />
                                    <input type="password" className="home_input_field" maxlength="256" name="password" data-name="password" placeholder="Create a password" id="password-5" onChange={handleChange}/> <br />
                                    <input type="submit" className="btn home_buttom_field" value="Create your free account" data-wait="Please wait..." onClick={onClickSubmit}/>
                                </form>
                                <div style={{ maxWidth: "33rem" }} className="py-3">
                                    <span className="home_text">Already have an account?<button className="btn btn-none text-decoration-underline text-primary fs-6 fw-bold" onClick={()=>{navigate('user-login', {state: {email: input.email}})}}>Sign in now</button></span>
                                </div>
                                <div style={{ maxWidth: "33rem" }}>
                                    <p className="home_text">By signing up, you are indicating that you have read and agree to the Terms of Use and Privacy Policy.</p>
                                </div>
                            </div>
                            <div className="col-6">
                                <img
                                    src="https://assets-global.website-files.com/62446230dcb514b828a6e237/62d1bd91987ce716de672cf4_harmony_numbers.webp"
                                    alt="An entrepreneur conductor with Bill Beam's money management dashboards behind them."
                                    // sizes="(max-width: 479px) 100vw, (max-width: 767px) 69vw, (max-width: 991px) 55vw, 47vw"= 
                                    width={600}
                                    height={600}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="video_title_dev py-5">
                    <div className="container">
                        <div className="home_video">
                            {/* <video
                                tabindex="-1"
                                className="video-stream html5-main-video"
                                controls
                                controlslist="nodownload"
                                style={{ width: '787px', height: '443px' }}
                                src={"https://www.youtube.com/embed/liy-T2VCwHU?si=w-Lsl3GcqMXsnkmS"}>
                            </video> */}
                            {/* <iframe 
                                width="787px" 
                                height="443px" 
                                src="https://www.youtube.com/embed/liy-T2VCwHU?si=w-Lsl3GcqMXsnkmS" 
                                title="YouTube video player" 
                                frameborder="0" 
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen>
                            </iframe> */}

                            {/* <iframe 
                                width="787px" 
                                height="443px" 
                                src="https://www.youtube.com/embed/D_qpVpb6cEg?si=hlRcMEJdfaH4_0su" 
                                title="YouTube video player" 
                                frameborder="0" 
                                // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                                allowfullscreen>    
                            </iframe> */}

                            <iframe width="787px" height="443px" src="https://www.youtube.com/embed/pseSUUcUGvA?si=_HdpkQKNf65fbGZL" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe> 
                        </div>

                        <div className="feature-component pt-4 px-5 mx-5">
                            <div className="margin-bottom margin-medium">
                                <h2 className="heading-style-2">Ease your mind with one less concern</h2>
                            </div>
                            <div className="margin-bottom margin-large">
                                <p id="feature_ul-li" className="text-style-intro">Your free Bill Beam account unlocks access to all this and beyond:</p>
                            </div>
                            <ul id="feature_ul-li" role="list" className="feature-list w-list-unstyled">
                                <li id="feature_ul-li" className="feature-list-item">
                                    <img src="https://assets-global.website-files.com/62446230dcb514b828a6e237/624ddabe2fe68d1c0d5106d7_heart.svg" loading="lazy" alt="Heart icon" className="feature-list-item_icon" />
                                    <p id="feature_ul-li" className="text-style-body" style={{ marginTop: "14px" }}>A user-friendly dashboard built for you, not your accountant.</p>
                                </li>
                                <li id="feature_ul-li" className="feature-list-item">
                                    <img src="https://assets-global.website-files.com/62446230dcb514b828a6e237/624ddabe2fe68dfe355106d8_folder.svg" loading="lazy" alt="Folder icon" className="feature-list-item_icon" />
                                    <p id="feature_ul-li" className="text-style-body" style={{ marginTop: "14px" }}>Peace of mind that you’re always organized ahead of tax season.</p>
                                </li>
                                <li id="feature_ul-li" className="feature-list-item">
                                    <img src="https://assets-global.website-files.com/62446230dcb514b828a6e237/624ddabe2fe68d56745106d6_plant.svg" loading="lazy" alt="Plant icon" className="feature-list-item_icon" />
                                    <p id="feature_ul-li" className="text-style-body" style={{ marginTop: "14px" }}>A complete picture of your business health, wherever you are.</p>
                                </li>
                                <li id="feature_ul-li" className="feature-list-item">
                                    <img src="https://assets-global.website-files.com/62446230dcb514b828a6e237/624ddabe2fe68dd2295106d5_users.svg" loading="lazy" alt="Icon of several people" className="feature-list-item_icon" />
                                    <p id="feature_ul-li" className="text-style-body" style={{ marginTop: "14px" }}>Our in-house team of bookkeeping, accounting, and payroll coaches.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div >
                    <Home_Navigation />
                </div>
                <div className="py-5 w-100" style={{ backgroundColor: '#f9f4f1' }}>
                    <Small_business />
                </div>
                <div>
                    <Why_choose_wave/>
                    <How_to_get_started/>
                    <Let_go />
                    <Footer />
                </div>
            </div>
        </section>
    )
}

function Home_Navigation() {
    const [selectedTab, setSelectedTab] = useState("tool-tab-invoicing");
    const [backgroundColor, setBackgroundColor] = useState("rgb(233, 171, 255)");

    const [up, setUp] = useState(0);

    const [selectedTabChanging, setSelectedTabChanging] = useState(false);

    // Function to handle tab click

    const handleTabClick = (tabId) => {
        setSelectedTab(tabId);
        setSelectedTabChanging(true);
    };


    // Function to handle scroll
    const handleScroll = () => {
        const scrollY = window.scrollY;
        setUp(window.scrollY)
        // Define scroll thresholds for color changes
        const invoiceScreen = 2200; // Change color after scrolling pixels
        const paymentScreen = 3000; // Change color after scrolling pixels
        const accountingScreen = 3800;
        const featuresScreen = 4500;
        const benefitsScreen = 5800;

        if (!selectedTabChanging) {
            // Only update the background color if selectedTab is not changing
            if (scrollY < invoiceScreen) {
              setBackgroundColor("rgb(233, 171, 255)");
              setSelectedTab("tool-tab-invoicing");
            } else if (scrollY < paymentScreen) {
              setBackgroundColor("rgb(130, 189, 223)");
              setSelectedTab("tool-tab-payments");
            } else if (scrollY < accountingScreen) {
              setBackgroundColor("rgb(120, 183, 242)");
              setSelectedTab("tool-tab-accounting");
            } else if (scrollY < featuresScreen) {
              setBackgroundColor("rgb(241, 192, 170)");
              setSelectedTab("tool-tab-features");
            } else if (scrollY < benefitsScreen) {
                setBackgroundColor("rgb(245, 223, 204)");
                setSelectedTab("tool-tab-benefits");
            } else {
              setBackgroundColor("rgb(233, 171, 255)");
              setSelectedTab("tool-tab-invoicing");
            }
          }

          setSelectedTabChanging(false);
    };

    

    useEffect(() => {
        // Add scroll event listener when the component mounts
        window.addEventListener("scroll", handleScroll);

        // Remove the event listener when the component unmounts
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        if (selectedTab === "tool-tab-invoicing") {
            // setBackgroundColor("rgb(233, 171, 255)");
            setSelectedTab("tool-tab-invoicing");
        } else if (selectedTab === "tool-tab-payments") {
            // setBackgroundColor("rgb(130, 189, 223)");
            setSelectedTab("tool-tab-payments")
        }
        else if (selectedTab === "tool-tab-accounting") {
            // setBackgroundColor("rgb(120, 183, 242)");
            setSelectedTab("tool-tab-accounting")
        }
        else if (selectedTab === "tool-tab-features") {
            // setBackgroundColor("rgb(241, 192, 170)");
            setSelectedTab("tool-tab-features")
        }
        else if (selectedTab === "tool-tab-benefits") {
            // setBackgroundColor("rgb(245, 223, 204)");
            setSelectedTab("tool-tab-benefits")
        }
        else {
            // setBackgroundColor("rgb(233, 171, 255)");
            setSelectedTab("tool-tab-invoicing")
        }
    }, [selectedTab]);

    

    console.log("screen : ", up)

    return (
        <section
            style={{
                willChange: "background",
                backgroundColor: backgroundColor,//selectedTab === "tool-tab-invoicing" ? 'rgb(233, 171, 255)' : selectedTab === "tool-tab-payments" ? 'rgb(130, 189, 223)' : selectedTab === "tool-tab-accounting" ? 'rgb(120, 183, 242)' : "",
                position: "relative",
            }}
        >
            <div className="page-padding">
                <div className="padding-vertical">
                    <div className="container-medium">
                        <div className="section-tool-tab-wrapper">
                            <div className={`section-tool-tabs__titles ${up <= 4800 ? 'section-tool-tabs__titles_enable' : 'section-tool-tabs__titles_disable'}`}>
                                <div className="tools__titles" style={{ willChange: "background", backgroundColor: backgroundColor }}>
                                    <div className="margin-bottom margin-medium text-align-center">
                                        <h2 className="heading-style-1">Everything essential,<br />nothing surplus.</h2>
                                    </div>
                                    <div id="tools__nav_name" className="tools__nav">
                                        <a href="#tool-tab-invoicing"
                                            className={`tools__nav__button w-inline-block ${selectedTab === "tool-tab-invoicing" ? "w--current" : ""}`}
                                            onClick={() => handleTabClick("tool-tab-invoicing")}
                                        >
                                            <div className="tool-nav__button-text">Invoicing</div>
                                        </a>
                                        <a href="#tool-tab-payments"
                                            className={`tools__nav__button w-inline-block ${selectedTab === "tool-tab-payments" ? "w--current" : ""}`}
                                            onClick={() => handleTabClick("tool-tab-payments")}
                                        >
                                            <div className="tool-nav__button-text">Payments</div>
                                        </a>
                                        <a href="#tool-tab-accounting"
                                            className={`tools__nav__button w-inline-block ${selectedTab === "tool-tab-accounting" ? "w--current" : ""}`}
                                            onClick={() => handleTabClick("tool-tab-accounting")}
                                        >
                                            <div className="tool-nav__button-text">Accounting</div>
                                        </a>
                                        <a href="#tool-tab-features"
                                            className={`tools__nav__button w-inline-block ${selectedTab === "tool-tab-features" ? "w--current" : ""}`}
                                            onClick={() => handleTabClick("tool-tab-features")}
                                        >
                                            <div className="tool-nav__button-text">Features</div>
                                        </a>
                                        <a href="#tool-tab-benefits"
                                            className={`tools__nav__button w-inline-block ${selectedTab === "tool-tab-benefits" ? "w--current" : ""}`}
                                            onClick={() => handleTabClick("tool-tab-benefits")}
                                        >
                                            <div className="tool-nav__button-text">Benefits</div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="section-tool-tabs__content" style={{padding: "0px 0px 12rem 0px"}}>
                                <div id="tool-tab-invoicing" className="tools__tool-list__item">
                                    <div className="tools__tool-list__item-group">
                                        {/* <img className="tool-image" src="https://assets-global.website-files.com/62446230dcb514b828a6e237/62690cd34d788f3dca8e8f81_homepage-invoicing.webp" width="1109" height="758" alt="" sizes="(max-width: 767px) 100vw, (max-width: 991px) 320px, 43vw" id="w-node-_690d2b6b-d27d-2ca1-724a-f1d974625618-746255f6" loading="lazy" srcset="https://assets-global.website-files.com/62446230dcb514b828a6e237/62690cd34d788f3dca8e8f81_homepage-invoicing-p-500.png 500w, https://assets-global.website-files.com/62446230dcb514b828a6e237/62690cd34d788f3dca8e8f81_homepage-invoicing-p-800.png 800w, https://assets-global.website-files.com/62446230dcb514b828a6e237/62690cd34d788f3dca8e8f81_homepage-invoicing.webp 1109w" /> */}
                                        <img className="tool-image" src={invoice_gif} alt="account_gif" width="500" height="500"/>
                                        <div className="grid-content-block">
                                            <div className="margin-bottom margin-small margin-medium">
                                                <h3 className="heading-style-2" style={{color:"#232426" ,marginLeft: "-15px"}}>Invoicing</h3>
                                            </div>
                                            <div className="margin-bottom margin-medium">
                                                <div className="heading-style-4">Stop chasing clients for money</div>
                                                <p className="text-style-body">Reduce unpaid invoices, keep tabs on your income, and get paid faster—for the low, low price of FREE.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tool-tab-payments" className="tools__tool-list__item">
                                    <div className="tools__tool-list__item-group">
                                        {/* <img className="tool-image" src="https://assets-global.website-files.com/62446230dcb514b828a6e237/62c844eae603a91476db28a5_Payments-on-product-feature.png" width="1143" height="817" alt="Bill Beam Payments Features" sizes="(max-width: 767px) 100vw, (max-width: 991px) 39vw, 43vw" id="w-node-_690d2b6b-d27d-2ca1-724a-f1d974625626-746255f6" loading="lazy" srcset="https://assets-global.website-files.com/62446230dcb514b828a6e237/62c844eae603a91476db28a5_Payments-on-product-feature-p-500.png 500w, https://assets-global.website-files.com/62446230dcb514b828a6e237/62c844eae603a91476db28a5_Payments-on-product-feature-p-800.png 800w, https://assets-global.website-files.com/62446230dcb514b828a6e237/62c844eae603a91476db28a5_Payments-on-product-feature.png 1143w" /> */}
                                        <img className="tool-image" src={payment_gif} alt="account_gif" width="500" height="500"/>
                                        <div className="grid-content-block">
                                            <div className="margin-bottom margin-small margin-medium">
                                                <h3 className="heading-style-2" style={{color:"#232426" ,marginLeft: "-15px"}}>Payments</h3>
                                            </div>
                                            <div className="margin-bottom margin-medium">
                                                <div className="heading-style-4">Get paid faster</div>
                                                <p className="text-style-body">Convenience is everything. Give your customers the option of paying with one click using a credit card, bank transfer, or Apple Pay.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tool-tab-accounting" className="tools__tool-list__item">
                                    <div className="tools__tool-list__item-group">
                                        {/* <img className="tool-image" src="https://assets-global.website-files.com/62446230dcb514b828a6e237/62b9ae3daf5a4c8004e6a074_Dashboard-accouting-invoice.png" width="1162" height="844" alt="" sizes="(max-width: 767px) 100vw, (max-width: 991px) 39vw, 43vw" id="w-node-_690d2b6b-d27d-2ca1-724a-f1d974625634-746255f6" loading="lazy" srcset="https://assets-global.website-files.com/62446230dcb514b828a6e237/62b9ae3daf5a4c8004e6a074_Dashboard-accouting-invoice-p-500.png 500w, https://assets-global.website-files.com/62446230dcb514b828a6e237/62b9ae3daf5a4c8004e6a074_Dashboard-accouting-invoice-p-800.png 800w, https://assets-global.website-files.com/62446230dcb514b828a6e237/62b9ae3daf5a4c8004e6a074_Dashboard-accouting-invoice.png 1162w" /> */}
                                        <img className="tool-image" src={account_gif} alt="account_gif" width="450" height="350"/>
                                        <div className="grid-content-block">
                                            <div className="margin-bottom margin-small margin-medium">
                                                <h3 className="heading-style-2" style={{color:"#232426" ,marginLeft: "-15px"}}>Accounting</h3>
                                            </div>
                                            <div className="margin-bottom margin-medium">
                                                <div className="heading-style-4">Track your income &amp; expenses, painlessly</div>
                                                <p className="text-style-body">Monitor your cash flow, stay organized, and stop sweating tax season. Say #sorrynotsorry to your spreadsheets and shoeboxes.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tool-tab-features" className="tools__tool-list__item">
                                    <div className="tools__tool-list__item-group">
                                        {/* <img className="tool-image" src="https://assets-global.website-files.com/62446230dcb514b828a6e237/62b9ae3daf5a4c8004e6a074_Dashboard-accouting-invoice.png" width="1162" height="844" alt="" sizes="(max-width: 767px) 100vw, (max-width: 991px) 39vw, 43vw" id="w-node-_690d2b6b-d27d-2ca1-724a-f1d974625634-746255f6" loading="lazy" srcset="https://assets-global.website-files.com/62446230dcb514b828a6e237/62b9ae3daf5a4c8004e6a074_Dashboard-accouting-invoice-p-500.png 500w, https://assets-global.website-files.com/62446230dcb514b828a6e237/62b9ae3daf5a4c8004e6a074_Dashboard-accouting-invoice-p-800.png 800w, https://assets-global.website-files.com/62446230dcb514b828a6e237/62b9ae3daf5a4c8004e6a074_Dashboard-accouting-invoice.png 1162w" /> */}
                                        <img className="tool-image" src={account_gif} alt="account_gif" width="450" height="350"/>
                                        <div className="grid-content-block">
                                            <div className="margin-bottom margin-small margin-medium">
                                                <h3 className="heading-style-2" style={{color:"#232426" ,marginLeft: "-15px"}}>Features</h3>
                                            </div>
                                            <div className="margin-bottom margin-medium">
                                                <div className="heading-style-4">Efficient Business Management Suite</div>
                                                <p className="text-style-body">Bill Beam offers a suite of features designed to streamline your business processes. You can effortlessly generate accurate estimates and professional invoices, quickly share them with clients via email, and efficiently manage customer and product details. Customize templates to showcase your brand, and keep a close eye on expenses to maintain financial clarity.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="tool-tab-benefits" className="tools__tool-list__item pb-5">
                                    <div className="tools__tool-list__item-group">
                                        {/* <img className="tool-image" src="https://assets-global.website-files.com/62446230dcb514b828a6e237/62b9ae3daf5a4c8004e6a074_Dashboard-accouting-invoice.png" width="1162" height="844" alt="" sizes="(max-width: 767px) 100vw, (max-width: 991px) 39vw, 43vw" id="w-node-_690d2b6b-d27d-2ca1-724a-f1d974625634-746255f6" loading="lazy" srcset="https://assets-global.website-files.com/62446230dcb514b828a6e237/62b9ae3daf5a4c8004e6a074_Dashboard-accouting-invoice-p-500.png 500w, https://assets-global.website-files.com/62446230dcb514b828a6e237/62b9ae3daf5a4c8004e6a074_Dashboard-accouting-invoice-p-800.png 800w, https://assets-global.website-files.com/62446230dcb514b828a6e237/62b9ae3daf5a4c8004e6a074_Dashboard-accouting-invoice.png 1162w" /> */}
                                        <img className="tool-image" src={account_gif} alt="account_gif" width="450" height="350"/>
                                        <div className="grid-content-block">
                                            <div className="margin-bottom margin-small margin-medium">
                                                <h3 className="heading-style-2" style={{color:"#232426" ,marginLeft: "-15px"}}>Benefits</h3>
                                            </div>
                                            <div className="margin-bottom margin-medium">
                                                <div className="heading-style-4">Efficient, Professional &amp; Secure</div>
                                                <p className="text-style-body">Experience efficiency with our user-friendly interface, ensuring less paperwork and more business growth. Impress clients with customized, error-free invoices and estimates, accessible anytime, anywhere. Bill Beam ensures cost-effectiveness and top-notch security for your data.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function Small_business() {
    return (
        <section style={{ position: "relative" }}>
            <div className="page-padding">
                <div className="container-medium" style={{ maxWidth: "70rem" }}>
                    <div className="padding-vertical">
                        <div className="customer-grid">
                            <div className="customer-grid_block bg-color-orchid">
                                <h4 className="heading-style-3">Built for small business owners like you</h4>
                                <a id="small_business_sign_in" href="user-register" className="business_button cc-powder" >Sign me up</a>
                            </div>
                            <div id="buiness_box" className="customer-grid_block" style={{backgroundColor:"white"}}>
                                <div className="text-style-eyebrow">Freelancers</div>
                                <img src={FreeLancer_gif} loading="lazy" style={{height:"250px"}} width="501" height="360" alt="Bill Beam helps freelancers manage their money" className="customer-grid_block-image" />
                                <div className="text-style-body">Create and send professional invoices in minutes.</div>
                            </div>
                            <div id="buiness_box" className="customer-grid_block">
                                <div className="text-style-eyebrow">Self-employed entrepreneurs</div>
                                <img src="https://assets-global.website-files.com/62446230dcb514b828a6e237/62690cd2633d7f650e3eefe6_homepage-self-employed.webp" loading="lazy" width="501" height="360" alt="Self Employed Entrepreneurs using Bill Beam to manage their money" className="customer-grid_block-image" />
                                <div className="text-style-body">Pay your staff (and yourself!) with confidence.</div>
                            </div>
                            <div className="customer-grid_block" style={{ borderBottom: 'none', borderLeft: "none" }}></div>
                            <div id="buiness_box" className="customer-grid_block" style={{backgroundColor:"white"}}>
                                <div className="text-style-eyebrow">Consultants</div>
                                <img src={Consulting_gif} loading="lazy" width="501" height="361" alt="A consultant using Bill Beam to manage their money" className="customer-grid_block-image" />
                                <div className="text-style-body">Set up recurring invoices and payments for retainer clients.</div>
                            </div>
                            <div id="buiness_box" className="customer-grid_block">
                                <div className="text-style-eyebrow">Contractors</div>
                                <img src="https://assets-global.website-files.com/62446230dcb514b828a6e237/62690cd2d736c6beca37fd88_homepage-contractors.webp" loading="lazy" width="517" height="361" alt="A contractor using Bill Beam to manage their money." srcset="https://assets-global.website-files.com/62446230dcb514b828a6e237/62690cd2d736c6beca37fd88_homepage-contractors-p-500.webp 500w, https://assets-global.website-files.com/62446230dcb514b828a6e237/62690cd2d736c6beca37fd88_homepage-contractors.webp 517w" sizes="(max-width: 479px) 100vw, (max-width: 767px) 65vw, 100vw" className="customer-grid_block-image" />
                                <div className="text-style-body">Track your business expenses with our free accounting tools.</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function Why_choose_wave() {
    const [animationTriggered, setAnimationTriggered] = useState(false);
  const defaultValue = 6000;

  useEffect(() => {
    const handleScroll = () => {
      const scrollYValue = window.scrollY;
      if (scrollYValue > defaultValue && !animationTriggered) {
        setAnimationTriggered(true);
      } else if (scrollYValue <= defaultValue) {
        setAnimationTriggered(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animationTriggered]);

  const animateListClass = animationTriggered ? 'Choose_ul-list-item' : '';
  const animateList = animationTriggered ? 'animate-list' : '';


    return (
        <section style={{ position: "relative", backgroundColor: "#E0F2EB", padding:"50px 0px"}}>
            <div className="page-padding">
                <div className="container-medium" style={{ maxWidth: "70rem" }}>
                    <div className="padding-vertical">
                        <div className="feature-component pt-4 px-5 mx-5 text-black">
                            <div className="margin-bottom margin-medium">
                                <h2 className="heading-style-3">Why Choose Bill Beam</h2>
                            </div>
                            <ul id="Choose_ul-li" role="list" className={`feature-list w-list-unstyled ${animateList}`}>
                                <li id="Choose_ul-li" className={`card px-3 my-1 mx-2 ${animateListClass}`} style={{backgroundColor: "#f69870"}}>
                                    <p id="Choose_ul-li" className="text-style-body"> <span className="heading-style-4">Effortless Invoicing:</span>  With our intuitive interface, generating invoices becomes quick and painless.</p>
                                </li>
                                <li id="Choose_ul-li" className={`card px-3 my-1 mx-2 ${animateListClass}`} style={{backgroundColor: "#9a9ef5"}}>
                                    <p id="Choose_ul-li" className="text-style-body"> <span className="heading-style-4">Time Efficiency:</span> Bill Beam's streamlined features help you manage your billing tasks in a fraction of the time.</p>
                                </li>
                                <li id="Choose_ul-li" className={`card px-3 my-1 mx-2 ${animateListClass}`} style={{backgroundColor: "#78b7f2"}}>
                                    <p id="Choose_ul-li" className="text-style-body"> <span className="heading-style-4">Sleek Professionalism:</span> Impress your clients with sleek, customized invoices that reflect your brand's identity.</p>
                                </li>
                                <li id="Choose_ul-li" className={`card px-3 my-1 mx-2 ${animateListClass}`} style={{backgroundColor: "#30dfe8"}}>
                                    <p id="Choose_ul-li" className="text-style-body"> <span className="heading-style-4">User-Friendly:</span> Bill Beam's user-friendly design ensures that you don't need to be a tech expert to create polished invoices.</p>
                                </li> 
                            </ul>
                            <div style={{margin:"20px auto 20px", width:"50%"}}>
                                <li id="Choose_ul-li" className={`card px-3 my-1 mx-2 ${animateListClass}`} style={{backgroundColor: "#42C3A0"}}>
                                    <p id="Choose_ul-li" className="text-style-body"> <span className="heading-style-4">User-Friendly:</span> Bill Beam's user-friendly design ensures that you don't need to be a tech expert to create polished invoices.</p>
                                </li>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function How_to_get_started() {
    return(
        <section style={{ position: "relative", backgroundColor: "#f9f4f1"}} className="py-5">
            <div className="page-padding">
                <div className="container-medium" style={{ maxWidth: "70rem" }}>
                    <div className="padding-vertical">
                    <div className="feature-component pt-4 px-5 mx-5">
                            <div className="margin-bottom margin-medium">
                                <h2 className="heading-style-2 text-black">How to get started</h2>
                            </div>
                            <div className="margin-bottom margin-large">
                                <p id="feature_ul-li" className="text-style-intro text-black">Ready to transform your invoicing process? Say hello to Bill Beam – the future of invoicing is here.</p>
                            </div>
                            <ul id="feature_ul-li" role="list" className="feature-list w-list-unstyled text-black">
                                <li id="feature_ul-li" className="feature-list-item">
                                    <img src={sign_in_group} loading="lazy" alt="sign in group" className="feature-list-item_icon" style={{fill:"red"}}/>
                                    <p id="feature_ul-li" className="text-style-body" style={{ marginTop: "14px" }}> <span className="text-primary fw-bold fs-5" style={{lineHeight:"50px"}}>Sign Up <br/></span> Visit our website and create your Bill Beam account. It's your gateway to a seamless invoicing experience.</p>
                                </li>
                                <li id="feature_ul-li" className="feature-list-item">
                                    <img src={gear} loading="lazy" alt="Gear icon" className="feature-list-item_icon" />
                                    <p id="feature_ul-li" className="text-style-body" style={{ marginTop: "14px" }}><span className="text-primary fw-bold fs-5" style={{lineHeight:"50px"}}>Add Your Touch <br/></span>Personalize your account with your brand's logo and details.</p>
                                </li>
                                <li id="feature_ul-li" className="feature-list-item">
                                    <img src={graph} loading="lazy" alt="graph icon" className="feature-list-item_icon" />
                                    <p id="feature_ul-li" className="text-style-body" style={{ marginTop: "14px" }}><span className="text-primary fw-bold fs-5" style={{lineHeight:"50px"}}>Create Invoices <br/></span>Effortlessly create and customize invoices tailored to your clients needs.</p>
                                </li>
                                <li id="feature_ul-li" className="feature-list-item">
                                    <img src={idea} loading="lazy" alt="Icon of idea bulb" className="feature-list-item_icon" />
                                    <p id="feature_ul-li" className="text-style-body" style={{ marginTop: "14px" }}><span className="text-primary fw-bold fs-5" style={{lineHeight:"50px"}}>Email or Download <br/></span>Send invoices directly from Bill Beam, or download them for offline use.</p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function Let_go() {
    return (
        <section className="cta-section" style={{ backgroundColor: '#f9f4f1' }}>
            <div className="page-padding">
                <div className="container-small">
                    <div className="cta-block">
                        <img className="cta-block_image" src="https://assets-global.website-files.com/62446230dcb514b828a6e237/62c708cd9ed0362517b1dd4f_high%20five%402x.webp" width="710" height="599" alt="" sizes="(max-width: 479px) 100vw, (max-width: 767px) 300px, (max-width: 991px) 26vw, 254px" id="cta-image" loading="lazy" srcset="https://assets-global.website-files.com/62446230dcb514b828a6e237/62c708cd9ed0362517b1dd4f_high%20five%402x-p-500.webp 500w, https://assets-global.website-files.com/62446230dcb514b828a6e237/62c708cd9ed0362517b1dd4f_high%20five%402x.webp 710w" />
                        <div id="" className="cta-block_content">
                            <div className="margin-bottom margin-small">
                                <div className="heading-style-3">Do what you love. Leave the rest to us.</div>
                            </div>
                            <a href="user-register" className="btn home_buttom_field w-50">
                                <div className="button_text">Let's do this</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

function Footer() {
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    return (
        <footer className="footer bg-color-navy">
            <div className="page-padding">
                <div className="padding-vertical-footer">
                    <div className="container-large">
                        <div className="footer-nav">
                            <div id="w-node-a0b95ea0-6f92-c958-e900-fd5a001b2be9-8ac6b8f5" className="footer__logos">
                                <a href="/" aria-current="page" className="footer__brand-logo-link w-inline-block w--current"><img src={logo} loading="lazy" width="150" height="45" id="w-node-ba0bd341-c8ae-1f3e-de09-9e8b8ac6b8fa-8ac6b8f5" alt="Bill Beam logo in white" className="footer__brand-logo" /></a>
                                <div className="footer__social-links">
                                    <div className="footer__social-links__icons">
                                        <a href="https://www.facebook.com/aspireacademykochi" target="_blank" className="social-links__icon w-inline-block">
                                            <img src="https://assets-global.website-files.com/62446230dcb514b828a6e237/624defe4c099444b40de10b0_facebook-icon.svg" loading="lazy" width="30" height="31" alt="Facebook logo" />
                                        </a>
                                        <a href="https://twitter.com/ASPIREITACADEMY" target="_blank" className="social-links__icon hide w-inline-block">
                                            <img src="https://assets-global.website-files.com/62446230dcb514b828a6e237/624defe4c09944209ade10ac_twitter-icon.svg" loading="lazy" width="30" height="31" alt="Twitter logo" />
                                        </a>
                                        <a href="https://www.instagram.com/aspire.it.academy.kochi" target="_blank" className="social-links__icon w-inline-block">
                                            <img src="https://assets-global.website-files.com/62446230dcb514b828a6e237/624defe4c099442728de10ae_instagram-icon.svg" loading="lazy" width="31" height="31" alt="Instagram logo" />
                                        </a>
                                        <a href="https://www.linkedin.com/company/aspire-it-academy" target="_blank" className="social-links__icon w-inline-block">
                                            <img src="https://assets-global.website-files.com/62446230dcb514b828a6e237/624defe4c09944aacbde10af_linkedin-icon.svg" loading="lazy" width="31" height="31" alt="Linkedin logo" />
                                        </a>
                                        <a href="https://www.youtube.com/channel/UCQiFW4tGkS9PW8ur1xt_ZBw" target="_blank" className="social-links__icon w-inline-block">
                                            <img src="https://assets-global.website-files.com/62446230dcb514b828a6e237/624defe4c099447956de10ad_youtube-icon.svg" loading="lazy" width="30" height="31" alt="Youtube logo" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer__terms">
                            <div className="content-block">
                                <div className="margin-bottom margin-xsmall">
                                    <div className="text-style-body-small">Copyright © 2023 Bill Beam Financial Inc. All Rights Reserved.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <a className="back-to-top-link w-inline-block w--current" onClick={scrollToTop}>
                <img src="https://assets-global.website-files.com/61f30ac32529b509c8cb3ad0/61fc06b712cbac7816d10e83_chevron-up.svg" loading="lazy" alt="" class="back-to-top-link_icon" />
                <div className="text-style-body">Back to top</div>
            </a>
        </footer>
    )
}

