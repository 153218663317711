import React, { useState } from "react";
import { Slide } from '@mui/material';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useNavigate } from "react-router-dom";
import './Estimate.css'

function Customization_Warning_Popup(props) {
    const navigate = useNavigate();
    const { displayPopup, isOpen, onClose, navigateBack } = props;
    const [_isOpen, setOpen] = useState(isOpen);

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
        if (navigateBack) navigate(-1);
    };

const converttoInvoice = ()=>{
    navigate('/settings/invoice-customization')
    if (onClose) onClose();

}

    return (
        <section>
            <Dialog
                
                open={displayPopup}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll={'paper'}
                disablebackdropclick="true"
                disableEscapeKeyDown={true}
                fullWidth={true}
                maxWidth={false}
                PaperProps={{
                style: {
                    
                    maxWidth: "550px",
                    width: "100%",
                    }
                }}
            >
                {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Edit</DialogTitle> */}
                <DialogTitle sx={{ position: 'relative', height: '50px' }} id="alert-dialog-title" className="align-content-center ">
                    <p style={{ fontWeight: '500' }}>Warning!</p>
                    <i
                        className="bi bi-x-lg"
                        style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                        onClick={handleClose}
                    ></i>
                </DialogTitle>
                <hr />
                <DialogContent sx={{ padding:'0px 24px' }} >
                {/* <h6 className="fw-bold">Customization Details are not set please do.</h6> */}
                <h6 className="fw-bold">Customization details have not been defined yet. Please provide the necessary customization information.</h6>
                </DialogContent>
                <hr />
                <DialogActions className='mb-3' sx={{ height: '30px' }}>
                    <button  className="btn btn-primary popup-add-btn me-2"
                    onClick={converttoInvoice}
                    >
                       Proceed
                    </button> or
                    <span onClick={handleClose} className="mx-2 popup-cancel-btn">
                        Cancel
                    </span>

                </DialogActions>
            </Dialog>
        </section>
    )
}

export default Customization_Warning_Popup