import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import logo from '../../assets/logo.png'

export default function Home_Navbar() {
  const navigate = useNavigate();

  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [lastName, setLastName] = useState('');

  useEffect(() => {
    const urlParts = window.location.pathname.split('/');
    const lastPart = urlParts[urlParts.length - 1];
    setLastName("/"+lastPart);
  }, []);

  return (
    <div style={{ backgroundColor: '#f9f4f1', position: isSticky ? 'sticky' : '', top: "0", zIndex: 9999, padding: isSticky ? '5px' : '15px' }}>
      <Navbar
        className={`container`}
        variant="dark"
        expand="sm"
        style={{ backgroundColor: isSticky ? '#f9f4f1' : 'transparent' }}>
        <Navbar.Brand >
          <NavLink to={lastName === "/" ? "#" : "/"}>
            <img src={logo}
              width="60"
              height="56"
              alt="Wave logo"
              className="brand-logo_image"
              />
              {/* <img src='https://assets-global.website-files.com/62446230dcb514b828a6e237/624dedebca225a69a155c7b6_wave-logo-black.svg'
              width="133"
              height="36"
              alt="Wave logo"
              className="brand-logo_image"
              style={{ willChange: 'width, height', width: '7.5rem' }} /> */}
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse >
          {/* <Nav className="ms-3 ms-auto" >
                        <NavLink to="/" className="nav-link text-primary" >Features</NavLink>
                        <NavLink to="/" className="nav-link text-primary" >Pricing</NavLink>
                        <NavLink to="/" className="nav-link text-primary" >Blog</NavLink>
                        <NavLink to="/" className="nav-link text-primary" >Resources</NavLink>
                    </Nav> */}
          <Nav className="ms-3 ms-auto" >
            <button style={{display: lastName === "/user-login" ? 'none' : 'block'}} className='btn btn-outline-primary wv-outline-btn px-4 me-2' onClick={() => { navigate("/user-login") }}>Sign in</button>
            <button style={{display: lastName === "/user-register" ? 'none' : 'block'}} className='btn btn-primary wv-actn-fill-btn px-4' onClick={() => { navigate("/user-register") }}>Sign up for free</button>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}