import { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from 'react-toastify';
import { Tooltip } from 'antd';
import { useNavigate } from "react-router-dom";

export default function EditCustomersPopup(props) {
    const navigate = useNavigate();
    const { displayPopup, isOpen, onClose ,onUpdatecusList, onUpdateselectedcus,customerDetails} = props;
    const [_isOpen, setOpen] = useState(isOpen);
    const user = localStorage.getItem("user");
    const businessID = JSON.parse(user)[0].business.id;
    const [customerForm, getCustomerForm] = useState({
        id:"",
        customer_company_name: "",
        first_name: "",
        last_name: null,
        email: null,
        phone: null,
        address1: null,
        address2: null,
        city: null,
        state: null,
        country: null,
        postal_code: null,
        note: null
    });
    const [displayFullDetails,setDisplayFullDetails] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorsMessage, setErrorsMessage] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
        setErrors({});
        getCustomerForm({id:"",customer_company_name: "",first_name: "",last_name: null,email: "",phone: null,address1: null,address2: null,city: null,state: null,country: null,postal_code: null,note: null});
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        getCustomerForm((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};

        // for (const [key, value] of Object.entries(customerForm)) {
        //     if (key === 'customer_company_name'|| key === 'email') {
        //         if (!String(value).trim()) {
        //             errors[key] = true;
        //             errorMessage[key] = `name is required`;
        //         }
        //     }
        // }

        if (customerForm.customer_company_name === "") {
            errors["customer_company_name"] = true;
            errorMessage['customer_company_name'] = `Name is required`;
        }
        else if (customerForm.customer_company_name &&  !/^[\w\d\s-]{3,150}(?![\w\d\s-]*\d{2})[\w\d\s-]*$/.test(customerForm.customer_company_name)) {
            errors["customer_company_name"] = true;
            errorMessage['customer_company_name'] = "Must be 3-150 letters";
        }

        // if (customerForm.email === "") {
        //     errors["email"] = true;
        //     errorMessage['email'] = `Email is required`;
        // }
        // else 
        if (customerForm.email && !/\S+@\S+\.\S+/.test(customerForm.email)) {
            errors["email"] = true;
            errorMessage['email'] = `Email is invalid`;
        }
        if (customerForm.phone && !/^\d{10}$/.test(customerForm.phone)) {
            errors["phone"] = true;
            errorMessage['phone'] = `Phone is invalid`;
        }
        if (customerForm.postal_code && !/^-?\d+(\.\d+)?$/.test(customerForm.postal_code)) {
            errors["postal_code"] = true;
            errorMessage['postal_code'] = `Postal is invalid`;
        }

        setErrors(errors);
        setErrorsMessage(errorMessage);
      
        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
            axios.put(`https://wavedemo.cydeztechnologies.com/edit-customer/${customerForm.id}`, {
                id: customerForm.id,
                business_id: businessID,
                customer_company_name: customerForm.customer_company_name,
                first_name: customerForm.first_name,
                last_name: customerForm.last_name,
                email: customerForm.email,
                phone: customerForm.phone,
                address: customerForm.address1 && customerForm.address2 ? customerForm.address1 + ", " + customerForm.address2 : customerForm.address1 ? customerForm.address1 : customerForm.address2 || null,
                line_one: customerForm.address1,
                line_two: customerForm.address2,
                country: customerForm.country,
                state: customerForm.state,
                city: customerForm.city,
                postal_code: customerForm.postal_code,
                note: customerForm.note
            })
                .then((res) => {
                    if(res.data === "Email already exists"){
                        toast.error(res.data, {toastId:"add-cus3"});
                        setIsSubmitting(false);
                    }
                    else if (res.data){
                        onUpdateselectedcus({...res.data.customer, id:customerForm.id});
                        onUpdatecusList(res.data.allCustomer);
                        setOpen(false);
                        if (onClose) onClose();
                        setErrors({});
                        getCustomerForm({id:"",customer_company_name: "",first_name: "",last_name: null,email: "",phone: null,address1: null,address2: null,city: null,state: null,country: null,postal_code: null,note: null});
                        toast.success("Customer Updated Successfully!", {toastId:"upd-cus1"});
                        setIsSubmitting(false);
                    }
                    else {
                        toast.error(res.data.trim(), {toastId:"upd-cus2"});
                    }
                })
                .catch((e) => {
                    toast.error(e.message, {toastId:"err-api"});
                });
        } 
        else{
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error"});
        }
    }

    useEffect(() => {
        if(user === null){
            localStorage.removeItem("user");
            navigate('/error-page');
            }
            // const address = customerDetails.address;
            // const substrings = String(address).split(", ");
            
        getCustomerForm({...customerDetails, address1:customerDetails.line_one, address2:customerDetails.line_two});
        console.log("customerDetails editpage: ", customerDetails)
    }, [isOpen]);

    const styleName = "form-control w-75 fs-13";
    const styleNameError = "form-control w-75 fs-13 border border-danger";

    return (
        <section>
            <Dialog
                open={displayPopup}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll={'paper'}
                disablebackdropclick="true"
                disableEscapeKeyDown={true}
                fullWidth={true}
                maxWidth={'sm'}
                // style={{ height: "auto", maxHeight: "900px" }}
            >
                {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Edit</DialogTitle> */}
                <DialogTitle sx={{ position: 'relative', height:'50px' }} id="alert-dialog-title" className="fw-bold fs-4 align-content-center ">
                    Edit customer
                    <i
                        className="bi bi-x-lg"
                        style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                        onClick={handleClose}
                    ></i>
                </DialogTitle>
                <hr />
                <DialogContent className="px-5 text-center">
                        <div className="form-group row mb-2 text-center">
                            <label className="col-5 fw-bold text-end text-nowrap">Business / Person <span className="importent">*</span> </label>
                            <div className="col-7">
                            <Tooltip
                                    title={errorsMessage.customer_company_name}
                                    placement="bottomLeft"
                                    color="#990000"
                                    trigger={"contextMenu"}
                                    open={errors.customer_company_name}
                                    overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                    autoAdjustOverflow={false}
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    <input
                                        className={`${errors.customer_company_name ? styleNameError : styleName}`}
                                        type="text"
                                        name="customer_company_name"
                                        placeholder="company/person name"
                                        value={customerForm.customer_company_name}
                                        onChange={handleChange}
                                    />
                                    {errors.customer_company_name && <div className="mb-4"></div>}
                                </Tooltip>
                                {/* <label style={{fontSize:"13px", color:"grey"}} className="ms-2">Name of a business or person.</label> */}
                            </div>
                        </div>
                        <div className="form-group row mb-2 text-center">
                            <label className="col-5 fw-bold text-end">First Name </label>
                            <div className="col-7">
                                <input
                                    className= {styleName}
                                    type="text"
                                    name="first_name"
                                    value={customerForm.first_name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-2 text-center">
                            <label className="col-5 fw-bold text-end">Last Name </label>
                            <div className="col-7">
                                <input
                                    className={styleName}
                                    type="text"
                                    name="last_name"
                                    value={customerForm.last_name}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-2 text-center">
                            <label className="col-5 fw-bold text-end">Email</label>
                            <div className="col-7">
                            <Tooltip
                                    title={errorsMessage.email}
                                    placement="bottomLeft"
                                    color="#990000"
                                    trigger={"contextMenu"}
                                    open={errors.email}
                                    overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                    autoAdjustOverflow={false}
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    <input
                                        className={`${errors.email ? styleNameError : styleName}`}
                                        type="text"
                                        name="email"
                                        value={customerForm.email}
                                        onChange={handleChange}
                                    />
                                    {errors.email && <div className="mb-4"></div>}
                                </Tooltip>
                            </div>
                        </div>
                        <div className="form-group row mb-2 text-center">
                            <label className="col-5 fw-bold text-end">Phone </label>
                            <div className="col-7">
                            <Tooltip
                                    title={errorsMessage.phone}
                                    placement="bottomLeft"
                                    color="#990000"
                                    trigger={"contextMenu"}
                                    open={errors.phone}
                                    overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                    autoAdjustOverflow={false}
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                >
                                    <input
                                        className={`${errors.phone ? styleNameError : styleName}`}
                                        type="text"
                                        name="phone"
                                        value={customerForm.phone}
                                        onChange={handleChange}
                                    />
                                    {errors.phone && <div className="mb-4"></div>}
                                </Tooltip>
                            </div>
                        </div>
                        {displayFullDetails ? 
                        <div className="form-group row mb-2 text-center">
                        <label className="col-4"></label>
                        <div className="col-7">
                            <button className="btn btn-none text-primary fw-bold text-decoration-underline" onClick={ () => {setDisplayFullDetails(false)}}>hide additional information</button> 
                        </div>
                        </div>
                        :
                        <div className="form-group row mb-2 text-center">
                        <label className="col-4"></label>
                        <div className="col-7">
                        <button className="btn btn-none text-primary fw-bold text-decoration-underline text-end" onClick={ () => {setDisplayFullDetails(true)}}>add additional information</button>
                        </div>
                        </div>
                        }
                        {displayFullDetails ? 
                        <>
                        <div className="form-group row mb-2 text-center">
                            <label className="col-5 fw-bold text-end">Address line 1  </label>
                            <div className="col-7">
                                <input
                                    className={styleName}
                                    type="text"
                                    name="address1"
                                    value={customerForm.address1}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-2 text-center">
                            <label className="col-5 fw-bold text-end">Address line 2  </label>
                            <div className="col-7">
                                <input
                                    className={styleName}
                                    type="text"
                                    name="address2"
                                    value={customerForm.address2}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-2 text-center">
                            <label className="col-5 fw-bold text-end">City  </label>
                            <div className="col-7">
                                <input
                                    className={styleName}
                                    type="text"
                                    name="city"
                                    value={customerForm.city}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-2 text-center">
                            <label className="col-5 fw-bold text-end">Postal  </label>
                            <div className="col-7">
                            <Tooltip
                                    title={errorsMessage.postal_code}
                                    placement="bottomLeft"
                                    color="#990000"
                                    trigger={"contextMenu"}
                                    open={errors.postal_code}
                                    overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                    autoAdjustOverflow={false}
                                    zIndex={9999}
                                >
                                    <input
                                        className={`${errors.postal_code ? styleNameError : styleName}`}
                                        type="text"
                                        name="postal_code"
                                        value={customerForm.postal_code}
                                        onChange={handleChange}
                                    />
                                    {errors.postal_code && <div className="mb-4"></div>}
                                </Tooltip>
                            </div>
                        </div>
                        <div className="form-group row mb-2 text-center">
                            <label className="col-5 fw-bold text-end">State  </label>
                            <div className="col-7">
                                <input
                                    className={styleName}
                                    type="text"
                                    name="state"
                                    value={customerForm.state}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row mb-2 text-center">
                            <label className="col-5 fw-bold text-end">Country </label>
                            <div className="col-7">
                                <input
                                    className={styleName}
                                    type="text"
                                    name="country"
                                    value={customerForm.country}
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="form-group row text-center">
                            <label className="col-5 fw-bold text-end">Notes</label>
                            <div className="col-7">
                                <textarea
                                    type="text"
                                    className={styleName}
                                    name="note"
                                    value={customerForm.note}
                                    onChange={handleChange}
                                ></textarea>
                            </div>
                        </div>
                        </> : <></>
                        }
                </DialogContent>
                <hr />
                <DialogActions className='mb-3' sx={{height:'30px' }}>
                    <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
                        Cancel
                    </button>
                    <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
                        {isSubmitting ? 'Uploading...' : 'Ok'}
                    </button>
                </DialogActions>
            </Dialog>
        </section>
    )
}