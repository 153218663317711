import React, { useState, useEffect } from 'react'
import Sidebar from '../../Sidebar/Sidebar';
import DataTable from 'react-data-table-component';
import './Estimate.css'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import Dropdown from 'react-bootstrap/Dropdown';
// import DropdownButton from 'react-bootstrap/DropdownButton';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import LoadingSpinner from '../../Utils/Loader';
import dayjs from 'dayjs';
import Delete from '../../AlertBox/Delete'

import { Menu, Dropdown, Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Customization_Warning_Popup from './Customization_Warning_Popup';
import Business_Warning_Popup from './Business_Warning _Popup';
import SendReceipt from '../Invoices/Send_Invoice';

import { format } from 'date-fns';
import { AES } from 'crypto-js';

function EstimateList() {
  const navigate = useNavigate();
  const [status, setStatus] = useState([]);
  const [filterStatus, setFilterStatus] = useState('');
  const [filterCustomer, setFilterCustomer] = useState('');
  const [filterFromDate, setFilterFromDate] = useState();
  const [filterToDate, setFilterToDate] = useState();
  const [isOpenFromDate, setIsOpenFromDate] = useState(false);
  const [isOpenToDate, setIsOpenToDate] = useState(false);
  const [filtertog, setFiltertog] = useState(false);
  const currentDate = dayjs(); // Get current date using dayjs
  const [estimateData, setEstimateData] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = localStorage.getItem("user");
  const businessID = user && JSON.parse(user)[0].business.id;
  const business_name = user&&JSON.parse(user)[0].business.business_name;
  const userName = user && JSON.parse(user)[0].first_name;

  const [selectedEstimate, setSelectedEstimate] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [customizationWarning, setCustomizationWarning] = useState(false);
  const [businessdata, setBusinessdata] = useState({});
  const [businessWarning,setBusinessWarning] = useState(false);

  const [pdfDownloadUrl, setPdfDownloadUrl] = useState('');

  const [sendEstimate, setSendEstimate] = useState(false);
  const [sendEstimateData,setSendEstimateData] = useState({});

  function handleDelete(item) {
    setSelectedEstimate(item);
    setOpenDelete(true);
  }

  const confirm = (confirm) => {
    if (confirm && confirm === true) {
      axios.delete(`https://wavedemo.cydeztechnologies.com/delete-estimate/${selectedEstimate.id}`).then((res) => {
        if (res.data) {
          toast.success(res.data, { toastId: "listdel" });
          setIsUpdated((prevIsUpdated) => !prevIsUpdated);
        }
      })
        .catch((e) => {
          toast.error(e.message, { toastId: "err-listdel" });
        });
    }
  }

  function fetchData() {
    axios.get(`https://wavedemo.cydeztechnologies.com/estimate/${businessID}`)
      .then((res) => {
        if (res.data === "No data found") {
          setEstimateData([]);
          setLoading(true);
        } else {
          setEstimateData(res.data);
          setLoading(true);
        }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-listest" });
      });

    axios.get(`https://wavedemo.cydeztechnologies.com/list-customers/${businessID}`)
      .then((res) => {
        if (res.data === "No data found") {
          setCustomerData([]);
          setLoading(true);
        } else {
          setCustomerData(res.data);
          setLoading(true);
        }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-listcus" });
      });

    axios.get('https://wavedemo.cydeztechnologies.com/list-status')
      .then((res) => {
        setStatus(res.data);
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-liststa" });
      });
  }

  useEffect(() => {
    fetchData();
    business_data();
  }, [isUpdated]);


  const sendconfirm = (sendconfirm) => {
    if(sendconfirm && sendconfirm === true && sendEstimateData.status === "Saved"){
      axios.put(`https://wavedemo.cydeztechnologies.com/update-status/${sendEstimateData.id}`, { status: 2 })
      .then(() => {
        setIsUpdated((prevIsUpdated) => !prevIsUpdated);
      })
      .catch((error) => {
        toast.error(error.message, { toastId: "err-api" });
      });
    }
  }

  function handleView(item) {
    navigate("/estimate/view-estimate", {
      state: { estimateId: item.id },
    });
  }

  function handleEdit(item) {
    navigate('/estimate/add-estimate', {
      state: { estimateId: item.id },
    });
  }

  function handleSend(item) {

    const encryptionConstant = 5;
    const encryptedParts = [];
    const encryptnPart = `${item.id}`;
    for (let i = 0; i < encryptnPart.length; i++) {
      const charCode = encryptnPart.charCodeAt(i);
      
      const encryptedCharCode = charCode * encryptionConstant;
      encryptedParts.push(encryptedCharCode);
    }
    const encryptedString = encryptedParts.join('-') + "-xyc";
    const urls = `https://wavedemo.cydeztechnologies.com/estimatedownload/${businessID}/${encryptedString}`;
    setSendEstimateData(item);
    setPdfDownloadUrl(urls);
    setSendEstimate(true);

    //mail configure
  // const transactionName = item && item.transaction_name;
  // const transactionNumber = item && item.transaction_number;
  // const totalAmount = item && item.total_amount;
  // const currency = item && item.currency.code;
  // const expiryDate = item && format(new Date(item.expiry_date), "MMMM d, yyyy");

  // const secretKey = 'Cy23Wv25Cy23Wv25'; // Use a strong secret key
  //   const endpoint = `${item.id}`;
  //   const encryptedUrl = AES.encrypt(endpoint, secretKey).toString();
  //   const urlParameter = encodeURIComponent(encryptedUrl); 
  //   const url = `https://wavedemo.cydeztechnologies.com/estimatedownload/${businessID}/${urlParameter}`;
  
 
  // const subject = `${transactionName} #${transactionNumber} from ${business_name}`;
  // const body = `Below please find a link to ${transactionName} #${transactionNumber}.\n\nAmount due: ${totalAmount} ${currency}\n\nExpires on: ${expiryDate}\n\nTo view this estimate online, please visit: ${url}`;

  // const bodyy = `Hey ${item.customer_id && item.customer_id.customer_company_name},\n\nHope you're doing well! Just wanted to drop you a quick note with the details of ${transactionName} #${transactionNumber}.\n\nAmount due: ${totalAmount} ${currency}\n\nExpires on: ${expiryDate}\n\nTo view this estimate online, simply click on the link below:\n\n${urls}\n\nIf you have any questions or need further assistance, feel free to reach out. We're always here to help!\n\nThanks,\n\n${userName}`

  // const gmailUrl = `https://mail.google.com/mail/?view=cm&tf=0&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(bodyy)}`;

  // window.open(gmailUrl, '_blank');
 //mail configure : end
  }

  function handleDownload(item){
    const encryptionConstant = 5;
    const encryptedParts = [];
    const encryptnPart = `${item.id}`;
    for (let i = 0; i < encryptnPart.length; i++) {
      const charCode = encryptnPart.charCodeAt(i);
      
      const encryptedCharCode = charCode * encryptionConstant;
      encryptedParts.push(encryptedCharCode);
    }
    const encryptedString = encryptedParts.join('-') + "-xyc";
    const urls = `https://wavedemo.cydeztechnologies.com/estimatedownload/${businessID}/${encryptedString}`;
    window.location.href = urls;
  }

  const business_data = () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-business`)
    .then((res)=>{
      const responseData = res.data; 
      const filteredData = responseData && responseData.filter(item => item.id === businessID);
      setBusinessdata(filteredData[0]);
    }).catch(error =>{
      toast.error(error.message,{toastId: 'addest5',})  ;
  });
  }

  const createEstimate = () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/get-estimate-customization-details/${businessID}`)
      .then((res) => {

        if (parseInt(res.data.estimate_number) == null || res.data.estimate_default_title == null) {
          setCustomizationWarning(true)
        }
        else if(businessdata && businessdata.business_address === null){
          setBusinessWarning(true);
        }
        else {
          navigate('/estimate/add-estimate');
        }

      }).catch(error => {
        toast.error(`Estimate Details API Error1 ${error}`, { toastId: 'addest5', });
      });

  }


  //react data table config 
  const customStyles = {
    headRow: {
      style: {
        color: '#000',
        fontWeight: "bolder",
        fontSize: "16px"
      },
    },
    rows: {
      style: {
        color: "#000",
        backgroundColor: "#fff",
        fontSize: "16px",
        minHeight: '55px'
      }
    },

  }

  const columns = [
    {
      name: 'Status',
      selector: row => row.status,
      // width:"50px",  
    },
    {
      name: 'Date',
      selector: row => row.date,
      sortable: true,
    },
    {
      name: 'Number',
      selector: row => row.number,
    },
    {
      name: 'Customer',
      selector: row => row.customer,
      grow: 3,
    },
    {
      name: 'Amount',
      selector: row => row.amount,
      sortable: true,
      // width: "150px",
      grow: 1,
      right: true,
    },
    {
      name: '',
      selector: row => row.action,
      width: "100px",
      cell: (row) => {
        const menu = (
          <Menu>
            <Menu.Item key="view" onClick={() => handleView(row.action)}>
              View
            </Menu.Item>
            <Menu.Item key="edit" onClick={() => handleEdit(row.action)}>
              Edit
            </Menu.Item>
            <Menu.Item key="send" onClick={() => handleSend(row.action)}>
              Send
            </Menu.Item>
            <Menu.Item key="download" onClick={() => handleDownload(row.action)}>
              Download
            </Menu.Item>
            <Menu.Item key="delete" onClick={() => handleDelete(row.action)}>
              Delete
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown trigger={['click']} overlay={menu}>
            <Button className="action-icon-btn" style={{ display: "flex", alignItems: "center", justifyContent: "center" }} type="primary" icon={<CaretDownOutlined style={{ fontSize: "13px" }} />} />
          </Dropdown>
        );
      },
    }
  ];

  //filter button toggle on change
  const onfFilterToggleChange = () => {
    setFiltertog((prevState) => !prevState);
    setFilterCustomer(null);
    setFilterStatus(null);
    setFilterFromDate(null);
    setFilterToDate(null);
  }

  const handleFilterClear = () => {
    setFilterCustomer(null);
    setFilterStatus(null);
    setFilterFromDate(null);
    setFilterToDate(null);
  }

  // const filterCustomerData = ['Gireesh', 'Ramesh', 'Harish', 'dhinesh'];
  const arrFilterCustomerData = estimateData.map(item => {
    return customerData && customerData.map(data => {
      const { id, customer_company_name } = data;
      return id === item.customer_id.id ? customer_company_name : null;
    });
  });

  const filterCustomerData = [...new Set(arrFilterCustomerData.flat().filter(name => name !== null))];


  // const dataToRender = (filterStatus || filterCustomer) ?
  //   filteredData.length > 0 ? filteredData : ''
  //   : estimateData

  let filteredData = estimateData;
  let count = 0;

  if (filterStatus) {
    filteredData = filteredData.filter(item => {
      const estimateDataStatus = item.status && item.expiry_date && currentDate > dayjs(item.expiry_date) ? "Expired" : item.status === 'Saved' ? "Saved" : "Send";
      return estimateDataStatus === filterStatus;
    });
    count += 1;
  }

  if (filterCustomer) {
    filteredData = filteredData.filter(
      item =>
        item.customer_id.customer_company_name &&
        item.customer_id.customer_company_name
          .toLowerCase()
          .includes(filterCustomer.toLowerCase()) &&
          item.customer_id.customer_company_name.length === filterCustomer.length 
    );
    count += 1;
  }

  if (filterFromDate) {
    filteredData = filteredData.filter(
      item =>
        item.date &&
        (dayjs(item.date).isSame(dayjs(filterFromDate), 'day') ||
          dayjs(item.date).isAfter(dayjs(filterFromDate), 'day'))
    );
    count += 1;
  }

  if (filterToDate) {
    filteredData = filteredData.filter(
      item =>
        item.date &&
        (dayjs(item.date).isSame(dayjs(filterToDate), 'day') ||
          dayjs(item.date).isBefore(dayjs(filterToDate), 'day'))
    );
    count += 1;
  }

  // const rows = dataToRender ? dataToRender.map((item) => {
  //   return {
  //     number: `${item.prefix}${item.transaction_number}`,
  //     status: currentDate > dayjs(item.expiry_date) ? <span className='status-expired'>Expired</span> : item.status == 'Saved' ? <span className='status-saved'>Saved</span> : <span className='status-sent'>Sent</span>,
  //     //.add(1, 'day')
  //     date: item.date && dayjs(item.date).format("DD/MM/YYYY"),
  //     customer: item.customer_id.customer_company_name,
  //     amount: item.total_amount,
  //     action: item
  //   }
  // }) : '';

  const rows = (filterStatus || filterCustomer || filterFromDate || filterToDate) ?
    filteredData ? filteredData.map((item) => {
      return {
        number: `${item.prefix}${item.transaction_number}`,
      status: currentDate > dayjs(item.expiry_date) ? <span className='status-expired'>Expired</span> : item.status == 'Saved' ? <span className='status-saved'>Saved</span> : <span className='status-sent'>Send</span>,
      //.add(1, 'day')
      date: item.date && dayjs(item.date).format("DD/MM/YYYY"),
      customer: item.customer_id.customer_company_name,
      amount: `${item.currency.symbol}${Number(item.total_amount).toFixed(2)}`,
      action: item
      }
    }) : '' :
    estimateData ? estimateData.map((item) => {
      return {
        number: `${item.prefix}${item.transaction_number}`,
      status: currentDate > dayjs(item.expiry_date) ? <span className='status-expired'>Expired</span> : item.status == 'Saved' ? <span className='status-saved'>Saved</span> : <span className='status-sent'>Send</span>,
      //.add(1, 'day')
      date: item.date && dayjs(item.date).format("DD/MM/YYYY"),
      customer: item.customer_id.customer_company_name,
      amount: `${item.currency.symbol}${Number(item.total_amount).toFixed(2)}`,
      action: item
      }
    }) : '';

    const handleRowClick = (row) => {
      navigate('/estimate/view-estimate', {
        state: { estimateId: row.action.id },
      });
    };

  return (
    <>

      <section >
        <Sidebar />
        <div className="container main-coloum1 py-4">
          <div className="estimatelist-container">
            <div className="estimatelist-header d-flex justify-content-between align-items-center">
              <div className="estimatelist-title">
                <h2 className='section-title'>Estimates</h2>
              </div>
              <div className="estimate-list-add-action">
                <button className='btn btn-primary wv-actn-fill-btn'
                  onClick={createEstimate}>Create an estimate</button>
              </div>
            </div>
            {/* filter section */}
            <div className='mt-3 filter-section-container' >
              <button className='btn btn-outline-primary px-4 py-1 wv-outline-btn'
                onClick={() => { onfFilterToggleChange() }}
              >Filter <i className={filtertog ? 'bi bi-chevron-up ps-2' : 'bi bi-chevron-down ps-2'}></i> </button>
              <div className="filter-section mt-3  p-4" style={{ display: filtertog ? 'block' : 'none' }}>
                <form>
                <div className="filter-input-count-dev">
              <span className="filter-count-num">{count}</span>
              <p className='filter-count-text'>Active filters</p>
              {count > 0 &&
                <>
                  <p className='filter-count-text'>|</p>
                  <p className='filter-clear' onClick={() => handleFilterClear()}>Clear filters</p>
                </>
              }
            </div>
                  <div className="row mx-2">
                    <div className="col-4">
                      <Autocomplete
                        disablePortal
                        id="filter-status"
                        size="small"
                        value={filterStatus}
                        onChange={(event, newValue) => {
                          setFilterStatus(newValue);
                        }}
                        options={status.filter(item => item.id != 4 & item.id != 5 & item.id != 6 & item.id != 7 & item.id != 8 & item.id != 9).map(item => item.status_name)}
                        sx={{
                          display: 'flex',
                          // width: 400,
                          height: 40,

                        }}
                        renderInput={(params) => <TextField
                          {...params}
                          size="small"
                          placeholder="All statuses"

                        />}
                      />
                    </div>
                  
                  {/* <div className="filter-input-box row"> */}
                    <div className="col-4">
                      <Autocomplete
                        disablePortal
                        id="filter-customer"
                        size="small"
                        value={filterCustomer}
                        onChange={(event, newValue) => {
                          setFilterCustomer(newValue);
                        }}
                        options={filterCustomerData}
                        sx={{
                          display: 'flex',
                          // width: 400,
                          height: 40,

                        }}
                        renderInput={(params) => <TextField
                          {...params}

                          placeholder="All Customer"
                          size="small" />}
                      />
                    </div>
                  {/* </div> */}
                  {/* <div className=""> */}
                    {/* <div className='filter-input-box row '> */}
                      <div className="col-4 d-flex">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format='DD/MM/YYYY'
                              value={filterFromDate}
                              onChange={(date) => setFilterFromDate(date)}
                              open={isOpenFromDate}
                              onOpen={() => setIsOpenFromDate(true)}
                              onClose={() => setIsOpenFromDate(false)}
                              sx={{
                                display: 'flex',
                                // width: 195,
                                height: 40,
                              }}
                              slotProps={{ textField: { size: 'small', placeholder: 'From', type: 'text' } }}
                            />
                        </LocalizationProvider>
                      {/* </div>
                      <div className="col-sm-2"> */}
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                              format='DD/MM/YYYY'
                              value={filterToDate}
                              onChange={(date) => setFilterToDate(date)}
                              open={isOpenToDate}
                              onOpen={() => setIsOpenToDate(true)}
                              onClose={() => setIsOpenToDate(false)}
                              sx={{
                                display: 'flex',
                                // width: 195,
                                height: 40,
                                marginLeft: 1.3
                              }}
                              slotProps={{ textField: { size: 'small', placeholder: "To" } }}
                            />
                        </LocalizationProvider>
                      </div>
                    {/* </div> */}
                  {/* </div> */}
                  </div>
                </form>
              </div>
            </div>
            <div className="estimate-list-table-data my-5">
              <DataTable
                columns={columns}
                data={rows}
                fixedHeaderScrollHeight="600px"
                customStyles={customStyles}
                persistTableHead
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
                highlightOnHover
                pointerOnHover
                onRowClicked={handleRowClick}
              />
            </div>
          </div>
        </div>
      </section>
      <Delete
        title1="Are you sure"
        title2="Do you really want to delete these record?"
        isOpen={openDelete}
        onClose={() => {
          setOpenDelete(false);
        }}
        confirm={confirm}
      />
      <Customization_Warning_Popup
        displayPopup={customizationWarning}
        isOpen={customizationWarning}
        onClose={() => {
          setCustomizationWarning(false);
        }}
      />
      <Business_Warning_Popup
        displayPopup={businessWarning}
        isOpen={businessWarning}
        onClose={() => {
          setBusinessWarning(false);
        }}
    />
    <SendReceipt 
        opensend={sendEstimate}
        sendconfirm={sendconfirm}
        invoicedata={sendEstimateData}
        isOpen={sendEstimate}
        onClose={() => {
          setSendEstimate(false);
        }}
        estimateFlag={sendEstimate}
        estimatePDF={pdfDownloadUrl}
      />
    </>
  )
}

export default EstimateList