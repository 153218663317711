import { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

export default function ImageUploader(props) {

  const onDrop = useCallback((acceptedFiles) => {
    props.onImageSelect(acceptedFiles[0]);
  }, [props]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} type='file' accept="image/*" />
      <div className='dropbox'>
            <i className="bi bi-cloud-arrow-up-fill" style={{ fontSize: "50px", color: "blue", textShadow:"5px"}}></i>
            <p>Browse or drop your logo here</p>
            <pre>
                Maximum 5MB in size <br />
                JPG, PNG or IMG formats <br />
                Recommended size: 300 x 200 pixels.
            </pre>
      </div>
    </div>
  );
}
