import React, { useEffect, useState } from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Setting from '../Setting.sidebar'
import { useNavigate } from "react-router-dom";
import axios from 'axios'
import { toast } from 'react-toastify';
import './Business_Profile.css'
import data from "../../Utils/Countries_States.json";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Business_Profile_Validation from './Business_Profile_Validation'

function Business_Profile() {
    const navigate = useNavigate();
    const [inputs, setInputs] = useState({
        business_name : "",
        business_weburl : "",
        business_email : "",
        business_mobile : "",
        business_address : "",
        business_city : "",
        business_state : "",
        business_country : "",
        business_pincode: ""

    })
    const [errors, setErrors] = useState({});
    const [selectedcountry,setSelectedCountry] = useState("");
    const [selectedStates,setSelectedStates] = useState("");
    const countryName = data.map((item) => (item.country))
    const states = []
    const countryStates = data.map((item) => {
        if(item.country == selectedcountry){
            item.states.forEach((statenames) => {
                states.push(statenames)
            })
        } 
    })

 

  
   
    const user = localStorage.getItem("user");
    const business_id = JSON.parse(user)[0].business.id;

useEffect(()=>{
    const user = localStorage.getItem("user");
    if(user === null){
        localStorage.removeItem("user");
        navigate('/user-login');
    }
   
     //fetch business details
     axios.get('https://wavedemo.cydeztechnologies.com/list-business').then((res) => {
        const business_details = res.data.find(obj => obj.id === business_id )
        setInputs(business_details);
        setSelectedCountry(business_details.business_country);
        
    }).catch( error =>{
        toast.error("Something Went Wrong",{toastId: 'editbus5',})  ;
    });;


},[])
//prevent form submitting
const handleSubmit = (e) => {
    e.preventDefault();
}
const handleChange = (e) => {
   
    setInputs({ ...inputs, [e.target.name]: e.target.value })
    setErrors( { ...errors, [e.target.name]: "" });
   
};

const countryonChange = (event,value) => {
    setSelectedCountry(value);
    setInputs({ ...inputs, business_country : value , business_state: '' });   
}
const stateonChange = (event,value) => {
    setInputs({ ...inputs, business_state: value })
}


const editProfile = () =>{
    const validationstatus = Business_Profile_Validation(inputs);
    if (!validationstatus.status) {
      setErrors(validationstatus.errors);
    
    } else{
    axios.put(`https://wavedemo.cydeztechnologies.com/business-updation/${business_id}`,{
        business_name : inputs.business_name,
        business_weburl : inputs.business_weburl,
        business_email : inputs.business_email,
        business_mobile : inputs.business_mobile,
        business_address : inputs.business_address,
        business_city : inputs.business_city,
        business_state : inputs.business_state,
        business_country : inputs.business_country,
        business_pincode: inputs.business_pincode
    }).then(res => {
            if(res.data === "Business updated successfully"){
                toast.success("Business updated successfully",{toastId: 'editbus1', })  ;
            }
            else if(res.data === "Email already exists"){
                toast.error(res.data,{toastId: 'editbus2',});
            }
            else{
                toast.error("Something Went Wrong",{toastId: 'editbus2',});
            }

    }).catch( error =>{
        toast.error("Something Went Wrong",{toastId: 'editbus3',})  ;
    });
}

}

  return (
    <section>
    <Sidebar/>
    <Setting />
    <div className="column2 container my-5">
        <div className="header text-start fw-bold fs-4 text-capitalize">
            Edit Business Profile
        </div>
        <br/>
        <div className="edit-profile-form">
        <form onSubmit={handleSubmit}>
            <div className="form-group row mb-4">
                <label htmlFor="business_name" className="col-4 fw-bold text-end col-form-label text-muted">Company Name
                    <span className="importent">*</span>
                </label>
                <div className="col-sm-8">
                <input type="text"  className="form-control" 
                      name='business_name' 
                      value={inputs.business_name?inputs.business_name:''}
                      onChange={(e)=>{handleChange(e)}}/>
                   <span className="error-msg">
                                {errors.business_name ? errors.business_name : ""}
                    </span>    
                </div>
            </div>
            <div className="form-group row mb-4">
                <label htmlFor="business_address" className="col-4 fw-bold text-end col-form-label text-muted">Address
                    <span className="importent">*</span>
                </label>
                <div className="col-sm-8">
                    <input  name='business_address' 
                    className='form-control'  cols="30" rows="3"
                      value={inputs.business_address?inputs.business_address:''} 
                      onChange={(e)=>{handleChange(e)}} />
                       <span className="error-msg">
                        {errors.business_address ? errors.business_address : ""}
                    </span>
                </div>
            </div>
            <div className="form-group row mb-4">
                <label htmlFor="business_city" className="col-4 fw-bold text-end col-form-label text-muted">City
                    <span className="importent">*</span>
                </label>
                <div className="col-sm-8">
                <input type="text"  className="form-control"
                  name='business_city' 
                  value={inputs.business_city?inputs.business_city:''}
                  onChange={(e)=>{handleChange(e)}}
                />
                <span className="error-msg">
                        {errors.business_city ? errors.business_city : ""}
                </span>
                </div>
            </div>
            <div className="form-group row mb-4">
                <label htmlFor="business_country" className="col-4 fw-bold text-end col-form-label text-muted">Country
                    <span className="importent">*</span>
                </label>
                <div className="col-sm-8">
                <Autocomplete
                              
                                disablePortal
                                sx={{
                                    display: 'flex',
                                    '& input': {
                                      fontSize: 'smaller',
                                      height: '2px',
                                    },
                                  }}
                                  value={inputs.business_country}
                                onChange={(event, value) => {countryonChange(event,value)}}
                                options={countryName}
                                renderInput={(params) => (
                                            <TextField {...params}  />
                                             
                                             
                                             )}
                    />
                    <span className="error-msg">
                        {errors.business_country ? errors.business_country : ""}
                  </span>
                </div>
            </div>
            <div className="form-group row mb-4">
                <label htmlFor="business_state" className="col-4 fw-bold text-end col-form-label text-muted">Province/State
                    <span className="importent">*</span>
                </label>
                <div className="col-sm-8">
                <Autocomplete
                                
                                disablePortal
                                sx={{
                                    display: 'flex',
                                    '& input': {
                                      fontSize: 'smaller',
                                      height: '2px',
                                    },
                                  }}
                                  value={inputs.business_state}
                                  onChange={(event, value) => stateonChange(event,value)}
                                options={states}
                                renderInput={(params) => <TextField {...params} />}
                            />
                             <span className="error-msg">
                             {errors.business_state ? errors.business_state : ""}
                                </span>
                </div>
            </div>
            <div className="form-group row mb-4">
                <label htmlFor="business_pincode" className="col-4 fw-bold text-end col-form-label text-muted">Postal/Zip Code
                    <span className="importent">*</span>
                </label>
                <div className="col-sm-8">
                <input type="text"  className="form-control"
                  name='business_pincode' 
                  value={inputs.business_pincode?inputs.business_pincode:''}
                  onChange={(e)=>{handleChange(e)}} />
                   <span className="error-msg">
                        {errors.business_pincode ? errors.business_pincode : ""}
                    </span>
                </div>
            </div>
            <div className="form-group row mb-4">
                <label htmlFor="business_mobile" className="col-4 fw-bold text-end col-form-label text-muted">Phone
                    <span className="importent">*</span>
                </label>
                <div className="col-sm-8">
                <input type="text"  className="form-control"
                 name='business_mobile' 
                 value={inputs.business_mobile?inputs.business_mobile:''}
                 onChange={(e)=>{handleChange(e)}} />
                   <span className="error-msg">
                        {errors.business_mobile ? errors.business_mobile : ""}
                    </span>
                </div>
            </div>
            <div className="form-group row mb-4">
                <label htmlFor="business_email" className="col-4 fw-bold text-end col-form-label text-muted">Email
                    <span className="importent">*</span>
                </label>
                <div className="col-sm-8">
                <input type="email"  className="form-control"
                  name='business_email' 
                  value={inputs.business_email?inputs.business_email:''}
                  onChange={(e)=>{handleChange(e)}} />
                   <span className="error-msg">
                        {errors.business_email ? errors.business_email : ""}
                    </span>
                </div>
            </div>
            <div className="form-group row mb-4">
                <label htmlFor="business_weburl" className="col-4 fw-bold text-end col-form-label text-muted">Website
                    <span className="importent">*</span>
                </label>
                <div className="col-sm-8">
                <input type="text"  className="form-control" 
                 name='business_weburl' 
                 value={inputs.business_weburl?inputs.business_weburl:''}
                 onChange={(e)=>{handleChange(e)}} />
                 <span className="error-msg">
                        {errors.business_weburl ? errors.business_weburl : ""}
                    </span>
                </div>
                
            </div>
            <div className='col-12 text-center'>
                <button className='btn btn-primary edit-business-btn'
                         onClick={editProfile}
                >Save</button></div>
            </form>
        </div>
    </div>
    </section>

  )
}

export default Business_Profile