import { useNavigate } from "react-router-dom";
import Sidebar from "../../Sidebar/Sidebar";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import DataTable from 'react-data-table-component';
import LoadingSpinner from '../../Utils/Loader'


export default function ProductService() {
    const navigate = useNavigate();

    const addProducts = () => {
        navigate("/add-products");
    }

    const [products, setProducts] = useState([{
        id: "",
        product_name: "",
        description: "",
        price: "",
        created_at: "",
        business_id: "",
        tax_id: ""
    }]);

    const user = localStorage.getItem("user");
    const businessID = user && JSON.parse(user)[0].business.id;
    const businessCurrency = user && JSON.parse(user)[0].business_currency === null ? "$" : JSON.parse(user)[0].business_currency.toString().split(" - ")[2];

    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    useEffect(() => {
        if(user === null){
            localStorage.removeItem("user");
            navigate('/error-page');
            }

        axios.get(`https://wavedemo.cydeztechnologies.com/list-products/${businessID}`)
            .then((res) => {
                if(res.data === "No data found"){
                    setProducts([])
                    setLoading(true)
                }
                else{
                    setProducts(res.data)
                    setLoading(true)
                }
                
            })
            .catch((e) => {
                console.log("Error in /list-products:", e.message)
                setLoading(true)
                toast.error(e.message, {toastId:"error"})
            })
    }, [])

    const filteredData = products && products.filter(item => {
        return item.product_name.toLowerCase().includes(searchQuery.toLowerCase());
    });

    function handleEdit(item) {
        const selectedProduct = products.find((data) => data.id === item.id)
        navigate("/edit-product-service", {
            state: { selectedProduct }
        });
    }


    const customStyles = {
        headRow: {
            style: {
                color: '#000',
                fontWeight: "bolder",
                fontSize: "16px",
                borderBottom: "1px solid black"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontSize: "16px",
                minHeight: '55px'
            }
        },

    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.product_name,
            grow: 3,
            sortable: true,
        },
        {
            name: 'Price',
            selector: row => row.price,
            grow: 1,
            // center: true
        },
        {
            name: 'Action',
            selector: row => row.action,
            grow: 1,
            center: true
        },
    ];

    const rows = filteredData ? filteredData.map((item) => {
        return {
            product_name: item.product_name,
            price: `${businessCurrency} ${Number(item.price).toFixed(2)}`,
            action: <button className="btn btn-none" onClick={() => handleEdit(item)}><FontAwesomeIcon icon={faPen} style={{ color: "#2046cf", fontSize: "18px" }} /></button>,
        }
    }) : '';


    return (
        <section>
            <Sidebar />
            {loading ?
                <div className="container main-coloum1">
                    <div className="row container">
                        <div className="col-6 mt-5 fw-bold fs-3 text-capitalize">
                            Products & Services (Sales)
                        </div>
                        <div className="col-6 form-group mt-5 text-end">
                            <button type="button" onClick={addProducts} className="btn btn-primary rounded-5">Add a Products or Services</button>
                        </div>
                    </div>
                    <div className="row container mt-5">
                        <div className="col-sm-3">
                            <input type="text" className="form-control rounded border-primary" placeholder="Search by product" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                        </div>
                        {searchQuery && searchQuery.length > 0 &&
                            <div className="col pt-1">
                                {filteredData.length} product found
                            </div>}
                    </div>
                    <div className="container mt-4">
                        <DataTable
                            columns={columns}
                            data={rows}
                            fixedHeaderScrollHeight="600px"
                            customStyles={customStyles}
                            persistTableHead
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 25, 50]}
                            highlightOnHover
                        />
                    </div>
                </div>
                :
                <LoadingSpinner />}
        </section>
    )
}