import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Tooltip } from "antd";
import dayjs from 'dayjs';

export default function SendReceipt(props) {
  const { sendconfirm, invoicedata, isOpen, onClose, flag, estimateFlag, estimatePDF, sendEmailFlagReminder} = props;
  const [_isOpen, setOpen] = useState(isOpen);
  const user = localStorage.getItem("user");
  const businessID = JSON.parse(user)[0].business.id;
  const businessName = user && JSON.parse(user)[0].business.business_name;
  const userEmail = JSON.parse(user)[0].email;

  const [data, setData] = useState({
      from: "",
      to: "",
      subject: "",
      message: ""
    }
  );

  const [messagePlace, setMessagePlace] = useState("");
  const [errors, setErrors] = useState({});
  const [errorsMessage, setErrorsMessage] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
    setErrors(false);
    setData({});
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (invoicedata) {
      setData((prevData) => ({
        ...prevData,
        from: userEmail,
        subject:
          flag ?
          `Payment Receipt for Invoice ${invoicedata.prefix} ${invoicedata.transaction_number}`
          :
          estimateFlag ? 
          `${invoicedata.transaction_name} #${invoicedata.transaction_number} from ${businessName}`
          :
          `Invoice ${invoicedata.prefix} ${invoicedata.transaction_number} from ${businessName}`,
        message: 
          flag ? 
`Hey ${ invoicedata.customer_id && invoicedata.customer_id.customer_company_name},\n\nHope you're doing well! Just wanted to drop you a quick email to let you know that we've received your payment for Invoice ${invoicedata.prefix}${invoicedata.transaction_number}. You rock!\n\nTo keep things organized, we've attached your payment receipt to this email. Feel free to download it and have a look. If you have any questions or need further clarification, just give us a shout and we'll be more than happy to assist you.\n\nThanks again for your prompt payment!\n\nThanks,\n\n${businessName}`
  :
  estimateFlag ? 
`Hey ${invoicedata && invoicedata.customer_id.id && invoicedata.customer_id.customer_company_name},\n\nHope you're doing well! Just wanted to drop you a quick note with the details of ${invoicedata.transaction_name} #${invoicedata.transaction_number}.\n\nAmount due: ${invoicedata.total_amount} ${invoicedata.currency.code}\n\nExpires on: ${invoicedata.expiry_date &&  dayjs(invoicedata.expiry_date).format('MMMM DD, YYYY')}\n\nTo view this estimate online, simply click on the link below:\n\n${estimatePDF}\n\nIf you have any questions or need further assistance, feel free to reach out. We're always here to help!\n\nThanks,\n\n${businessName}`
  :
sendEmailFlagReminder ?
`Hey ${ invoicedata.customer_id && invoicedata.customer_id.customer_company_name},\n\nJust wanted to give you a friendly reminder that payment for the invoice dated ${invoicedata.date &&  dayjs(invoicedata.date).format('MMMM DD, YYYY')} is now overdue. It was actually due on ${invoicedata.expiry_date &&  dayjs(invoicedata.expiry_date).format('MMMM DD, YYYY')}. We kindly request you to submit the payment for this invoice as soon as possible.\n\nIf you have any questions or need assistance with the payment process, feel free to reach out. We're here to help!\n\nThanks,\n\n${businessName}`
  :
`Hey ${ invoicedata.customer_id && invoicedata.customer_id.customer_company_name},\n\nHope you're doing well! Just wanted to drop you a quick note about the Invoice ${invoicedata.prefix}${invoicedata.transaction_number}. You can find all the details by downloading the attached document.\n\nThe total amount due is  ${invoicedata.currency && invoicedata.currency['symbol']}${invoicedata.payment_record !== undefined ? invoicedata.payment_record['amount'] : invoicedata.due_amount } ${invoicedata.currency &&  invoicedata.currency['code']}, and please note that this invoice will expire on ${invoicedata.expiry_date &&  dayjs(invoicedata.expiry_date).format('MMMM DD, YYYY')}.\n\nIf you have any questions or need further assistance, feel free to reach out. We're always here to help!\n\nThanks,\n\n${businessName}`
      }));

      invoicedata.customer_id && setMessagePlace(`Enter your message to ${invoicedata.customer_id.customer_company_name}...`);
    }
  }, [invoicedata, isOpen]);

  //due_amount

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors(false);
  };

  const sendEmailCallback = () => {
    sendconfirm(true);
      setOpen(false);
      if (onClose) onClose();
      setErrors(false);
      setData({});
  };

  const handleSendEmail = (mailFrom ,mailTo, mailsubject, mailmessage, callback) => {
    const from = mailFrom
    const recipient = mailTo;
    const subject = encodeURIComponent(mailsubject);
    const message = encodeURIComponent(mailmessage);
    const mailtoLink = `mailto:${recipient}?subject=${subject}&body=${message}&from=${from}`;

    window.location = mailtoLink;

    if (typeof callback === 'function') {
      callback();
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const errors = {};
    const errorMessage = {};
    for (const [key, value] of Object.entries(data)) {
      var name;
      if (key === "from") {
        name = "Onwer email";
      }
      if (key === "to") {
        name = "Customer email";
      }
      if (key === "subject") {
        name = "Subject";
      }
      if (key !== "message") {
        if (!String(value).trim()) {
          errors[key] = true;
          errorMessage[key] = `${name} is required`;
        } else if (key === "from" && !/\S+@\S+\.\S+/.test(value)) {
          errors[key] = true;
          errorMessage[key] = `Onwer email is invalid`;
        } else if (key === "to" && !/\S+@\S+\.\S+/.test(value)) {
          errors[key] = true;
          errorMessage[key] = `Customer email is invalid`;
        }
      }
    }

    setErrors(errors);
    setErrorsMessage(errorMessage);
    if (Object.keys(errors).length === 0) {
      // setIsSubmitting(true);
      // handleSendEmail(data.from ,data.to, data.subject, data.message, sendEmailCallback);
        setIsSubmitting(true);
        axios.post(
          estimateFlag
            ? `https://wavedemo.cydeztechnologies.com/estimate-email/${invoicedata.id}`
            : `https://wavedemo.cydeztechnologies.com/invoice-email/${invoicedata.id}`,
          {
            email: data.to,
            subject: data.subject,
            content: data.message,
            from: data.from,
          }
        )
        .then((res) => {
            if (res.data === "Email sent successfully") {
                toast.success('Email sended successfully', {toastId:"addty-1"});
                sendEmailCallback();
                setIsSubmitting(false);
            }
            else {
                toast.error(res.data.trim(), {toastId:"addty-1"});
            }
        })
        .catch((e) => {
            toast.error(e.message, {toastId:"err-api"});
        });
    }
    else{
      toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId: 'sendmessage'})
    }
  };

  return (
    <>
      <Dialog
        open={_isOpen}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll={"body"}
        disablebackdropclick="true"
        disableEscapeKeyDown={true}
        fullWidth={true}
        maxWidth={"sm"}
        style={{ height: "auto", maxHeight: "900px" }}
      >
        {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Add Payment Method</DialogTitle> */}
        <DialogTitle
          sx={{ position: "relative" }}
          id="alert-dialog-title"
          className="fw-bold fs-4 mt-2 align-content-center "
        >
        {flag ? 
          <>Send a Receipt</> : 
          estimateFlag ? <>Send a Estimate</> :
          // <>Send a Invoice</>
          invoicedata && invoicedata.status === "Unsend" || invoicedata && invoicedata.status === "Draft" || invoicedata && invoicedata.status === "Overdue" ? <>Send a Invoice</> : 
          sendEmailFlagReminder ? <>Send a Reminder Invoice</>:
          <>Resend a Invoice</>
        }  
          <i
            className="bi bi-x-lg"
            style={{
              position: "absolute",
              top: "22%",
              right: "5%",
              bottom: "0%",
              color: "#d5cdcd",
              cursor: "pointer",
            }}
            onClick={handleClose}
          ></i>
        </DialogTitle>
        <hr />
        <DialogContent className="px-5 mt-2">
          <div className="form-group row m-4">
            <label className="col-sm-5 fw-bold text-start text-nowrap">
              From <span className="importent">*</span>
            </label>
            <div className="col-sm-7">
              <Tooltip
                title={"Date is requried"}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.from}
                overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                autoAdjustOverflow={false}
                zIndex={9999}
              >
                <input
                  type="text"
                  className={`${
                    errors.from
                      ? "ms-2 rounded border border-2 border-danger py-1 w-100 ps-2"
                      : "ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"
                  }`}
                  name="from"
                  value={data.from}
                  onChange={handleChange}
                  disabled
                />
                {errors.from && <div className="mb-2"></div>}
              </Tooltip>
            </div>
          </div>
          <div className="form-group row m-4">
            <label className="col-sm-5 fw-bold text-start pe-5 text-nowrap">
              To <span className="importent">*</span>{" "}
            </label>
            <div className="col-sm-7">
              <Tooltip
                title={errorsMessage.to}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.to}
                overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                autoAdjustOverflow={false}
                zIndex={9999}
              >
                <input
                  type="text"
                  className={`${
                    errors.to
                      ? "ms-2 rounded border border-2 border-danger py-1 w-100 ps-2"
                      : "ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"
                  }`}
                  name="to"
                  value={data.to}
                  onChange={handleChange}
                />
                {errors.to && <div className="mb-2"></div>}
              </Tooltip>
            </div>
          </div>
          <div className="form-group row m-4">
            <label className="col-sm-5 fw-bold text-start pe-5 text-nowrap">
              Subject <span className="importent">*</span>{" "}
            </label>
            <div className="col-sm-7">
              <Tooltip
                title={errorsMessage.subject}
                placement="bottom"
                color="#990000"
                trigger={"contextMenu"}
                open={errors.subject}
                overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                autoAdjustOverflow={false}
                zIndex={9999}
              >
                <input
                  type="text"
                  className={`${
                    errors.subject
                      ? "ms-2 rounded border border-2 border-danger py-1 w-100 ps-2"
                      : "ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"
                  }`}
                  name="subject"
                  value={data.subject}
                  onChange={handleChange}
                />
                {errors.subject && <div className="mb-2"></div>}
              </Tooltip>
            </div>
          </div>
          <div className="form-group row m-4">
            <label className="col-sm-5 fw-bold text-start text-nowrap">
              Message
            </label>
            <div className="col-sm-7">
              <textarea rows="6"
                className="ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"
                name="message"
                value={data.message}
                onChange={handleChange}
                placeholder={messagePlace}
              ></textarea>
            </div>
          </div>
        </DialogContent>
        <hr />
        <DialogActions className="mb-4">
          <button
            onClick={handleClose}
            className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}
          >
            Cancel
          </button>
          <button
            onClick={handleSubmit}
            disabled={isSubmitting}
            className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize"
          >
            {isSubmitting ? "Uploading..." : "Send"}
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
}
