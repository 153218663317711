import React, { useState, useEffect } from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import Invoice_Preview from './Invoice_Preview';
import { useNavigate, useLocation } from "react-router-dom";
import { format } from 'date-fns';
import axios from 'axios';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import Customization_Warning_Popup from '../Estimate/Customization_Warning_Popup';
import Business_Warning_Popup from '../Estimate/Business_Warning _Popup';


function Invoice_Editable_Preview() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = localStorage.getItem("user");
  const business_id = user&&JSON.parse(user)[0].business.id;
  const invoice = location.state && location.state.invoicePreview;
  const productDetails = location.state && location.state.invoiceProducts;
  const editFlag = location.state && location.state.editInvoice;
  const editEstimate = location.state && location.state.editEstimate;
  const [errors, setErrors] = useState({});

  const [customizationWarning, setCustomizationWarning] = useState(false);
  const [businessdata, setBusinessdata] = useState({});
  const [businessWarning,setBusinessWarning] = useState(false);

  const addInvoice = () => {
    const validaion = invoiceValidation();
    if (!validaion) {

      const apiDate = dayjs(invoice.expiry_date).format('YYYY-MM-DD');
      const currentTime = dayjs().format('HH:mm:ss');
      const combinedDateTime = `${apiDate}T${currentTime}`;


      const data = {
        "business_id": invoice.business_id,
        "prefix": invoice.prefix,
        "transaction_type": 2,
        "transaction_number": invoice.transaction_number,
        "transaction_name": invoice.transaction_name,
        "purchase_order": invoice.purchase_order,
        "customer_id": invoice.customer_id.id,
        "currency": invoice.currency.id,
        "date": dayjs(invoice.date).format('YYYY-MM-DDTHH:mm:ss'),
        "expiry_date": dayjs(combinedDateTime).format('YYYY-MM-DDTHH:mm:ss'),
        "subheading": invoice.subheading,
        "footer": invoice.footer,
        "memo": invoice.memo,
        "discount_description": "",
        "discount_type": "",
        "discount_value": "",
        "status": 5,
        "total_amount": invoice.total_amount,
        "total_amount_business": invoice.total_amount_business,
        "products": invoice.products
      }

      axios.post('https://wavedemo.cydeztechnologies.com/invoices', data).
        then((res) => {
          const invoice = res.data;
          const invoiceid = res.data.id;
          if (invoiceid) {
            toast.success("Invoice successfully created", { toastId: 'invc1' })
            navigate('/invoices/view-invoice', {
              state: { invoice }
            })
          }
          else {
            toast.error("Invoice Creation failed", { toastId: 'invc2' })

          }

        }).catch((error) => { toast.error("API Error", { toastId: 'invc3' }); })

    }
  }
  const invoiceValidation = () => {
    const error = {};
    if (!invoice.customer_id) {
      error["customer"] = true;
    }

    if (invoice.products.length === 0) { error["product"] = true; }

    setErrors(error);

    return Object.values(error).some((value) => value === true);

  }

  const updateInvoice = () => {
    const validaion = invoiceValidation();
    if (!validaion) {

      const apiDate = dayjs(invoice.expiry_date).format('YYYY-MM-DD');
      const currentTime = dayjs().format('HH:mm:ss');
      const combinedDateTime = `${apiDate}T${currentTime}`;

      let statusid = 5;

      if( invoice && invoice.status){
        axios.get("https://wavedemo.cydeztechnologies.com/list-status").then((res)=>{
          if(invoice.status === "Saved" || invoice.status === "Expired" || invoice.status === "Send"){
            statusid = 5;
          }
          else if(res[0].status_name === invoice.status){
            statusid = res[0].id;
          }
        }).catch((error) => { toast.error(error, { toastId: 'status1' });});
      }

      const data = {
        "business_id": invoice.business_id,
        "prefix": invoice.prefix,
        "transaction_type": 2,
        "transaction_number": invoice.transaction_number,
        "transaction_name": invoice.transaction_name,
        "purchase_order": invoice.purchase_order,
        "customer_id": invoice.customer_id.id,
        "currency": invoice.currency.id,
        "date": dayjs(invoice.date).format('YYYY-MM-DDTHH:mm:ss'),
        "expiry_date": dayjs(combinedDateTime).format('YYYY-MM-DDTHH:mm:ss'),
        "subheading": invoice.subheading,
        "footer": invoice.footer,
        "memo": invoice.memo,
        "discount_description": "",
        "discount_type": "",
        "discount_value": "",
        "status": statusid,
        "total_amount": invoice.total_amount,
        "total_amount_business": invoice.total_amount_business,
        "products": invoice.products
      }
      
      axios.put(`https://wavedemo.cydeztechnologies.com/invoices-update/${invoice.id}`, data).
        then((res) => {
          const invoice = res.data;
          const invoiceid = res.data.id;
          if (invoiceid) {
            toast.success("Invoice updated successfully ", { toastId: 'invcupd1' })
            navigate('/invoices/view-invoice', {
              state: { invoice }
            })
          }
          else {
            toast.error("Invoice updation failed", { toastId: 'invcupd2' })
          }

        }).catch((error) => { toast.error("API Error", { toastId: 'invcupd3' });});
    }
  }

  const business_data = () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-business`)
    .then((res)=>{
      const responseData = res.data; 
      const filteredData = responseData && responseData.filter(item => item.id === business_id);
      setBusinessdata(filteredData[0]);
    }).catch(error =>{
      toast.error(error.message,{toastId: 'addest5',})  ;
  });
  }
  
  useEffect(()=>{
    const user = localStorage.getItem("user");
    if(user === null){
        localStorage.removeItem("user");
        navigate('/user-login');
    }
    else if(!location.state){
      navigate(-1);
    }
    else {
      business_data();
      axios.get(`https://wavedemo.cydeztechnologies.com/get-invoice-customization-details/${business_id}`)
        .then((res) => {
          if (parseInt(res.data.transaction_number) == null || res.data.invoice_default_title == null) {
            setCustomizationWarning(true)
          }
          else if (businessdata && businessdata.business_address === null) {
            setBusinessWarning(true);
          }
        }).catch(error => {
          toast.error(`Invoice Details API Error1 ${error}`, { toastId: 'addest5', });
        });
    }
  },[])



  return (
    <section >
      <Sidebar />
      <div className="main-body-container px-5" >
        <div className="add-invoice-container" >

          <div className='d-flex justify-content-between align-items-center mb-3'>
            <div className="add-invoice-title">
              <h2 className='section-title'>{editEstimate ? "Edit Invoice" : editFlag ? "Edit Invoice" : "New Invoice"}</h2>
            </div>
            <div>
              <button className='btn btn-outline-primary wv-outline-btn  me-4 px-4'
                onClick={() => {
                  editEstimate ? 
                    navigate('/invoices/edit-invoice', { state: { estimateDetails: invoice } })
                  :
                  editFlag ?
                    navigate('/invoices/edit-invoice', { state: { invoice: invoice, editpreviewflag: true } })
                    :
                    navigate('/invoices/add-invoice', { state: { invoice, productDetails } })
                }} >Edit</button>
              <button className='btn btn-primary wv-actn-fill-btn' onClick={editEstimate ? addInvoice : editFlag ? updateInvoice : addInvoice}> 
                Save and continue</button>
            </div>
          </div>
          {errors.customer || errors.product ?
            <div className="warning-section my-3 " style={{ width: "885px", margin: "0 auto" }}>
              <div class="alert alert-danger" role="alert">
                <p className='fw-bold'>Oops! There was an issue with your invoice. Please try again.</p>
                {errors.customer && <span className='ms-5' style={{ display: "block" }}><b>Customer:</b> Add a customer to this invoice.</span>}
                {errors.product && <span className='ms-5'><b>Item:</b> Add an item to this invoice.</span>}

              </div>
            </div>
            : ''
          }
          {invoice && <Invoice_Preview invoice={invoice} />}
        </div>
      </div>
      <Customization_Warning_Popup
        displayPopup={customizationWarning}
        isOpen={customizationWarning}
        onClose={() => {
          setCustomizationWarning(false);
        }}
        navigateBack={true}
      />
      <Business_Warning_Popup
        displayPopup={businessWarning}
        isOpen={businessWarning}
        onClose={() => {
          setBusinessWarning(false);
        }}
        navigateBack={true}
    />
    </section>
  )
}

export default Invoice_Editable_Preview