import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import AddPaymentMethod from "../../Settings/Payment/Payment Method/AddPaymentMethod";
import AddPaymentAccount from "../../Settings/Payment/Payment Type/AddPaymentAccount";
import { Tooltip } from 'antd';
import Succuess from "../../AlertBox/Success";

export default function RecordPayment(props) {
    const { displayRecordPayment, paymentData, selectRecordPayment, isOpen, onClose } = props;
    const [_isOpen, setOpen] = useState(isOpen);
    const user = localStorage.getItem("user");
    const businessID = JSON.parse(user)[0].business.id;
    const businessCurrency = paymentData && paymentData.currency.symbol
    const currentDate = dayjs(); // Get current date using dayjs
    const [payment, setPayment] = useState({
        date: "",
        amount: "",
        paymentmethod_name: "",
        paymentaccount: "",
        description: "",
    });

    const [paymentAccount, setPaymentAccount] = useState([]);
    const [paymentType, setPaymenttype] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [errors, setErrors] = useState({});
    const [errorsMessage, setErrorsMessage] = useState({});
    const [addPaymentMethod, setAddPaymentMethod] = useState(false)
    const [addPaymentAccount, setAddPaymentAccount] = useState(false)
    const [successPayment, setSuccessPayment] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [findData, setFindData] = useState(false);

    useEffect(() => {
        if (selectRecordPayment && selectRecordPayment !== undefined) {
            setPayment({
                date: selectRecordPayment.date !== null ? selectRecordPayment.date : selectRecordPayment.created_at,
                amount: selectRecordPayment.amount,
                paymentmethod_name: selectRecordPayment.payment_method,
                paymentaccount: selectRecordPayment.payment_type,
                description: selectRecordPayment.description,
            })
            setFindData(true);
        }
        else if (paymentData && paymentData !== "No data found!") {
            setPayment({
                date: currentDate,
                amount: paymentData && paymentData.due_amount,
                paymentmethod_name: "",
                paymentaccount: "",
                description: "",
            })
        }
        console.log(paymentData, selectRecordPayment);
    }, [isOpen])

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
        setPayment({ ...payment, amount: "", paymentmethod_name: "", paymentaccount: "", description: "" });
        setErrors(false);
    };

    useEffect(() => {
        setOpen(isOpen);

        axios.get(`https://wavedemo.cydeztechnologies.com/list-payment-method-by-businessid/${businessID}`).then((response) => {
            if (response.data === "No data found") {
                setPaymentAccount([]);
            }
            else {
                const methodName = response.data.filter((item) => item.parent_id === null);
                setPaymentAccount(methodName);
                const typeName = response.data.filter((item) => item.parent_id !== null);
                setPaymenttype(typeName);
            }
        }).catch((e) => {
            toast.error(e.message);
        });

        // if(selectRecordPayment){
        //     console.log("selectRecordPayment: ", selectRecordPayment)
        //     axios.get(`https://wavedemo.cydeztechnologies.com/payment-record-byid/${selectRecordPayment.id}`).then((response) => {
        //         if (response.data === "No data found") {
        //             setPayment([]);
        //         }
        //         else{
        //             setPayment(response.data);
        //             // setFindData(true);
        //         }
        //     }).catch((e) => {
        //         toast.error(e.message);
        //     });
        // }



    }, [isOpen, !addPaymentMethod, paymentData]);

    useEffect(() => {
        if (payment.paymentmethod_name) {
            findIdByName(payment.paymentmethod_name);
        }
    }, [payment.paymentmethod_name, !addPaymentAccount])

    const findIdByName = (name) => {
        const foundObject = paymentAccount.find((obj) => obj.paymentmethod_name === name);
        if (foundObject) {
            return axios.get(`https://wavedemo.cydeztechnologies.com/list-payment-method-by-parentid/${foundObject.id}`).then((response) => {
                if (response.data !== "No data found") {
                    const methodName = response.data.filter((item) => item.parent_id !== null);
                    setPaymentMethod(methodName);
                }
                else if (response.data === "No data found") {
                    setPaymentMethod([]);
                }
            }).catch((e) => {
                console.log("Error in /list-payment-method-by-businessid:", e);
                toast.error(e.message, { toastId: "error1" });
            });
        }
        return "";
    };

    const statusUpdate = () => {
        if (paymentData && 
            paymentData !== "No data found!" && 
            paymentData.due_amount >= 0 && 
            (paymentData.due_amount === payment.amount || 
                selectRecordPayment && Number(selectRecordPayment.amount) + Number(paymentData.due_amount) === Number(payment.amount))) {
            
                axios.put(`https://wavedemo.cydeztechnologies.com/update-status/${paymentData.id}`, {
                "status": 6
            })
                .catch((e) => {
                    toast.error(e.message, { toastId: "err-api" });
                });
        }
        else {
            const currentD = dayjs(currentDate).toDate();
            const exp_date = dayjs(paymentData.expiry_date).toDate();
            console.log("paid status working");
            if (currentD > exp_date)
                axios.put(`https://wavedemo.cydeztechnologies.com/update-status/${paymentData.id}`, {
                    "status": 4
                })
                    .catch((e) => {
                        toast.error(e.message, { toastId: "err-api" });
                    });
            else {
                axios.put(`https://wavedemo.cydeztechnologies.com/update-status/${paymentData.id}`, {
                    "status": 8
                })
                    .catch((e) => {
                        toast.error(e.message, { toastId: "err-api" });
                    });
            }
        }
    }

    const updateSelectMethod = (newValue) => {
        // console.log(newValue);
        if (newValue) {
            setPayment({ ...payment, paymentmethod_name: newValue.paymentmethod_name });
        }
    }

    const handleChange = (event) => {
        const { name, value } = event.target;
        setPayment((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const parentId = paymentAccount.find((item) => {
            if (item.paymentmethod_name === payment.paymentmethod_name) {
                return (item.id)
            }
        });

        const errors = {};
        const errorMessage = {};
        for (const [key, value] of Object.entries(payment)) {
            var name;
            if (key === "amount") { name = "Amount" };
            if (key === "paymentmethod_name") { name = "Payment method" };
            if (key === "paymentaccount") { name = "Payment type" };
            if (key !== 'description') {
                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`;
                }
                else if (key === "paymentmethod_name" && value === null){
                    errors[key] = true;
                    errorMessage[key] = `Payment method is required`;
                }
                else if (key === 'amount' && !/^-?[1-9]\d*(\.\d+)?$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = `Amount is invalid`;
                }
                else if (key === 'amount' && value > (selectRecordPayment ? selectRecordPayment && Number(selectRecordPayment.amount) + Number(paymentData.due_amount) : paymentData ? Number(paymentData.due_amount) : 0)) {
                    errors[key] = true;
                    errorMessage[key] = `Amount is more than the actual amount ${selectRecordPayment ? selectRecordPayment && Number(selectRecordPayment.amount) + Number(paymentData.due_amount) : paymentData ? paymentData.due_amount : 0}${businessCurrency}`;
                }
            }
        }

        setErrors(errors);
        setErrorsMessage(errorMessage);
        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
            if (findData && selectRecordPayment && selectRecordPayment !== undefined) {
                axios.put(`https://wavedemo.cydeztechnologies.com/payment-record-update/${selectRecordPayment.id}`, {
                    business_id: businessID,
                    invoice_number: paymentData.id,
                    payment_method: parentId.paymentmethod_name,
                    amount: payment.amount,
                    payment_type: payment.paymentaccount,
                    description: payment.description,
                    date: payment.date
                })
                    .then((res) => {
                        if (res.data) {
                            toast.success(res.data, { toastId: "pyme-1" });
                            statusUpdate();
                            setOpen(false);
                            if (onClose) onClose();
                            setPayment({
                                ...payment,
                                amount: "",
                                paymentmethod_name: "",
                                paymentaccount: "",
                                description: "",
                            });
                            setErrors(false);
                            setIsSubmitting(false);
                            setSuccessPayment(true);
                        }
                        else {
                            toast.error(res.data.trim(), { toastId: "pyme-1" });
                        }
                    })
                    .catch((e) => {
                        toast.error(e.message, { toastId: "err-api" });
                    });
            }
            else {
                axios.post(`https://wavedemo.cydeztechnologies.com/payment-record`, {
                    business_id: businessID,
                    invoice_number: paymentData.id,
                    payment_method: parentId.paymentmethod_name,
                    amount: payment.amount && Number(payment.amount),
                    payment_type: payment.paymentaccount,
                    description: payment.description,
                    date: payment.date
                })
                    .then((res) => {
                        if (res.data) {
                            toast.success(res.data, { toastId: "pyme-1" });
                            statusUpdate();
                            setOpen(false);
                            if (onClose) onClose();
                            setPayment({
                                ...payment,
                                amount: "",
                                paymentmethod_name: "",
                                paymentaccount: "",
                                description: "",
                            });
                            setErrors(false);
                            setIsSubmitting(false);
                            setSuccessPayment(true);
                        }
                        else {
                            toast.error(res.data.trim(), { toastId: "pyme-1" });
                        }
                    })
                    .catch((e) => {
                        toast.error(e.message, { toastId: "err-api" });
                    });
            }
        }
        else {
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, { toastId: "error1" });
        }
    }

    return (
        <>
            <Dialog
                open={displayRecordPayment}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll={'body'}
                disablebackdropclick="true"
                disableEscapeKeyDown={true}
                fullWidth={true}
                maxWidth={'sm'}
                style={{ height: "auto", maxHeight: "900px" }}
            >
                {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Add Payment Method</DialogTitle> */}
                <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center ">
                    Record Payment
                    <i
                        className="bi bi-x-lg"
                        style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                        onClick={handleClose}
                    ></i>
                </DialogTitle>
                <hr />
                <DialogContent className="px-5 mt-2" >
                    <div className="form-group row m-4">
                        <label className="col-sm-5 fw-bold text-start text-nowrap">Date <span className="importent">*</span></label>
                        <div className="col-sm-7">
                            <Tooltip
                                title={"Date is requried"}
                                placement="bottom"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.date}
                                overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                                autoAdjustOverflow={false}
                                zIndex={9999}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className={`${errors.date ? "ms-2 rounded border border-2 border-danger w-100" : "ms-2 rounded border border-2 border-primary w-100"}`}
                                        slotProps={{ textField: { size: 'small' } }}
                                        value={payment ? dayjs(payment.date) : null}
                                        onChange={(newDate) => {
                                            const updatedDate = newDate ? dayjs(newDate).toDate() : null;
                                            setPayment({ ...payment, date: updatedDate });
                                        }}
                                        format='DD/MM/YYYY'
                                        maxDate={currentDate}
                                    />
                                </LocalizationProvider>
                                {errors.date && <div className="mb-2"></div>}
                            </Tooltip>
                        </div>
                    </div>
                    <div className="form-group row m-4">
                    <label className="col-sm-5 fw-bold text-start pe-5 text-nowrap">{`Amount${businessCurrency && `(${businessCurrency})`}`} <span className="importent">*</span> </label>
                        <div className="col-sm-7">
                            <Tooltip
                                title={errorsMessage.amount}
                                placement="bottom"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.amount}
                                overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                                autoAdjustOverflow={false}
                                zIndex={9999}
                            >
                                <input
                                    type="text"
                                    className={`${errors.amount ? "ms-2 rounded border border-2 border-danger py-1 w-100 ps-2" : "ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"}`}
                                    name='amount'
                                    value={payment.amount}
                                    onChange={handleChange}
                                />
                                {errors.amount && <div className="mb-2"></div>}
                            </Tooltip>
                        </div>
                    </div>
                    <div className="form-group row m-4">
                        <label className="col-sm-5 fw-bold text-start pe-5 text-nowrap">Payment Method <span className="importent">*</span> </label>
                        <div className="col-sm-7">
                            <Tooltip
                                title={errorsMessage.paymentmethod_name}
                                placement="bottom"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.paymentmethod_name}
                                overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                                autoAdjustOverflow={false}
                                zIndex={1300}
                            >
                                <Autocomplete
                                    // disablePortal
                                    sx={{
                                        '& input': {
                                            height: 0,
                                        },
                                    }}
                                    className={`${errors.paymentmethod_name ? "border border-2 rounded border-danger ms-2 w-100" : "border border-2 rounded border-primary ms-2 w-100"}`}
                                    ListboxProps={{ style: { maxHeight: 150 } }}
                                    value={payment.paymentmethod_name}
                                    onChange={(event, newValue) => {
                                        setPayment({ ...payment, paymentmethod_name: newValue, paymentaccount: "" });
                                        // setErrors({ ...errors, paymentmethod_name: false })
                                    }}
                                    options={paymentAccount.map((item) => item.paymentmethod_name)}
                                    renderInput={(params) => <TextField {...params} />}
                                    PaperComponent={({ children }) => {
                                        return (
                                            <Paper>
                                                {children}
                                                <Button
                                                    color="primary"
                                                    fullWidth
                                                    style={{ textTransform: 'none' }}
                                                    sx={{ mx: 'auto', py: '8px', mt: '2px', fontSize: 12, borderTop: 1, borderColor: 'grey.500', borderRadius: 0 }}
                                                    onMouseDown={() => {
                                                        setAddPaymentMethod(true)
                                                    }}
                                                >
                                                    <i className="bi bi-plus-circle me-1" ></i> Add new Payment Method
                                                </Button>
                                            </Paper>
                                        );
                                    }}
                                />
                                {errors.paymentmethod_name && <div className="mb-3"></div>}
                            </Tooltip>
                        </div>
                    </div>
                    <div className="form-group row m-4">
                        <label className="col-sm-5 fw-bold pe-5 text-start text-nowrap">Payment Account <span className="importent">*</span> </label>
                        <div className="col-sm-7">
                            {/* <input
                                type="text"
                                className="ms-2 rounded border border-2 border-primary py-1 w-100"
                                name='paymentaccount'
                                value={payment.paymentaccount}
                                onChange={handleChange}
                            /> */}
                            <Tooltip
                                title={errorsMessage.paymentaccount}
                                placement="bottom"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.paymentaccount}
                                overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                                autoAdjustOverflow={false}
                                zIndex={1300}
                            >
                                <Autocomplete
                                    // disablePortal
                                    sx={{
                                        '& input': {
                                            height: 0,
                                        },
                                    }}
                                    className={`${errors.paymentaccount ? "border border-2 rounded border-danger ms-2 w-100" : "border border-2 rounded border-primary ms-2 w-100"}`}
                                    ListboxProps={{ style: { maxHeight: 150 } }}
                                    value={payment.paymentaccount}
                                    onChange={(event, newValue) => {
                                        setPayment({ ...payment, paymentaccount: newValue });
                                    }}
                                    options={payment.paymentmethod_name ? paymentMethod.map((item) => item.paymentmethod_name) : []}
                                    renderInput={(params) => <TextField {...params} />}
                                    PaperComponent={({ children }) => {
                                        return (
                                            <Paper>
                                                {children}
                                                <Button
                                                    color="primary"
                                                    fullWidth
                                                    style={{ textTransform: 'none' }}
                                                    sx={{ mx: 'auto', py: '8px', mt: '2px', fontSize: 12, borderTop: 1, borderColor: 'grey.500', borderRadius: 0 }}
                                                    onMouseDown={() => {
                                                        setAddPaymentAccount(true)
                                                    }}
                                                >
                                                    <i className="bi bi-plus-circle me-1" ></i> Add new Payment Account
                                                </Button>
                                            </Paper>
                                        );
                                    }}
                                />
                                {errors.paymentaccount && <div className="mb-3"></div>}
                            </Tooltip>
                        </div>
                    </div>
                    <div className="form-group row m-4">
                        <label className="col-sm-5 fw-bold text-start text-nowrap">Notes</label>
                        <div className="col-sm-7">
                            {/* <input type="text" className="ms-2 rounded border border-2 border-primary py-1 w-100"/> */}
                            <textarea
                                className="ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"
                                name='description'
                                value={payment.description}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>
                </DialogContent>
                <hr />
                <DialogActions className='mb-4'>
                    <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
                        Cancel
                    </button>
                    <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
                        {isSubmitting ? 'Uploading...' : 'Ok'}
                    </button>
                </DialogActions>
            </Dialog>
            <AddPaymentMethod
                uploadSelectMethod={updateSelectMethod}
                uploadMethodList={paymentAccount}
                displayAddPaymentMethod={addPaymentMethod}
                isOpen={addPaymentMethod}
                onClose={() => {
                    setAddPaymentMethod(false);
                }}
            />
            <AddPaymentAccount
                uploadAccountList={paymentType}
                displayAddPaymentAccount={addPaymentAccount}
                isOpen={addPaymentAccount}
                onClose={() => {
                    setAddPaymentAccount(false);
                }}
            />
            <Succuess
                title1="Record a payment for this invoice"
                title2="The payment was recorded."
                isOpen={successPayment}
                onClose={() => {
                    setSuccessPayment(false);
                }}
                send={true}
                data={paymentData}
            />
        </>
    )
}