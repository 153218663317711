import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import User_Register from './components/Register/User_Register';
import User_Login from './components/Login/User_Login';
import Changepassword from './components/Login/Forgot_Password/Change_Password';
import Dashboard from './components/Dashboard/Dashboard';
import EditorDashboard from './components/Editor view/Dashboard/Dashboard';
import EstimateList from './components/Sales/Estimate/EstimateList';
import Customers from './components/Sales/Customers/Customer';
import ViewCustomers from './components/Sales/Customers/View_Customer';
import AddCustomers from './components/Sales/Customers/Add_Customer';
import EditCustomers from './components/Sales/Customers/Edit_Customer';
import ProductService from './components/Sales/Product_Service/Product_Service';
import AddProducts from './components/Sales/Product_Service/AddProducts';
import EditProductService from './components/Sales/Product_Service/Edit_Product_Service';
import User from './components/Settings/Users/User';
import InvoiceCustomization from './components/Settings/Invoice_Customization/Invoice_Customization';
import SalesTaxes from './components/Settings/Accounting/Sales Taxes/Sales_Taxes';
// import Expenses from './components/Settings/Accounting/Expenses/Expenses';
import PaymentIncome from './components/Accounting/PaymentIncome';
import Expenses from './components/Accounting/Expenses';
import PaymentMethod from './components/Settings/Payment/Payment Method/PaymentMethod';
import PaymentAccount from './components/Settings/Payment/Payment Type/PaymentAccount';
import Currency from './components/Settings/Accounting/Currrency/Currency';
import Export_Data from './components/Accounting/Export_Data';
import Invoices from './components/Sales/Invoices/Invoices';
import Business_Profile from './components/Settings/Business/Business_Profile';
import Add_Estimate from './components/Sales/Estimate/Add_Estimate';
import View_Estimate from './components/Sales/Estimate/View_Estimate';
import ErrorPage from './components/Utils/Page404';
import Add_Invoice from './components/Sales/Invoices/Add_Invoice';
import View_Invoice from './components/Sales/Invoices/View_Invoice';
import Edit_Invoice from './components/Sales/Invoices/Edit_Invoice';
import Invoice_Editable_Preview from './components/Sales/Invoices/Invoice_Editable_Preview';
import Home_page from './components/Home/Home_Page';
import { useState, useEffect } from 'react';


function App() {
  const [role,setRole] = useState('');

  useEffect(()=>{
    const user = localStorage.getItem("user");
    setRole(user && JSON.parse(user)[0].role);
  },[])

  return (
    role === 3 ?
      <>
        <Router>
          <Routes>
            <Route path='/' element={< Home_page />}></Route>
            <Route path='/user-login' element={< User_Login />}></Route>
            <Route path='/change-forgot-password' element={<Changepassword />}></Route>
            <Route path='/user-register' element={< User_Register />}></Route>
            <Route path='/dashboard' element={<EditorDashboard />}></Route>
            <Route path='/estimate' element={<EstimateList />}></Route>
            <Route path='/invoices' element={<Invoices />}></Route>
            <Route path='/customers' element={<Customers />}></Route>
            <Route path='/view-customers' element={<ViewCustomers />}></Route>
            <Route path='/add-customers' element={<AddCustomers />}></Route>
            <Route path='/edit-customers' element={<EditCustomers />}></Route>
            {/* <Route path='/add-products' element={<AddProducts />}></Route> */}
            <Route path='/estimate/add-estimate' element={<Add_Estimate />}></Route>
            <Route path='/estimate/view-estimate' element={<View_Estimate />}></Route>
            <Route path='/invoices/add-invoice' element={<Add_Invoice />}></Route>
            <Route path='/invoices/view-invoice' element={<View_Invoice />}></Route>
            <Route path='/accounting-payment-transaction' element={<PaymentIncome />}></Route>
            <Route path='/accounting-expenses' element={<Expenses />}></Route>
            <Route path='/accounting-export-your-data' element={<Export_Data />}></Route>
            <Route path='/error-page' element={<ErrorPage />}></Route>
            <Route path='*' element={<ErrorPage />}></Route>
          </Routes>
        </Router>

        <ToastContainer
          position="bottom-right"
          limit={1}
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored" />
      </>
      :
      <>
        <Router>
          <Routes>
            <Route path='/' element={< Home_page />}></Route>
            <Route path='/user-login' element={< User_Login />}></Route>
            <Route path='/change-forgot-password' element={<Changepassword />}></Route>
            <Route path='/user-register' element={< User_Register />}></Route>
            <Route path='/dashboard' element={<Dashboard />}></Route>
            <Route path='/estimate' element={<EstimateList />}></Route>
            <Route path='/invoices' element={<Invoices />}></Route>
            <Route path='/customers' element={<Customers />}></Route>
            <Route path='/view-customers' element={<ViewCustomers />}></Route>
            <Route path='/add-customers' element={<AddCustomers />}></Route>
            <Route path='/edit-customers' element={<EditCustomers />}></Route>
            <Route path='/product-&-services' element={<ProductService />}></Route>
            <Route path='/add-products' element={<AddProducts />}></Route>
            <Route path='/edit-product-service' element={<EditProductService />}></Route>
            <Route path='/accounting-payment-transaction' element={<PaymentIncome />}></Route>
            <Route path='/accounting-expenses' element={<Expenses />}></Route>
            <Route path='/settings/users' element={<User />}></Route>
            <Route path='/settings/invoice-customization' element={<InvoiceCustomization />}></Route>
            <Route path='/settings/sales-taxes' element={<SalesTaxes />}></Route>
            <Route path='/settings/payment-method' element={<PaymentMethod />}></Route>
            <Route path='/settings/payment-account' element={<PaymentAccount />}></Route>
            <Route path='/settings/currency' element={<Currency />}></Route>
            <Route path='/accounting-export-your-data' element={<Export_Data />}></Route>
            <Route path='/settings/edit-business-profile' element={<Business_Profile />}></Route>
            <Route path='/estimate/add-estimate' element={<Add_Estimate />}></Route>
            <Route path='/estimate/view-estimate' element={<View_Estimate />}></Route>
            <Route path='/invoices/add-invoice' element={<Add_Invoice />}></Route>
            <Route path='/invoices/edit-invoice' element={<Edit_Invoice />}></Route>
            <Route path='/invoices/view-invoice' element={<View_Invoice />}></Route>
            <Route path='/invoices/view-edit-preview' element={<Invoice_Editable_Preview />}></Route>
            <Route path='/error-page' element={<ErrorPage />}></Route>
            <Route path='*' element={<ErrorPage />}></Route>
          </Routes>
        </Router>
      
        <ToastContainer
          position="bottom-right"
          limit={1}
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored" />
      </>
  );
}

export default App;