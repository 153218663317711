import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { toast } from 'react-toastify';
import { RotatingLines } from 'react-loader-spinner'
import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Tooltip } from 'antd';


export default function Forgotpassword(props) {
    const { isOpen, onClose } = props;
    const [_isOpen, setOpen] = useState(isOpen);

    const [email, setEmail] = useState('');
    const [loader, setLoader] = useState(false);
    const [errors, setErrors] = useState(false);
    const [errorsMessage, setErrorsMessage] = useState('');
    const navigate = useNavigate();
    
    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);

    const handleChange = (event) => {
        setEmail(event.target.value);
        setErrors(false);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        
        if (String(email).trim().length === 0) {
            setErrors(true);
            setErrorsMessage(`Email is required`);
        }
        else if (email && !/\S+@\S+\.\S+/.test(email)) {
            setErrors(true);
            setErrorsMessage(`Email is invalid`);
        }
        else{
            setErrorsMessage('');
        }

        // email.trim().length > 0
        if (email.trim().length > 0 && errorsMessage.length === 0) {
            setLoader(true);
            axios.post('https://wavedemo.cydeztechnologies.com/forgot_password_request', {
                email: email
            }).then(res => {
                if (res.data === "OTP Sent Successfully") {
                    setLoader(false);
                    localStorage.setItem('userMail', JSON.stringify(email));
                    let userMail = JSON.parse(localStorage.getItem('userMail'));
                    navigate("/change-forgot-password",{
                        state: {
                            email: userMail
                        }
                    });
                }
                else {
                    toast.error("Please Enter Registered Email ID", {toastId:"email"})
                    setLoader(false);
                }
            }).catch(error => {
                toast.warning("Something Went Wrong", {toastId:"err-email"});
                setLoader(false);
            });

        }
    }

    return (
        <Dialog
            open={_isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="p-5 m-5 w-100"
            disablebackdropclick="true"
            disableEscapeKeyDown={true}
        >
            <DialogTitle sx={{ position: 'relative', color:"#001b66" }} id="alert-dialog-title" className="fw-bold mt-2 align-content-center ">
                Forgot Your Password ?
                <i
                    className="bi bi-x-lg"
                    style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                    onClick={handleClose}
                ></i>
            </DialogTitle>
            <DialogContent className="px-5 mt-2 text-center">
                <div className='container' >
                    <p className="fs-6 mb-4" >Enter the email address assoicated with your account</p>
                    <Tooltip
                                    title={errorsMessage}
                                    placement="bottomLeft"
                                    color="#8B0000"
                                    trigger={"contextMenu"}
                                    open={errors}
                                    overlayStyle={{ fontSize: "12px" }}
                                    autoAdjustOverflow={false}
                                    getPopupContainer={(triggerNode) => triggerNode.parentNode}
                                    zIndex={9999}
                                >
                                    <input
                                        className={`${errors ? 'ms-2 rounded border border-2 border-danger p-1 w-100' : 'ms-2 rounded border border-2 border-primary p-1 w-100'}`}
                                        type="email"
                                        name="email"
                                        onChange={handleChange}
                                        placeholder="Enter Email" 
                                        required="required"
                                    />
                                    {errors && <><br /><br /></>}
                                </Tooltip>
                </div>
            </DialogContent>
            <DialogActions className='my-3'  style={{justifyContent: 'center'}}>
            <button type="submit" onClick={handleSubmit}
                    // disabled={btnDisable ? "disabled" : ''}
                    className="btn btn-primary w-25">
                    {loader ? <><RotatingLines
                        strokeColor="#fff"
                        strokeWidth="4"
                        animationDuration="1.5"
                        width="19"
                        visible={true} />
                        <span className='ms-2' style={{ fontSize: "13px" }}>
                            Sending....</span> </>
                        : "Send OTP"}
                </button>
            </DialogActions>
        </Dialog>
    );
}




// export default function Forgotpassword(props) {

//     const [email, setEmail] = useState('');
//     const [show, setShow] = useState(false);
//     const [loader, setLoader] = useState(false);
//     const [btnDisable, setBnDisable] = useState(true);

//     const handleClose = () => {
//         setShow(false);
//         setEmail('');
//         setLoader(false);
//         setBnDisable(false);
//     }
//     const handleShow = () => setShow(true);

//     const navigate = useNavigate();

//     const handleSubmit = () => {

//         if (email.trim().length > 0) {
//             setLoader(true);
//             setBnDisable(true);
//             axios.post('https://rc.prod-app.in/JSFR', {
//                 email: email
//             }).then(res => {
//                 localStorage.setItem("msg", JSON.stringify(res.data));
//                 let msg = JSON.parse(localStorage.getItem("msg")).status;
//                 if (msg !== "Invalid Email! try again" && email !== "") {
//                     setLoader(false);
//                     localStorage.setItem('userMail', JSON.stringify(email));
//                     let userMail = JSON.parse(localStorage.getItem('userMail'));
//                     navigate('/Change_password', {
//                         state: {
//                             email: userMail,
//                             role: props.role
//                         }
//                     });

//                 }
//                 else {
//                     toast.error("Please Enter Registered Email ID")
//                     setLoader(false);
//                 }
//             }).catch(error => {
//                 //toast.warning("Something Went Wrong")  ;
//                 setLoader(false);
//             });

//         }

//     }
//     //prevent form submitting
//     const handleFormSubmit = (e) => {
//         e.preventDefault();
//     }

//     return (


//         <>
//             <a onClick={handleShow} className='sgn-fgtpass' href='#Forgotpassword'>Forgot Password</a>

//             <Modal
//                 show={show}
//                 onHide={handleClose}
//                 backdrop="static"
//                 keyboard={false}
//                 dialogClassName="my-modal">
//                 <Modal.Header closeButton style={{ borderBottom: '0px' }}>
//                 </Modal.Header>
//                 <Modal.Body className='mb-5' >

//                     <div className='container  d-sm-flex ' >
//                         <img src={fgtImg} alt="forgotPassword" width="170px" height="170px"
//                             className="rounded-circle mb-2" />
//                         <div className='ms-3 container-fluid'>
//                             <h6 className='mb-2 fgt-title'>Forgot Your Password ?</h6>
//                             <p className="fgt-parag mb-4" >Enter the email address assoicated with your account</p>
//                             <form className='form flex' onSubmit={handleFormSubmit}>
//                                 <input name="email" id='fgt-input'
//                                     type="email" onChange={(e) => { setEmail(e.target.value) }}
//                                     placeholder="Enter Email" required="required"
//                                     className="form-control w-100  mb-3" autoFocus />

//                                 <button type="submit" onClick={handleSubmit}
//                                     disabled={btnDisable ? "disabled" : ''}
//                                     id="fgt-btn"
//                                     className="btn btn-primary w-20 ">
//                                     {loader ? <><RotatingLines
//                                         strokeColor="#fff"
//                                         strokeWidth="4"
//                                         animationDuration="1.5"
//                                         width="19"
//                                         visible={true} />
//                                         <span className='ms-2' style={{ fontSize: "13px" }}>
//                                             Sending....</span> </>
//                                         : "Send OTP"}
//                                 </button>
//                             </form>
//                         </div>
//                     </div>

//                 </Modal.Body>
//             </Modal>
//         </>
//     );
// }