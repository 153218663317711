import React, { useEffect, useState } from 'react'
import Sidebar from '../../Sidebar/Sidebar';
import { useLocation, useNavigate } from "react-router-dom";
import Invoice_Preview from './Invoice_Preview';
import axios from "axios";
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import RecordPayment from "./RecordPayment";
import Succuess from '../../AlertBox/Success';
import SendReceipt from './Send_Invoice';
import Delete from '../../AlertBox/Delete';
import relativeTime from 'dayjs/plugin/relativeTime';
import Customization_Warning_Popup from '../Estimate/Customization_Warning_Popup';
import Business_Warning_Popup from '../Estimate/Business_Warning _Popup';


// Import and apply the relativeTime plugin
dayjs.extend(relativeTime);
dayjs.locale('en-in');

function View_Invoice() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = localStorage.getItem("user");
  const business_id = user && JSON.parse(user)[0].business.id;
  const invoice = location.state && location.state.invoice;
  const paymentData = location.state && location.state.paymentData;
  const showMoreData = location.state && location.state.showMoreData;
  const [invoiceData, setInvoiceData] = useState();

  const pdfDownloadUrl = invoice && `https://wavedemo.cydeztechnologies.com/invoicedownload/${business_id}/${invoice.id}`

  const now = dayjs();
  const targetDate = invoice &&  dayjs(invoice.expiry_date);
  const isPastDate = now.diff(targetDate, 'days') > 0;
  const timeDifference = isPastDate ? now.to(targetDate) : dayjs(targetDate).fromNow();

  const [recordPayment, setRecordPayment] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);

  const [isDisabledDraft, setIsDisabledDraft] = useState(false); // Condition to disable the div
  const [isDisabledSend, setIsDisabledUnsend] = useState(false);
  const [isDisabledpaid, setIsDisabledPaid] = useState(false);

  const [displayPayment, setDisplayPayment] = useState(false);
  const [recordPaymentData, setRecordPaymentData] = useState();
  const [selectRecordPaymentData, setSelectRecordPaymentData] = useState();

  const [showAllPayments, setShowAllPayments] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [deleteConfirmData, setDeleteConfirmData] = useState('');

  const [sendInvoice, setSendInvoice] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [successMessageMarkas, setSuccessMessageMarkas] = useState(false);
  const [sendInvoiceData, setSendInvoiceData] = useState();
  const [sendEmailFlag,setSendEmailFlag] = useState(false);
  const [sendEmailFlagReminder,setSendEmailFlagReminder] = useState(false);

  const divStyles = isDisabledDraft ? { opacity: 0.5, pointerEvents: 'none' } : {};
  const paymentDiv = isDisabledDraft || isDisabledSend ? { opacity: 0.5, pointerEvents: 'none' } : {}

  const [customizationWarning, setCustomizationWarning] = useState(false);
  const [businessdata, setBusinessdata] = useState({});
  const [businessWarning,setBusinessWarning] = useState(false);

  const business_data = () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-business`)
    .then((res)=>{
      const responseData = res.data; 
      const filteredData = responseData && responseData.filter(item => item.id === business_id);
      setBusinessdata(filteredData[0]);
    }).catch(error =>{
      toast.error(error.message,{toastId: 'addest5',})  ;
  });
  }
  
  useEffect(()=>{
    const user = localStorage.getItem("user");
    if(user === null){
        localStorage.removeItem("user");
        navigate('/user-login');
    }
    else if(!location.state){
      navigate(-1);
    }
    else {
      business_data();
      axios.get(`https://wavedemo.cydeztechnologies.com/get-invoice-customization-details/${business_id}`)
        .then((res) => {
          if (parseInt(res.data.transaction_number) == null || res.data.invoice_default_title == null) {
            setCustomizationWarning(true)
          }
          else if (businessdata && businessdata.business_address === null) {
            setBusinessWarning(true);
          }
        }).catch(error => {
          toast.error(`Invoice Details API Error1 ${error}`, { toastId: 'addest5', });
        });
    }
  },[])


  function fetchInvoiceData() {
    invoice && axios.get(`https://wavedemo.cydeztechnologies.com/invoices/${business_id}/${invoice.id}`)
      .then((res) => {
        if (res.data !== "No data found") {
          setInvoiceData(res.data);
          // setLoading(true);
        }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-listest" });
      })
  }

  function fetchRecordPayment() {
    invoice && axios.get(`https://wavedemo.cydeztechnologies.com/payment-record/${invoice.id}`)
      .then((res) => {
        if (res.data !== "No data found") {
          setRecordPayment(res.data);
          // setLoading(true);
        }
        else{
          setRecordPayment([]);
        }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-listest" });
      })
  }

  function IsDisabledDraft() {
    if (invoiceData && invoiceData.status === "Draft") {
      setIsDisabledDraft(true);
    } else {
      setIsDisabledDraft(false);
    }
  }

  function IsDisabledUnsend() {
    if (invoiceData && invoiceData.status === "Unsend") {
      setIsDisabledUnsend(true);
    } else {
      setIsDisabledUnsend(false);
    }
  }

  function IsDisabledPaid() {
    if (invoiceData && invoiceData.status === "Paid" ||
      invoiceData && invoiceData.status === "Send" ||
      invoiceData && invoiceData.status === "Overdue" ||
      invoiceData && invoiceData.status === "Partially") {
      setIsDisabledPaid(true);
    } else {
      setIsDisabledPaid(false);
    }
  }

  useEffect(() => {
    fetchInvoiceData();
    invoice && invoice !== undefined && fetchRecordPayment();
    showMoreData && setShowAllPayments(showMoreData)
    // setIsDisabledDraft(IsDisabled());
  }, [isUpdated, displayPayment]);

  useEffect(() => {
    IsDisabledDraft();
    IsDisabledUnsend();
    IsDisabledPaid();
  }, [invoiceData]);


  const onClickApproved = () => {
    axios.put(`https://wavedemo.cydeztechnologies.com/update-status/${invoice.id}`, { status: 9 })
      .then(() => {
        setIsUpdated((prevIsUpdated) => !prevIsUpdated);
      })
      .catch((error) => {
        toast.error(error.message, { toastId: "err-api" });
      });
  };

  const sendconfirm = (sendconfirm) => {
    if(sendconfirm && sendconfirm === true && (sendInvoiceData.status === "Unsend" || sendInvoiceData.status === "Draft")){
      axios.put(`https://wavedemo.cydeztechnologies.com/update-status/${sendInvoiceData.id}`, { status: 2 })
      .then(() => {
        setIsUpdated((prevIsUpdated) => !prevIsUpdated);
        setSuccessMessage(true);
      })
      .catch((error) => {
        toast.error(error.message, { toastId: "err-api" });
      });
    }
    else{
      setIsUpdated((prevIsUpdated) => !prevIsUpdated);
      setSuccessMessage(true);
    }
  }

  const markAsSend = () => {
    if(invoiceData.status === "Unsend" || invoiceData.status === "Draft"){
      axios.put(`https://wavedemo.cydeztechnologies.com/update-status/${invoiceData.id}`, { status: 2 })
      .then(() => {
        setIsUpdated((prevIsUpdated) => !prevIsUpdated);
        setSuccessMessageMarkas(true);
      })
      .catch((error) => {
        toast.error(error.message, { toastId: "err-api" });
      });
    }
  }

  const onClickSend = (data) => {
    setSendInvoice(true);
    setSendInvoiceData(data);
  };

  const onClickPaid = (data) => {
    setDisplayPayment(true);
    setRecordPaymentData(invoiceData);
    if(data) setSelectRecordPaymentData(data)
    else setSelectRecordPaymentData("");
  }

  const confirm = (confirm) => {
    if(confirm && confirm === true){
      axios.delete(`https://wavedemo.cydeztechnologies.com/delete-payment-record/${deleteConfirmData}`).then((res) => {
        if (res.data) {
          toast.success(res.data, { toastId: "listdel" });
          setIsUpdated((prevIsUpdated) => !prevIsUpdated);
        }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-listdel" });
      });
    }
  }

  const deleteRecordpayment = (data) => {
    setOpenDelete(true);
    setDeleteConfirmData(data);
  }

  const toggleShowAllPayments = () => {
    setShowAllPayments((prevState) => !prevState);
  };

  const displayedPayments = showAllPayments
    ? recordPayment.slice().reverse()
    :  recordPayment.slice(-1).reverse();

  // console.log('paymentData: ',paymentData)

  return (
    <section >
      <Sidebar />
      <div className="main-body-container px-5">
        <div className="estimatelist-container">
          <div className='action-section d-flex align-items-center justify-content-between my-4'>
            <div className="left">
              <h2 className='estimate-section-title'>
                {invoice && invoice.transaction_name} #{invoice && invoice.transaction_number}</h2>
            </div>

            <div className="right">

              {/* <button className='btn btn-outline-primary wv-outline-btn me-3 px-4'
                onClick={() => invoiceData && navigate('/invoices/edit-invoice', { state: { invoice: invoiceData } })}>Edit</button> */}
              <a className='btn btn-outline-primary wv-outline-btn  me-3 px-4'
                href={pdfDownloadUrl} target="_self" >Export to PDF</a>

            </div>
          </div>
          <hr />

          <div className="invoice-view-summary">
            <div className="invoice-view-summary__status">
              <div>
                <p className="invoice-view-summary-title">Status</p>
              </div>
              {
                (() => {
                  switch (invoiceData && invoiceData.status) {
                    case "Saved":
                      return <span className="invoice-view-summary-status-box status-saved">Saved</span>;
                    case "Send":
                      return <span className='invoice-view-summary-status-box status-sent'>Send</span>;
                    case "Expired":
                      return <span className='invoice-view-summary-status-box status-expired'>Expired</span>;
                    case "Overdue":
                      return <span className='invoice-view-summary-status-box status-expired'>Overdue</span>;
                    case "Draft":
                      return <span className='invoice-view-summary-status-box status-draft'>Draft</span>;
                    case "Paid":
                      return <span className='invoice-view-summary-status-box status-paid'>Paid</span>;
                    case "Approved":
                      return <span className='invoice-view-summary-status-box status-approved'>Approved</span>;
                    case "Partially":
                      return <span className='invoice-view-summary-status-box status-approved'>Partially</span>;
                    case "Unsend":
                      return <span className='invoice-view-summary-status-box status-saved'>Unsend</span>;
                    default:
                      return null;
                  }
                })()
              }
            </div>
            <div className="invoice-view-summary__customer">
              <div>
                <p className="invoice-view-summary-title">invoice</p>
              </div>
              <div className="invoice-view-summary__text-value">
                <p style={{textDecoration: "none", fontWeight: "bold", color: "#2046cf", fontSize: "23px"}}>{invoiceData && invoiceData.customer_id.customer_company_name}</p>
              </div>
            </div>
            <div className="invoice-view-summary__amount">
              <div>
                <p className="invoice-view-summary-title">Amount due</p>
              </div>
              <div className="invoice-view-summary__text-value">
                <span className="invoice-view-summary-amount">{invoiceData && `${invoiceData.currency.symbol}${(invoiceData.due_amount).toFixed(2)}`}</span>
              </div>
            </div>
            <div className="invoice-view-summary__due-date">
              <div>
                <p className="invoice-view-summary-title">Due</p>
              </div>
              <div className="invoice-view-summary__text-value">
                {
                  invoiceData && invoiceData.status === "Paid" ? 
                  <span className="invoice-view-summary-amount">--</span>:
                  <span className="invoice-view-summary-amount">{timeDifference}</span>
                }
              </div>
            </div>
          </div>

          <div className='invoice-view-summary-main-box'>
            <div className='invoice-view-summary-border'>
              <div className='invoice-view-summary-box-inside'>
                <i className="bi bi-file-earmark-text invoice-view-summary-icons"></i>
                <div className='invoice-view-summary-name-btn'>
                  <p className='invoice-view-summary-box-name'>Create
                    <br /><p className='invoice-view-summary-box-subname'>Created on: {invoiceData && dayjs(invoiceData.date).format('MMMM D, YYYY')}</p>
                  </p>
                  {isDisabledDraft ? <>
                    <button className='invoice-view-summary-btn-main mx-2' onClick={() => onClickApproved()}>Approve draft</button>
                    <button className='invoice-view-summary-btn mx-2'
                     onClick={() => invoiceData && navigate('/invoices/edit-invoice', { state: { invoice: invoiceData } })}
                    >Edit draft</button>
                  </> :
                    <button className='invoice-view-summary-btn'
                    onClick={() => invoiceData && navigate('/invoices/edit-invoice', { state: { invoice: invoiceData } })} >
                    Edit Invoice</button>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className='invoice-view__body__vertical-line'></div>
          <div className='invoice-view-summary-main-box' style={divStyles}>
            <div className='invoice-view-summary-border'>
              <div className='invoice-view-summary-box-inside'>
                <i className="bi bi-send invoice-view-summary-icons"></i>
                <div className='invoice-view-summary-name-btn'>
                  <p className='invoice-view-summary-box-name'>Send
                    <br /><p className='invoice-view-summary-box-subname'>Last send: 
                    {invoiceData && invoiceData.last_email_send && invoiceData.last_email_send !== null ? ` ${dayjs(invoiceData.last_email_send).format('MMMM DD, YYYY')}` : " -- "}
                    </p>
                  </p>
                  <div style={{flex:1}} className='text-end'>
                    {invoiceData && invoiceData.status === "Overdue" && 
                     <button 
                     className={isDisabledSend ? 'invoice-view-summary-btn-main mx-2' : 'invoice-view-summary-btn mx-2'} 
                     onClick={() => {
                     onClickSend(invoiceData)
                     setSendEmailFlag(false)
                     setSendEmailFlagReminder(true)
                     }}>
                       Send reminder
                     </button> 
                    }
                  <button 
                    className={isDisabledSend ? 'invoice-view-summary-btn-main' : 'invoice-view-summary-btn'} 
                    onClick={() => {
                    onClickSend(invoiceData)
                    setSendEmailFlag(false)
                    setSendEmailFlagReminder(false)
                    }}>
                      {invoiceData && invoiceData.status === "Unsend" || invoiceData && invoiceData.status === "Draft" || invoiceData && invoiceData.status === "Overdue"? <>Send Invoice</> : <>Resend Invoice</>}
                    </button> 
                    {invoiceData && invoiceData.status === "Unsend" && <>
                    <br />
                    <span style={{marginRight: "10px"}}>or <button className="invoice-view-summary-box-btn btn btn-none" style={{ margin: "0px", paddingBottom: "2px" }} onClick={()=>markAsSend()}>mark as sent</button></span>
                    </>}
                    </div>
                  
                </div>
              </div>
            </div>
          </div>
          <div className='invoice-view__body__vertical-line'></div>
          <div className='invoice-view-summary-main-box' style={paymentDiv}>
            <div className='invoice-view-summary-border'>
              <div className='invoice-view-summary-box-inside'>
                <i className="bi bi-credit-card-2-back invoice-view-summary-icons"></i>
                <div className='invoice-view-summary-name-btn'>
                  <p className='invoice-view-summary-box-name'>Get paid
                    <br /><p className='invoice-view-summary-box-subname'>Amount due: {invoiceData && `${invoiceData.currency.symbol}${(invoiceData.due_amount).toFixed(2)}`} —
                      Record a payment manually
                      <br />
                      {
                        invoiceData && invoiceData.status !== "Partially" && invoiceData && invoiceData.status !== "Paid" ?
                          <p className='invoice-view-summary-box-subname'>Status: Your invoice is awaiting payment</p> :
                          invoiceData && invoiceData.status === "Paid" ? 
                            <p className='invoice-view-summary-box-subname'>Status: Your invoice is paid in full</p> : 
                              <p className='invoice-view-summary-box-subname'>Status: Your invoice is Partially payment</p>
                        }
                    </p>
                  </p>
                  <button style={{display: invoiceData && invoiceData.status === "Paid" ? 'none' : 'block'}} className={isDisabledpaid ? 'invoice-view-summary-btn-main' : 'invoice-view-summary-btn'} onClick={() => onClickPaid()}>Record payment</button>
                </div>
              </div>
              {recordPayment && 
              recordPayment.length > 0 &&
              <>
              <hr className='right-positioned-hr' />
              <div className='ms-5 ps-5'>
                <p className='fw-bold' style={{ fontSize: "16px", lineHeight: "8px" }}>Payments received:</p>
                {
                  displayedPayments && invoiceData &&
                  displayedPayments.map((item, index) => {
                    return (
                      <div style={
                        {
                          backgroundColor: paymentData && paymentData.id === item.id && "#E4E6EF", 
                          padding: paymentData && paymentData.id === item.id && "13px 0px 0px 10px",
                          height: paymentData && paymentData.id === item.id && "60px",
                          marginBottom: paymentData && paymentData.id === item.id && "20px",
                          maxWidth: paymentData && paymentData.id === item.id && "700px"
                        }}>
                        <p style={{ fontSize: "16px", lineHeight: "8px" }}>{item.date !== null ? dayjs(item.date).format("MMMM DD, YYYY") : dayjs(item.created_at).format("MMMM DD, YYYY")} - A payment for <span className='fw-bold'>{`${invoiceData.currency.symbol}${Number((item.amount)).toFixed(2)}`}</span>  was made using {item.payment_type}.</p>
                        <span className='d-flex' style={{ lineHeight: "8px" }}>
                          <button 
                          className="invoice-view-summary-box-btn btn btn-none" 
                          onClick={() => {
                            onClickSend({...invoiceData, payment_record: {...item}})
                            setSendEmailFlag(true)
                            }
                          } 
                          style={{ marginLeft: "0px" }}>
                            Send a receipt
                          </button>  |
                          <button className="invoice-view-summary-box-btn btn btn-none" onClick={() => onClickPaid(item)}>Edit payment</button>  |
                          <button className="invoice-view-summary-box-btn btn btn-none" onClick={() => deleteRecordpayment(item.id)}>Remove payment</button>
                        </span>
                      </div>
                    )
                  })
                }
                {recordPayment.length > 1 && (
                  <button onClick={toggleShowAllPayments} className='invoice-view-summary-box-btn btn btn-none' style={{marginLeft:"0"}}>
                    {showAllPayments ? 'Show Less' : 'Show More'}<i className={`bi ${showAllPayments ? 'bi-dash-circle' : 'bi-plus-circle'} invoice-view-summary-box-btn-add-less ms-1`}></i>
                  </button>
                )}
              </div>
              </>
              }
            </div>
          </div>


          {invoice && <Invoice_Preview invoice={invoice} paymentrecordsdata={recordPayment}/>}
        </div>
      </div>
      <RecordPayment
        displayRecordPayment={displayPayment}
        paymentData={recordPaymentData}
        selectRecordPayment = {selectRecordPaymentData}
        isOpen={displayPayment}
        onClose={() => {
          setDisplayPayment(false);
        }}
      />
      <SendReceipt 
        opensend={sendInvoice}
        sendconfirm={sendconfirm}
        invoicedata={sendInvoiceData}
        isOpen={sendInvoice}
        onClose={() => {
          setSendInvoice(false);
        }}
        flag={sendEmailFlag}
        sendEmailFlagReminder={sendEmailFlagReminder}
      />
      <Delete 
      title1 = "Are you sure"
      title2 = "Do you really want to delete these record?"
      isOpen={openDelete}
      onClose={() => {
        setOpenDelete(false);
      }}
      confirm = {confirm}
      />
      <Succuess
                title1="Send a receipt"
                title2="This receipt was sent."
                isOpen={successMessage}
                onClose={() => {
                    setSuccessMessage(false);
                }}
                send={false}
            />
      <Succuess
                title1="Send a receipt"
                title2="This receipt was mark as sent."
                isOpen={successMessageMarkas}
                onClose={() => {
                    setSuccessMessageMarkas(false);
                }}
                send={false}
            />
            <Customization_Warning_Popup
        displayPopup={customizationWarning}
        isOpen={customizationWarning}
        onClose={() => {
          setCustomizationWarning(false);
        }}
        navigateBack={true}
      />
      <Business_Warning_Popup
        displayPopup={businessWarning}
        isOpen={businessWarning}
        onClose={() => {
          setBusinessWarning(false);
        }}
        navigateBack={true}
    />
    </section>
  )
}

export default View_Invoice