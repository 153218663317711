import '../Accounting.css'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { toast } from 'react-toastify';


export default function EditSalesTax(props) {
    const { displayEditTax, taxId ,isOpen, onClose } = props;
    const [_isOpen, setOpen] = useState(isOpen);

    const user = localStorage.getItem("user");
    const businessID = JSON.parse(user)[0].business.id;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [formData, setFormData] = useState({
        tax_name: "",
        description: "",
        tax_rate: "",
        status: "",
    });

    useEffect(() => {
        setOpen(isOpen);
        if(taxId !== undefined){
        axios.get(`https://wavedemo.cydeztechnologies.com/list-tax-by-id/${taxId}`).then((response) => {
            if(response.data !== "No data found"){
                setFormData(response.data[0])
            }
            else{
                setFormData({tax_name: "",description: "",tax_rate: "",status: ""})
            }
        }).catch((e) => {
            console.log("Error in /list-tax-by-id:", e);
            toast.error(e.message);
        });
        }
    }, [isOpen]);
  
    const handleClose = () => {
      setOpen(false);
      if (onClose) onClose();
      setFormData("")
    };
  
  
    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    // update checked state of radio buttons
    document.querySelectorAll('input[name="status"]').forEach((el) => {
        el.checked = parseInt(el.value) === parseInt(formData.status);
    });
  
  
    const handleSubmit = (event) => {
        event.preventDefault();
        setIsSubmitting(true);
        axios.put(`https://wavedemo.cydeztechnologies.com/tax-updation/${taxId}`, {
            // id:taxId,
            // business_id: businessID,
            // tax_name: formData.tax_name,
            // description: formData.description,
            // tax_rate: formData.tax_rate,
            status: formData.status
        })
            .then((res) => {
                console.log(res.data)
                if (res.data) {
                    // Success("Tax has been updated successfully")
                    toast.success("Tax has been updated", {toastId:"editTax-1"})
                    setOpen(false);
                    if (onClose) onClose();
                    setIsSubmitting(false);
                }
                else {
                    toast.error(res.data.trim(), {toastId:"editTax-1"})
                }
            })
            .catch((e) => {
                console.log("Error in /tax-updation:", e);
                toast.error(e.message, {toastId:"error"});
            });
    };
  
    return (
      <Dialog
        open={displayEditTax}
        onClose={()=>setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll={'body'}
        disablebackdropclick="true"
        disableEscapeKeyDown = {true}
        style={{height:"auto", maxHeight:"900px"}}
      >
        {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Edit a sales tax</DialogTitle> */}
        <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center ">
        Edit a Sales Tax
        <i
          className="bi bi-x-lg"
          style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
          onClick={handleClose}
        ></i>
      </DialogTitle>
      <hr />
        <DialogContent className="px-5 mt-2">
          {/* <DialogContentText id="alert-dialog-description"> */}
          <div className="my-4">
                    <div className="form-group row m-4">
                        <label className="col-sm-4 fw-bold py-1 text-sm-start">Tax Name <span className="importent">*</span></label>
                        <div className="col-sm-8 text-center">
                            <input
                                type="text"
                                className="ms-2 rounded border border-2 border-primary py-1 w-100 "
                                name="tax_name"
                                value={formData.tax_name}
                                // onChange={handleChange}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="form-group row m-4">
                        <label className="col-sm-4 fw-bold py-1 text-sm-start">Description</label>
                        <div className="col-sm-8 text-center">
                            <textarea
                                className="ms-2 rounded border border-2 border-primary py-1 w-100"
                                name="description"
                                value={formData.description}
                                // onChange={handleChange}
                                disabled
                            ></textarea>
                        </div>
                    </div>
                    <div className="form-group row m-4">
                        <label className="col-sm-4 fw-bold py-1 text-sm-start">Tax Rate (%) <span className="importent">*</span></label>
                        <div className="col-sm-8 text-center">
                            <input
                                type="number"
                                className="ms-2 rounded border border-2 border-primary py-1 w-100"
                                name="tax_rate"
                                value={formData.tax_rate}
                                // onChange={handleChange}
                                disabled
                            />
                            <label style={{ fontSize: "13px", color: "grey" }} className="ms-2">Please provide a numerical value without including the percentage sign.</label>
                        </div>
                    </div>
                    <div className="form-group row m-4">
                        <label className="col-sm-4 fw-bold py-1 text-sm-start">Status <span className="importent">*</span></label>
                        <div className="col-sm-8 py-1">
                            <div className="form-group row">
                                <div className='col-sm-6'>
                                    <input
                                        type="radio"
                                        className="form-check-input ms-2"
                                        id="inlineCheckbox1"
                                        name="status"
                                        value={1}
                                        onChange={handleChange}
                                    /> <label className="form-check-label fw-bold text-dark" htmlFor='inlineCheckbox1'>Active</label>
                                </div>
                                <div className='col-sm-6'>
                                    <input
                                        type="radio"
                                        className="form-check-input ms-2"
                                        id="inlineCheckbox2"
                                        name="status"
                                        value={0}
                                        onChange={handleChange}
                                    /> <label className="form-check-label fw-bold text-dark" htmlFor='inlineCheckbox2'>Inactive</label>
                                </div>
                            </div>
                        </div>

                    </div>
                    
                </div>
          {/* </DialogContentText> */}
        </DialogContent>
        <hr />
        <DialogActions className='mb-4'>
          <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
            Cancel
          </button>
          <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
            {isSubmitting ? 'Uploading...' : 'Ok'}
          </button>
        </DialogActions>
      </Dialog>
    );
  }