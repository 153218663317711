import { useState, useEffect } from "react";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import SendReceipt from "../Sales/Invoices/Send_Invoice";
import { toast } from 'react-toastify';



export default function Succuess(props) {
    const { title1, title2, isOpen, onClose, send, data} = props;
    const [_isOpen, setOpen] = useState(isOpen);

    const [sendInvoice,setSendInvoice] = useState(false);

    const [recordData,setRecordData] = useState([]);

    function fetchRecordPayment(data) {
        data.id && axios.get(`https://wavedemo.cydeztechnologies.com/payment-record/${data.id}`)
          .then((res) => {
            if (res.data !== "No data found") {
              var jsonArray = res.data;
              if (jsonArray.length === 1) {
                var singleObject = jsonArray[0];
                setRecordData(singleObject);
              } else {
                var lastObject = jsonArray[jsonArray.length - 1];
                setRecordData(lastObject);
              }
              // setLoading(true);
            } else {
                setRecordData([]);
            }
          })
          .catch((e) => {
            toast.error(e.message, { toastId: "err-listest" });
          });
      }

    useEffect(() => {
        setOpen(isOpen);
        data && data !== undefined && fetchRecordPayment(data);
    }, [isOpen]);

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    const sendconfirm = (sendconfirm) => {
        if(sendconfirm && sendconfirm === true){
            toast.success("This invoice was sent", { toastId: "invoice-1" });
          }
      }
    
    const handleSubmit = () => {
        setOpen(false);
        if (onClose) onClose();
        setSendInvoice(true);
    }

    return (
        <>
            <Dialog
                open={_isOpen}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll={'body'}
                disablebackdropclick="true"
                disableEscapeKeyDown={true}
                fullWidth={true}
                maxWidth={'sm'}
                style={{ height: "auto", maxHeight: "900px" }}
            >
                {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Add Payment Method</DialogTitle> */}
                <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 align-content-center ">
                    {title1}
                    <i
                        className="bi bi-x-lg"
                        style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                        onClick={handleClose}
                    ></i>
                </DialogTitle>
                <hr />
                <DialogContent className="px-5 mt-2" >
                <div className="text-center">
                    <i className="bi bi-check-circle" style={{fontSize:"100px", color:'green'}}></i>
                    <br />
                    <p className="fw-bold fs-4">{title2}</p>
                </div>
                </DialogContent>
                <hr />
                <DialogActions className='mb-2'>
                    <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
                        Close
                    </button>
                    {send && 
                    <button onClick={handleSubmit} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
                        Send a receipt
                    </button>
                    }
                </DialogActions>
            </Dialog>
            <SendReceipt
            sendconfirm={sendconfirm}
            invoicedata={{...data, payment_record: recordData}}
            isOpen={sendInvoice}
            onClose={() => {
              setSendInvoice(false);
            }}
            flag={send}
            />
        </>
    )
}