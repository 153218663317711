import Setting from "../../Setting.sidebar"
import Sidebar from "../../../Sidebar/Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from "react"
import axios from "axios";
import { toast } from 'react-toastify';
import AddPaymentMethod from "./AddPaymentMethod";
import EditPaymentMethod from "./EditPaymentMethod"
import DataTable from 'react-data-table-component';
import LoadingSpinner from '../../../Utils/Loader'
import { useNavigate } from "react-router-dom";

export default function PaymentMethod() {
    const navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = useState([]);
    const [addPaymentMethod, setAddPaymentMethod] = useState(false);
    const [editPaymentMethod, setEditPaymentMethod] = useState(false);
    const [editPaymentMethodId, setEditPaymentMethodId] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const user = localStorage.getItem("user");
        const businessID = user && JSON.parse(user)[0].business.id;

        if(user === null){
            localStorage.removeItem("user");
            navigate('/error-page');
            }

        axios.get(`https://wavedemo.cydeztechnologies.com/list-payment-method-by-businessid/${businessID}`).then((response) => {
            if (response.data === "No data found") {
                setPaymentMethod([]);
                setLoading(true);
            }
            else{
                const methodName = response.data.filter((item) => item.parent_id === null);
                setPaymentMethod(methodName);
                setLoading(true);
            }
        }).catch((e) => {
            console.log("Error in /list-payment-method-by-businessid:", e);
            toast.error(e.message, {toastId:"error"});
        });
    }, [addPaymentMethod, editPaymentMethod]);

    const onClickCreate = () => {
        setAddPaymentMethod(true);
    }

    function onClickEdit(item) {
        setEditPaymentMethod(true)
        setEditPaymentMethodId(item.id)
    }

    const updateSelectMethod = (newvalue) =>{
        console.log(newvalue);
      }  

    const customStyles = {
        headRow: {
            style: {
                color: '#000',
                fontWeight: "bolder",
                fontSize: "16px",
                borderBottom: "1px solid black"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontSize: "16px",
                minHeight: '55px'
            }
        },

    }

    const columns = [
        {
            name: 'Payment Method',
            selector: row => row.paymentmethod_name,
            sortable: true,
            grow: 3,
        },
        {
            name: 'Description',
            selector: row => row.description,
            grow: 1,
        },
        {
            name: 'Actions',
            selector: row => row.action,
            grow: 1,
            center: true
        },
    ];

    const rows = paymentMethod ? paymentMethod.map((item) => {
        return {
            paymentmethod_name: item.paymentmethod_name,
            description: item.description,
            action: <button className="btn btn-none" onClick={() => onClickEdit(item)}><FontAwesomeIcon icon={faPen} style={{ color: "#2046cf", fontSize: "18px" }} /></button>
        }
    }) : '';

    return (
        <div>
            <Sidebar />
            <Setting />
            {loading ?
                <div className="column2 container my-5">
                    <div className="row">
                        <div className="col-9 text-start fw-bold fs-3 text-capitalize">
                            Payment Method
                        </div>
                        <div className="col-3 text-end">
                            <button className="btn btn-primary" onClick={onClickCreate}>Create a Payment Method</button>
                        </div>
                    </div>
                    <div className="container mt-5">
                        <DataTable
                            columns={columns}
                            data={rows}
                            fixedHeaderScrollHeight="600px"
                            customStyles={customStyles}
                            persistTableHead
                            pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
                highlightOnHover
                        />
                    </div>
                </div>
                :
                <LoadingSpinner />}
            <AddPaymentMethod
                uploadSelectMethod={updateSelectMethod}
                uploadMethodList={paymentMethod}
                displayAddPaymentMethod={addPaymentMethod}
                isOpen={addPaymentMethod}
                onClose={() => {
                    setAddPaymentMethod(false);
                }}
            />
            <EditPaymentMethod
                uploadMethodList={paymentMethod}
                displayEditPaymentMethod={editPaymentMethod}
                PaymentId={editPaymentMethodId}
                isOpen={editPaymentMethod}
                onClose={() => {
                    setEditPaymentMethod(false);
                }}


            />
        </div>
    )
}