import React, { useState, useEffect, useRef } from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import addCustomerIcon from '../../../assets/add_csutomer.svg'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';
import { useNavigate,useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import Accordion from 'react-bootstrap/Accordion';
import { format } from 'date-fns';
import { Tooltip } from 'antd';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import LoadingSpinner from '../../Utils/Loader';
import AddCustomersPopup from '../Estimate/add_Customer_Popup';
import AddCurrencyPopup from '../Estimate/add_Currency_Popup';
import AddTaxPopup from '../Estimate/add_tax_popup';
import AddProductPopup from '../Estimate/add_product_popup';
import EditCustomersPopup from '../Estimate/edit_Customer_Popup';
import Customization_Warning_Popup from '../Estimate/Customization_Warning_Popup';
import Business_Warning_Popup from '../Estimate/Business_Warning _Popup';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);
dayjs.locale('en-in');

function Add_Invoice() {
 const location = useLocation();
const navigate = useNavigate();
const divRef = useRef(null);
const user = localStorage.getItem("user");
const business_id = user&&JSON.parse(user)[0].business.id;
const bcur = user&&JSON.parse(user)[0].business_currency.split("-");
const role = user && JSON.parse(user)[0].role;
const wv_busCur_code =bcur && bcur[0].trim();   // bussiness currency code :INR
const wv_busCur_symbol =bcur && bcur[2].trim();  //business currency symbol
const [displayProduct,setDisplayProduct] =useState(false);
const [displayCustomer,setDisplayCustomer] =useState(false);
const [businessDetails,setbusinessDetails] = useState({})
const [customerList, setCustomerList]=useState([]);
const [productList, setProductList]=useState([]);
const [taxList,setTaxList]=useState([]);
const [currencyList, setCurrencyList]=useState([]);
const [invoiceProducts, setInvoiceProducts] = useState([])
const [taxCalc,setTaxCalc]=useState([]);
const [selectedCurrency, setSelectedCurrency]=useState();
const currentDate = dayjs(); // Get current date using dayjs
const [errors, setErrors] = useState({});
const [btnDisabled, setBtnDisabled] = useState(false);
const [loading, setLoading] = useState(true);
const [invoice,SetInvoice] = useState({
    invoice_name:'',
    invoice_prefix:'',
    invoice_number:'',
    customer:'',
    currency:'',
    date:currentDate,
    exp_date:'',
    purchase_order:'',
    subheading:'',
    footer:'',
    memo:'',
    logo:'',
    total_amount:'',

});
let curList = [];
let customerListArray = [];
var subtotal = 0;
var totaltax=0;
const [allTaxList,setAllTaxList]=useState([]);
const [productIndex,setProductIndex]=useState();
const [taxIndex,setTaxIndex]=useState();
const [customerPopup,setCustomerPopup] = useState(false);
const [editCustomerPopup,setEditCustomerPopup] = useState(false);
const [currencyPopup,setCurrencyPopup] = useState(false);
const [taxPopup,setTaxPopup] = useState(false);
const [productPopup,setProductPopup] = useState(false);
const invoicedata = location.state && location.state.invoice; //from preview
const productDetails = location.state && location.state.productDetails; //from preview

const [customizationWarning, setCustomizationWarning] = useState(false);
const [businessdata, setBusinessdata] = useState({});
const [businessWarning,setBusinessWarning] = useState(false);


//prevent form submitting
const handleSubmit = (e) => {
  e.preventDefault();
}

const fetchTaxList =  () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-tax-by-businessid/${business_id}`).then((res)=>{
      if(res.data !== "No data found"){    
        setTaxList( res.data.filter(item => item.status === 1));
        setAllTaxList(res.data);
      }
    }).catch(error =>{
            toast.error("Something Went Wrong",{toastId: 'addest3',})  ;
        });
}
const fetchProductList =  () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-products/${business_id}`).then((res)=>{
      if(res.data !== "No data found"){
        setProductList( res.data);
      }
    }).catch(error =>{
            toast.error("Something Went Wrong",{toastId: 'addest2',})  ;
        });
}
const fetchCustomerList =   () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-customers/${business_id}`).then((res)=>{
        //const customers =  res.data.map((item)=> item.customer_company_name)
        if(res.data !== "No data available"){
          customerListArray.push(...res.data);
          setCustomerList( res.data);
          setLoading(false)
        }
        
    }).catch(error =>{
            toast.error("Something Went Wrong",{toastId: 'addest1',})  ;
            setLoading(false)
        });
}
const fetchCurrencyList =  () => {
    
    axios.get(`https://wavedemo.cydeztechnologies.com/currencies/${business_id}`).then((res)=>{
      curList.push(...res.data);
        setCurrencyList( res.data);
       
    }).catch(error =>{
            toast.error("Something Went Wrong",{toastId: 'addest4',})  ;
        });
  }
 //fetch business details
 const fetchBusinessDetails = () =>{
    
  axios.get('https://wavedemo.cydeztechnologies.com/list-business').then((res) => {
     const business_details = res.data.find(obj => obj.id === business_id )
     setbusinessDetails(business_details);
     
     
 }).catch( error =>{
     toast.error("Something Went Wrong",{toastId: 'business1',})  ;
 });
}



//fetch invoice customized details 
const fetchInvoiceDetails =  () => {
   axios.get(`https://wavedemo.cydeztechnologies.com/get-invoice-customization-details/${business_id}`).then((res)=>{
       
   const currncy_data = curList.find((option) =>option.code == wv_busCur_code);
    setSelectedCurrency(currncy_data);  
        let logo='';
       if(res.data.logo !== null){
           logo = `https://wave.aspireitacademy.com${res.data.logo}`
       }   
       
       
          SetInvoice({...invoice,
                 invoice_name:res.data.invoice_default_title,
                 invoice_prefix:res.data.prefix,
                 invoice_number:res.data.transaction_number,
                 subheading:res.data.invoice_default_subheading,
                 footer:res.data.invoice_default_footer,
                 memo:res.data.invoice_default_notes,
                 currency:currncy_data?currncy_data.id :'',
                 logo:logo,
                 exp_date:dayjs(res.data.invoice_payment_term),
  
                })
                setLoading(false);
    }).catch(error =>{
            toast.error("Something Went Wrong",{toastId: 'addest5',})  ;
            setLoading(false);
        });
  }
  
const fetchEditInvoiceDetails =  () => {

   //fetch logo from business
   let  business_logo;
   axios.get(`https://wavedemo.cydeztechnologies.com/list-invoice-by-id/${business_id}`).then((res)=>{
   if(res.data !== "No data found"){
      business_logo = res.data[0].logo!==null ?`https://wave.aspireitacademy.com${res.data[0].logo}`:''   
   }


   SetInvoice({...invoice,
    invoice_name:invoicedata.transaction_name,
    invoice_prefix:invoicedata.prefix,
    invoice_number:invoicedata.transaction_number,
    subheading:invoicedata.subheading,
    footer:invoicedata.footer,
    memo:invoicedata.memo,
    purchase_order:invoicedata.purchase_order,
    currency:invoicedata.currency.id,
    customer:invoicedata.customer_id,
    date:dayjs(invoicedata.date),
    exp_date:dayjs(invoicedata.expiry_date),
    logo:business_logo,
   })
 
   }).catch(error =>{
     toast.error("Something Went Wrong",{toastId: 'inclogo',})  ;
   });

 
   setInvoiceProducts(productDetails);
   setSelectedCurrency(invoicedata.currency)
  }







useEffect(() => {
    const handleBodyClick = (event) => {
         // Check if the click occurred inside the div
      if (divRef.current && divRef.current.contains(event.target)) {
        return;
      }
        setDisplayProduct(false);
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  const business_data = () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-business`)
    .then((res)=>{
      const responseData = res.data; 
      const filteredData = responseData && responseData.filter(item => item.id === business_id);
      setBusinessdata(filteredData[0]);
    }).catch(error =>{
      toast.error(error.message,{toastId: 'addest5',})  ;
  });
  }

useEffect(()=>{
    const user = localStorage.getItem("user");
    if(user === null){
        localStorage.removeItem("user");
        navigate('/user-login');
    }
    else {
      business_data();
      axios.get(`https://wavedemo.cydeztechnologies.com/get-invoice-customization-details/${business_id}`)
        .then((res) => {
          if (parseInt(res.data.transaction_number) == null || res.data.invoice_default_title == null) {
            setCustomizationWarning(true)
          }
          else if (businessdata && businessdata.business_address === null) {
            setBusinessWarning(true);
          }
          else {
            fetchCurrencyList();
            fetchCustomerList();
            fetchProductList();
            fetchTaxList();
            fetchBusinessDetails();
            if (invoicedata) { fetchEditInvoiceDetails() } else { fetchInvoiceDetails(); }
          }
        }).catch(error => {
          toast.error(`Invoice Details API Error1 ${error}`, { toastId: 'addest5', });
        });
    }
},[])

useEffect ( ()=>{
  calculateTax();
},[invoiceProducts,productDetails])



//creating new product
const productonChange = (newValue)=>{
    const taxarray = taxList.filter(item => item.id === newValue.tax_id);
    taxarray.push({}); // Append new empty object
    const newItem = {
        product_id:newValue.id,
        product_name:newValue.product_name,
        product_description:newValue.description,
        product_quantity:1,
        product_tax:taxarray,
        product_price:Number(newValue.price),
        product_amount:Number(newValue.price).toFixed(2),
    }
    setInvoiceProducts([...invoiceProducts, newItem]);
    setDisplayProduct(false);
    setErrors({...errors,product:false});
}


const customerOnChange = (newValue)=>{
    SetInvoice({...invoice,customer:newValue});
    setDisplayCustomer(false);
    setErrors({...errors,customer:false});

}

const taxOnChange = (newValue,productIndex,taxIndex)=>{

  console.log("newValue",newValue)
  console.log("productIndex",productIndex)
  console.log("taxIndex",taxIndex)
    const updatedproduct = [...invoiceProducts];
     const product = updatedproduct[productIndex];
     product.product_tax[taxIndex] = newValue;
     
     if (taxIndex === product.product_tax.length - 1) {
        product.product_tax.push({}); // Append new empty object
      }
    setInvoiceProducts(updatedproduct);

}
//product section :desc,quantity,price onchange
const handleChange = (index,event) =>{
       
  const {name,value} = event.target;
  const rowsInput = [...invoiceProducts];
  rowsInput[index][name]=value;
 

 //  Calculate the amount based on price and quantity
  const price = parseFloat(rowsInput[index].product_price);
   const quantity = parseFloat(rowsInput[index].product_quantity);
   let amount = isNaN(price) || isNaN(quantity) ? 0 : price * quantity;
   amount = amount.toFixed(2);
   rowsInput[index].product_amount = amount;

  setInvoiceProducts(rowsInput)
  const errname = name+index;
  setErrors({...errors,[errname]:false});
}
const handleInvoiceInputChange = (e)=>{
  SetInvoice({ ...invoice, [e.target.name]: e.target.value })
}

const deleteProduct = (productIndex) =>{
    const rows = [...invoiceProducts];
     rows.splice(productIndex,1);
     setInvoiceProducts(rows)
    
}
const deleteTax = (productIndex,taxIndex) =>{
    const rows = [...invoiceProducts];
    const product = rows[productIndex];
    console.log( taxIndex)
    product.product_tax.splice(taxIndex,1);
    if(product.product_tax.length === 0){
        product.product_tax.push({}); // Append new empty object
    }
    setInvoiceProducts(rows);
    
}

const calculateTax = () =>{
    const taxMap = []; 
  
        invoiceProducts.forEach((product)=>{
            product.product_tax.filter((item)=> Object.keys(item).length !== 0)
            .forEach((tax)=>{
              const taxName = tax.tax_name;
              const taxAmount = (tax.tax_rate * product.product_amount) / 100;
              const existingTax = taxMap.find((item) => item.taxName === taxName);
              if (existingTax) {
                existingTax.taxAmount += taxAmount;
              } else {
                taxMap.push({ taxName, taxAmount });
              }
  
  
            })
        })
        setTaxCalc(taxMap)
}

//invoice submit
const addInvoice = ()=>{
  
  const validaion = invoiceValidation();
  if(validaion){ window.scrollTo(0, 0)}
  else{
    setBtnDisabled(true);
    let subtotalamt =0;
    let totaltax =0;
    let totalinvoiceamt =0;
    let totalinvoicebusamt =0;
    
 // convert product into required format   
const transformedProducts = invoiceProducts && invoiceProducts
    .filter((pro) => pro.product_name !== '')
    .map((product) => {
const {product_name,product_description,product_quantity,product_price,product_tax,product_amount} = product;

subtotalamt = parseFloat(subtotalamt)+ parseFloat(product_amount);

// Map the product_tax array to the required taxes format

const taxes = product_tax.filter(obj => Object.keys(obj).length > 0 )
.map((tax) => ({
tax_percentage: tax.tax_rate,
tax_description: tax.tax_name
}));

// Create the transformed product object
const transformedProduct = {
product_name,
product_description,
product_quantity: parseInt(product_quantity), 
product_price: parseFloat(product_price), 
taxes
};

return transformedProduct;
});

taxCalc.map((tax,index)=>{
    totaltax +=tax.taxAmount;
   
  })
  totalinvoiceamt = subtotalamt + totaltax;
  totalinvoiceamt=parseFloat(totalinvoiceamt.toFixed(2));


  selectedCurrency && parseFloat(selectedCurrency.exchange_rate) !== 0.00 ?
  totalinvoicebusamt = totalinvoiceamt * parseFloat(selectedCurrency.exchange_rate):
  totalinvoicebusamt = totalinvoiceamt;

  const apiDate = dayjs(invoice.exp_date).format('YYYY-MM-DD');
  const currentTime = dayjs().format('HH:mm:ss');
  const combinedDateTime = `${apiDate}T${currentTime}`;


   const data = {
    "business_id": business_id,
    "prefix": invoice.invoice_prefix,
    "transaction_type": 2,
    "transaction_number": invoice.invoice_number,
    "transaction_name":invoice.invoice_name,
    "purchase_order":invoice.purchase_order,
    "customer_id": invoice.customer.id,
    "currency": invoice.currency,
    "date":  dayjs(invoice.date).format('YYYY-MM-DDTHH:mm:ss'),
    "expiry_date": combinedDateTime,
    "subheading": invoice.subheading,
    "footer":invoice.footer,
    "memo": invoice.memo,
    "discount_description": "",
    "discount_type": "",
    "discount_value": "",
    "status": 5,
    "total_amount": totalinvoiceamt,
    "total_amount_business": totalinvoicebusamt,
    "products":transformedProducts
}

axios.post('https://wavedemo.cydeztechnologies.com/invoices',data).
then((res)=>{
  const invoice = res.data;
  const invoiceid = res.data.id;
    if(invoiceid){
      toast.success("Invoice successfully created",{toastId: 'invc1'})
      navigate('/invoices/view-invoice',{
        state:{ invoice }
      })
    }
    else{
      toast.error("Invoice Creation failed",{toastId: 'invc2'})
      setBtnDisabled(false);
    }

}).catch((error) =>{toast.error("API Error",{toastId: 'invc3'});setBtnDisabled(false);})

  }
}

const invoiceValidation = () =>{
  const error = {};
  if(!invoice.customer){
    error["customer"] = true;
  }
 
  if(!selectedCurrency){
    error["currency"] = true;
  }

  invoiceProducts && invoiceProducts.map((item,index)=>{
      
    
        //after product selected then check quqntity value,price
        if(item.product_name && (item.product_quantity === ''|| item.product_quantity <= 0)){
          error[`product_quantity${index}`] = true;
        }
        else if(item.product_name && (item.product_price === ''|| item.product_price <= 0)) {
          error[`product_price${index}`] = true;
        }
   })
    if(invoiceProducts.length === 0){error["product"] = true;}
  
   setErrors(error);
  
   return Object.values(error).some((value) => value === true);
  
}

//update customer list from add customer popup
const updateCustomerListfromPopup  = (newvalue) =>{
  setCustomerList(newvalue);
}  
const updateselectdcusfrompopup  = (newvalue) =>{
   SetInvoice ({...invoice,customer:newvalue})
}  
//update currency list from add currency popup
const updateSelelctedCurrencyPopup = (newvalue) => {
  setSelectedCurrency(newvalue);
  SetInvoice({...invoice,currency:newvalue.id})
}
//update tax list from add tax popup
const updateTaxListfromPopup  = (newvalue) =>{
  setTaxList(newvalue);
} 
//update product list from product tax popup
const updateProductListfromPopup  = (newProdList, newTaxList,newProduct,index) =>{
  setProductList(newProdList);
  setTaxList(newTaxList);

  if(newProduct){
		const fetchedtax = newTaxList&&newTaxList.filter((obj) => obj.id === newProduct.tax_id);
		console.log("fetchedtax",fetchedtax);
    fetchedtax.push({}); // Append new empty object
    const newItem = {
        product_id:newProduct.id,
        product_name:newProduct.product_name,
        product_description:newProduct.description,
        product_quantity:1,
        product_tax:fetchedtax,
        product_price:Number(newProduct.price),
        product_amount: Number(newProduct.price).toFixed(2),
    }
    setInvoiceProducts([...invoiceProducts, newItem]);
    }

} 



const preview = () =>{
  let subtotalamt =0;
  let totaltax =0;
  let totalinvoiceamt =0;
  let totalinvoicebusamt =0;

// convert product into required format   
const transformedProducts = invoiceProducts && invoiceProducts
    .filter((pro) => pro.product_name !== '')
    .map((product) => {
const {product_name,product_description,product_quantity,product_price,product_tax,product_amount} = product;

subtotalamt = parseFloat(subtotalamt)+ parseFloat(product_amount);

// Map the product_tax array to the required taxes format

const taxes = product_tax.filter(obj => Object.keys(obj).length > 0 )
.map((tax) => ({
tax_percentage: tax.tax_rate,
tax_description: tax.tax_name
}));

// Create the transformed product object
const transformedProduct = {
product_name,
product_description,
product_quantity: parseInt(product_quantity), 
product_price: parseFloat(product_price), 
taxes
};

return transformedProduct;
});

taxCalc.map((tax,index)=>{
    totaltax +=tax.taxAmount;
   
  })
  totalinvoiceamt = subtotalamt + totaltax;
  totalinvoiceamt=parseFloat(totalinvoiceamt.toFixed(2));


  selectedCurrency && parseFloat(selectedCurrency.exchange_rate) !== 0.00 ?
  totalinvoicebusamt = totalinvoiceamt * parseFloat(selectedCurrency.exchange_rate):
  totalinvoicebusamt = totalinvoiceamt;

  const apiDate = dayjs(invoice.exp_date).format('YYYY-MM-DD');
  const currentTime = dayjs().format('HH:mm:ss');
  const combinedDateTime = `${apiDate}T${currentTime}`;


  const invoicePreview = {
    "business_id": business_id,
    "prefix": invoice.invoice_prefix,
    "transaction_number":  invoice.invoice_number,
    "transaction_name": invoice.invoice_name,
    "customer_id": invoice.customer,
    "currency": selectedCurrency,
    "date": dayjs(invoice.date).format('YYYY-MM-DDTHH:mm:ss'),
    "expiry_date":  combinedDateTime,
    "purchase_order": invoice.purchase_order,
    "subheading":  invoice.subheading,
    "footer":  invoice.footer,
    "memo": invoice.memo,
    "total_amount": totalinvoiceamt,
    "total_amount_business": totalinvoicebusamt,
    "products":transformedProducts,
    
}



  navigate('/invoices/view-edit-preview',{
    state:{ 
      invoicePreview,
      invoiceProducts,
      editInvoice: false,
      editEstimate: false,
     }
  })
}



  return (
    <section >
    <Sidebar/>
{loading? <LoadingSpinner /> :
     <>
    <div className="main-body-container px-5" >
    <div className="add-invoice-container" >
{/* action header Starts*/}
        <div className='d-flex justify-content-between align-items-center'>
        <div className="add-invoice-title">
            <h2 className='section-title'>New Invoice</h2>
         </div>
         <div>
            {/* <button className='btn btn-outline-primary wv-outline-btn  me-4 px-3'    onClick={()=>{ preview()}}>Preview</button>
            <button  className='btn btn-primary wv-actn-fill-btn' disabled={btnDisabled}
            onClick={()=>{ addInvoice()}}>
            Save and continue</button> */}
         </div>
        </div>
{/* action header End*/}   
<div className='invoice-container'>
<form onSubmit={handleSubmit}>
{ errors.customer || errors.product ? 
  <div className="warning-section my-3">
    <div class="alert alert-danger" role="alert">
         <p className='fw-bold'>Oops! There was an issue with your invoice. Please try again.</p>
         { errors.customer && <span className='ms-5' style={{display:"block"}}><b>Customer:</b> Add a customer to this invoice.</span>} 
         { errors.product && <span className='ms-5'><b>Item:</b> Add an item to this invoice.</span>} 

  </div>
  </div>
  :''
}
    <div className="business-section my-4">
    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header className='fw-bold'>Business address and contact details, title,summary and logo</Accordion.Header>
        <Accordion.Body>
        <div className='p-2 d-flex justify-content-between'>
            <div className="business-log" >
            {invoice.logo && <img src={invoice.logo} alt="business logo" className='img-fluid'/>}
            </div>
            <div className="business-info" style={{width:"45%"}}>
                <div> <input type="text" className='form-control mb-3 text-end' 
                 value={invoice.invoice_name?invoice.invoice_name:''}
                 onChange={(e)=>{handleInvoiceInputChange(e)}}
                 name='invoice_name'
                 placeholder='Invoice Title' /></div>
                <div> <input type="text"className='form-control mb-3 text-end' 
                 value={invoice.subheading?invoice.subheading:''}
                 name="subheading"
                 placeholder='Summary (e.g. project name, description of invoice)'
                 onChange={(e)=>handleInvoiceInputChange(e)}
                  /></div>
                <div className='busienss-address text-end'>
                <div className="company_name text-end fw-bolder">{businessDetails&&businessDetails.business_name}</div>
                 {businessDetails.business_address && <div className="company_address text-end" style={{ whiteSpace: 'pre-line'}}>{businessDetails&&businessDetails.business_address}</div>}
                <div className="company_address text-end">
                  {businessDetails&&businessDetails.business_city} {businessDetails&&businessDetails.business_state} {businessDetails&&businessDetails.business_pincode}</div>
                <div className="company_address text-end">{businessDetails&&businessDetails.business_country}</div>
                <br/>
                {businessDetails.business_mobile && <div className="company_address text-end">Phone: {businessDetails&&businessDetails.business_mobile}</div>}
                <div className="company_address text-end">{businessDetails&&businessDetails.business_weburl}</div>
                </div>
            </div>
        </div>
        
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
    <div className='invoice-section p-3' >
        <div className="invoice-primary-details d-flex justify-content-between ">
            <div className="customer-details ">
                {
                    invoice.customer?
                    <div>
                       <p className='p-0 m-0'>Bill To</p>
                       <p className='p-0 m-0 fw-bolder'>{invoice.customer.customer_company_name}</p>
                       <p>{invoice.customer.first_name} {invoice.customer.last_name}</p>
                       <span className="invoice-edit-actn-btn me-2"
                       onClick={()=>{ setEditCustomerPopup(true);}}
                       >Edit {invoice.customer.customer_company_name}  </span>
                       <span className="invoice-edit-actn-btn" onClick={()=>{
                            setDisplayCustomer(true)
                            SetInvoice({...invoice,customer:''});
                            }} >Choose a different customer</span>
                    </div>:
                    !displayCustomer?
                    <button className='invoice-add-customer-btn' onClick={()=>{setDisplayCustomer(true)}}>
                        <img src={addCustomerIcon} alt="AddCustomer" />
                       <span  className='invoice-add-customer-btn-text' >Add a Customer</span> 
                     </button>
                    :
                    <div className='invoice-cus-select-box '>
                          <Autocomplete    
                        open={true} disableClearable  disablePortal openOnFocus
                        
                        options={customerList}
                      
                        onChange={(event, newValue) => {
                            customerOnChange(newValue)
                            }} 
                            getOptionLabel={(option) => option.customer_company_name !== undefined ? option.customer_company_name : ""}
                            filterOptions={(options, { inputValue }) =>
                                options.filter(option =>
                                  option.customer_company_name.toLowerCase().includes(inputValue.toLowerCase())
                                )
                              }
                        renderOption={(props, option) => {
                        return (
                        <li {...props} key={option.id} >
                          <div >
                         <div className="fw-bold">{option.customer_company_name}</div> 
                          <div>{option.first_name} {option.last_name}</div>
                          </div>

                        </li>
                        );
                        }}
                        renderInput={(params) => (
                        <TextField {...params} size="small" placeholder='choose'    />
                        )}
                        PaperComponent={({ children }) => {
                          return (
                            <Paper>

                              {children}
                              <Button
                                style={{textTransform: 'none',color: '#2046cf',fontWeight:'600',
                               
                               
                              }}
                                color="primary"
                                fullWidth
                                sx={{  mx:'auto',py:'8px',mt:'2px',fontSize:12,borderTop:1,borderRadius:0 }}
                                onMouseDown={() => {
                                 setCustomerPopup(true)
                                 setErrors({})
                                }}
                              >
                             
                                <i className="bi bi-plus-circle me-1" ></i> Add new customer
                              </Button>
                            </Paper>
                          );
                        }}
                    />
                     </div>
                }


               
                
            </div>
            <div className="invoice-info">
            <div class="form-group row mb-4">
                <label htmlFor="invoice_number" class="col-5 col-form-label field-label ">Invoice number</label>
                <div class="col-7">
                <input type="text" 
                    class="form-control"
                    readOnly
                    value={invoice.invoice_number?invoice.invoice_prefix+invoice.invoice_number:''}
                    name="invoice_number"
                   
                    style={{height:'38px'}}/>
                </div>
            </div>
            <div class="form-group row mb-4">
                <label htmlFor="purchase_order" class="col-5 col-form-label field-label ">P.O./S.O. number</label>
                <div class="col-7">
                <input type="text"
                     class="form-control"
                     name="purchase_order"
                     value={invoice.purchase_order?invoice.purchase_order:''}
                     onChange={(e)=>handleInvoiceInputChange(e)}
                    
                     style={{height:'38px'}} />
                </div>
            </div>
            <div class="form-group row mb-4">
                <label htmlFor="inputPassword" class="col-5 col-form-label field-label ">Invoice date</label>
                <div class="col-7">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={invoice?dayjs(invoice.date):null}
                                format='DD/MM/YYYY'
                                slotProps={{ textField: { size: 'small',readOnly: true, } }}
                                onChange={(newDate) => {
                                    SetInvoice({...invoice,date:newDate})
                                  }}
                                />             
                    </LocalizationProvider>
                </div>
            </div>
            <div class="form-group row mb-5">
                <label htmlFor="inputPassword" class="col-5 col-form-label field-label ">Payment due</label>
                <div class="col-7">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={invoice?dayjs(invoice.exp_date):null}
                                format='DD/MM/YYYY'
                                slotProps={{ textField: { size: 'small',readOnly: true, } }} 
                                onChange={(newDate) => {
                                    SetInvoice({...invoice,exp_date:newDate})
                                  }}
                                />             
                    </LocalizationProvider>
                </div>
            </div>
            
            
            
            
            </div>

        </div>
   
    <div className="product-section" style={{margin:'0 -16px'}}>
        <table className='table ' >
        <thead >
                    <tr style={{background: "#ecf0f3"}}>
                        <th scope="col" colSpan={1} style={{width:'220px',paddingLeft: '14px'}} >Items<span className="importent">*</span></th>
                        <th scope="col" colSpan={1} style={{width:'320px'}}>Description</th>
                        <th scope="col" colSpan={1} style={{width:'100px'}} >Quantity<span className="importent">*</span></th>
                        <th scope="col" colSpan={1} style={{width:'100px'}} > Price<span className="importent">*</span></th>
                        <th scope="col" colSpan={1} className='text-end' style={{width:'150px'}}>Amount</th>
                        <th scope="col" colSpan={1}></th>
                    </tr>
        </thead>
                <tbody>
          
        {
            invoiceProducts&&invoiceProducts.map((item,productIndex)=>{
                
                subtotal = isNaN(item.product_amount) ? 0 : parseFloat(subtotal)+ parseFloat(item.product_amount);
                const product_quant= "product_quantity"+productIndex;
                const prd_price= "product_price"+productIndex;
       
                return(
    <tr key={productIndex}>
    <td colSpan={6}>
        <table className='table table-borderless product table'>
        <tbody>
            <tr >
                <td colSpan={1} style={{width:'220px'}}>{item.product_name}</td>
                <td colSpan={1} style={{width:'320px'}}>
                <input type="text" 
                    name="product_description" style={{width:"90%"}}value={item.product_description?item.product_description:''}
                    className='form-control  '
                    onChange={ (e)=>{handleChange(productIndex,e)}}/></td>

                <td colSpan={1} style={{width:'100px'}} >
                <Tooltip
                title={<span>Product quantity should be greater than zero</span>}
                placement="topLeft"
                color="#8B0000"
                trigger={"contextMenu"}
                open={errors[product_quant]}
                overlayStyle={{ fontSize: "12px" }}
                autoAdjustOverflow={false}
                zIndex={9999} >     
                <input type="text" 
                name="product_quantity" value={item.product_quantity?item.product_quantity:''}
                 className='form-control  wv-input-xs-small ' 
                onChange={ (e)=>{handleChange(productIndex,e)}} />
                </Tooltip>
                </td>

                <td colSpan={1} style={{width:'100px'}} >
                <Tooltip
                title={<span>Product price should be greater than zero</span>}
                placement="topLeft" color="#8B0000"
                trigger={"contextMenu"}
                open={errors[prd_price]}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
                zIndex={9999}>
                  
                  <input type="text"    onChange={ (e)=>{handleChange(productIndex,e)}}
                 name="product_price"  value={item.product_price?item.product_price:''}
                 className='form-control  wv-input-xs-small '/>
                  </Tooltip>
                 </td>
                <td colSpan={1} className='text-end' style={{width:'150px'}}>
                {selectedCurrency&&selectedCurrency.symbol}{item.product_amount}</td>
                <td colSpan={1}  className='text-center'>
                <span onClick={()=>{deleteProduct(productIndex)}} className='estimate-trash'><i className="bi bi-trash"></i></span>

                </td>
            </tr>
            {
                item.product_tax.map((tax,taxIndex)=>{
                  
                    let taxamt = ((parseFloat(item.product_amount)*parseFloat(tax.tax_rate))/100)
                      taxamt = isNaN(taxamt)?'-':parseFloat(taxamt).toFixed(2)
                        
                    return (
                        <tr key={taxIndex}>
                        <td colSpan={2} > </td>
                        <td colSpan={2}>
                            <div className='d-flex '>
                            <span className='me-2'>Tax</span> 
                            <Autocomplete  
                                onChange={(event, newValue)=>{
                                    taxOnChange(newValue,productIndex,taxIndex)
                                }}
                                disableClearable 
                                disablePortal
                               
                                sx={{   display: 'flex',
                                width:'80%',
                                '& input':{
                                height:18,
                                },

                                }}
                                options={taxList}
                                getOptionLabel={(option) =>option.tax_name?`${option.tax_name} ${option.tax_rate}%`: ''}
                                value={tax?tax:null}
                                renderOption={(props, option) => {
                                return (
                                <li {...props} key={option.id}>
                                {option.tax_name} {option.tax_rate}%
                                </li>
                                );
                                }}
                                renderInput={(params) => (
                                <TextField {...params} size="small"     />
                                )}
                                PaperComponent={({ children }) => {
                                  return (
                                    <Paper>
                                       
                                      {children}
                                      <Button
                                       style={{textTransform: 'none', display : role === 3 ? 'none' : 'block'}}
                                        color="primary"
                                        fullWidth
                                        sx={{  mx:'auto',py:'10px',mt:'2px',fontSize:12,borderTop:1,borderRadius:0 }}
                                        onMouseDown={() => {
                                         setTaxPopup(true)
                                         setTaxIndex(taxIndex)
                                         setProductIndex(productIndex)
                                         setErrors({})
                                        }}
                                      >
                                       <i className="bi bi-plus-circle me-1" ></i> Add new tax
                                      </Button>
                                    </Paper>
                                  );
                                }}
                            />
                            </div>
                       

                            
                        </td>
                        <td className='text-end'>{taxamt!=='-'? selectedCurrency&&selectedCurrency.symbol+ taxamt:taxamt}</td>
                        <td className='text-center'>
                  <span onClick={()=>{deleteTax(productIndex,taxIndex)}} className='estimate-trash'><i className="bi bi-trash"></i></span>

                        </td>
                        </tr>
                    )
                })
            }
        </tbody>
        </table>
                    </td>
                    </tr>
                )
            })
        }
     
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={6} className='add-product-row'>
                            <div className='ps-4' >
                            <div   className='add-new'
                                onClick={(event)=>{  event.stopPropagation();setDisplayProduct(!displayProduct)}}>
                                <i className="bi bi-plus-circle me-1" ></i>Add an item
                            </div>
                            {displayProduct && (
                                <div className="product-select-box" ref={divRef}>
<Autocomplete    
    open={true} disableClearable  disablePortal openOnFocus
    onChange={(event, newValue) => {
    productonChange(newValue)
    }} 
    options={productList}
    getOptionLabel={(option) =>option.product_name?option.product_name : ''}

    renderOption={(props, option) => {
    return (
    <li {...props} key={option.id} className='d-flex justify-content-between custom-product-option  align-items-center'>
        <div className='d-flex justify-content-between custom-product-list align-items-center py-2'  >
            <div> 
                <div>{option.product_name}</div>
                 <div style={{fontWeight:'normal',color:'grey'}}>{option.description}</div>
            </div>
            <div  style={{fontWeight:'normal',color:'grey'}}>{selectedCurrency&&selectedCurrency.symbol}{Number(option.price).toFixed(2)}</div>
        </div>

    </li>
    );
    }}
    renderInput={(params) => (
    <TextField {...params} size="small" placeholder='choose'    />
    )}
    PaperComponent={({ children }) => {
      return (
        <Paper>

          {children}
          <Button
            color="primary"
            fullWidth
            style={{textTransform: 'none', display : role === 3 ? 'none' : 'block'}}
            sx={{  mx:'auto',py:'8px',mt:'2px',fontSize:12,borderTop:1, borderColor: 'grey.500',borderRadius:0 }}
            onMouseDown={() => {
             setProductPopup(true)
             setDisplayProduct(false)
             setErrors({})
            }}
          >
            <i className="bi bi-plus-circle me-1" ></i> Add new product
          </Button>
        </Paper>
      );
    }}
   />
                                 </div>
                            )}
                            
                            </div>
                        </td>
                    </tr>
                </tfoot>
        </table>
        <div className="invoice-product-add-footer mb-5 ">
    
        <div  >
                <table className="table  table-borderless">
                    <thead >
                        
                        <tr>
                        <td className='text-end' colSpan={5}>Subtotal:</td>
                        <td colSpan={1} style={{width:'150px'}} className='text-end'>
                        {selectedCurrency&&selectedCurrency.symbol}{subtotal.toFixed(2)}</td>
                        <td style={{width:'50px'}}></td>
                        </tr>                     
                    </thead>
                    <tbody>
            {
                taxCalc.map((tax,index)=>{
                totaltax +=tax.taxAmount;
                return ( 
                <tr key={index}>
                <td className='text-end' colSpan={5}>{tax.taxName}</td>
                <td className='text-end'>{selectedCurrency&&selectedCurrency.symbol}{tax.taxAmount.toFixed(2)}</td>
                <td></td>
                </tr>
                )
                
                })
            }

                    </tbody>
                    <tfoot>
                    <tr><td className='text-end' colSpan={5}>
                        <div className='d-flex justify-content-end align-items-center'>
                         <span className='fw-bolder'>Total</span>
                    <Autocomplete    
                                disableClearable
                                   disablePortal
                                   size="small"
                                   className='ms-3'
                                   sx={{  
                                   width:230,  
                                   '& input': {
                                    height:20,
                                  },
                                 }}
                                
                                value={selectedCurrency || null}
                                 onChange={(event, newValue) => {
                                 setSelectedCurrency(newValue)
                                  SetInvoice({...invoice,currency:newValue.id})
                                }} 
                                   options={currencyList}
                                   getOptionLabel={(option) =>`${option.code} - ${option.currency_name}`}
                                  
                                renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={option.id}>
                                        {option.code} - {option.currency_name}
                                      </li>
                                    );
                                  }}
                                   renderInput={(params) => (
                                               <TextField {...params} size="small" placeholder='choose' />         
                                   )}
                                   PaperComponent={({ children }) => {
                                    return (
                                      <Paper>
                                         
                                        {children}
                                        <Button
                                         style={{textTransform: 'none', display : role === 3 ? 'none' : 'block'}}
                                          color="primary"
                                          fullWidth
                                          sx={{  mx:'auto',py:'10px',mt:'2px',fontSize:12,borderTop:1,borderRadius:0 }}
                                          onMouseDown={() => {
                                           setCurrencyPopup(true)
                                           setErrors({})
                                         
                                          }}
                                        >
                                         <i className="bi bi-plus-circle me-1" ></i> Add new currency
                                        </Button>
                                      </Paper>
                                    );
                                  }}
                                  
                               />
                    </div>
                    </td><td className='text-end'>{selectedCurrency&&selectedCurrency.symbol}{ (subtotal+totaltax).toFixed(2)}</td><td></td></tr>
                    {selectedCurrency&&selectedCurrency.code !== wv_busCur_code &&
                    <tr><td className='text-end' colSpan={5}>Total ({wv_busCur_code} at {selectedCurrency.exchange_rate}):</td>
                          <td className='text-end'>{wv_busCur_symbol}{ ((subtotal+totaltax)*selectedCurrency.exchange_rate).toFixed(2)}</td>
                    </tr>
                    }
                    
                    </tfoot>
                </table>
        </div>
               
        </div>
     </div>
        <div className="invoice-memo">
            <p className='invoice-memo-title mb-1'>Notes /Terms</p>
            <textarea  className='form-control invoice-memo-content'  
            name='memo'
            value={invoice.memo?invoice.memo:''}
            onChange={(e)=>handleInvoiceInputChange(e)}
            placeholder='Enter notes or terms of service that are visible to your customer'></textarea>
        </div>
    

    </div>
    <div className="invoice-footer my-4">
    <Accordion >
      <Accordion.Item eventKey="0">
        <Accordion.Header className='fw-bold'>Footer</Accordion.Header>
        <Accordion.Body>
        <textarea  className='form-control invoice-memo-content'  
         name="footer"
         value={invoice.footer?invoice.footer:''}
         onChange={(e)=>handleInvoiceInputChange(e)}
            placeholder='Enter a footer for this invoice (e.g. tax information, thank you note)'></textarea>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    </div>
    <div className="btn-actn-section text-end">
    <button className='btn btn-outline-primary wv-outline-btn me-4  px-3'
          onClick={()=>navigate('/invoices')}
          >Cancel</button>
            <button className='btn btn-outline-primary wv-outline-btn  me-4 px-3'
             onClick={()=>{ preview()}}>Preview</button>
            <button  className='btn btn-primary wv-actn-fill-btn' disabled={btnDisabled}
            onClick={()=>{ addInvoice()}}>
            Save and continue</button>
    </div>
</form>
</div>
    </div>
    </div>
    <AddCustomersPopup
      onUpdatecusList={updateCustomerListfromPopup}
      onUpdateselectedcus ={updateselectdcusfrompopup}
                
        displayPopup={customerPopup}
        isOpen={customerPopup}
        onClose={() => {
          setCustomerPopup(false);
        }}
      />
       <EditCustomersPopup
      onUpdatecusList={updateCustomerListfromPopup}
      onUpdateselectedcus ={updateselectdcusfrompopup}
        customerDetails = {invoice.customer}      
        displayPopup={editCustomerPopup}
        isOpen={editCustomerPopup}
        onClose={() => {
          setEditCustomerPopup(false);
        }}
      />
      <AddTaxPopup
       taxOnChange={taxOnChange}
        index={taxIndex}
        productIndex={productIndex}
         onUpdatetaxList={updateTaxListfromPopup}
        allTaxList={allTaxList}
        displayPopup={taxPopup}
        isOpen={taxPopup}
        onClose={() => {
          setTaxPopup(false);
        }}
      />
        <AddProductPopup
       index={productIndex}
       onUpdateproductList={updateProductListfromPopup}
        displayPopup={productPopup}
        isOpen={productPopup}
        onClose={() => {
          setProductPopup(false);
        }} 
        />
      <AddCurrencyPopup
                onUpdateselectedcurrency={updateSelelctedCurrencyPopup}
                displayAddCurrency={currencyPopup}
                waveCurrency={currencyList}
                isOpen={currencyPopup}
                onClose={() => {
                    setCurrencyPopup(false);
                }}
            />
    </>
    
}
<Customization_Warning_Popup
        displayPopup={customizationWarning}
        isOpen={customizationWarning}
        onClose={() => {
          setCustomizationWarning(false);
        }}
        navigateBack={true}
      />
      <Business_Warning_Popup
        displayPopup={businessWarning}
        isOpen={businessWarning}
        onClose={() => {
          setBusinessWarning(false);
        }}
        navigateBack={true}
    />
    </section>
  )

}

export default Add_Invoice