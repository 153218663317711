import Sidebar from "../Sidebar/Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from "react"
import axios from "axios";
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import LoadingSpinner from '../Utils/Loader'
import AddExpenses from "./AddExpenses";
import EditExpenses from "./EditExpenses";
import { useNavigate } from "react-router-dom";
import dayjs from 'dayjs';

import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import Delete from '../AlertBox/Delete'
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';

import { Menu, Dropdown, Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

export default function Expenses() {
    const navigate = useNavigate();
    const user = localStorage.getItem("user");
    const businessID = user && JSON.parse(user)[0].business.id;
    const business_name = user && JSON.parse(user)[0].business.business_name;
    const businessCurrency = user && JSON.parse(user)[0].business_currency === null ? "$" : JSON.parse(user)[0].business_currency.toString().split(" - ")[2];
    const [expenses, setExpenses] = useState([]);
    const [addExpenses, setAddExpenses] = useState(false);
    const [editExpenses, setEditExpenses] = useState(false);
    const [editExpensesId, setEditExpensesId] = useState();
    const [loading, setLoading] = useState(false);

    const [filterName, setFilterName] = useState(null);
    const [filterFromDate, setFilterFromDate] = useState();
    const [filterToDate, setFilterToDate] = useState();
    const [isOpenFromDate, setIsOpenFromDate] = useState(false);
    const [isOpenToDate, setIsOpenToDate] = useState(false);

    const [selectedExpense, setSelectedExpense] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [filterHide, setFilterHide] = useState(false);

    useEffect(() => {
        if(user === null){
            localStorage.removeItem("user");
            navigate('/error-page');
            }

        axios.get(`https://wavedemo.cydeztechnologies.com/listExpenseByBusinessId/${businessID}`).then((response) => {
            if(response.data !== "No data found") {
                setExpenses(response.data);
                setLoading(true);
            }
            else{
                setExpenses([]);
                setLoading(true);
            }
            
        }).catch((e) => {
            toast.error(e.message, {toastId:"error"});
        });
    }, [addExpenses, editExpenses,isUpdated]);

    function handleDelete(item) {
      setSelectedExpense(item);
      setOpenDelete(true);
    }
  
    const confirm = (confirm) => {
      if(confirm && confirm === true){
        axios.delete(`https://wavedemo.cydeztechnologies.com/delete_expense/${selectedExpense.id}`).then((res) => {
          if (res.data) {
            toast.success(res.data, { toastId: "listdel" });
            setIsUpdated((prevIsUpdated) => !prevIsUpdated);
          }
        })
        .catch((e) => {
          toast.error(e.message, { toastId: "err-listdel" });
        });
      }
    }

    const onClickCreate = () => {
        setAddExpenses(true);
    }

    function onClickEdit(item) {
        setEditExpenses(true)
        setEditExpensesId(item)
    }

    const handleFilterClear = () => {
        setFilterName('');
        setFilterFromDate(null);
        setFilterToDate(null);
      }

      const onFilterToggleChange = () => {
        setFilterHide((prevState) => !prevState);
        setFilterName('');
        setFilterFromDate(null);
        setFilterToDate(null);
      }

    const arrFilterNameData = expenses.map(item => {
        return item.expense_name
      });
    
      const filterNameData = [...new Set(arrFilterNameData.flat().filter(name => name !== null))];


    let count = 0;
  let filteredData = expenses;
 
  if (filterName) {
    filteredData = filteredData.filter(
      item =>
        item.expense_name &&
        item.expense_name
          .toLowerCase()
          .includes(filterName.toLowerCase())
    );
    count += 1;
  }

  if (filterFromDate) {
    filteredData = filteredData.filter(
      item =>
        item.expense_date &&
        (dayjs(item.expense_date).isSame(dayjs(filterFromDate), 'day') ||
          dayjs(item.expense_date).isAfter(dayjs(filterFromDate), 'day'))
    );
    count += 1;
  }
  
  if (filterToDate) {
    filteredData = filteredData.filter(
      item =>
        item.expense_date &&
        (dayjs(item.expense_date).isSame(dayjs(filterToDate), 'day') ||
          dayjs(item.expense_date).isBefore(dayjs(filterToDate), 'day'))
    );
    count += 1;
  }

    const customStyles = {
        headRow: {
            style: {
                color: '#000',
                fontWeight: "bolder",
                fontSize: "16px",
                borderBottom: "1px solid black"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontSize: "16px",
                minHeight: '55px'
            }
        },

    }

    const columns = [
        {
            name: 'Date',
            sub_name: 'expenses_date',
            selector: row => row.expenses_date,
            grow: 2,
            sortable: true,
        },
        {
            name: 'Expense Name',
            sub_name: 'expenses_name',
            selector: row => row.expenses_name,
            grow: 2,
            wrap: true
        },
        {
            name: 'Description',
            sub_name: 'description',
            selector: row => row.description,
            grow: 2,
            wrap: true
        },
        {
            name: `Amount (${businessCurrency})`,
            sub_name: 'amount',
            selector: row => row.amount,
            grow: 2,
            right: true,
        },
        {
            name: 'Actions',
            sub_name: 'Actions',
            selector: row => row.action,
            grow: 1,
            center: true,
            cell: (row) => {
              const menu = (
                <Menu>
                  <Menu.Item key="edit" onClick={() => onClickEdit(row.action)}>
                    Edit
                  </Menu.Item>
                  <Menu.Item key="delete" onClick={() => handleDelete(row.action)}>
                    Delete
                  </Menu.Item>
                </Menu>
              );
          
              return (
                <Dropdown trigger={['click']}  overlay={menu}>
                  <Button className="action-icon-btn" style={{display: "flex", alignItems: "center", justifyContent: "center"}} type="primary"  icon={<CaretDownOutlined style={{fontSize:"13px"}}/>} />
                </Dropdown>
              );
            },
        },
    ];

    const rows = (filterName || filterFromDate || filterToDate ) ? 
    filteredData ? filteredData.map((item) => {
        return {
            expenses_date: item.expense_date && dayjs(item.expense_date).format("DD/MM/YYYY"),
            expenses_name: item.expense_name,
            description: item.description,
            amount: Number(item.amount).toFixed(2),
            action: item
          }
    }) : '':
    expenses ? expenses.map((item) => {
        return {
            expenses_date: item.expense_date && dayjs(item.expense_date).format("DD/MM/YYYY"),
            expenses_name: item.expense_name,
            description: item.description,
            amount: Number(item.amount).toFixed(2),
            action: item
            
        }
    }) : '';

    const exportToExcel = () => {
      if(Array.isArray(expenses) && expenses.length === 0 ){
        toast.warning("Expenses data is empty!");
      }
      else {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet('Expenses');
  
      // Add column headers
      columns.forEach((column, index) => {
        if(column.sub_name !== 'Actions'){
          worksheet.getCell(1, index + 1).value = column.name;
          worksheet.getCell(1, index + 1).alignment = { horizontal: 'center', vertical: 'middle' };
          worksheet.getColumn(index + 1).width = 20/* Add some padding, e.g., 2 characters */;
        }
      });

      const totalSum = rows.reduce((sum, row) => sum + Number(row.amount), 0);

    // Add the total sum to the Excel worksheet
    const lastRowIndex = rows.length + 3; // Add 3 for the header row and 1-based indexing
    const totalSumColumnIndex = columns.findIndex((column) => column.sub_name === 'description') + 1;
    worksheet.getCell(lastRowIndex, totalSumColumnIndex).value = 'Total Amount:';
    worksheet.getCell(lastRowIndex, totalSumColumnIndex).alignment = { horizontal: 'left', vertical: 'middle' };
    worksheet.getCell(lastRowIndex, totalSumColumnIndex + 1).value = `${businessCurrency} ${totalSum}.00`;
    worksheet.getCell(lastRowIndex, totalSumColumnIndex + 1).alignment = { horizontal: 'right', vertical: 'middle' };
  
      // Add data rows
      rows.forEach((row, rowIndex) => {
        columns.forEach((column, columnIndex) => {
          if(column.sub_name !== 'Actions'){
            worksheet.getCell(rowIndex + 2, columnIndex + 1).value = row[column.sub_name];
            // worksheet.getCell(rowIndex + 2, columnIndex + 1).alignment = { horizontal: 'left', vertical: 'middle' };
          }
          if(column.sub_name === 'amount'){
            worksheet.getCell(rowIndex + 2, columnIndex + 1).value = Number(row[column.sub_name]);
            worksheet.getCell(rowIndex + 2, columnIndex + 1).numFmt = `0.00`;
            // worksheet.getCell(rowIndex + 2, columnIndex + 1).alignment = { horizontal: 'left', vertical: 'middle' };
          }
        });
      });
  
      // Create a buffer and save it as a file
      workbook.xlsx.writeBuffer().then((buffer) => {
        saveAs(new Blob([buffer]), `Expenses data from ${business_name}.xlsx` );
      });
    }
    };
  

    return (
        <div>
            <Sidebar />
            {loading ?
                <div className="container main-coloum1 my-5 px-5">
                    <div className="row">
                        <div className="col-12 text-start fw-bold fs-3 text-capitalize">
                            Expenses
                        </div>
                        {/* <div className="col-3 text-end">
                            <button className="btn btn-primary" onClick={onClickCreate}>Add Expenses</button>
                        </div> */}
                    </div>

                    <div className="mt-3">
                    <div className="d-flex justify-content-between">
  <div>
    <button className="btn btn-outline-primary px-4 py-1 wv-outline-btn mb-3" onClick={() => { onFilterToggleChange() }}>Filter <i className={filterHide ? 'bi bi-chevron-up ps-2' : 'bi bi-chevron-down ps-2'}></i> </button>
  </div>
  <div className="d-flex justify-content-end align-items-center">
    <button className="btn btn-outline-primary px-4 py-1 wv-outline-btn mb-3" onClick={() => { exportToExcel() }}>{filterHide ? 'Export filter Data' : 'Export Data'}</button>
    <div className="ms-4">
      <button className="btn btn-outline-primary px-4 py-1 wv-outline-btn mb-3" onClick={onClickCreate}>Add Expenses</button>
    </div>
  </div>
</div>

            <div className='filter-body' style={{ display: filterHide ? 'block' : 'none' }}>
            <div className="filter-input-count-dev">
              <span className="filter-count-num">{count}</span>
              <p className='filter-count-text'>Active filters</p>
              {count > 0 &&
                <>
                  <p className='filter-count-text'>|</p>
                  <p className='filter-clear' onClick={() => handleFilterClear()}>Clear filters</p>
                </>
              }
            </div>
            <div className='row'>
              <div className="filter-input-box  col-sm-6">
                <p className="filter-input-lable">Expense name: </p>
                <div className='w-100'>
                {/* <Autocomplete
                  disablePortal
                  id="filter-Name"
                  size="small"
                  value={filterName}
                  onChange={(event, newValue) => {
                    setFilterName(newValue);
                  }}
                  options={filterNameData}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: 40,
                  }}
                  renderInput={(params) => <TextField
                    {...params}
                    placeholder="Expense Name"
                    size="small" />}
                /> */}
                <TextField 
                  id="outlined-basic" 
                  // label="Search by name" 
                  placeholder="Search by name" 
                  variant="outlined" 
                  value={filterName} 
                  onChange={(e) => setFilterName(e.target.value)}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: 40,
                  }}
                  size="small"
                />
              </div>
              </div>
              <div className="filter-input-box  col-sm-6"> 
                <p className="filter-input-lable">Date: </p>
                <div className='d-flex'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div style={{ position: 'relative' }}>
                    <DatePicker
                      format='DD/MM/YYYY'
                      value={filterFromDate}
                      onChange={(date) => setFilterFromDate(date)}
                      open={isOpenFromDate}
                      onOpen={() => setIsOpenFromDate(true)}
                      onClose={() => setIsOpenFromDate(false)}
                      slotProps={{ textField: { size: 'small', placeholder: 'From', type: 'text' } }}
                    />
                    {/* {filterFromDate ?
                      <span style={{ position: 'absolute', right: '10px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray", fontSize: "18px" }} onClick={() => setFilterFromDate(null)}>
                        X
                      </span>
                      :
                      <span style={{ position: 'absolute', right: '10px', top: '47%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray", fontSize: "18px" }} onClick={() => setIsOpenFromDate(true)}>
                        <i className="bi bi-calendar"></i>
                      </span>
                    } */}
                  </div>
                </LocalizationProvider>
                <div className='mx-1'></div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div style={{ position: 'relative' }}>
                    <DatePicker
                      format='DD/MM/YYYY'
                      value={filterToDate}
                      onChange={(date) => setFilterToDate(date)}
                      open={isOpenToDate}
                      onOpen={() => setIsOpenToDate(true)}
                      onClose={() => setIsOpenToDate(false)}
                      slotProps={{ textField: { size: 'small', placeholder: "To" } }}
                    />
                    {/* {filterToDate ?
                      <span style={{ position: 'absolute', right: '10px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray", fontSize: "18px" }} onClick={() => setFilterToDate(null)}>
                        X
                      </span>
                      :
                      <span style={{ position: 'absolute', right: '10px', top: '47%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray", fontSize: "18px" }} onClick={() => setIsOpenToDate(true)}>
                        <i className="bi bi-calendar"></i>
                      </span>
                    } */}
                  </div>
                </LocalizationProvider>
              </div>
              </div>
              
            </div>
            </div>
          </div>


                    <div className="container mt-3">
                        <DataTable
                            columns={columns}
                            data={rows}
                            fixedHeaderScrollHeight="600px"
                            customStyles={customStyles}
                            persistTableHead
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 25, 50]}
                            highlightOnHover
                        />
                    </div>
                </div>
                :
                <LoadingSpinner />}
            <AddExpenses
                displayAddExpenses={addExpenses}
                isOpen={addExpenses}
                onClose={() => {
                    setAddExpenses(false);
                }}
            />
            <EditExpenses
                displayEditExpenses={editExpenses}
                ExpensesDetails={editExpensesId}
                isOpen={editExpenses}
                onClose={() => {
                    setEditExpenses(false);
                }}
            />
            <Delete 
      title1 = "Are you sure"
      title2 = "Do you really want to delete these expense record?"
      isOpen={openDelete}
      onClose={() => {
        setOpenDelete(false);
      }}
      confirm = {confirm}
      />
        </div>
    )
}