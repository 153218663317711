import { useState } from "react";
import axios from 'axios';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Tooltip } from 'antd';
import './Estimate.css'

function AddTaxPopup(props) {
    const { displayPopup, isOpen, onClose, onUpdatetaxList, allTaxList,index,taxOnChange,productIndex=null } = props;
    const [_isOpen, setOpen] = useState(isOpen);
    const user = localStorage.getItem("user");
    const businessID = JSON.parse(user)[0].business.id;
    const [formData, setFormData] = useState({
        tax_name: '',
        description: '',
        tax_rate: '',
    });
    const [errors, setErrors] = useState({});
    const [errorsMessage, setErrorsMessage] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
        setFormData({ tax_name: '', description: '', tax_rate: '', })
        setErrors(false);
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(false);
    };


    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(formData);
        const errors = {};
        const errorMessage = {};

        for (const [key, value] of Object.entries(formData)) {
            if (key !== 'description') {
                var name;
      if(key === "tax_name"){name = "Tax name"};
      if(key === "tax_rate"){name = "Tax rate"};
                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`;
                }
                else if (key === "tax_name" && !/^(?![\d!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+$).+$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = `Tax name is invalid`;
                }
                else if (key === "tax_rate" && !/^-?\d*(?:\.\d+)?$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = `Tax rate is invalid`;
                }
            }
        }

        setErrors(errors);
        setErrorsMessage(errorMessage);
        if (Object.keys(errors).length === 0) {
            const isTaxExisted = allTaxList.some((item) => {
                return (item.tax_name === formData.tax_name && item.tax_rate == formData.tax_rate);
              });
              if (isTaxExisted) {
                toast.error("Sales tax already exists!", { toastId: "addTax-1" });
              } 
              else{
                setIsSubmitting(true);
                axios.post("https://wavedemo.cydeztechnologies.com/tax-registration", {
                    business_id: businessID,
                    tax_name: formData.tax_name,
                    description: formData.description,
                    tax_rate: formData.tax_rate,
                    status: 1
                })
                    .then((res) => {
                        if (res.data) {
                           // console.log(res.data)
                            onUpdatetaxList(res.data.allTaxes)
                            if(productIndex === null){ taxOnChange([res.data.Tax],index)}
                            else{ taxOnChange(res.data.Tax,productIndex,index)}
                           
                            toast.success("Tax has been created", {toastId:"add-tax"})
                            setOpen(false);
                            if (onClose) onClose();
                            setFormData({ tax_name: '', description: '', tax_rate: '', })
                            setErrors(false);
                            setIsSubmitting(false);
                        }
                        else {
                            toast.error("Failed please try again", {toastId:"add-tax"})
                            setIsSubmitting(false);
                        }
                    })
                    .catch((e) => {
                        toast.error(e.message, {toastId:"err-api"})
                        setIsSubmitting(false);
                    });
              }
        }
        else{
            setIsSubmitting(false);
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error"});
        }
    };

    const styleName = "form-control w-75 fs-13 ";
    const styleNameError = "form-control w-75 fs-13 border border-danger";

    return (
        <Dialog
            open={displayPopup}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll={'paper'}
            disablebackdropclick="true"
            disableEscapeKeyDown={true}
            fullWidth={true}
            maxWidth={false}
            PaperProps={{
                style: {
                    maxWidth: "600px",
                    width: "100%",
                    }
                }}
            >
            <DialogTitle sx={{ position: 'relative', height: '50px' }} id="alert-dialog-title" className="align-content-center ">
                    <p style={{ fontWeight: '500' }}>Add Sales Tax</p>
                    <i
                        className="bi bi-x-lg"
                        style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                        onClick={handleClose}
                    ></i>
                </DialogTitle>
            <hr />
            <DialogContent className="px-5">
                <div>
                    <div className="form-group row m-4">
                        <label className="col-sm-5 fw-bold text-md-end py-1">Tax Name <span className="importent">*</span></label>
                        <div className="col-sm-7 text-center">
                            <Tooltip
                                title={errorsMessage.tax_name}
                                placement="topLeft"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.tax_name}
                                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                autoAdjustOverflow={false}
                                zIndex={9999}
                            >
                                <input
                                    type="text"
                                    className={`${errors.tax_name ? styleNameError : styleName}`}
                                    name='tax_name'
                                    value={formData.tax_name}
                                    onChange={handleChange}
                                />
                               
                            </Tooltip>
                        </div>
                    </div>
                    <div className="form-group row m-4">
                        <label className="col-sm-5 fw-bold text-md-end py-1">Description</label>
                        <div className="col-sm-7 text-center">
                            <textarea
                                className="form-control w-75 fs-13 "
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>
                    <div className="form-group row m-4">
                        <label className="col-sm-5 fw-bold text-md-end py-1">Tax Rate (%) <span className="importent">*</span></label>
                        <div className="col-sm-7">
                            <Tooltip
                                title={errorsMessage.tax_rate}
                                placement="topLeft"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.tax_rate}
                                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                autoAdjustOverflow={false}
                                zIndex={9999}
                            >
                                <input
                                    type="text"
                                    className={`${errors.tax_rate ? styleNameError : styleName}`}
                                    // placeholder="example 8, without a %."
                                    name='tax_rate'
                                    value={formData.tax_rate}
                                    onChange={handleChange}
                                />
                               
                            </Tooltip>
                            <label style={{ fontSize: "13px", color: "grey" }} className="me-5">Please provide a numerical value without including the percentage sign.</label>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <hr />
            <DialogActions className='mb-3' sx={{ height: '30px' }}>
                <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary popup-add-btn me-2">
                    {isSubmitting ? 'Uploading...' : 'Add Sales Tax'}
                </button> or
                <span onClick={handleClose} className="mx-2 popup-cancel-btn">
                    Cancel
                </span>
            </DialogActions>
        </Dialog>

    );
}

export default AddTaxPopup