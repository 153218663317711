import React, { useEffect, useState } from 'react'
import './Estimate_Preview.css'
import axios from 'axios';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
function Estimate_Preview(props) {
    var subtotal = 0;
    var totaltax = 0;
    const { estimate } = props;
    const [businessDetails, setbusinessDetails] = useState({})
    const [customerDetails, setCustomerDetails] = useState({})
    const [taxCalc, setTaxCalc] = useState([]);  //for tax calculation
    const esdate = format(new Date(estimate.date), "MMMM d, yyyy");
    const expiry_date = format(new Date(estimate.expiry_date), "MMMM d, yyyy");
    const curSymbol = estimate && estimate.currency.symbol;
    const [logo, setLogo] = useState(null);
    const [selectedColor, setSelectedColor] = useState('#1E4C88');

    const calculateTax = () => {
        const taxMap = [];

        estimate.products.forEach((product) => {
            const product_amount = (parseFloat(product.product_price) * product.product_quantity).toFixed(2);
            product.taxes.forEach((tax) => {
                const tax_per = parseFloat(tax.tax_percentage) % 1 === 0 ? parseFloat(tax.tax_percentage).toFixed(0) : tax.tax_percentage;
                const taxName = tax.tax_description + " " + tax_per + "%";
                const taxAmount = (parseFloat(tax.tax_percentage) * product_amount) / 100;
                const existingTax = taxMap.find((item) => item.taxName === taxName);
                if (existingTax) {
                    existingTax.taxAmount += taxAmount;
                } else {
                    taxMap.push({ taxName, taxAmount });
                }

            })
        })
        setTaxCalc(taxMap)
    }

    useEffect(() => {
        calculateTax();
        if (estimate.business_id) {
            //fetch logo 
            fetchBusinessDetails();
            fetchLogo();
        }
        if (estimate.business_id && estimate.customer_id) {
            //fetch customer details from cusid
            fetchCusDetails();
        }

    }, [])
    //fetch business details
    const fetchBusinessDetails = () => {

        axios.get('https://wavedemo.cydeztechnologies.com/list-business').then((res) => {
            const business_details = res.data.find(obj => obj.id === estimate.business_id)
            setbusinessDetails(business_details);


        }).catch(error => {
            toast.error("Something Went Wrong", { toastId: 'editbus5', });
        });
    }
    //fetch customer details
    const fetchCusDetails = () => {

        axios.get(`https://wavedemo.cydeztechnologies.com/list-customers/${estimate.business_id}`).then((res) => {
            const cus_details = res.data.find(obj => obj.id === estimate.customer_id.id)
            setCustomerDetails(cus_details);
        }).catch(error => {
            toast.error("Something Went Wrong", { toastId: 'editbus5', });
        });
    }
    //fetch Logo
    const fetchLogo = () => {

        axios.get(`https://wavedemo.cydeztechnologies.com/list-invoice-by-id/${estimate.business_id}`).then((res) => {

            if (res.data !== "No data found") {

                setLogo(res.data[0].logo);
                setSelectedColor(res.data[0].row_bgcolor)
            }

        }).catch(error => {
            toast.error("Something Went Wrong", { toastId: 'addest4', });
        });
    }




    const invoice_table_color = { backgroundColor: selectedColor, color: 'white' };



    return (
        <div className="preview_template">
            <div className="template_container">
                <section className='template_header'>
                    <div className="template_header_logo">
                        {logo && (
                            <img className="img-fluid" src={typeof logo === 'object' ? URL.createObjectURL(logo) : `https://wavedemo.cydeztechnologies.com/${logo}`}
                                alt="logo" style={{ maxWidth: "300px", maxHeight: "200px" }} />
                        )}
                    </div>
                    <div className="template_header_info">
                        <div className="template_header_title text-end">{estimate && estimate.transaction_name}</div>
                        {estimate.subheading && <div className="template_subheader text-end mb-2">{estimate && estimate.subheading}</div>}
                        <div className="company_name text-end fw-bolder">{businessDetails && businessDetails.business_name}</div>
                        <div style={{maxWidth: '300px', float: 'right'}}>
                            {businessDetails.business_address && <div className="company_address text-end">{businessDetails&&businessDetails.business_address}{businessDetails&&businessDetails.business_address && ','}</div>}
                        <div className="company_address text-end">
                            {businessDetails&&businessDetails.business_city}{businessDetails && businessDetails.business_city && ','} {businessDetails&&businessDetails.business_state}{businessDetails && businessDetails.business_state && ','}</div>
                        <div className="company_address text-end">{businessDetails&&businessDetails.business_pincode} {businessDetails && businessDetails.business_pincode && ','} {businessDetails&&businessDetails.business_country}</div>
                        {businessDetails.business_mobile && <div className="company_address text-end mt-2">{businessDetails && `Phone : ${businessDetails.business_mobile}`}</div>}
                        </div>
                        <div className="company_address text-end w-100" style={{maxWidth: '100%', float: 'right'}}>{businessDetails&&businessDetails.business_weburl}</div>
                    </div>
                </section>
                <hr className='pv-full-width my-4' />
                <section className='template_metadata d-flex justify-content-between '>
                    <div className="template_metadata_customer" style={{ width: "50%" }}>
                        <div className="customer_address_head">BILL TO</div>
                        {/* <div className="customer_address fw-bolder">{customerDetails && customerDetails.customer_company_name}</div>
                        {customerDetails && customerDetails.first_name !== null && customerDetails.first_name !== undefined &&
                            <div className="customer_firstname fw-bolder">{customerDetails && customerDetails.first_name} {customerDetails && customerDetails.last_name}</div>
                        }
                        {customerDetails &&
                            <div className="customer_address fw-bolder">{customerDetails.address && customerDetails.address}{customerDetails.address && customerDetails.city && ','}</div>
                        }
                        {customerDetails &&
                            <div className="customer_address fw-bolder">{customerDetails.city && customerDetails.city}{customerDetails.city && customerDetails.city && ','} {customerDetails.state && customerDetails.state}{customerDetails.state && customerDetails.postal_code && ','} {customerDetails.postal_code && customerDetails.postal_code}{customerDetails.postal_code && ','} {customerDetails.country && customerDetails.country} </div>
                        } */}
                        {/* <div className="customer_address fw-bolder" style={{width:"300px"}}>
                            {customerDetails&&customerDetails.customer_company_name}
                            {customerDetails&&customerDetails.customer_company_name&&customerDetails.first_name && ", "}
                            {customerDetails&&customerDetails.first_name} {customerDetails&&customerDetails.last_name}
                            {customerDetails&&customerDetails.first_name&&customerDetails.last_name && customerDetails.address&& ", "}
                            {customerDetails.address&&customerDetails.address}{customerDetails.address && customerDetails.city && ', '}
                            {customerDetails.city&&customerDetails.city}{customerDetails.city && customerDetails.city && ', '} {customerDetails.state&&customerDetails.state}{customerDetails.state && customerDetails.postal_code && ','} {customerDetails.postal_code&&customerDetails.postal_code}{customerDetails.postal_code && ','} {customerDetails.country&&customerDetails.country}
                        </div> */}
                        <div className="customer_address fw-bolder" style={{width:"300px"}}>
                            {customerDetails&&customerDetails.customer_company_name}

                            {customerDetails&&customerDetails.customer_company_name && 
                            (customerDetails.first_name || customerDetails.last_name || customerDetails.city || customerDetails.state || customerDetails.postal_code || customerDetails.country) && ", "}

                            {customerDetails&&customerDetails.first_name} {customerDetails&&customerDetails.last_name}

                            {customerDetails&&(customerDetails.first_name || customerDetails.last_name) &&
                            (customerDetails.first_name || customerDetails.last_name || customerDetails.city || customerDetails.state || customerDetails.postal_code || customerDetails.country) && ", "}

                            {customerDetails.address&&customerDetails.address}

                            {customerDetails.address && 
                            (customerDetails.first_name || customerDetails.last_name || customerDetails.city || customerDetails.state || customerDetails.postal_code || customerDetails.country) && ", "}

                            {customerDetails.city&&customerDetails.city}
                            {customerDetails.city && (customerDetails.first_name || customerDetails.last_name || customerDetails.city || customerDetails.state || customerDetails.postal_code || customerDetails.country) && ", "} 

                            {customerDetails.state&&customerDetails.state}
                            {customerDetails.state && (customerDetails.first_name || customerDetails.last_name || customerDetails.city || customerDetails.state || customerDetails.postal_code || customerDetails.country) && ", "} 
                            
                            {customerDetails.postal_code&&customerDetails.postal_code}
                            {customerDetails.postal_code && (customerDetails.first_name || customerDetails.last_name || customerDetails.city || customerDetails.state || customerDetails.postal_code || customerDetails.country) && ", "} 
                            {customerDetails.country&&customerDetails.country}
                        </div>
                        {customerDetails && <div className="csutomer_phone mt-2">{customerDetails.phone && `${customerDetails.phone}`}</div>}
                        {customerDetails && <div className="csutomer_phone mt-1">{customerDetails.email && `${customerDetails.email}`}</div>}
                    </div>
                    <div className="template_metadata_txn_info me-5" >
                        <table className='template_metadata_txn_info-table' >
                            <tbody>
                                <tr>
                                    <td className="text-end">
                                        <span className="fw-bolder ">Estimate Number :</span>
                                    </td>
                                    <td className="ps-1">
                                        {estimate.prefix}
                                        {estimate.transaction_number}
                                    </td>
                                </tr>
                                {estimate.purchase_order && (
                                    <tr>
                                        <td className="text-end">
                                            <span className="fw-bolder">P.O./S.O. Number :</span>
                                        </td>
                                        <td className="ps-1">{estimate.purchase_order}</td>
                                    </tr>
                                )}
                                <tr>
                                    <td className="text-end">
                                        <span className="fw-bolder">Estimate Date :</span>
                                    </td>
                                    <td className="ps-1">{esdate}</td>
                                </tr>
                                <tr>
                                    <td className="text-end">
                                        <span className="fw-bolder">Expires On :</span>
                                    </td>
                                    <td className="ps-1">{expiry_date}</td>
                                </tr>
                                <tr style={{ background: "#f4f5f5" }}>
                                    <td className="text-end">
                                        <span className="fw-bolder ps-3">
                                            Grand Total ({estimate.currency.code}) :
                                        </span>
                                    </td>
                                    <td className="ps-1">
                                        <span className="fw-bolder">
                                            {curSymbol}
                                            {estimate && parseFloat(estimate.total_amount).toFixed(2)}
                                        </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
                <section className='template_items pv-full-width mt-4'>
                    <table className='table table-borderless'>
                        <thead>
                            <tr>
                                <th colSpan={4} scope="col" className='pl-30' style={invoice_table_color}>Items</th>
                                <th colSpan={1} scope="col" style={invoice_table_color}>Quantity</th>
                                <th colSpan={1} scope="col" className='text-end' style={invoice_table_color} >Price</th>
                                <th colSpan={1} scope="col" className='text-end pr-30' style={invoice_table_color}>Amount</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                estimate && estimate.products.map((item, index) => {

                                    const product_amount = (parseFloat(item.product_price) * item.product_quantity).toFixed(2);

                                    subtotal = parseFloat(subtotal) + parseFloat(product_amount);
                                    return (
                                        <tr key={index}>
                                            <td colSpan={4} className='pl-30 '>
                                                <span className='fw-bolder'>{item.product_name}</span>
                                                <p className='p-0 m-0' style={{ wordBreak: 'break-word' }}>{item.product_description}</p>
                                            </td>
                                            <td>{item.product_quantity}</td>
                                            <td className='text-end' >{curSymbol}{(parseFloat(item.product_price)).toFixed(2)}</td>
                                            <td className='text-end pr-30'>{curSymbol}{product_amount}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>

                    </table>
                    <hr className='thick-border' />
                </section>
                <section className='template_totals d-flex jsutify-content-between'>
                    <div className='template_totals-blank'></div>
                    <div className='template_totals-amounts'>
                        <table className="table  table-borderless template_totals-amounts-table">
                            <thead>
                                <tr><td className='fw-bolder text-end pe-5'>Subtotal</td>
                                    <td className='text-end'>{curSymbol}{parseFloat(subtotal).toFixed(2)}</td></tr>
                            </thead>
                            <tbody>
                                {
                                    taxCalc.map((tax, index) => {
                                        totaltax += tax.taxAmount;
                                        return (
                                            <tr key={index}>
                                                <td className='text-end pe-5'>{tax.taxName}</td><td className='text-end'>{curSymbol}{tax.taxAmount.toFixed(2)}</td>
                                            </tr>
                                        )

                                    })
                                }
                                <tr><td className='p-0 m-0' colSpan={2}><hr className='p-0 m-0' /></td></tr>
                            </tbody>

                            <tfoot>

                                <tr><td className='fw-bolder text-end pe-5'>Total</td><td className='text-end'>{curSymbol}{(subtotal + totaltax).toFixed(2)}</td></tr>
                                <tr><td className='p-0 m-0' colSpan={2}><hr className='p-0 m-0 thick-border' /></td></tr>
                                <tr className='fw-bolder text-end'><td className='pe-5'>Grand Total ({estimate.currency.code})</td><td className='text-end'>{curSymbol}{(subtotal + totaltax).toFixed(2)}</td></tr>
                            </tfoot>
                        </table>
                    </div>
                </section>

                <div className='template_memo'>

                    {estimate.memo ?
                        <>
                            <div className='fw-bolder my-2' >Notes/Terms</div>
                            <div style={{ fontFamily: "inherit", whiteSpace: 'pre-line' }}>{estimate.memo}</div>
                        </>
                        : ''}
                </div>
                <div className='template_footer'>
                    {estimate.footer ?
                        <span>{estimate.footer}</span>
                        : ''}
                </div>

            </div>
        </div>

    )
}

export default Estimate_Preview