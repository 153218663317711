import React from 'react'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import './Dashboard.css';
import Sidebar from '../Sidebar/Sidebar';
import { Link } from "react-router-dom";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import LoadingSpinner from '../Utils/Loader'
import dayjs from 'dayjs';

function ProfirAndLoss() {
  const user = localStorage.getItem("user");
  const businessID = user && JSON.parse(user)[0].business.id;
  const [monthlyTotals, setMonthlyTotals] = useState([]);
  const [monthlyInflow, setMonthlyInflow] = useState([]);
  const businessCurrency = user && JSON.parse(user)[0].business_currency === null ? "$" : JSON.parse(user)[0].business_currency.toString().split(" - ")[2];
  const [show12Months, setShow12Months] = useState(true);

  useEffect(() => {
    axios.get(`https://wavedemo.cydeztechnologies.com/listExpenseByBusinessId/${businessID}`).then((response) => {
      if (response.data !== "No data found") {
        setMonthlyTotals(response.data);
      }
    }).catch((e) => {
      toast.error(e.message, { toastId: "err-api" });
    });

    axios.get(`https://wavedemo.cydeztechnologies.com/list-record-payment-by-business/${businessID}`).then((response) => {
      if (response.data !== "No data found") {
        setMonthlyInflow(response.data);
      }
    }).catch((e) => {
      toast.error(e.message, { toastId: "err-api" });
    });
  }, []);

  const currentDate = new Date();
  const xAxisCategoriesMonths = show12Months ? generateXAxisCategories(currentDate, 12) : generateXAxisCategories(currentDate, 24);
  const amountList = xAxisCategoriesMonths.map((expense) => expense.outflowAmount);
  const inflowAmount = xAxisCategoriesMonths.map((expense) => expense.inflowAmount);
  const monthList = xAxisCategoriesMonths.map((expense) => expense.date);

  const chart = {
    chart: {
      type: 'column'
    },
    title: {
      text: ''
    },
    xAxis: {
      title: {text: '<span><br/><br/><strong style="font-size:15px"> Profit & Loss value </strong></span>'},
      minTickInterval: 1,
      categories: monthList,
      labels: {
        rotation: 0,
        align: 'center',
        formatter: function () {
          var monthYear = this.value.toString().split(' ');
          return '<span>' + monthYear[0] + '</span><br/>' + monthYear[1].substring(2, 4);
        }
      },
      crosshair: true
    },
    yAxis: {
      title: {
        text: ''
      },
      labels: {
        formatter: function () {
          return businessCurrency + this.value;
        }
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.x}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{point.name}:</td>' +
        `<td style="padding:0"> <b>{point.y:.1f} ${businessCurrency}</b></td></tr>`,
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
      borderRadius: 10,
      borderWidth: 3
    },
    plotOptions: {
      series: {
        borderRadius: '0%'
      },
      column: {
        pointWidth: show12Months ? 30 : 16,
        pointPadding: 0.1,
        borderWidth: 0,
      },
    },
    legend: {
      enabled: false,
    },
    series: [{
      // type:"",
      // name: "",
      color:"black",
      data: inflowAmount.map((value, index) => {
        const netAmount = value - amountList[index];
        return {
          y: netAmount,
          name: netAmount >= 0 ? 'Profit' : 'Loss',
          color: netAmount >= 0 ? '#23c770' : '#f44336'
        };
      }),
    },
    ]
  };

  function generateXAxisCategories(startDate, months) {
    const categories = [];
    for (let i = months - 1; i >= 0; i--) {
      const date = new Date(startDate.getFullYear(), startDate.getMonth() - i);
      const month = date.toLocaleString('default', { month: 'short' });
      let amountTotal = 0;
      let inflowAmount = 0;
      monthlyTotals.forEach((item) => {
        if (item.expense_date && item.expense_date.substring(0, 7) === `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`) {
          amountTotal += item.amount;
        }
      })
      monthlyInflow.forEach((item) => {
        if (item.date && item.date.substring(0, 7)  === `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`) { //: item.created_at && item.created_at.substring(0, 7)
          inflowAmount += Number(item.amount);
        }
      })
      categories.push({ date: `${month} ${date.getFullYear()}`, outflowAmount: amountTotal, inflowAmount: inflowAmount });
    }
    return categories;
  }

  return <>
    <div className='text-end'>
      <Link className={show12Months ? `text-decoration-none text-primary mx-2 fw-bold` : `text-decoration-none text-secondary mx-2 fw-bold`} onClick={() => { setShow12Months(true) }}>Last 12 Months</Link>
      <Link className={show12Months ? `text-decoration-none text-secondary mx-2 fw-bold` : `text-decoration-none text-primary mx-2 fw-bold`} onClick={() => { setShow12Months(false) }}>Last 24 Months</Link>
    </div>
    <HighchartsReact highcharts={Highcharts} options={chart} />
  </>
}

function InflowOutflow() {
  const user = localStorage.getItem("user");
  const businessID = user && JSON.parse(user)[0].business.id;
  const [monthlyOutflow, setMonthlyOutflow] = useState([]);
  const [monthlyInflow, setMonthlyInflow] = useState([]);
  const businessCurrency = user && JSON.parse(user)[0].business_currency === null ? "$" : JSON.parse(user)[0].business_currency.toString().split(" - ")[2];
  const [show12Months, setShow12Months] = useState(true);

  useEffect(() => {
    axios.get(`https://wavedemo.cydeztechnologies.com/listExpenseByBusinessId/${businessID}`).then((response) => {
      if (response.data !== "No data found") {
        setMonthlyOutflow(response.data);
      }
    }).catch((e) => {
      toast.error(e.message, { toastId: "err-api" });
    });

    axios.get(`https://wavedemo.cydeztechnologies.com/list-record-payment-by-business/${businessID}`).then((response) => {
      if (response.data !== "No data found") {
        setMonthlyInflow(response.data);
      }
    }).catch((e) => {
      toast.error(e.message, { toastId: "err-api" });
    });
  }, []);

  const currentDate = new Date();
  const xAxisCategoriesMonths = show12Months ? generateXAxisCategories(currentDate, 12) : generateXAxisCategories(currentDate, 24);
  const outflowAmount = xAxisCategoriesMonths.map((data) => data.outflowAmount);
  const inflowAmount = xAxisCategoriesMonths.map((data) => data.inflowAmount);
  const monthList = xAxisCategoriesMonths.map((data) => data.date);

  const options = {
    // chart: {
    //   type: 'column',
    // },
    title: {
      text: ''
    },
    // subtitle: {
    //   text: `Inflow: ${inflowData.reduce((a, b) => a + b, 0)} | Outflow: ${outflowData.reduce((a, b) => a + b, 0)} | Netchange: ${inflowData.reduce((a, b, i) => a + (b - outflowData[i]), 0)}`
    // },

    xAxis: {
      minTickInterval: 1,
      categories: monthList,
      labels: {
        rotation: 0,
        align: 'center',
        formatter: function () {
          var monthYear = this.value.toString().split(' ');
          return '<span>' + monthYear[0] + '</span><br/>' + monthYear[1].substring(2, 4);
        }
      },
      crosshair: true
    },

    yAxis: {
      title: {
        text: ''
      },
      labels: {
        formatter: function () {
          return businessCurrency + this.value;
        }
      },
    },
    tooltip: {
      headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
      pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
        `<td style="padding:0"><b>{point.y:.1f} ${businessCurrency}</b></td></tr>`,
      footerFormat: '</table>',
      shared: true,
      useHTML: true,
      borderRadius: 10,
      borderWidth: 3
    },
    plotOptions: {
      series: {
        borderRadius: '0%'

      },
      column: {
        pointWidth: show12Months ? 15 : 7,
        pointPadding: 0.2,
        borderWidth: 0
      },
    },
    series: [{
      type: 'column',
      name: 'Inflow',
      color: "#23c770",
      data: inflowAmount
    },

    {
      type: 'column',
      color: "rgb(138, 162, 178)",
      name: 'outflow',
      data: outflowAmount
    },

    {
      type: 'line',
      name: 'Netchange',
      align: 'center',
      data: inflowAmount.map((value, index) => value - outflowAmount[index]),
      colorIndex: "#000000",
      plotOptions: {
        series: {
          color: "#000000",
          centerInCategory: false,
        }
      },
      marker: {
        lineWidth: 3,
        lineColor: "black",
        fillColor: 'white',
      }

    },
    ]
  }

  function generateXAxisCategories(startDate, months) {
    const categories = [];
    for (let i = months - 1; i >= 0; i--) {
      const date = new Date(startDate.getFullYear(), startDate.getMonth() - i);
      const month = date.toLocaleString('default', { month: 'short' });
      let outflowAmount = 0;
      let inflowAmount = 0;
      monthlyOutflow.forEach((item) => {
        if (item.expense_date && item.expense_date.substring(0, 7) === `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`) {
          outflowAmount += item.amount;
        }
      })
      monthlyInflow.forEach((item) => {
        if (item.date && item.date.substring(0, 7)  === `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`) { //item.created_at && item.created_at.substring(0, 7) 
          inflowAmount += Number(item.amount);
        }
      })
      categories.push({ date: `${month} ${date.getFullYear()}`, outflowAmount: outflowAmount, inflowAmount: inflowAmount });
    }
    console.log("data: ",categories)
    return categories;
  }

  return <div>
    <div className='text-end'>
      <Link className={show12Months ? `text-decoration-none text-primary mx-2 fw-bold` : `text-decoration-none text-secondary mx-2 fw-bold`} onClick={() => { setShow12Months(true) }}>Last 12 Months</Link>
      <Link className={show12Months ? `text-decoration-none text-secondary mx-2 fw-bold` : `text-decoration-none text-primary mx-2 fw-bold`} onClick={() => { setShow12Months(false) }}>Last 24 Months</Link>
    </div>
    <HighchartsReact highcharts={Highcharts} options={options} />
  </div>
}

function Dashboard() {
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const businessID = user && JSON.parse(user)[0].business.id;
  const role = user && JSON.parse(user)[0].role;
  const [loading, setLoading] = useState(false);
  const businessCurrency = user && JSON.parse(user)[0].business_currency === null ? null : JSON.parse(user)[0].business_currency.toString().split(" - ")[2];
  // const [customerData, setCustomerData] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);

  const currentDate = dayjs();

  // function fetchCustomerList (){
  //   axios.get(`https://wavedemo.cydeztechnologies.com/list-customers/${businessID}`).then((res) => {
  //           if(res.data !== "No data found"){
  //             setCustomerData(res.data);
  //             // customerListMaxOverdue();
  //           }
  //         //   else {
  //         //     toast.error(res.data.trim(), {toastId:"cus-1"});
  //         // }
  //       }).catch((e) => {
  //           toast.error(e.message, {toastId:"err-api"})
  //       })
  // } 

  function fetchInvoiceList (){
    axios.get(`https://wavedemo.cydeztechnologies.com/invoice/${businessID}`)
      .then((res) => {
        if (res.data !== "No data found") {
          setInvoiceData(res.data);
        } 
        // else {
        //   toast.error(res.data.trim(), {toastId:"inv-1"});
        // }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-api" });
      })
  } 

  const dueAmounts = invoiceData.filter(item => item.status !== "Draft" && Number(item.due_amount) > 0 && dayjs(item.expiry_date) < currentDate);
  const sortedDueAmounts = dueAmounts.sort((a, b) => Number(b.due_amount) - Number(a.due_amount));


  useEffect(() => {
    if (user === null) {
      localStorage.removeItem("user");
      navigate('/error-page');
    }
    setLoading(true);
    // fetchCustomerList();
    fetchInvoiceList();
  }, []);

  const onClickView = (item) => {
    console.log("onclickcustomer: ",item)
    navigate('/invoices/view-invoice',{
      state:{ invoice: item }
    })
  }

  return (
    <section>
      <Sidebar />
      {loading ?
        <div className="container main-coloum1">
          <div className="row container">
            <div className="col-12 my-3 mb-5 fw-bold fs-3 text-capitalize px-3">
              Dashboard
            </div>
          </div>
          <div className='row px-3'>
            <div className='col-4'>
              <div>
                <h5 className="fs-4 mb-2 fw-bold">Overdue invoice & bills</h5>
                {sortedDueAmounts && sortedDueAmounts.length > 0? 
                <div className="DashboardCard" style={{ width: "90%", height: "auto" }}>
                  <h6 className="mb-2 text-muted fs-5">Overdue</h6>
                  <ul className='fw-bold'>
                  {
                    sortedDueAmounts.length > 0 ?
                    sortedDueAmounts.slice(0, 5).map((item, index) => {
                      return (
                          <li key={index}>
                            <a onClick={()=>onClickView(item)} className="DashboardOverdue">{item.customer_id.customer_company_name}</a>
                            <span className='text-dark'>, 
                            {businessCurrency === item.currency.symbol ? 
                            ` ${businessCurrency}${Number(item.due_amount).toFixed(2)}` :
                            ` ${businessCurrency}${(Number(item.due_amount)*Number(item.currency.exchange_rate)).toFixed(2)}`
                            }
                            </span>
                          </li>
                      )
                    })
                    :
                    <div>
                    <p className='mx-3'>All Overdue are paid</p>
                    </div>
                  }
                  </ul>
                  </div>
                  :
                  <div className="DashboardCard" style={{ width: "90%", height: "auto" }}>
                    <h6 className="mb-2 text-muted fs-5">Overdue</h6>
                    <p className='mx-3'>No record found</p>
                  </div>
                }
              </div>
              <div>
                <h5 className="fs-4 mt-5 mb-2 fw-bold">Things You Can Do</h5>
                <div className='DashboardCard' style={{ width: "90%", height: "auto" }}>
                {role === 3 ?
                    <>
                      <Link to='/add-customers' className='DashboardSublink'>Add a customer</Link> <br />
                      <Link to='/estimate/add-estimate' className='DashboardSublink'>Add an estimate</Link> <br />
                      <Link to='/invoices/add-invoice' className='DashboardSublink'>Add an invoice</Link>                      
                    </>
                     :
                    <>
                      <Link to='/add-customers' className='DashboardSublink'>Add a customer</Link> <br />
                      <Link to='/add-products' className='DashboardSublink'>Add a product</Link> <br />
                      <Link to='/estimate/add-estimate' className='DashboardSublink'>Add an estimate</Link> <br />
                      <Link to='/invoices/add-invoice' className='DashboardSublink'>Add an invoice</Link> <br />
                      <Link to='/settings/users' className='DashboardSublink'>Add a user</Link>
                    </>
                  }
                </div>
              </div>
            </div>
            <div className='col-8'>
              <div className='chart'>
                <div className='chart-head' style={{ paddingLeft: "10px", }} >
                  <h4><strong>Cash Flow</strong></h4>
                  <p>Cash coming in and going out of your business.</p>
                </div>
                <div className='inflow-chart' style={{ width: "100%", }}>
                  <InflowOutflow />
                  <br />
                  <div className='chart-head' style={{ paddingLeft: "10px", }} >
                    <h4><strong>Profit And Loss</strong></h4>
                    <p>Income and expenses only (includes unpaid invoices and bills).</p>
                  </div>
                  <div className='barchart' style={{ width: "100%" }}>
                    <ProfirAndLoss /> <br/><br/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        :
        <LoadingSpinner />}
    </section>
  )
}

export default Dashboard