import React, { useState, useEffect, useRef } from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import { Link, useNavigate } from 'react-router-dom';
import './Invoices.css'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import DataTable from 'react-data-table-component';
// import Dropdown from 'react-bootstrap/Dropdown';
// import Invoice_Preview from './Invoice_Preview';
import axios from 'axios';
import { toast } from 'react-toastify';
import RecordPayment from "./RecordPayment";
import Succuess from '../../AlertBox/Success';
import SendReceipt from './Send_Invoice';
import { Menu, Dropdown, Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import Delete from '../../AlertBox/Delete';
import Customization_Warning_Popup from '../Estimate/Customization_Warning_Popup';
import LoadingSpinner from '../../Utils/Loader';
import Business_Warning_Popup from '../Estimate/Business_Warning _Popup';

function Invoices() {
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const businessID = user && JSON.parse(user)[0].business.id;
  const businessCurrency = user && JSON.parse(user)[0].business_currency === null ? "$" : JSON.parse(user)[0].business_currency;
  const [invoiceData, setInvoiceData] = useState([]);
  const [status, setStatus] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [filtertog, setFiltertog] = useState(false);
  const [filterStatus, setFilterStatus] = useState('');
  // const [filterStatusName, setFilterStatusName] = useState('');
  const [filterCustomer, setFilterCustomer] = useState('');
  const [filterFromDate, setFilterFromDate] = useState();
  const [filterToDate, setFilterToDate] = useState();
  const [filterInvoiceNumber, setFilterInvoiceNumber] = useState('');
  const [unpaid, setUnpaid] = useState(false);
  const [draft, setDraft] = useState(false);
  const [allInvoices, setAllInvoices] = useState(true);
  const [recordPayment, setRecordPayment] = useState(false);
  const [isOpenFromDate, setIsOpenFromDate] = useState(false);
  const [isOpenToDate, setIsOpenToDate] = useState(false);
  const [recordPaymentData, setRecordPaymentData] = useState();
  const [invoiceListByStatus, setinvoiceListByStatus] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  const [averageDate, setAverageDate] = useState('-');
  const [invoiceStatusBar, setInvoiceStatusBar] = useState({
    totalDueAmount : "",
    totalDueAmountNext30Days : "",
    averageTimeToGetPaid : "-",
  })
  const [customerSearchData, setCustomerSearchData] = useState({
    total_due_amount : "",
    total_overdue_amount : "",
    total_paid_amount : "",
    last_payment: ""
  })

  const [sendInvoice, setSendInvoice] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [sendInvoiceData, setSendInvoiceData] = useState({});
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [businessdata, setBusinessdata] = useState({});
  const [customizationWarning,setCustomizationWarning] = useState(false);
  const [businessWarning,setBusinessWarning] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pending, setPending] = useState(false);

  function fetchData() {
    axios.get(`https://wavedemo.cydeztechnologies.com/invoice/${businessID}`)
      .then((res) => {
        if (res.data === "No data found") {
          setInvoiceData([]);
          setLoading(true);
        } else {
          setInvoiceData(res.data);
          setinvoiceListByStatus(res.data);
          setLoading(true);
        }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-listest" });
      })
  }

  function fetchFilterData() {
    axios.get('https://wavedemo.cydeztechnologies.com/list-status')
      .then((res) => {
        setStatus(res.data);
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-liststa" });
      });

    axios.get(`https://wavedemo.cydeztechnologies.com/list-customers/${businessID}`)
      .then((res) => {
        if (res.data === "No data found") {
          setCustomerData([]);
          // setLoading(true);
        } else {
          setCustomerData(res.data);
          // setLoading(true);
        }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-listcus" });
      });
  }

  async function fetchDataByStatus() {
    setPending(true);
    try {
      const response = await axios.get(`https://wavedemo.cydeztechnologies.com/invoice/${businessID}`);
      if (response.data !== "No data found") {
        setInvoiceData(response.data);
        let filteredData = response.data;

        if (unpaid) {
          filteredData = filteredData.filter(
            (item) =>
              item.status === 'Overdue' ||
              item.status === 'Approved' ||
              item.status === 'Partially' ||
              item.status === 'Unsend' ||
              item.status === 'Send'
          );
        } else if (draft) {
          filteredData = filteredData.filter((item) => item.status === 'Draft');
        } else if (allInvoices) {
          setinvoiceListByStatus(filteredData);
          setPending(false);
          return; 
        }
        setinvoiceListByStatus(filteredData);
        setPending(false);
      } else {
        setinvoiceListByStatus([]);
        setPending(false);
      }
    } catch (error) {
      toast.error(error.message, { toastId: 'err-listest' });
    }
  }

  // ============================== invoice total due amount and day status box ================================

  function calculateInvoiceSummary(invoiceData) {
    const currentDate = dayjs();
    const nextDate = currentDate.add(30, 'day');
  
    const totalDueAmount = invoiceData.reduce(function(sum, invoice) {
      if (dayjs(invoice.expiry_date) < currentDate && invoice.status !== "Draft") {
        if (invoice.total_amount_business !== invoice.total_amount) {
          return sum + invoice.due_amount * invoice.currency.exchange_rate;
        } else {
          return sum + invoice.due_amount;
        }
      }
      return sum;
    }, 0);
    
    const oneMonthTotalDueAmount = invoiceData.filter(invoice => {
      const dueDate = dayjs(invoice.expiry_date);
      return dueDate.isBetween(currentDate, nextDate, null, '[]');
    });
  
    const totalDueAmountNext30Days = oneMonthTotalDueAmount.reduce(function(sum, invoice) {
      if(invoice.status !== "Draft"){
        if (invoice.total_amount_business !== invoice.total_amount) {
          return sum + invoice.due_amount * invoice.currency.exchange_rate;
        } else {
          return sum + invoice.due_amount;
        }
      }
      return sum;
    }, 0);
  
    // const totalTimeDifference = invoiceData.reduce((total, invoice) => {
    //   const invoiceDate = dayjs(invoice.date);
    //   const paymentMadeDate = invoice.last_paid_date !== "Not paid yet" ? dayjs(invoice.last_paid_date) : null;
    //   const timeDifference = paymentMadeDate && paymentMadeDate.diff(invoiceDate, 'day');
    //   return total + timeDifference;
    // }, 0);
  
    // const averageTimeToGetPaid = Math.ceil((totalTimeDifference === 0 ? totalTimeDifference + 1 : totalTimeDifference) / invoiceData.length);
  
    setInvoiceStatusBar({
      totalDueAmount : totalDueAmount.toFixed(2),
      totalDueAmountNext30Days : totalDueAmountNext30Days.toFixed(2),
      // averageTimeToGetPaid : averageTimeToGetPaid,
    })
  }
  

  // ===============================================================================================================

  const arrFilterCustomerData = invoiceData.map(item => {
    return customerData.map(data => {
      const { id, customer_company_name } = data;
      return id === item.customer_id.id ? customer_company_name : null;
    });
  });

  const filterCustomerData = [...new Set(arrFilterCustomerData.flat().filter(name => name !== null))];

  
  // ========================================== handleSearchCustomerData =============================================

  const handleSearchCustomerData = () => {  

    const searchCustomerData = filterCustomer && invoiceData.filter(item =>
      item.customer_id.customer_company_name && 
      item.customer_id.customer_company_name.toLowerCase().includes(filterCustomer.toLowerCase()) &&
      item.customer_id.customer_company_name === filterCustomer
    );

    const customerTotalPaidData = filterCustomer && searchCustomerData.filter(item => item.due_amount === 0);
    const customerTotalPaidData12 = filterCustomer && searchCustomerData.filter(item => item.due_amount === 0 || item.due_amount !== item.total_amount);

    const customerTotalOverdue = filterCustomer && searchCustomerData.reduce(function(sum, invoice) {
      if(invoice.status !== "Draft"){
        if (invoice.total_amount_business !== invoice.total_amount) {
          return sum + invoice.due_amount * invoice.currency.exchange_rate;
        } else {
          return sum + invoice.due_amount;
        }
      }
      return sum;
    }, 0);

    const currentDate = dayjs();
    const customerOverdue = filterCustomer && searchCustomerData.reduce(function(sum, invoice) {
      if (dayjs(invoice.expiry_date) < currentDate && invoice.status === "Overdue") {
        if (invoice.total_amount_business !== invoice.total_amount) {
          return sum + invoice.due_amount * invoice.currency.exchange_rate;
        } else {
          return sum + invoice.due_amount;
        }
      }
      return sum;
    }, 0);

    const twelveMonthsAgo = dayjs().subtract(12, 'months');
    const customerTotalPaid = customerTotalPaidData12 && customerTotalPaidData12.reduce(function(sum, invoice) {
        const invoiceDate = invoice.last_paid_date !== 'Not paid yet' && dayjs(invoice.last_paid_date); // we need here payment_date property for each invoice
        // if (invoice.last_paid_date !== 'Not paid yet' && invoiceDate.isAfter(twelveMonthsAgo)) {
        //     return sum + Number(invoice.total_amount_business);
        // } else {
        //     return sum;
        // }

        if (invoice.last_paid_date !== 'Not paid yet' && invoiceDate.isAfter(twelveMonthsAgo)) {
          if(invoice.due_amount !== 0){
            if (invoice.total_amount_business !== invoice.total_amount) {
              return sum + (Number(invoice.total_amount) - Number(invoice.due_amount)) * invoice.currency.exchange_rate;
            } else {
              return sum + (Number(invoice.total_amount_business) - Number(invoice.due_amount))
            }
          }
          if(invoice.due_amount === 0){
            return sum + Number(invoice.total_amount_business);
          }
        }
        return sum;
    }, 0);

    const lastPaymentMade = customerTotalPaidData && dayjs(customerTotalPaidData.last_paid_date).format('MMMM-DD')

    setCustomerSearchData({ 
      total_due_amount : customerTotalOverdue, 
      total_overdue_amount : customerOverdue, 
      total_paid_amount : customerTotalPaid, 
      last_payment: lastPaymentMade})
  
  }


  useEffect(() => {
    fetchData();
    fetchFilterData();
    business_data();
  }, []);

  useEffect(() => {
    handleSearchCustomerData();
    calculateInvoiceSummary(invoiceData);
  }, [invoiceData]);

  useEffect(() => {
    handleSearchCustomerData();
  }, [filterCustomer]);

  useEffect(() => {
    fetchDataByStatus();
    handleSearchCustomerData();
    calculateInvoiceSummary(invoiceData);
  }, [isUpdated, recordPayment, unpaid, draft, allInvoices])


  const handleUnpaid = () => {
    setUnpaid(true);
    setDraft(false);
    setAllInvoices(false);
  }
  const handleDraft = () => {
    setUnpaid(false);
    setDraft(true);
    setAllInvoices(false);
  }
  const handleAllInvoices = () => {
    setUnpaid(false);
    setDraft(false);
    setAllInvoices(true);
  }

  const onClickRecord = (item) => {
    setRecordPayment(true);
    setRecordPaymentData(item)
  }

  const onfFilterToggleChange = () => {
    setFiltertog((prevState) => !prevState);
    setFilterCustomer('');
    setFilterStatus('');
    setFilterFromDate(null);
    setFilterToDate(null);
    setFilterInvoiceNumber('')
  }

  const handleFilterClear = () => {
    setFilterStatus('');
    // setFilterStatusName('');
    setFilterCustomer('');
    setFilterFromDate(null);
    setFilterToDate(null);
    setFilterInvoiceNumber('');
  }

  const onClickApproved = (item) => {
    axios.put(`https://wavedemo.cydeztechnologies.com/update-status/${item.id}`, { status: 9 })
      .then(() => {
        setIsUpdated((prevIsUpdated) => !prevIsUpdated);
      })
      .catch((error) => {
        toast.error(error.message, { toastId: "err-api" });
      });
  };

  // const onClickSend = (item) => {
  //   axios.put(`https://wavedemo.cydeztechnologies.com/update-status/${item.id}`, { status: 2 })
  //     .then(() => {
  //       setIsUpdated((prevIsUpdated) => !prevIsUpdated);
  //     })
  //     .catch((error) => {
  //       toast.error(error.message, { toastId: "err-api" });
  //     });
  // };

const business_data = () => {
  axios.get(`https://wavedemo.cydeztechnologies.com/list-business`)
  .then((res)=>{
    const responseData = res.data; 
    const filteredData = responseData && responseData.filter(item => item.id === businessID);
    setBusinessdata(filteredData[0]);
  }).catch(error =>{
    toast.error(error.message,{toastId: 'addest5',})  ;
});
}


const createInvoice = ()=>{
  axios.get(`https://wavedemo.cydeztechnologies.com/get-invoice-customization-details/${businessID}`)
  .then((res)=>{

    if(parseInt(res.data.transaction_number) == null || res.data.invoice_default_title == null ){
      setCustomizationWarning(true)
     }
    else if(businessdata && businessdata.business_address === null){
      setBusinessWarning(true);
    }
     else{
      navigate('/invoices/add-invoice');
     } 

    }).catch(error =>{
            toast.error(error.message,{toastId: 'addest5',})  ;
            
        });

}

  const sendconfirm = (sendconfirm) => {
    if(sendconfirm && sendconfirm === true && (sendInvoiceData.status === "Unsend" || sendInvoiceData.status === "Draft")){
      axios.put(`https://wavedemo.cydeztechnologies.com/update-status/${sendInvoiceData.id}`, { status: 2 })
      .then(() => {
        setIsUpdated((prevIsUpdated) => !prevIsUpdated);
        setSuccessMessage(true);
      })
      .catch((error) => {
        toast.error(error.message, { toastId: "err-api" });
      });
    }
    else{
      setIsUpdated((prevIsUpdated) => !prevIsUpdated);
      setSuccessMessage(true);
    }
  }


  function handleDelete(item) {
    setSelectedInvoice(item);
    setOpenDelete(true);
  }

  const confirm = (confirm) => {
    if(confirm && confirm === true){
      axios.delete(`https://wavedemo.cydeztechnologies.com/delete_invoice/${selectedInvoice.id}`).then((res) => {
        if (res.data) {
          toast.success(res.data, { toastId: "listdel" });
          setIsUpdated((prevIsUpdated) => !prevIsUpdated);
        }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-listdel" });
      });
    }
  }

  const onClickSend = (item) => {
    setSendInvoice(true);
    setSendInvoiceData(item);
    console.log("invoicedata: ",item)
  };

  const onClickView = (item) => {
    navigate('/invoices/view-invoice',{
      state:{ invoice: item }
    })
  }

  const customStyles = {
    headRow: {
      style: {
        color: '#000',
        fontWeight: "bolder",
        fontSize: "16px"
      },
    },
    rows: {
      style: {
        color: "#000",
        backgroundColor: "#fff",
        fontSize: "16px",
        minHeight: '55px',
        fontSize: '16px'
      },
      conditionalRowStyles: [
        {
          when: row => true, // Apply to all rows
          style: {
            '&:hover': {
              // className: 'custom-hover-row'
              color: 'red',
              backgroundColor: 'blue'
            },
          },
        },
      ],
    },

  }

  const columns = [
    {
      name: 'Status',
      selector: row => row.status,
      width:"100px",  
      cell: (row) => {
        let status = row.status
        switch (status) {
          case "Saved":
            return <span className='status-card status-saved'>Saved</span>;
          case "Send":
            return <span className='status-card status-sent'>Send</span>;
          case "Expired":
            return <span className='status-card status-expired'>Expired</span>;
          case "Overdue":
            return <span className='status-card status-expired'>Overdue</span>;
          case "Draft":
            return <span className='status-card status-draft'>Draft</span>;
          case "Paid":
            return <span className='status-card status-paid'>Paid</span>;
          case "Approved":
            return <span className='status-card status-approved'>Approved</span>;
          case "Partially":
            return <span className='status-card status-approved'>Partially</span>;
          case "Unsend":
            return <span className='status-card status-saved'>Unsend</span>;
        }
      }
    },
    {
      name: 'Date',
      selector: row => row.date,
    },
    {
      name: 'Number',
      selector: row => row.number,
    },
    {
      name: 'Customer',
      selector: row => row.customer,
      grow: 2,
    },
    {
      name: 'Amount due',
      selector: row => row.amount,
      right: true,
      grow: 2,
      cell: (row) => (
        <div className='text-end'>
          {String(row.due_amount).split(" ")[1] === businessCurrency.toString().split(" - ")[0] ?
            <span>{String(row.due_amount).toString().split(" ")[0]}</span>
            :
            <><span>{row.due_amount}</span>
              <br />
              <span className='invoice-businessCurrency'>{`${businessCurrency.toString().split(" - ")[2]}${(Number(row.cusCurrency) * Number(row.busCurrency)).toFixed(2)} ${businessCurrency.toString().split(" - ")[0]}`}</span></>
          }
        </div>
      ),
    },
    {
      name: 'Action',
      selector: row => row.action,
      right: true,
      grow: 2,
      width:"220px",
      // wrap: false,
      cell: (row) => {
        let status = row.status;
        let element = '';

        switch (status) {
          case "Draft":
            element = <a className="invoice-action-link" onClick={() => onClickApproved(row.action)}>Approve</a>;
            break;
          case "Unsend":
            element = <a className="invoice-action-link" onClick={() => onClickSend(row.action)}>Send</a>;
            break;
          case "Send":
            element = <a className="invoice-action-link" onClick={() => onClickRecord(row.action)}>Record Payment</a>;
            break;
          case "Partially":
            element = <a className="invoice-action-link text-nowrap" onClick={() => onClickRecord(row.action)}>Record Payment</a>;
            break;
          case "Paid":
            element = <a className="invoice-action-link" onClick={() => onClickView(row.action)}>View</a>;
            break;
          case "Overdue":
            element = <a className="invoice-action-link text-nowrap" onClick={() => onClickRecord(row.action)}>Record Payment</a>;
            break;
        }

        const menu = (
          <Menu className='m-2'>
            <Menu.Item key="view" onClick={() => onClickView(row.action)}>
              View
            </Menu.Item>
            <Menu.Item key="edit" onClick={() => navigate('/invoices/edit-invoice', { state: { invoice: row.action } })}>
              Edit
            </Menu.Item>
            {status === 'Unsend' &&
            <Menu.Item key="send" onClick={() => onClickSend(row.action)}>Send</Menu.Item>
            }
            {(status === 'Send' || status === 'Partially' || status === 'Paid' || status === 'Overdue') &&
            <Menu.Item key="Resend" onClick={() => onClickSend(row.action)}>Resend</Menu.Item>
            }
            <Menu.Item key="download" 
            onClick={() => {
              const downloadUrl = `https://wavedemo.cydeztechnologies.com/invoicedownload/${businessID}/${row.action.id}`;
              window.location.href = downloadUrl;
            }}>
              Download
            </Menu.Item>
            {(status === 'Draft' || status === 'Unsend' || status === 'Send') &&
            <Menu.Item key="delete" onClick={() => handleDelete(row.action)}>
              Delete
            </Menu.Item>
            }
          </Menu>
        );

        return (
          <div className='d-flex'>
            <div className='mt-1 text-wrap text-end'>{element}</div> 
            <Dropdown trigger={['click']}  overlay={menu}>
              <Button className="action-icon-btn" style={{display: "flex", alignItems: "center", justifyContent: "center"}} type="primary"  icon={<CaretDownOutlined style={{fontSize:"13px"}}/>} />
            </Dropdown>
          </div>
        );
      },
    }
  ];

  const totalAmountColumn = {
    name: 'Total amount',
    selector: row => row.amount,
    right: true,
    grow: 2,
    cell: (row) => (
      <div className='text-end'>
        {row.amount.toString().split(" ")[1] === businessCurrency.toString().split(" - ")[0] ? (
          <span>{row.amount.toString().split(" ")[0]}</span>
        ) : (
          <>
            <span>{row.amount}</span>
            <br />
            <span className='invoice-businessCurrency'>{row.total_amount}</span>
          </>
        )}
      </div>
    )
  };
  
  if (allInvoices) {
    const insertIndex = 4;
    columns.splice(insertIndex, 0, totalAmountColumn);
  }

  let count = 0;
  let filteredData = invoiceListByStatus;

  if (filterStatus) {
    filteredData = filteredData.filter(
      item => item.status && item.status === filterStatus
    );
    count += 1;
  }

  if (filterCustomer) {
    filteredData = filteredData.filter(
      item =>
        item.customer_id.customer_company_name &&
        item.customer_id.customer_company_name
          .toLowerCase()
          .includes(filterCustomer.toLowerCase()) &&
          item.customer_id.customer_company_name.length === filterCustomer.length 
    );
    count += 1;
  }

  if (filterFromDate) {
    filteredData = filteredData.filter(
      item =>
        item.date &&
        (dayjs(item.date).isSame(dayjs(filterFromDate), 'day') ||
          dayjs(item.date).isAfter(dayjs(filterFromDate), 'day'))
    );
    count += 1;
  }
  
  if (filterToDate) {
    filteredData = filteredData.filter(
      item =>
        item.date &&
        (dayjs(item.date).isSame(dayjs(filterToDate), 'day') ||
          dayjs(item.date).isBefore(dayjs(filterToDate), 'day'))
    );
    count += 1;
  }

  if (filterInvoiceNumber) {
    filteredData = filteredData.filter(
      item =>
        // item.transaction_number && item.transaction_number == filterInvoiceNumber
        String(`${item.prefix}${item.transaction_number}`).toLowerCase().includes(String(filterInvoiceNumber.toLowerCase()))
    );
    count += 1;
  }

  const rows = (filterStatus || filterCustomer || filterFromDate || filterToDate || filterInvoiceNumber)
    ? filteredData.map(item => ({
      number: `${item.prefix}${item.transaction_number}`,
      status: item.status,
      date: dayjs(item.date).format('DD/MM/YYYY'),
      customer: item.customer_id.customer_company_name,
      amount: `${item.currency.symbol}${Number(item.total_amount).toFixed(2)} ${item.currency.code}`,
      total_amount: `${businessCurrency.toString().split(" - ")[2]}${Number(item.total_amount_business).toFixed(2)} ${businessCurrency.toString().split(" - ")[0]}`,
      due_amount: `${item.currency.symbol}${Number(item.due_amount).toFixed(2)} ${item.currency.code}`,
      cusCurrency: item.due_amount,
      busCurrency: item.currency.exchange_rate,
      action: item
    }))
    : invoiceListByStatus.map(item => ({
      number: `${item.prefix}${item.transaction_number}`,
      status: item.status, //item.status == "Saved" ? "Send" : item.status,
      date: dayjs(item.date).format('DD/MM/YYYY'),
      customer: item.customer_id.customer_company_name,
      amount: `${item.currency.symbol}${Number(item.total_amount).toFixed(2)} ${item.currency.code}`,
      total_amount: `${businessCurrency.toString().split(" - ")[2]}${Number(item.total_amount_business).toFixed(2)} ${businessCurrency.toString().split(" - ")[0]}`,
      due_amount: `${item.currency.symbol}${Number(item.due_amount).toFixed(2)} ${item.currency.code}`,
      cusCurrency: item.due_amount,
      busCurrency: item.currency.exchange_rate,
      action: item
    }));

    const handleRowClick = (row) => {
      navigate('/invoices/view-invoice', {
        state: { invoice: row.action },
      });
    };

    function TableLoadingSpinner() {
      return (
        <div className="custom-loader">
        <div className="spinner"></div>
      </div>
      );
    }

  return (
    <section >
      <Sidebar />
      {loading ? 
      <div className="container main-coloum1 py-4">
        <div className="invoice-list-container">
          <div className="invoice-header d-flex justify-content-between align-items-center">
            <div>
              <h2 className='section-title'>Invoices</h2>
            </div>
            <div className="invoice-list-add-action">
              <button className='btn btn-primary wv-actn-fill-btn'
                onClick={createInvoice}>Create an invoice</button>
            </div>
          </div>

          <div className='row statusbar my-3 text-center'>
            <div className='col-6'>
              <span className='statusbar-label'>Overdue</span>
              <div >
                <span className='statusbar-name'>{`${businessCurrency.toString().split(" - ")[2]}${invoiceStatusBar.totalDueAmount}`}</span>
                <p className='statusbar-subname'>{`${businessCurrency.toString().split(" - ")[0]}`}</p>
              </div>
            </div>
            <div className='col-6'>
              <span className='statusbar-label'>Due within next 30 days</span>
              <div >
                <span className='statusbar-name'>{`${businessCurrency.toString().split(" - ")[2]}${invoiceStatusBar.totalDueAmountNext30Days}`}</span>
                <p className='statusbar-subname'>{`${businessCurrency.toString().split(" - ")[0]}`}</p>
              </div>
            </div>
            {/* <div className='col-4'>
              <span className='statusbar-label'>Average time to get paid</span>
              <div >
                <span className='statusbar-name'>{invoiceStatusBar.averageTimeToGetPaid}</span>
                <p className='statusbar-subname'>days</p>
              </div>
            </div> */}
            {/* <div className='col-3'>
              <span className='statusbar-label'>Upcoming payout</span>
              <div >
                <span className='statusbar-name'>None</span>
                <p className='statusbar-subname'>......</p>
              </div>
            </div> */}
            {/* <div className='my-3'>
              <div>Last updated just a moment ago.</div>
            </div> */}
          </div>
<div>
<button className='btn btn-outline-primary px-4 py-1 wv-outline-btn' style={{display : filtertog ? 'none' : 'block', marginTop: '25px'}}
                onClick={() => { onfFilterToggleChange() }}
              >Filter <i className={filtertog ? 'bi bi-chevron-up ps-2' : 'bi bi-chevron-down ps-2'}></i> </button>
              <div  style={{ display: filtertog ? 'block' : 'none' }}>
            <div class="filter-count-dev">
              <button className='btn btn-outline-primary px-4 py-1 wv-outline-btn' style={{display : filtertog ? 'block' : 'none'}}
                onClick={() => { onfFilterToggleChange() }}
              >Filter <i className={filtertog ? 'bi bi-chevron-up ps-2' : 'bi bi-chevron-down ps-2'}></i> </button>
              <span class="filter-count-num">{count}</span>
              <p className='filter-count-text'>Active filters</p>
              {count > 0 &&
                <>
                  <p className='filter-count-text'>|</p>
                  <p className='filter-clear' onClick={() => handleFilterClear()}>Clear filters</p>
                </>
              }
            </div>
            <div className='row'>
              <div className='col-4'>
                <Autocomplete
                  disablePortal
                  id="filter-customer"
                  size="small"
                  value={filterCustomer}
                  onChange={(event, newValue) => {
                    setFilterCustomer(newValue);
                  }}
                  options={filterCustomerData}
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: 40,
                  }}
                  renderInput={(params) => <TextField
                    {...params}
                    placeholder="All Customer"
                    size="small" />}
                />
              </div>
              <div className='col-2'>
                <Autocomplete
                  disablePortal
                  id="filter-customer"
                  size="small"
                  value={filterStatus}
                  // onChange={(event, newValue) => {
                  //   if (newValue) {
                  //     const selectedStatus = status.find(item => item.status_name === newValue);
                  //     if (selectedStatus) {
                  //       setFilterStatus(selectedStatus.id);
                  //       setFilterStatusName(newValue)
                  //     }
                  //   } else {
                  //     setFilterStatus('');
                  //   }
                  // }}
                  onChange={(event, newValue) => {
                    setFilterStatus(newValue);
                  }}
                  // options={status.filter(item => item.id >= 4 && item.id <= 6).map(item => item.status_name)}
                  options={
                    unpaid === true ? status.filter(item => item.id != 7 & item.id != 3 & item.id != 1 & item.id != 5 & item.id != 6).map(item => item.status_name):
                    status.filter(item => item.id != 7 & item.id != 3 & item.id != 1).map(item => item.status_name)
                  }
                  sx={{
                    display: 'flex',
                    width: '100%',
                    height: 40,
                  }}
                  renderInput={(params) => <TextField
                    {...params}
                    placeholder="All status"
                    size="small" />}
                  disabled={draft}
                />
              </div>
              <div className='col-4 d-flex'>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div style={{ position: 'relative' }}>
                    <DatePicker
                      format='DD/MM/YYYY'
                      value={filterFromDate}
                      onChange={(date) => setFilterFromDate(date)}
                      open={isOpenFromDate}
                      onOpen={() => setIsOpenFromDate(true)}
                      onClose={() => setIsOpenFromDate(false)}
                      slotProps={{ textField: { size: 'small', placeholder: 'From', type: 'text' } }}
                    />
                    {/* {filterFromDate ?
                      <span style={{ position: 'absolute', right: '10px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray", fontSize: "18px" }} onClick={() => setFilterFromDate(null)}>
                        X
                      </span>
                      :
                      <span style={{ position: 'absolute', right: '10px', top: '47%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray", fontSize: "18px" }} onClick={() => setIsOpenFromDate(true)}>
                        <i className="bi bi-calendar"></i>
                      </span>
                    } */}
                  </div>
                </LocalizationProvider>
                <div className='mx-1'></div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <div style={{ position: 'relative' }}>
                    <DatePicker
                      format='DD/MM/YYYY'
                      value={filterToDate}
                      onChange={(date) => setFilterToDate(date)}
                      open={isOpenToDate}
                      onOpen={() => setIsOpenToDate(true)}
                      onClose={() => setIsOpenToDate(false)}
                      slotProps={{ textField: { size: 'small', placeholder: "To" } }}
                    />
                    {/* {filterToDate ?
                      <span style={{ position: 'absolute', right: '10px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray", fontSize: "18px" }} onClick={() => setFilterToDate(null)}>
                        X
                      </span>
                      :
                      <span style={{ position: 'absolute', right: '10px', top: '47%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray", fontSize: "18px" }} onClick={() => setIsOpenToDate(true)}>
                        <i className="bi bi-calendar"></i>
                      </span>
                    } */}
                  </div>
                </LocalizationProvider>
              </div>
              <div className='col-2'>
                <TextField
                  id="outlined-search"
                  placeholder="Enter invoice #"
                  value={filterInvoiceNumber}
                  onChange={(event) => {
                    setFilterInvoiceNumber(event.target.value);
                  }}
                  size="small"
                  sx={{
                    width: '100%',
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {filterInvoiceNumber ?
                          <span style={{ position: 'absolute', right: '18px', top: '52%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray" }} onClick={() => setFilterInvoiceNumber('')}>
                            X
                          </span>
                          : <Link className='bi bi-search fw-bold text-dark'></Link>
                        }
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
          </div>
</div>
          

          {filterCustomer ?
            <div className='row my-3 customer-card'>
              <div className='col-2 mt-2' style={{color: '#2046cf', fontWeight: 'bold', cursor: 'pointer', fontSize: '16px'}}>
              <i className="bi bi-person-circle"></i> {filterCustomer}
              </div>
              <div className='col-2 search-box-subtitle'>
                <span className='search-box-title'>Total unpaid</span><br />
                <span>{`${businessCurrency.toString().split(" - ")[2]}${customerSearchData.total_due_amount} ${businessCurrency.toString().split(" - ")[0]}`}</span>
              </div>
              <div className='col-2 search-box-subtitle' style={{color:'#af2c2c'}}>
              <span className='search-box-title'>Overdue</span><br />
                <span>{`${businessCurrency.toString().split(" - ")[2]}${customerSearchData.total_overdue_amount} ${businessCurrency.toString().split(" - ")[0]}`}</span>
              </div>
              <div className='col-3 search-box-subtitle'>
              <span className='search-box-title'>Paid last 12 months</span><br />
               <span>{`${businessCurrency.toString().split(" - ")[2]}${customerSearchData.total_paid_amount} ${businessCurrency.toString().split(" - ")[0]}`}</span>
              </div>
              <div className='col-3 search-box-subtitle'>
              <span className='search-box-title'>Last item sent</span><br />
                <span>Invoice on {`${customerSearchData.last_payment}`}</span> 
              </div>
              {/* <div className='col-2'>
                Send reminder
              </div> */}
            </div>
            :
            <><br /></>
          }
          <div className='container'>
            <div>
              <ul className='linkblock'>
                <li className={`${unpaid ? 'link-name-active' : 'link-name-left'}`}>
                  <span className='link-name-style' onClick={handleUnpaid}>Unpaid</span>
                </li>
                <li className={`${draft ? 'link-name-active' : 'link-name'}`}>
                  <span className='link-name-style' onClick={handleDraft}>Draft</span>
                </li>
                <li className={`${allInvoices ? 'link-name-active' : 'link-name-right'}`}>
                  <span className='link-name-style' onClick={handleAllInvoices}>All invoices</span>
                </li>
              </ul>
            </div>
            <div className="estimate-list-table-data mb-5">
              <DataTable
                columns={columns}
                data={rows}
                fixedHeaderScrollHeight="600px"
                customStyles={customStyles}
                persistTableHead
                pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
                progressPending={pending}
			          progressComponent={<TableLoadingSpinner/>}
                highlightOnHover
                pointerOnHover
                onRowClicked={handleRowClick}
              />
            </div>
          </div>
        </div>
      </div>
      :
      <LoadingSpinner/>}

      <RecordPayment
        displayRecordPayment={recordPayment}
        paymentData={recordPaymentData}
        isOpen={recordPayment}
        onClose={() => {
          setRecordPayment(false);
        }}
      />

      <SendReceipt 
        sendconfirm={sendconfirm}
        invoicedata={sendInvoiceData}
        isOpen={sendInvoice}
        onClose={() => {
          setSendInvoice(false);
        }}
        flag={false}
      />
      <Succuess
                title1="Send a receipt"
                title2="This receipt was sent."
                isOpen={successMessage}
                onClose={() => {
                    setSuccessMessage(false);
                }}
                send={false}
            />
      <Delete 
      title1 = "Are you sure"
      title2 = "Do you really want to delete these Invoice record?"
      isOpen={openDelete}
      onClose={() => {
        setOpenDelete(false);
      }}
      confirm = {confirm}
      />
      <Customization_Warning_Popup
        displayPopup={customizationWarning}
        isOpen={customizationWarning}
        onClose={() => {
          setCustomizationWarning(false);
        }}
    />
    <Business_Warning_Popup
        displayPopup={businessWarning}
        isOpen={businessWarning}
        onClose={() => {
          setBusinessWarning(false);
        }}
    />
    </section>

  )
}

export default Invoices