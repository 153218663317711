import Sidebar from "../Sidebar/Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from "react"
import axios from "axios";
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import LoadingSpinner from '../Utils/Loader'
import RecordPayment from "../Sales/Invoices/RecordPayment";
import { Link, useNavigate } from "react-router-dom";
import dayjs from 'dayjs';


import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import InputAdornment from '@mui/material/InputAdornment';
import { saveAs } from 'file-saver';
import ExcelJS from 'exceljs';


export default function PaymentIncome() {
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const businessID = user && JSON.parse(user)[0].business.id;
  const business_name = user && JSON.parse(user)[0].business.business_name;
  const businessCurrency = user && JSON.parse(user)[0].business_currency === null ? "$" : JSON.parse(user)[0].business_currency.toString().split(" - ")[2];
  const [Income, setIncome] = useState([]);
  const [invoiceData, setInvoiceData] = useState({});
  const [addIncome, setAddIncome] = useState(false);
  const [editIncome, setEditIncome] = useState(false);
  const [editIncomeId, setEditIncomeId] = useState({});
  const [loading, setLoading] = useState(false);

  const [customerData, setCustomerData] = useState([]);
  const [paymentMethodData, setPaymentMethodData] = useState([]);

  const [filterAccount, setFilterAccount] = useState('');
  const [filterCustomer, setFilterCustomer] = useState('');
  const [filterFromDate, setFilterFromDate] = useState();
  const [filterToDate, setFilterToDate] = useState();
  const [filterInvoiceNumber, setFilterInvoiceNumber] = useState('');

  const [isOpenFromDate, setIsOpenFromDate] = useState(false);
  const [isOpenToDate, setIsOpenToDate] = useState(false);
  const [filterHide, setFilterHide] = useState(false);


  const fetchIncome = () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-record-payment-by-business/${businessID}`).then((response) => {
      if (response.data !== "No data found") {
        setIncome(response.data);
        setLoading(true);
      }
      else {
        setIncome([]);
        setLoading(true);
      }

    }).catch((e) => {
      toast.error(e.message, { toastId: "error" });
    });
  }

  const fetchInvoiceById = (item) => {
    axios.get(`https://wavedemo.cydeztechnologies.com/invoices/${businessID}/${item.invoice_number}`).then((response) => {
      if (response.data !== "No data found") {
        setInvoiceData(response.data);
        navigate('/invoices/view-invoice',{
          state:{ 
            invoice: response.data,
            paymentData: item,
            showMoreData: true
           }
         })
      }
      else {
        setInvoiceData({});
      }
    }).catch((e) => {
      toast.error(e.message, { toastId: "err-listest" });
    });
  }


  useEffect(() => {
    if (user === null) {
      localStorage.removeItem("user");
      navigate('/error-page');
    }
    fetchIncome();
    fetchFilterData();
  }, [addIncome, editIncome]);

  const onClickCreate = () => {
    setAddIncome(true);
  }

  // const onClickView = (item) => {
  //   navigate('/invoices/view-invoice',{
  //     state:{ invoice: item }
  //   })
  // }

  function onClickEdit(item) {
    // setEditIncome(true);
    setEditIncomeId(item);
    fetchInvoiceById(item);
  }

  function fetchFilterData() {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-payment-method-by-businessid/${businessID}`).then((response) => {
      if (response.data === "No data found") {
        setPaymentMethodData([]);
      }
      else {
        const methodName = response.data.filter((item) => item.parent_id === null);
        setPaymentMethodData(methodName);
      }
    }).catch((e) => {
      toast.error(e.message, { toastId: "error" });
    });

    axios.get(`https://wavedemo.cydeztechnologies.com/list-customers/${businessID}`)
      .then((res) => {
        if (res.data === "No data found") {
          setCustomerData([]);
          // setLoading(true);
        } else {
          setCustomerData(res.data);
          // setLoading(true);
        }
      })
      .catch((e) => {
        toast.error(e.message, { toastId: "err-listcus" });
      });
  }


  const handleFilterClear = () => {
    setFilterAccount('');
    // setFilterStatusName('');
    setFilterCustomer('');
    setFilterFromDate(null);
    setFilterToDate(null);
    setFilterInvoiceNumber('');
  }

  const onFilterToggleChange = () => {
    setFilterHide((prevState) => !prevState);
    setFilterCustomer('');
    setFilterAccount('');
    setFilterFromDate(null);
    setFilterToDate(null);
    setFilterInvoiceNumber('');
  }


  const customStyles = {
    headRow: {
      style: {
        color: '#000',
        fontWeight: "bolder",
        fontSize: "16px",
        borderBottom: "1px solid black"
      },
    },
    rows: {
      style: {
        color: "#000",
        backgroundColor: "#fff",
        fontSize: "16px",
        minHeight: '55px'
      }
    },

  }

  const columns = [
    {
      name: 'Date',
      sub_name: 'expenses_date',
      selector: row => row.expenses_date,
      grow: 2,
      sortable: true,
    },
    {
      name: 'Invoice Number',
      sub_name: 'invoice_number',
      selector: row => row.invoice_number,
      grow: 2,
      wrap: true
    },
    {
      name: 'Customer Name',
      sub_name: 'customer',
      selector: row => row.customer,
      grow: 2,
      wrap: true
    },
    {
      name: 'Account',
      sub_name: 'account',
      selector: row => row.account,
      grow: 2,
      wrap: true
    },
    {
      name: 'Description',
      sub_name: 'description',
      selector: row => row.description,
      grow: 2,
      wrap: true
    },
    {
      name: `Amount (${businessCurrency})`,
      sub_name: 'amount',
      selector: row => row.amount,
      grow: 2,
      right: true,
    },
    {
      name: 'Actions',
      sub_name: 'Actions',
      selector: row => row.action,
      grow: 1,
      center: true
    },
  ];


  const arrFilterCustomerData = Income.map(item => {
    return customerData.map(data => {
      const { customer_company_name } = data;
      return customer_company_name === item.customer_name ? customer_company_name : null;
    });
  });

  const filterCustomerData = [...new Set(arrFilterCustomerData.flat().filter(name => name !== null))];

  const arrFilterPaymentMethodData = Income.map(item => {
    return paymentMethodData.map(data => {
      const { paymentmethod_name } = data;
      return paymentmethod_name === item.payment_method ? paymentmethod_name : null;
    });
  });

  const filterPaymentMethodData = [...new Set(arrFilterPaymentMethodData.flat().filter(name => name !== null))];

  let count = 0;
  let filteredData = Income;

  if (filterAccount) {
    filteredData = filteredData.filter(
      item => item.payment_method && item.payment_method === filterAccount
    );
    count += 1;
  }

  if (filterCustomer) {
    filteredData = filteredData.filter(
      item =>
        item.customer_name &&
        item.customer_name
          .toLowerCase()
          .includes(filterCustomer.toLowerCase()) &&
          item.customer_name.length === filterCustomer.length 
    );
    count += 1;
  }

  // if (filterCustomer) {
  //   filteredData = filteredData.filter(
  //     item =>
  //       item.customer_name &&
  //       item.customer_name
  //         .toLowerCase()
  //         .includes(filterCustomer.toLowerCase())
  //   );
  //   console.log("data: ", filteredData)
  //   count += 1;
  // }

  if (filterFromDate) {
    filteredData = filteredData.filter(
      item =>
        item.date &&
        (dayjs(item.date).isSame(dayjs(filterFromDate), 'day') ||
          dayjs(item.date).isAfter(dayjs(filterFromDate), 'day'))
        // :
        // item.created_at &&
        // (dayjs(item.created_at).isSame(dayjs(filterFromDate), 'day') ||
        //   dayjs(item.created_at).isAfter(dayjs(filterFromDate), 'day'))
    );
    count += 1;
  }

  if (filterToDate) {
    filteredData = filteredData.filter(
      item =>
        item.date && 
        (dayjs(item.date).isSame(dayjs(filterToDate), 'day') ||
          dayjs(item.date).isBefore(dayjs(filterToDate), 'day'))
        // :
        // item.created_at &&
        // (dayjs(item.created_at).isSame(dayjs(filterToDate), 'day') ||
        //   dayjs(item.created_at).isBefore(dayjs(filterToDate), 'day'))
    );
    count += 1;
  }


  if (filterInvoiceNumber) {
    filteredData = filteredData.filter(
      item =>
        // item.transaction_number && item.transaction_number == filterInvoiceNumber
        String(`${item.invoice_prefix}${item.invoice_transaction_number}`).toLowerCase().includes(String(filterInvoiceNumber.toLowerCase()))
    );
    count += 1;
  }

  const rows = (filterAccount || filterCustomer || filterFromDate || filterToDate || filterInvoiceNumber) ?
    filteredData ? filteredData.map((item) => {
      return {
        created_at: item.created_at && dayjs(item.created_at).format("DD/MM/YYYY"),
        expenses_date: item.date && dayjs(item.date).format("DD/MM/YYYY"), // item.created_at && dayjs(item.created_at).format("DD/MM/YYYY"),
        invoice_number: `${item.invoice_prefix}${item.invoice_transaction_number}`,
        customer: item.customer_name,
        account: item.payment_method,
        description: item.description,
        amount: Number(item.amount).toFixed(2),
        action: <button className="btn btn-none" onClick={() => onClickEdit(item)}><FontAwesomeIcon icon={faPen} style={{ color: "#2046cf", fontSize: "18px" }} /></button>
      }
    }) : '' :
    Income ? Income.map((item) => {
      return {
        created_at: item.created_at && dayjs(item.created_at).format("DD/MM/YYYY"),
        expenses_date: item.date && dayjs(item.date).format("DD/MM/YYYY"), //item.date && item.date !== null ? item.date && dayjs(item.date).format("DD/MM/YYYY") : item.created_at && dayjs(item.created_at).format("DD/MM/YYYY"),
        invoice_number: `${item.invoice_prefix}${item.invoice_transaction_number}`,
        customer: item.customer_name,
        account: item.payment_method,
        description: item.description,
        amount: Number(item.amount).toFixed(2),
        action: <button className="btn btn-none" onClick={() => onClickEdit(item)}><FontAwesomeIcon icon={faPen} style={{ color: "#2046cf", fontSize: "18px" }} /></button>
      }
    }) : '';



  const exportToExcel = () => {
    if(Array.isArray(Income) && Income.length === 0 ){
      toast.warning("Transaction data is empty!");
    }
    else{
      const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Transaction');

    // Add column headers
    columns.forEach((column, index) => {
      if(column.sub_name !== 'Actions'){
        worksheet.getCell(1, index + 1).value = column.name;
        worksheet.getCell(1, index + 1).alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getColumn(index + 1).width = 20/* Add some padding, e.g., 2 characters */;
      }
    });

    const totalSum = rows.reduce((sum, row) => sum + Number(row.amount), 0);

    // Add the total sum to the Excel worksheet
    const lastRowIndex = rows.length + 3; // Add 3 for the header row and 1-based indexing
    const totalSumColumnIndex = columns.findIndex((column) => column.sub_name === 'description') + 1;
    worksheet.getCell(lastRowIndex, totalSumColumnIndex).value = 'Total Amount:';
    worksheet.getCell(lastRowIndex, totalSumColumnIndex).alignment = { horizontal: 'center', vertical: 'middle' };
    worksheet.getCell(lastRowIndex, totalSumColumnIndex + 1).value = `${businessCurrency} ${totalSum}.00`;
    worksheet.getCell(lastRowIndex, totalSumColumnIndex + 1).alignment = { horizontal: 'right', vertical: 'middle' };

    // Add data rows
    rows.forEach((row, rowIndex) => {
      columns.forEach((column, columnIndex) => {
        if(column.sub_name !== 'Actions'){
          worksheet.getCell(rowIndex + 2, columnIndex + 1).value = row[column.sub_name];
          // worksheet.getCell(rowIndex + 2, columnIndex + 1).alignment = { horizontal: 'left', vertical: 'middle' };
        }
        if(column.sub_name === 'amount'){
          worksheet.getCell(rowIndex + 2, columnIndex + 1).value = Number(row[column.sub_name]);
          worksheet.getCell(rowIndex + 2, columnIndex + 1).numFmt = `0.00`;
          // worksheet.getCell(rowIndex + 2, columnIndex + 1).alignment = { horizontal: 'left', vertical: 'middle' };
        }
      });
    });

    // Create a buffer and save it as a file
    workbook.xlsx.writeBuffer().then((buffer) => {
      saveAs(new Blob([buffer]), `Transaction data from ${business_name}.xlsx`);
    });
    }
  };

  return (
    <div>
      <Sidebar />
      {loading ?
        <div className="container main-coloum1 my-5 px-5">
          <div className="row">
            <div className="col-9 text-start fw-bold fs-3 text-capitalize">
              Transaction
            </div>
          </div>

          <div className="mt-3">
            <div className="d-flex justify-content-between">
              <div>
                <button className="btn btn-outline-primary px-4 py-1 wv-outline-btn mb-3" onClick={() => { onFilterToggleChange() }}>Filter <i className={filterHide ? 'bi bi-chevron-up ps-2' : 'bi bi-chevron-down ps-2'}></i> </button>
              </div>
              <div className="text-end">
                <button className="btn btn-outline-primary px-4 py-1 wv-outline-btn mb-3" onClick={() => { exportToExcel() }}>{filterHide ? 'Export filter Data' : 'Export Data'}</button>
              </div>
            </div>

            <div className='filter-body' style={{ display: filterHide ? 'block' : 'none' }}>
              <div class="filter-input-count-dev">
                <span class="filter-count-num">{count}</span>
                <p className='filter-count-text'>Active filters</p>
                {count > 0 &&
                  <>
                    <p className='filter-count-text'>|</p>
                    <p className='filter-clear' onClick={() => handleFilterClear()}>Clear filters</p>
                  </>
                }
              </div>
              <div className="row">
                <div className='col-4'>
                  <Autocomplete
                    disablePortal
                    id="filter-customer"
                    size="small"
                    value={filterCustomer}
                    onChange={(event, newValue) => {
                      setFilterCustomer(newValue);
                    }}
                    options={filterCustomerData}
                    sx={{
                      display: 'flex',
                      width: '100%',
                      height: 40,
                    }}
                    renderInput={(params) => <TextField
                      {...params}
                      placeholder="All Customer"
                      size="small" />}
                  />
                </div>
                <div className='col-sm-2'>
                  <Autocomplete
                    disablePortal
                    id="filter-customer"
                    size="small"
                    value={filterAccount}
                    // onChange={(event, newValue) => {
                    //   if (newValue) {
                    //     const selectedStatus = status.find(item => item.status_name === newValue);
                    //     if (selectedStatus) {
                    //       setFilterStatus(selectedStatus.id);
                    //       setFilterStatusName(newValue)
                    //     }
                    //   } else {
                    //     setFilterStatus('');
                    //   }
                    // }}
                    onChange={(event, newValue) => {
                      setFilterAccount(newValue);
                    }}
                    // options={status.filter(item => item.id >= 4 && item.id <= 6).map(item => item.status_name)}
                    options={filterPaymentMethodData}
                    sx={{
                      display: 'flex',
                      width: '100%',
                      height: 40,
                    }}
                    renderInput={(params) => <TextField
                      {...params}
                      placeholder="Account"
                      size="small" />}
                  />
                </div>
                {/* <div className="row mt-2"> */}
                <div className='col-4 d-flex'>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ position: 'relative' }}>
                      <DatePicker
                        format='DD/MM/YYYY'
                        value={filterFromDate}
                        onChange={(date) => setFilterFromDate(date)}
                        open={isOpenFromDate}
                        onOpen={() => setIsOpenFromDate(true)}
                        onClose={() => setIsOpenFromDate(false)}
                        slotProps={{ textField: { size: 'small', placeholder: 'From', type: 'text' } }}
                      />
                      {/* {filterFromDate ?
                      <span style={{ position: 'absolute', right: '10px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray", fontSize: "18px" }} onClick={() => setFilterFromDate(null)}>
                        X
                      </span>
                      :
                      <span style={{ position: 'absolute', right: '10px', top: '47%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray", fontSize: "18px" }} onClick={() => setIsOpenFromDate(true)}>
                        <i className="bi bi-calendar"></i>
                      </span>
                    } */}
                    </div>
                  </LocalizationProvider>
                  <div className='mx-1'></div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <div style={{ position: 'relative' }}>
                      <DatePicker
                        format='DD/MM/YYYY'
                        value={filterToDate}
                        onChange={(date) => setFilterToDate(date)}
                        open={isOpenToDate}
                        onOpen={() => setIsOpenToDate(true)}
                        onClose={() => setIsOpenToDate(false)}
                        slotProps={{ textField: { size: 'small', placeholder: "To" } }}
                      />
                      {/* {filterToDate ?
                      <span style={{ position: 'absolute', right: '10px', top: '45%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray", fontSize: "18px" }} onClick={() => setFilterToDate(null)}>
                        X
                      </span>
                      :
                      <span style={{ position: 'absolute', right: '10px', top: '47%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray", fontSize: "18px" }} onClick={() => setIsOpenToDate(true)}>
                        <i className="bi bi-calendar"></i>
                      </span>
                    } */}
                    </div>
                  </LocalizationProvider>
                </div>
                <div className='col-sm-2'>
                  <TextField
                    id="outlined-search"
                    placeholder="Enter invoice #"
                    value={filterInvoiceNumber}
                    onChange={(event) => {
                      setFilterInvoiceNumber(event.target.value);
                    }}
                    size="small"
                    sx={{
                      width: '100%',
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {filterInvoiceNumber ?
                            <span style={{ position: 'absolute', right: '18px', top: '52%', transform: 'translateY(-50%)', cursor: 'pointer', fontWeight: "bold", color: "gray" }} onClick={() => setFilterInvoiceNumber('')}>
                              X
                            </span>
                            : <Link className='bi bi-search fw-bold text-dark'></Link>
                          }
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>

          <div className="container mt-3">
            <DataTable
              columns={columns}
              data={rows}
              // fixedHeader={true} 
              fixedHeaderScrollHeight="600px"
              customStyles={customStyles}
              persistTableHead
              pagination
              paginationPerPage={20}
              paginationRowsPerPageOptions={[20, 40, 100]}
              highlightOnHover
            />
          </div>
        </div>
        :
        <LoadingSpinner />}
      {invoiceData && Object.keys(invoiceData).length > 0 && (
        <RecordPayment
          displayRecordPayment={editIncome}
          paymentData={invoiceData}
          selectRecordPayment={editIncomeId}
          isOpen={editIncome}
          onClose={() => {
            setEditIncome(false);
          }}
        />
      )}
    </div>
  )
}