import { useState , useEffect} from "react"
import './Sidebar.css'
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';

export default function SidebarItem({ item }) {
    // const [open, setOpen] = useState(true);
    const localOpen = JSON.parse(localStorage.getItem('stateKey'));
    const initialState = localOpen ?? false;
    const [open, setOpen] = useState(initialState);
  
    useEffect(() => {
      localStorage.setItem('stateKey', JSON.stringify(open));
    }, [open]);


    const accounting = JSON.parse(localStorage.getItem('openaccounting'));
    const accountingState = accounting ?? false;
    const [openaccounting, setaccountingOpen] = useState(accountingState);
  
    useEffect(() => {
      localStorage.setItem('openaccounting', JSON.stringify(openaccounting));
    }, [openaccounting]);

    if (item.childrens) {
        return (
            <div className={open ? "sidebar-item open" : "sidebar-item"} >
                <div className="sidebar-title" onClick={() => setOpen(!open)}>
                    <span>
                        {item.icon && <i className={item.icon}></i>}
                        {item.title}
                    </span>
                    <i className="bi-chevron-down toggle-btn" ></i>
                </div>
                <div className="sidebar-content">
                    {item.childrens.map((child, index) => <SidebarItem key={index} item={child} />)}
                </div>
            </div>
        )
    }
    else if (item.accounting_childrens) {
        return (
            <div className={openaccounting ? "sidebar-item open" : "sidebar-item"} >
                <div className="sidebar-title" onClick={() => setaccountingOpen(!openaccounting)}>
                    <span>
                        {item.icon && <i className={item.icon}></i>}
                        {item.title}
                    </span>
                    <i className="bi-chevron-down toggle-btn" ></i>
                </div>
                <div className="sidebar-content">
                    {item.accounting_childrens.map((child, index) => <SidebarItem key={index} item={child} />)}
                </div>
            </div>
        )
    }
    else {
        return (
            <Nav id="nav" >
                < NavLink to={item.path} className="nav-link  sidebar-item plain" onClick={() => {
                    setOpen(true) 
                    setaccountingOpen(true)
                    }} >
                    {item.icon && <i className={item.icon}></i>}
                    {item.title}
                </NavLink>
            </Nav>


            // <a href={item.path || "#"} className="sidebar-item plain">
            //     { item.icon && <i className={item.icon}></i> }
            //     {item.title}
            // </a>
        )
    }
}