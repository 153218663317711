import Setting from "../../Setting.sidebar"
import Sidebar from "../../../Sidebar/Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { faTrashCan } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from "react"
import axios from "axios";
import { toast } from 'react-toastify';
import { AddSalesTax } from "./Add_Tax";
import EditSalesTax from "./Edit_Tax"
import DataTable  from 'react-data-table-component';
import LoadingSpinner from '../../../Utils/Loader'
import { useNavigate } from "react-router-dom";

export default function Sales_Taxes() {
    const navigate = useNavigate();
    const [taxData, setTaxData] = useState([]);
    const [addTaxBox, setAddTaxBox] = useState(false);
    const [editTaxBox, setEditTaxBox] = useState(false);
    const [editTaxId,setEditTaxId] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const user = localStorage.getItem("user");
        const businessID = user && JSON.parse(user)[0].business.id;

        if(user === null){
            localStorage.removeItem("user");
            navigate('/error-page');
            }

        axios.get(`https://wavedemo.cydeztechnologies.com/list-tax-by-businessid/${businessID}`).then((response) => {
            if(response.data !== "No data found"){
                setTaxData(response.data);
                setLoading(true);
            }
            else{
                setTaxData([]);
                setLoading(true);
            }
        }).catch((e) => {
            console.log("Error in /list-tax-by-businessid:", e);
            toast.error(e.message, {toastId:"error"});
        });
    }, [addTaxBox, editTaxBox]);

    const onClickCreate = () => {
        setAddTaxBox(true);
    }

    function onClickEdit(item) {
        setEditTaxBox(true)
        setEditTaxId(item.id)
    }

    const updateTaxListfromPopup  = (newvalue) =>{
        console.log(newvalue)
      }  

    const customStyles = {
        headRow: {
          style: {
            color:'#000',
            fontWeight:"bolder",
            fontSize:"16px",
            borderBottom: "1px solid black"
          },
        },
        rows: {
          style: {
            color: "#000",
            backgroundColor: "#fff",
            fontSize:"16px",
            minHeight: '55px'
          }
        },
        
      }

    const columns = [
        {
            name: 'Tax Name',
            selector: row => row.tax_name,
            sortable: true,
            grow:3,
        },
        {
            name: 'Status',
            selector: row => row.status,
            grow:1,
        },
        {
            name: 'Actions',
            selector: row => row.action,
            grow:1,
            center: true
          },
      ];

      const rows =  taxData?taxData.map( (item) => {
        return {
            tax_name : `${item.tax_name} @ ${item.tax_rate} % `,
            status: item.status === 1 ? <p className="text-success fw-bold my-2">Active</p>:<p className="text-danger fw-bold my-2">Inactive</p>,
            action:<button className="btn btn-none" onClick={() => onClickEdit(item)}><FontAwesomeIcon icon={faPen} style={{ color: "#2046cf", fontSize: "18px" }} /></button>
      }
      }) :'';

    return (
        <div>
            <Sidebar />
            <Setting />
            {loading ?
            <div className="column2 container my-5">
                <div className="row">
                    <div className="col-9 text-start fw-bold fs-3 text-capitalize">
                        Sales Taxes
                    </div>
                    <div className="col-3 text-end">
                        <button className="btn btn-primary" onClick={onClickCreate}>Create a Sales Tax</button>
                    </div>
                </div>
                <div className="container mt-5">
                    <DataTable 
                            columns={columns}
                            data={rows} 
                            fixedHeaderScrollHeight="600px"
                            customStyles={customStyles}  
                            persistTableHead
                            pagination
                paginationPerPage={10}
                paginationRowsPerPageOptions={[10, 25, 50]}
                highlightOnHover
                        />
                </div>
            </div>
            :
            <LoadingSpinner/>}
            <AddSalesTax
                onUpdateselectedtax={updateTaxListfromPopup}
                onUpdateTaxList={taxData}
                displayAddTax={addTaxBox}
                isOpen={addTaxBox}
                onClose={() => {
                    setAddTaxBox(false);
                }}
            />
            <EditSalesTax
                displayEditTax={editTaxBox}
                taxId={editTaxId}
                isOpen={editTaxBox}
                onClose={() => {
                    setEditTaxBox(false);
                }}

            
            />
        </div>
    )
}