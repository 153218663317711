import Setting from "../../Setting.sidebar"
import Sidebar from "../../../Sidebar/Sidebar";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from "react"
import axios from "axios";
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import LoadingSpinner from '../../../Utils/Loader'
import AddCurrency from "./AddCurrency";
import EditCurrency from "./EditCurrency";
import { useNavigate } from "react-router-dom";

export default function PaymentMethod() {
    const navigate = useNavigate();
    const user = localStorage.getItem("user");
    const businessID = user && JSON.parse(user)[0].business.id;
    const businessCurrencycode = JSON.parse(user)[0].business_currency.toString().split(" - ")[0];
    const businessCurrency = JSON.parse(user)[0].business_currency.toString().split(" - ")[1];
    const [currency, setCurrency] = useState([]);
    const [addCurrency, setAddCurrency] = useState(false);
    const [editCurrency, setEditCurrency] = useState(false);
    const [editCurrencyId, setEditCurrencyId] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (user === null) {
            localStorage.removeItem("user");
            navigate('/error-page');
        }
        axios.get(`https://wavedemo.cydeztechnologies.com/currencies/${businessID}`).then((response) => {
            setCurrency(response.data);
            setLoading(true);
        }).catch((e) => {
            console.log("Error in /list-payment-method-by-businessid:", e);
            toast.error(e.message, { toastId: "error" });
        });
    }, [addCurrency, editCurrency]);

    const onClickCreate = () => {
        setAddCurrency(true);
    }

    function onClickEdit(item) {
        setEditCurrency(true)
        setEditCurrencyId(item)
    }

    const customStyles = {
        headRow: {
            style: {
                color: '#000',
                fontWeight: "bolder",
                fontSize: "16px",
                borderBottom: "1px solid black"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontSize: "16px",
                minHeight: '55px'
            }
        },

    }

    const columns = [
        {
            name: 'Currency Name',
            selector: row => row.currency_code_name,
            sortable: true,
            grow: 2,
        },
        {
            name: 'Symbol',
            selector: row => row.symbol,
            grow: 1,
        },
        {
            name: 'Exchange Rate',
            selector: row => row.exchange_rate,
            grow: 1,
        },
        {
            name: 'Actions',
            selector: row => row.action,
            grow: 1,
            center: true,
            cell: (row) => {
                if (String(businessCurrency).toLowerCase() == String(row.currencyBusiness).toLowerCase()) {
                    return (<p
                        className="my-2 text-success fw-bold"
                    >
                        Business Currency
                    </p>);
                }
                else {
                    return (<button
                        className="btn btn-none"
                        onClick={() => onClickEdit(row)}
                    >
                        <FontAwesomeIcon icon={faPen} style={{ color: "#2046cf", fontSize: "18px" }} />
                    </button>);
                }
            }
        },
    ];

    const rows = currency ? currency.map((item) => {
        return {
            id: item.id,
            currencyBusiness: item.currency_name.trim(),
            currency_code_name: `${item.code} - ${item.currency_name}`,
            currency_name: item.currency_name,
            symbol: item.symbol,
            code: item.code,
            exchange_rate: item.exchange_rate,
            action: item
        }
    }) : '';

    return (
        <div>
            <Sidebar />
            <Setting />
            {loading ?
                <div className="column2 container my-5">
                    <div className="row">
                        <div className="col-9 text-start fw-bold fs-3 text-capitalize">
                            Currency
                        </div>
                        <div className="col-3 text-end">
                            <button className="btn btn-primary" onClick={onClickCreate}>Add New Currency</button>
                        </div>
                    </div>
                    <div className="container mt-5">
                        <DataTable
                            columns={columns}
                            data={rows}
                            fixedHeaderScrollHeight="600px"
                            customStyles={customStyles}
                            persistTableHead
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 25, 50]}
                            highlightOnHover
                        />
                    </div>
                </div>
                :
                <LoadingSpinner />}
            <AddCurrency
                displayAddCurrency={addCurrency}
                waveCurrency={currency}
                isOpen={addCurrency}
                onClose={() => {
                    setAddCurrency(false);
                }}
            />
            <EditCurrency
                uploadCurrenyList={currency}
                displayEditCurrency={editCurrency}
                CurrencyDetails={editCurrencyId}
                isOpen={editCurrency}
                onClose={() => {
                    setEditCurrency(false);
                }}
            />
        </div>
    )
}