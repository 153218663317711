import React, { useState, useEffect } from 'react'
import './User_Login.css'
import { Link } from 'react-router-dom';
import LoginIllus from '../../assets/login-illus.png'
import logo from '../../assets/wave-logo.svg'
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from 'react-toastify';
import Forgotpassword from './Forgot_Password/Email_Verification';
import { Tooltip } from 'antd';
import Home_Navbar from "../Home/Home_Navbar"

function User_Login() {
    const location = useLocation();
    const navigate = useNavigate();
    const regemail = location.state ? location.state.email : "";
    const [passtype, setPassType] = useState('password');
    const [input, setInput] = useState({
        email: regemail,
        password: ""
    })
    const [forgotPassword, setForgotPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorsMessage, setErrorsMessage] = useState({});

    const handleChange = (e) => {
        setInput({ ...input, [e.target.name]: e.target.value });
        setErrors({ ...errors, [e.target.name]: false });
    }

    useEffect(() => {
        const preventBackNavigation = () => {
            window.location.replace('/');
        };

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', preventBackNavigation);

        return () => {
            window.removeEventListener('popstate', preventBackNavigation);
        };
    }, []);

    //prevent form submitting
    const handleSubmit = (e) => {
        e.preventDefault();
    }
    //login authentication
    const doAuthenticate = () => {
        const email_pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

        const errors = {};
        const errorMessage = {};

        for (const [key, value] of Object.entries(input)) {
            var name;
            if (key === "email") { name = "Email" };
            if (key === "password") { name = "Password" };

            if (!String(value).trim()) {
                errors[key] = true;
                errorMessage[key] = `${name} is required`;
            }
            else if (key === "email" && !/\S+@\S+\.\S+/.test(value)) {
                errors[key] = true;
                errorMessage[key] = `${name} is invalid`
            }
            // else if (key === "password" && !/^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d!@#$%^&*()\-_+=~`[\]{}|:;"'<>,.?/]{8,}$/.test(value)) {
            //     errors[key] = true;
            //     errorMessage[key] = `Password must be one letter, digit, special character and at least 8 characters long`
            // }
        }

        setErrors(errors);
        setErrorsMessage(errorMessage);

        if (Object.keys(errors).length === 0) {
            axios.post("https://wavedemo.cydeztechnologies.com/user-login", {
                email: input.email,
                password: input.password
            }).then((res) => {
                if (res.data[0].id) {
                    localStorage.setItem("user", JSON.stringify(res.data));
                    navigate('/dashboard');
                } else {
                    toast.error(res.data, { toastId: 'log1', })
                }

            }).catch((error) => {
                toast.error('Something went wrong', { toastId: 'log1', })
            });

        }
        else if (Object.keys(errors).length !== 0) {
            toast.error(`Please check ${errorMessage && Object.entries(errorMessage)[0][1]}`, { toastId: "error-1" });
        }
        else {
            toast.error("Invalid Email or Password!", { toastId: "err-log" });
        }
        // navigate('/dashboard');
        // localStorage.setItem("user", JSON.stringify([
        //     {
        //     business_id:'1',
        //     first_name:'jomon',
        //     last_name:'joji',
        //     email:'jomon@gmail.com',
        //     password:'jomon',
        //     designation:'Accountant',
        //     postal_code:'685517',
        //     role:'1'
        //     }
        // ]));
    }
    //password hide & seek toggle
    const handleToggle = () => {
        passtype === 'password' ? setPassType('text') : setPassType('password')
    }


    return (
        <section className='w-100' style={{backgroundColor:"#f9f4f1"}}>
            <Home_Navbar/>
            <div className="container-fluid vh-100 " >
                <div className="row px-0 px-sm-4 d-flex align-items-center justify-content-center">
                    <div className="col -12 col-md-6 "  >
                        <div className="login-input-section  mx-2  m-sm-5"  >
                            <div className="login-input-container mt-sm-0 ms-0 ms-sm-5" >
                                {/* <div className="login-header" style={{ marginBottom: '60px' }}>
                                    <img src={logo} alt="Logo" width="150px" height="35px" />
                                </div> */}
                                <div className="login-form">
                                    <h3 className=' mb-4 reg-form-title'>Welcome to Bill Beam</h3>
                                    <p className='text-muted'> Your all-in-one solution for estimate and invoice management</p>
                                    <form onSubmit={handleSubmit}>

                                        <div className="form-group   mb-3">
                                            <label className="labels">Email</label> <span className="importent">*</span>
                                            <Tooltip
                                                title={errorsMessage.email}
                                                placement="bottom"
                                                color="#990000"
                                                trigger={"contextMenu"}
                                                open={errors.email}
                                                overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                                                autoAdjustOverflow={false}
                                            >
                                                <input
                                                    type="email"
                                                    name="email"
                                                    className="form-control sgn-input"
                                                    placeholder="Your Email"
                                                    // required
                                                    id="name"
                                                    defaultValue={regemail ? regemail : ''}
                                                    onChange={handleChange} />
                                            </Tooltip>
                                        </div>
                                        <div className="form-group mb-1" style={{ position: 'relative' }}>
                                            <label className="labels">Password</label> <span className="importent">*</span>
                                            <Tooltip
                                                title={errorsMessage.password}
                                                placement="bottom"
                                                color="#990000"
                                                trigger={"contextMenu"}
                                                open={errors.password}
                                                overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                                                autoAdjustOverflow={false}
                                            >
                                                <div style={{ position: 'relative' }}>
                                                    <input
                                                        type={passtype}
                                                        name="password"
                                                        className="form-control sgn-input"
                                                        placeholder="Your Password"
                                                        id="password"
                                                        // required
                                                        onChange={handleChange} />
                                                    <i
                                                        className={
                                                            passtype === "password" ? "fa fa-eye-slash" : "fa fa-eye"
                                                        }
                                                        style={{ position: "absolute", top: "35%", right: "2.5%", color: "#d5cdcd", cursor: "pointer" }}
                                                        id="sgn-pass-tog"
                                                        onClick={handleToggle}
                                                    ></i>
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className='mb-2'>
                                            <Link className='text-decoration-none text-primary' onClick={() => { setForgotPassword(true) }}>Forgot it?</Link>
                                        </div>
                                        <div className="form-group mb-3 ">
                                            <button className='btn btn-primary w-100 login-btn'
                                                onClick={() => doAuthenticate()}
                                            >Sign in</button>
                                        </div>
                                        <div className="form-group mb-3 text-center" >
                                            <Link className="text-decoration-none" to="/Candidate_Login">
                                                <span className="text-black me-2 text-muted" style={{ fontSize: '14px' }}>
                                                    Don't have an account yet?
                                                    <Link className="text-decoration-none" to="/user-register">
                                                        <span className='signLink'>Sign up now</span>
                                                    </Link></span>
                                            </Link>
                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                    <div className="col-12 col-md-6 d-none d-sm-block" >
                        <div className="login-content-section">
                            <img src={LoginIllus} alt="login" className="img-fluid" width="400px" height="450px" />
                        </div>
                    </div>

                </div>
            </div>
            <Forgotpassword
                isOpen={forgotPassword}
                onClose={() => {
                    setForgotPassword(false);
                }}
            />
        </section>
    )
}

export default User_Login