import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Tooltip } from 'antd';

export default function AddPaymentMethod(props) {
  const { displayAddPaymentMethod, isOpen, onClose, uploadSelectMethod, uploadMethodList } = props;
  const [_isOpen, setOpen] = useState(isOpen);
  const user = localStorage.getItem("user");
  const businessID = JSON.parse(user)[0].business.id;
  const [errors, setErrors] = useState('');
  const [errorMessage,setErrorMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [payment, setPayment] = useState({
    paymentmethod_name: "",
    paymentaccount: "",
    description: "",
    // parent_id:""
  });

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
    setErrors(false);
    setPayment({ paymentmethod_name: "", paymentaccount: "", description: "" })
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);


  const handleChange = (event) => {
    const { name, value } = event.target;
    setPayment((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setErrors(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let error = '';
    if (!payment.paymentmethod_name || payment.paymentmethod_name.trim() === "") {
      error = "Payment method is required";
      setErrors(true);
    }
    else if (payment.paymentmethod_name && !/^[\w\d\s-]+$/.test(payment.paymentmethod_name)) {
      error = "Payment method is invalid";
      setErrors(true);
    }
    setErrorMessage(error);
    if (error.length === 0) {
      const isExisted = uploadMethodList.some((item) => {
        return (item.paymentmethod_name === payment.paymentmethod_name);
      });
      if (isExisted) {
        toast.error("Payment method already exists!", { toastId: "addme-1" });
      }
      else{
        setIsSubmitting(true);
      axios.post(`https://wavedemo.cydeztechnologies.com/add-payment-method`, {
        business_id: businessID,
        paymentmethod_name: payment.paymentmethod_name,
        // paymentaccount: payment.paymentaccount,
        description: payment.description,
      })
        .then((res) => {
          if (res.data) {
            toast.success('Payment method created successfully', {toastId:"addme-1"});
            uploadSelectMethod(payment);
            setOpen(false);
            if (onClose) onClose();
            setPayment({
              paymentmethod_name: "",
              paymentaccount: "",
              description: "",
            });
            setErrors(false);
            setIsSubmitting(false);
          }
          else {
            toast.error(res.data.trim(), {toastId:"addme-1"});
          }
        })
        .catch((e) => {
          toast.error(e.message, {toastId:"err-api"});
        });
      }
    }
    else{
      toast.warning(`${errorMessage && errorMessage} please make sure to include it.`, {toastId:"error"});
  }
  }

  return (
    <Dialog
      open={displayAddPaymentMethod}
      onClose={() => setOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      scroll={'body'}
      disablebackdropclick="true"
      disableEscapeKeyDown={true}
      style={{ height: "auto", maxHeight: "900px" }}
    >
      {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Add Payment Method</DialogTitle> */}
      <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center ">
        Add Payment Method
        <i
          className="bi bi-x-lg"
          style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
          onClick={handleClose}
        ></i>
      </DialogTitle>
      <hr />
      <DialogContent className="px-5 mt-2" >
        <div className="form-group row m-4">
          <label className="col-sm-5 fw-bold pe-5 text-start text-nowrap">Payment Method <span className="importent">*</span> </label>
          <div className="col-sm-7">
            <Tooltip
              title={errorMessage}
              placement="bottom"
              color="#990000"
              trigger={"contextMenu"}
              open={errors}
              overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
              autoAdjustOverflow={false}
              zIndex={9999}
            >
              <input
                type="text"
                className={`${errors ? "ms-2 rounded border border-2 border-danger py-1 w-100" : "ms-2 rounded border border-2 border-primary py-1 w-100"}`}
                name='paymentmethod_name'
                value={payment.paymentmethod_name}
                onChange={handleChange}
              />
              {errors && <div className="mb-2"></div>}
            </Tooltip>
          </div>
        </div>
        <div className="form-group row m-4">
          <label className="col-sm-5 fw-bold text-start text-nowrap">Description</label>
          <div className="col-sm-7">
            {/* <input type="text" className="ms-2 rounded border border-2 border-primary py-1 w-100"/> */}
            <textarea
              className="ms-2 rounded border border-2 border-primary py-1 w-100"
              name='description'
              value={payment.description}
              onChange={handleChange}
            ></textarea>
          </div>
        </div>
      </DialogContent>
      <hr />
      <DialogActions className='mb-4'>
        <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
          Cancel
        </button>
        <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
          {isSubmitting ? 'Uploading...' : 'Ok'}
        </button>
      </DialogActions>
    </Dialog>
  )
}