import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Tooltip } from 'antd';


export default function EditExpenses(props) {
    const { displayEditExpenses, ExpensesDetails, isOpen, onClose } = props;
    const [_isOpen, setOpen] = useState(isOpen);
    const user = localStorage.getItem("user");
    const businessID = JSON.parse(user)[0].business.id;
    const currentDate = dayjs();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [editExpenses, setEditExpenses] = useState({
        id: "",
        business_id: "",
        expense_name: "",
        description: "",
        amount: "",
        expense_date: "",
    });
    const [errors, setErrors] = useState('');
    const [errorsMessage, setErrorsMessage] = useState({});

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
        setErrors(false);
    };

    useEffect(() => {
        if (ExpensesDetails !== undefined) {
            setEditExpenses(ExpensesDetails)
            console.log(ExpensesDetails)
        }
        setOpen(isOpen);
    }, [isOpen]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditExpenses((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};

        for (const [key, value] of Object.entries(editExpenses)) {
            var name;
            if (key === "expense_name") { name = "Expense name" };
            if (key === "amount") { name = "Amount" };
            if (key === "expenses_date") { name = "Expenses date" };
            if (key === 'expense_name' || key === "amount" || key === "expenses_date") {
                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`
                }
                else if (key === 'expense_name' && !/^(?![0-9]{2,})[\w\d\s-]{3,25}$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = `Expenses name must be 3-25 letters`
                }
                else if (key === 'amount' && !/^0*[1-9]\d*$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = `Amount is invalid`
                }
            }
        }
        if (!editExpenses.expense_date) {
            errors['expense_date'] = true;
            errorMessage['expense_date'] = `Expense date is required`;
        }

        setErrors(errors);
        setErrorsMessage(errorMessage);
        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
            axios.put(`https://wavedemo.cydeztechnologies.com/updateExpense/${editExpenses.id}`, {
                business_id: businessID,
                expense_name: editExpenses.expense_name,
                description: editExpenses.description,
                amount: editExpenses.amount,
                expense_date: editExpenses.expense_date
                // expense_date: new Date().toISOString().substring(0, 10)
            })
                .then((res) => {
                    if (res.data) {
                        toast.success(res.data, { toastId: "editPro-1" });
                        setOpen(false);
                        if (onClose) onClose();
                        setErrors(false);
                        setIsSubmitting(false);
                    }
                    else {
                        toast.error(res.data.trim(), { toastId: "editPro-1" });
                    }
                })
                .catch((e) => {
                    toast.error(e.message, { toastId: "err-api" });
                });
        }
        else {
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error"});
        }
    }

    const styleName = "ms-2 rounded border border-2 border-primary w-100";
    const styleNameError = "ms-2 rounded border border-2 border-danger w-100";

    return (
        <Dialog
            open={displayEditExpenses}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll={'body'}
            disablebackdropclick="true"
            disableEscapeKeyDown={true}
            fullWidth={true}
            maxWidth={'sm'}
            style={{ height: "auto", maxHeight: "900px" }}
        >
            {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Edit Payment Method</DialogTitle> */}
            <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center ">
                Edit Expense
                <i
                    className="bi bi-x-lg"
                    style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                    onClick={handleClose}
                ></i>
            </DialogTitle>
            <hr />
            <DialogContent className="px-5 mt-2">
                <div className="form-group row m-4">
                    <label className="col-sm-4 pe-5 fw-bold test-wrap text-nowrap">Expense Name <span className="importent">*</span> </label>
                    <div className="col-sm-8">
                        <Tooltip
                            title={errorsMessage.expense_name}
                            placement="bottom"
                            color="#990000"
                            trigger={"contextMenu"}
                            open={errors.expense_name}
                            overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                            autoAdjustOverflow={false}
                            zIndex={1300}
                        >
                            <input
                                type="text"
                                className={`${errors.expense_name ? "ms-2 rounded border border-2 border-danger py-1 w-100 ps-2" : "ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"}`}
                                name='expense_name'
                                value={editExpenses.expense_name}
                                onChange={handleChange}
                            />
                            {errors.expense_name && <div className="mb-2"></div>}
                        </Tooltip>
                    </div>
                </div>
                <div className="form-group row m-4">
                    <label className="col-sm-4 fw-bold text-start text-nowrap">Description</label>
                    <div className="col-sm-8">
                        <textarea
                            type="text"
                            className="ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"
                            name='description'
                            value={editExpenses.description}
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <div className="form-group row m-4">
                    <label className="col-sm-4 fw-bold text-start text-nowrap">Amount <span className="importent">*</span></label>
                    <div className="col-sm-8">
                        <Tooltip
                            title={errorsMessage.amount}
                            placement="bottom"
                            color="#990000"
                            trigger={"contextMenu"}
                            open={errors.amount}
                            overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                            autoAdjustOverflow={false}
                            zIndex={1300}
                        >
                            <input
                                type="text"
                                className={`${errors.amount ? "ms-2 rounded border border-2 border-danger py-1 w-100 ps-2" : "ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"}`}
                                name='amount'
                                value={editExpenses.amount}
                                onChange={handleChange}
                            />
                            {errors.amount && <div className="mb-2"></div>}
                        </Tooltip>
                    </div>
                </div>
                <div className="form-group row m-4">
                    <label className="col-sm-4 fw-bold text-start text-nowrap">Date <span className="importent">*</span></label>
                    <div className="col-sm-8">
                        <Tooltip
                            title={"Date is requried"}
                            placement="bottom"
                            color="#990000"
                            trigger={"contextMenu"}
                            open={errors.expense_date}
                            overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                            autoAdjustOverflow={false}
                            zIndex={9999}
                        >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker
                                    className={`${errors.expense_date ? "ms-2 rounded border border-2 border-danger w-100" : "ms-2 rounded border border-2 border-primary w-100"}`}
                                    slotProps={{ textField: { size: 'small' } }}
                                    value={editExpenses ? dayjs(editExpenses.expense_date) : null}
                                    onChange={(newDate) => {
                                        const updatedDate = newDate ? dayjs(newDate).toDate() : null;
                                        setEditExpenses({ ...editExpenses, expense_date: updatedDate });
                                    }}
                                    format='DD/MM/YYYY'
                                    maxDate={currentDate}
                                />
                            </LocalizationProvider>
                            {/* {errors.expense_date && <><br /><br /><br /></>} */}
                        </Tooltip>
                    </div>
                </div>
            </DialogContent>
            <hr />
            <DialogActions className='mb-4'>
                <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
                    Cancel
                </button>
                <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
                    {isSubmitting ? 'Uploading...' : 'Ok'}
                </button>
            </DialogActions>
        </Dialog>
    )
}