import React, { useEffect, useState } from 'react'
import Sidebar from '../../Sidebar/Sidebar'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import './Estimate.css'
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import AddCustomersPopup from './add_Customer_Popup';
import AddProductPopup from './add_product_popup';
import AddTaxPopup from './add_tax_popup';
import { useLocation } from "react-router-dom";
import { Tooltip } from 'antd';
import AddCurrencyPopup from './add_Currency_Popup';
import LoadingSpinner from '../../Utils/Loader';
import Customization_Warning_Popup from './Customization_Warning_Popup';
import Business_Warning_Popup from './Business_Warning _Popup';


function Add_Estimate() {
  const location = useLocation();
  const estimateId = location.state && location.state.estimateId; // from view page for edting
  const currentDate = dayjs(); // Get current date using dayjs
  const [estimate,SetEstimate] = useState({
      estimate_name:'',
      estimate_prefix:'',
      estimate_number:'',
      customer_id:'',
      currency:'',
      date:currentDate,
      exp_date:currentDate,
      purchase_order:'',
      subheading:'',
      footer:'',
      memo:'',
      total_amount:'',

  });
  let curList = [];
  let customerListArray = [];
const [customerPopup,setCustomerPopup] = useState(false);
const [productPopup,setProductPopup] = useState(false);
const [taxPopup,setTaxPopup] = useState(false);
const [currencyPopup,setCurrencyPopup] = useState(false);
const navigate = useNavigate();
const[rowData, setRowData] = useState([{
    product_id:'',
    product_name:'',
    product_description:'',
    product_quantity:'',
    product_tax:[],
    product_price:'',
    product_amount:'0.00',
}]);
const[oldproductdetails, setOldproductdetails] = useState([]);

const [customerList, setCustomerList]=useState([]);
const [productList, setProductList]=useState([]);
const [taxList,setTaxList]=useState([]);
const [allTaxList,setAllTaxList]=useState([]);
const [currencyList, setCurrencyList]=useState([]);
const [selectedCurrency, setSelectedCurrency]=useState();
const [taxCalc,setTaxCalc]=useState([]);
const user = localStorage.getItem("user");
const business_id = user&&JSON.parse(user)[0].business.id;
const bcur = user&&JSON.parse(user)[0].business_currency.split("-");
const role = user && JSON.parse(user)[0].role;
const wv_busCur_code =bcur && bcur[0].trim();   // bussiness currency code :INR
const wv_busCur_symbol =bcur && bcur[2].trim();  //business currency symbol
const [taxIndex,setTaxIndex]=useState();
const [productIndex,setproductIndex]=useState();
const [selectedCustomer,setSelectedCustomer]=useState({});
const [errors, setErrors] = useState({});
const [btnDisabled, setBtnDisabled] = useState(false);
const [loading, setLoading] = useState(true);
const [addnewline, setaddnewline] = useState(true);

const [customizationWarning, setCustomizationWarning] = useState(false);
const [businessdata, setBusinessdata] = useState({});
const [businessWarning,setBusinessWarning] = useState(false);


var subtotal = 0;
var totaltax=0;
//prevent form submitting
const handleSubmit = (e) => {
    e.preventDefault();
}


const addTableRow = () =>{

    const rowInputs = {
        product_id:'',
        product_name:'',
        product_description:'',
        product_quantity:'',
        product_tax:[],
        product_price:'',
        product_amount:'0.00',
    }
    if(addnewline){
      setRowData([...rowData, rowInputs]);
    }
    else{
      setRowData([rowInputs]);
      setaddnewline(true);
    }
      setErrors({...errors,product:false}); //clear
}

const deleteTablRows = (index) =>{
     const rows = [...rowData];
     rows.splice(index,1);
     setRowData(rows)

     setErrors({...errors,
       [`product_name${index}`]:false, 
       [`product_quantity${index}`]:false,
       [`product_price${index}`]:false});

}

const deleteoldDataRow = (index) =>{
  const rows = [...oldproductdetails];
  rows.splice(index,1);
  setOldproductdetails(rows)
}

//product section :desc,quantity,price onchange
const handleChange = (index,event) =>{
       
        const {name,value} = event.target;
        const rowsInput = [...rowData];
        rowsInput[index][name]=value;
     
        // Calculate the amount based on price and quantity
        const price = parseFloat(rowsInput[index].product_price);
        const quantity = parseFloat(rowsInput[index].product_quantity);
        let amount = isNaN(price) || isNaN(quantity) ? 0 : price * quantity;
        amount = amount.toFixed(2);
        rowsInput[index].product_amount = amount;
      
        setRowData(rowsInput);
        const errname = name+index;
        setErrors({...errors,[errname]:false});
}
const productonChange = ( newValue , index)=>{

  const itemname = "product_name"+index;


    if(newValue){

        const fetchedtax = taxList.find((obj) => obj.id === newValue.tax_id);
       
       

    const updatedRowData  = [...rowData];
    updatedRowData[index] = { ...updatedRowData[index],
        product_name: newValue.product_name,
        product_description: newValue.description,
        product_price: Number(newValue.price).toFixed(2),
        product_tax: fetchedtax !== undefined ? [fetchedtax] : [],
        product_quantity: '1',
        product_amount: Number(newValue.price).toFixed(2),
        product_id:newValue.id
      };



    // updatedRowData[index].product_name = newValue.product_name;
    // updatedRowData[index].product_description = newValue.description;
    // updatedRowData[index].product_price = newValue.price;
    // updatedRowData[index].product_tax = [...newValue.tax_id];
    // updatedRowData[index].product_quantity = '1';
    // updatedRowData[index].product_amount = newValue.price.toFixed(2);
    setRowData(updatedRowData);
    }
    setErrors({...errors,[itemname]:false});
}

const taxOnChange = (newValue , index) =>{
 
    const updatedRowData  = [...rowData];
    updatedRowData[index] = {
        ...updatedRowData[index],
        product_tax: newValue,
      };
      setRowData(updatedRowData);
     
}

useEffect ( ()=>{
    calculateTax();
},[rowData,oldproductdetails])

const calculateTax = () =>{
  const taxMap = []; 

      rowData.forEach((product)=>{
           product.product_tax.forEach((tax)=>{
            const taxName = tax && tax.tax_name;
            const taxAmount = tax && (tax.tax_rate * product.product_amount) / 100;
            const existingTax = taxMap.find((item) => item.taxName === taxName);
            if (existingTax) {
              existingTax.taxAmount += taxAmount;
            } 
            else if (taxName && taxAmount) {
              taxMap.push({ taxName, taxAmount });
            }


          })
      })
      //existing product tax
     if(oldproductdetails && oldproductdetails.length>0){
       oldproductdetails.forEach (oldpro =>{
        oldpro.product_tax.forEach(oldtax =>{
         
          const taxName = oldtax.tax_description;
          const taxAmount = (oldtax.tax_percentage * oldpro.product_amount) / 100;
          const existingTax = taxMap.find((item) => item.taxName === taxName);
          if (existingTax) {
            existingTax.taxAmount += taxAmount;
          } else {
            taxMap.push({ taxName, taxAmount });
          }
        
        })
      })
    }



      
    
      setTaxCalc(taxMap)
    }

    const business_data = () => {
      axios.get(`https://wavedemo.cydeztechnologies.com/list-business`)
      .then((res)=>{
        const responseData = res.data; 
        const filteredData = responseData && responseData.filter(item => item.id === business_id);
        setBusinessdata(filteredData[0]);
      }).catch(error =>{
        toast.error(error.message,{toastId: 'addest5',})  ;
    });
    }


useEffect(()=>{
    const user = localStorage.getItem("user");
    if(user === null){
        localStorage.removeItem("user");
        navigate('/user-login');
    }
    else{
      business_data();
        axios.get(`https://wavedemo.cydeztechnologies.com/get-estimate-customization-details/${business_id}`)
        .then((res) => {
  
          if (parseInt(res.data.estimate_number) == null || res.data.estimate_default_title == null) {
            setCustomizationWarning(true)
          }
          else if(businessdata && businessdata.business_address === null){
            setBusinessWarning(true);
          }
          else {
            fetchCurrencyList();
            fetchCustomerList();
            fetchProductList();
            fetchTaxList();
            
            estimateId?fetchEditEstimateDetails():fetchEstimateDetails();
          }
  
        }).catch(error => {
          toast.error(`Estimate Details API Error1 ${error}`, { toastId: 'addest5', });
        });
    }
},[])


const fetchCurrencyList = () => {
  axios.get(`https://wavedemo.cydeztechnologies.com/currencies/${business_id}`).then((res)=>{
    curList.push(...res.data);
      setCurrencyList( res.data);
    
      setLoading(false)
     
  }).catch(error =>{
          toast.error("Currency API Error",{toastId: 'addest4',})  ;
          setLoading(false)
      });
}
function convertNullToEmptyString(value) {
  return value === 'null' || value === null ? '' : value;
}

//fetch estimate customized details 
const fetchEstimateDetails = () => {

  
  axios.get(`https://wavedemo.cydeztechnologies.com/get-estimate-customization-details/${business_id}`)
    .then((res)=>{
      
        let currncy_data = curList.find((option) =>option.code == wv_busCur_code);
        setSelectedCurrency(curList.find((option) =>option.code == wv_busCur_code));  
       // console.log("wv_busCur_code",curList)
        SetEstimate({...estimate,
                estimate_name:convertNullToEmptyString(res.data.estimate_default_title),
                estimate_prefix:convertNullToEmptyString(res.data.prefix),
                estimate_number:convertNullToEmptyString(res.data.estimate_number),
                subheading:convertNullToEmptyString(res.data.estimate_default_subheading),
                footer:convertNullToEmptyString(res.data.estimate_default_footer),
                memo:convertNullToEmptyString(res.data.estimate_default_notes),
               currency: convertNullToEmptyString(currncy_data ? currncy_data.id : null),
               exp_date:dayjs(res.data.estimate_payment_term),
               })
        
            

  }).catch(error =>{
          toast.error("Estimate Details API Error1",{toastId: 'addest5',})  ;
      });
}

const fetchEditEstimateDetails = () =>{

  axios.get(`https://wavedemo.cydeztechnologies.com/estimate/${business_id}/${estimateId}`).
  then((res)=>{
   
    setSelectedCurrency( curList.find((option) =>option.id === res.data[0].currency.id)); 

   setSelectedCustomer(customerListArray.find((option)=>option.id === res.data[0].customer_id.id));
    
    //const ddate = dayjs(res.data[0].date).toDate();
     
    SetEstimate({...estimate,
      estimate_name:res.data[0].transaction_name,
      estimate_prefix:res.data[0].prefix,
      estimate_number:res.data[0].transaction_number,
      subheading:res.data[0].subheading,
      footer:res.data[0].footer,
      memo:res.data[0].memo,
      purchase_order:res.data[0].purchase_order,
      currency:res.data[0].currency.id,
      customer_id:res.data[0].customer_id.id,
      date:res.data[0].date,
      exp_date:res.data[0].expiry_date
     
     })
     //set product details
     setOldproductdetails(res.data[0].products.map((item) => {
      return {
        product_name: item.product_name,
        product_description: item.product_description,
        product_quantity: item.product_quantity,
        product_tax: item.taxes,
        product_price: item.product_price,
        product_amount: item.product_price * item.product_quantity,
      };
    }));
    if(res.data[0].products){
      setaddnewline(false);
      setRowData([]);
    }
   // setRowData([]);
  }).catch();


}


const fetchCustomerList = () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-customers/${business_id}`).then((res)=>{
        //const customers =  res.data.map((item)=> item.customer_company_name)
        if(res.data !== "No data available"){
          customerListArray.push(...res.data);
          setCustomerList(res.data);
        }
        
    }).catch(error =>{
            toast.error("Estimate Edit API Error",{toastId: 'addest1',})  ;
        });
}
const fetchProductList = () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-products/${business_id}`).then((res)=>{
      if(res.data !== "No data found"){
        setProductList( res.data);
      }
    }).catch(error =>{
            toast.error("Product API Error",{toastId: 'addest2',})  ;
        });
}

const fetchTaxList = () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-tax-by-businessid/${business_id}`).then((res)=>{
      if(res.data !== "No data found"){    
        setTaxList( res.data.filter(item => item.status === 1));
        setAllTaxList(res.data);
      }
    }).catch(error =>{
            toast.error("Tax API Error",{toastId: 'addest3',})  ;
        });
}

//customer select onchange
const handleSelect = (event, value) => {
  if (value) {
    setSelectedCustomer(value)
    SetEstimate ({...estimate,customer_id:value.id})
  } else {
    SetEstimate ({...estimate,customer_id:null})
  }
  setErrors({...errors,customer:false});
};

//update customer list from add customer popup
  const updateCustomerListfromPopup  = (newvalue) =>{
    setCustomerList(newvalue);
  }  
  const updateselectdcusfrompopup  = (newvalue) =>{
    setSelectedCustomer(newvalue)
     SetEstimate ({...estimate,customer_id:newvalue.id})
  }                     

//update tax list from add tax popup
const updateTaxListfromPopup  = (newvalue) =>{
  setTaxList(newvalue);
}  

//update currency list from add currency popup
const updateSelelctedCurrencyPopup = (newvalue) => {
  setSelectedCurrency(newvalue);
  SetEstimate({...estimate,currency:newvalue.id})
}

//update product list from product tax popup
const updateProductListfromPopup  = (newProdList, newTaxList,newProduct,index) =>{
  setProductList(newProdList);
  setTaxList(newTaxList);

  //update produc on change
  if(newProduct){
		const fetchedtax = newTaxList.find((obj) => obj.id === newProduct.tax_id);
		const updatedRowData  = [...rowData];
		updatedRowData[index] = { ...updatedRowData[index],
			product_name: newProduct.product_name,
			product_description: newProduct.description,
			product_price: newProduct.price,
			product_tax: fetchedtax !== undefined ? [fetchedtax] : [],
			product_quantity: '1',
			product_amount: Number(newProduct.price).toFixed(2),
			product_id:newProduct.id
		};
    setRowData(updatedRowData);
    }

}  


// post estimate data

const addEstimate = () =>{

const validaion = estimateValidation();

if(!validaion){
  setBtnDisabled(true);
let subtotalamt =0;
let totaltax =0;
let totalestimateamt =0;
let totalestimatebusamt =0;

const transformedProducts = rowData && rowData
                  .filter((pro) => pro.product_name !== '')
                  .map((product) => {
  const {product_name,product_description,product_quantity,product_price,product_tax,product_amount} = product;

  subtotalamt = parseFloat(subtotalamt)+ parseFloat(product_amount);
  
  // Map the product_tax array to the required taxes format
  const taxes = product_tax.map((tax) => ({
    tax_percentage: tax.tax_rate,
    tax_description: tax.tax_name
  }));

  // Create the transformed product object
  const transformedProduct = {
    product_name,
    product_description,
    product_quantity: parseInt(product_quantity), 
    product_price: parseFloat(product_price), 
    taxes
  };

  return transformedProduct;
});




  taxCalc.map((tax,index)=>{
    totaltax +=tax.taxAmount;
   
  })
  totalestimateamt = subtotalamt + totaltax;
  totalestimateamt=parseFloat(totalestimateamt.toFixed(2));
 


  selectedCurrency && parseFloat(selectedCurrency.exchange_rate) !== 0.00 ?
  totalestimatebusamt = totalestimateamt * parseFloat(selectedCurrency.exchange_rate):
  totalestimatebusamt = totalestimateamt;

  const data = {
    "business_id": business_id,
    "prefix":estimate.estimate_prefix ,
    "transaction_type": 1,
    "transaction_number": estimate.estimate_number,
    "transaction_name":estimate.estimate_name,
    "customer_id":  estimate.customer_id,
    "currency":  estimate.currency,
    "date": estimate.date,
    "expiry_date": estimate.exp_date,
    "purchase_order":  estimate.purchase_order,
    "subheading":  estimate.subheading,
    "footer":  estimate. footer,
    "memo":  estimate.memo,
    "discount_description": "",
    "discount_type": "",
    "discount_value": "",
    "status": 1,
    "total_amount": totalestimateamt,
    "total_amount_business": totalestimatebusamt,
    "products":  transformedProducts
} ;


  axios.post('https://wavedemo.cydeztechnologies.com/estimate',data).
  then((res)=>{
    const estimateId = res.data.id;
      if(estimateId){
        toast.success("Estimate successfully created",{toastId: 'estcrtd'})
        navigate('/estimate/view-estimate',{
          state:{ estimateId }
        })
      }
      else{
        toast.error("Estimate Creation failed",{toastId: 'estupdt2'})
        setBtnDisabled(false);
      }

  }).catch((error) => toast.error("API Error",{toastId: 'estupdt6'}));
}
}

const handleEstimateInputChange = (e) =>{

  SetEstimate({ ...estimate, [e.target.name]: e.target.value })
}


//update estimate
const updateEstimate = () =>{

  const validaion = estimateValidation();

  if(!validaion){
  setBtnDisabled(true);

  let subtotalamt =0;
  let totaltax =0;
  let totalestimateamt =0;
  let totalestimatebusamt =0;
  
  const transformedProducts = rowData && rowData
          .filter((pro) => pro.product_name !== '')
          .map((product) => {
    const {product_name,product_description,product_quantity,product_price,product_tax,product_amount} = product;
  
    subtotalamt = parseFloat(subtotalamt)+ parseFloat(product_amount);
    
    // Map the product_tax array to the required taxes format
    const taxes = product_tax.map((tax) => ({
      tax_percentage: tax.tax_rate,
      tax_description: tax.tax_name
    }));
  
    // Create the transformed product object
    const transformedProduct = {
      product_name,
      product_description,
      product_quantity: parseInt(product_quantity), 
      product_price: parseFloat(product_price), 
      taxes
    };
  
    return transformedProduct;
  });
  
  const transformedoldProducts = oldproductdetails.map((product) => {
    const {product_name,product_description,product_quantity,product_price,product_tax,product_amount} = product;
  
    subtotalamt = parseFloat(subtotalamt)+ parseFloat(product_amount);
    // Create the transformed product object
    const transformedProduct = {
      product_name,
      product_description,
      product_quantity: parseInt(product_quantity), 
      product_price: parseFloat(product_price), 
      taxes:product_tax
    };
  
    return transformedProduct;
  });


  const combinedprod = [...transformedProducts, ...transformedoldProducts];
 

    taxCalc.map((tax,index)=>{
      totaltax +=tax.taxAmount;
     
    })
    totalestimateamt = subtotalamt + totaltax;
    totalestimateamt=parseFloat(totalestimateamt.toFixed(2));
   
  
  
    selectedCurrency && parseFloat(selectedCurrency.exchange_rate) !== 0.00 ?
    totalestimatebusamt = totalestimateamt * parseFloat(selectedCurrency.exchange_rate):
    totalestimatebusamt = totalestimateamt;
  
    const data = {
      "id":estimateId,
      "business_id": business_id,
      "prefix":estimate.estimate_prefix ,
      "transaction_type": 1,
      "transaction_number": estimate.estimate_number,
      "transaction_name":estimate.estimate_name,
      "customer_id":  estimate.customer_id,
      "currency":  estimate.currency,
      "date": estimate.date,
      "expiry_date": estimate.exp_date,
      "purchase_order":  estimate.purchase_order,
      "subheading":  estimate.subheading,
      "footer":  estimate. footer,
      "memo":  estimate.memo,
      "discount_description": "",
      "discount_type": "",
      "discount_value": "",
      "status": 1,
      "total_amount": totalestimateamt,
      "total_amount_business": totalestimatebusamt,
      "products":  combinedprod
  } ;

  
    axios.put(`https://wavedemo.cydeztechnologies.com/estimate-update/${estimateId}`,data).
    then((res)=>{
      const estimateId = res.data.id;

      if(estimateId){
          toast.success("Estimate successfully updated",{toastId: 'estupdt'})
          navigate('/estimate/view-estimate',{
            state:{ estimateId }
          })
      }
      else{
        toast.error("Estimate Updates failed",{toastId: 'estupdt2'})
        setBtnDisabled(false);
      }
          
  
    }).catch((error) => toast.error(error));
  }
  }


const estimateValidation = () =>{
  const errors = {};
  if(!estimate.customer_id){
    errors["customer"] = true;
  }
 
  if(!selectedCurrency){
    errors["currency"] = true;
  }

  rowData && rowData.map((item,index)=>{
        if(item.product_name === ""){
          errors[`product_name${index}`] = true;
        }
        //after product selected then check quqntity value
       else if(item.product_name && item.product_quantity <=0 ){
          errors[`product_quantity${index}`] = true;
        }
        else if(item.product_name && item.product_price <=0) {
          errors[`product_price${index}`] = true;
        }
        // else if (item.product_tax.length === 0) {
        //   errors[`product_tax${index}`] = true;
        // }
   })
    if(rowData.length === 0 && oldproductdetails.length ===0){errors["product"] = true;}
   setErrors(errors);
   return Object.values(errors).some((value) => value === true);
  
}


  return (
    <section >
    <Sidebar/>
    {loading? <LoadingSpinner /> :
    <>
    <div className="main-body-container px-5" >
    <div className="add-estimate-container" >
        
          <div className="add-estimate-title">
            <h2 className='section-title'>{estimateId?"Edit":"Add"} an Estimate</h2>
            </div>  
            <div style={{marginTop:"45px"}}>
            <form   onSubmit={handleSubmit} >
            <div className="row mb-4">
                <div className="col-3">
                <input type="text"  id="estimate_name" 
                    className="form-control estimate-input" 
                    placeholder='Estimate name'
                    name="estimate_name"
                    onChange={(e)=>handleEstimateInputChange(e)}
                    value={estimate.estimate_name?estimate.estimate_name:''}/>
                 </div>
                <div className="col-3">
                <input type="text" name="estimate_number" 
                        className="form-control estimate-input" id="estimate_number" 
                     placeholder='Estimate number'
                     readonly
                     value={estimate.estimate_number?estimate.estimate_prefix+estimate.estimate_number:''}/>
                </div>
            </div>
            <hr />
            <div className="row d-flex  justify-content-between" >
                 <div className="col-3" >
                        <div className="col-12 mb-3 d-flex" >
                            <label className="labels col-3 me-2 text-end">Customer<span className="importent">*</span></label>
                            <Tooltip
                                title={<span>Customer is required</span>}
                                placement="topLeft"
                                color="#8B0000"
                                trigger={"contextMenu"}
                                open={errors.customer}
                                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                autoAdjustOverflow={false}
                                zIndex={9999}
                            >
                              
                               
                            <Autocomplete 
                             className= {errors.customer ? "border rounded border-danger  ms-3" : "ms-3"}
                                       
                                   disableClearable   
                                   disablePortal
                                  
                                   ListboxProps={{ style: { maxHeight: 200 } }}
                                   sx={{   display: 'flex',
                                   width:150,     
                                 }}
                                   value={customerList && customerList.find((option) =>option.id === estimate.customer_id) || ''}
                                   options={customerList}
                                   getOptionLabel={(option) =>option.customer_company_name?option.customer_company_name : ''}
                                   isOptionEqualToValue={(option, value) => option === value}
                                   onChange={handleSelect}
                                  renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={option.id}>
                                        {option.customer_company_name}
                                      </li>
                                    );
                                  }}

                                   renderInput={(params) => (
                                               <TextField {...params} size="small" placeholder='choose'    />          
                                   )}
                                   PaperComponent={({ children }) => {
                                    return (
                                      <Paper>

                                        {children}
                                        <Button
                                          style={{textTransform: 'none',color: '#2046cf',fontWeight:'500',
                                         
                                          backgroundColor:'#f6f8fe'
                                        }}
                                          color="primary"
                                          fullWidth
                                          sx={{  mx:'auto',py:'8px',mt:'2px',fontSize:12,borderRadius:0 }}
                                          onMouseDown={() => {
                                           setCustomerPopup(true)
                                           setErrors({})
                                          }}
                                        >
                                       
                                          <i className="bi bi-plus-circle me-1" ></i> Add new customer
                                        </Button>
                                      </Paper>
                                    );
                                  }}

                               />
                          
                            </Tooltip>
                        </div>
                        {selectedCustomer && selectedCustomer.first_name !== undefined && selectedCustomer.first_name !== null && (
                        <div className="col-12 mb-3 d-flex">
                            <label className="labels col-3 text-end me-2"></label>
                            <span className=' ms-3'> {selectedCustomer.first_name } 
                            {selectedCustomer && selectedCustomer.last_name !== null &&  selectedCustomer.last_name !== undefined  &&
                            ' ' +selectedCustomer.last_name} 
                            </span> 
                        </div>)}
                        <div className="col-md-12 mb-3 d-flex" >
                            <label className="labels col-3 me-2 text-end">Currency<span className="importent">*</span></label>
                            <Tooltip
                                title={<span>Currency is required</span>}
                                placement="topLeft"
                                color="#8B0000"
                                trigger={"contextMenu"}
                                open={errors.currency}
                                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                autoAdjustOverflow={false}
                                zIndex={9999}
                            >
                            <Autocomplete    
                             className= {errors.currency ? "border rounded border-danger  ms-3" : "ms-3"}
                                disableClearable
                                   disablePortal
                                   size="small"
                                   ListboxProps={{ style: { maxHeight: 200 } }}
                                   sx={{   display: 'flex',
                                   width:150,  
                                   '& input': {
                                    fontSize: 5, // Change the font size here
                                  },
                                 }}
                                
                                value={selectedCurrency || null}
                                 onChange={(event, newValue) => {
                                  setSelectedCurrency(newValue)
                                  console.log("currncy id",newValue.id)
                                  SetEstimate({...estimate,currency:newValue.id})
                                }} 
                                   options={currencyList}
                                   getOptionLabel={(option) =>`${option.code} - ${option.currency_name}`}
                                  
                                renderOption={(props, option) => {
                                    return (
                                      <li {...props} key={option.id}>
                                        {option.code} - {option.currency_name}
                                      </li>
                                    );
                                  }}

                                   renderInput={(params) => (
                                               <TextField {...params} size="small" placeholder='choose' 
                                               
                                               />
                                               
                                               
                                   )}
                                   PaperComponent={({ children }) => {
                                    return (
                                      <Paper 
                                        // style={{}}
                                      >
                                         
                                        {children}
                                        <Button
                                         style={{textTransform: 'none', display : role === 3 ? 'none' : 'block'  }}
                                          color="primary"
                                          fullWidth
                                          sx={{  mx:'auto',py:'10px',mt:'2px',fontSize:12,borderTop:1,borderRadius:0 }}
                                          onMouseDown={() => {
                                           setCurrencyPopup(true)
                                           setErrors({})
                                         
                                          }}
                                        >
                                         <i className="bi bi-plus-circle me-1" ></i> Add new currency
                                        </Button>
                                      </Paper>
                                    );
                                  }}
                               />
                               </Tooltip>
                        </div>
                        {  selectedCurrency&&selectedCurrency.code !== wv_busCur_code && 
                        <div className="col-12 mb-3 d-flex">
                            <label className="labels  col-3 text-end me-3">Exchange<br/>Rate</label>
                            <input type="text"
                             className="form-control ms-2"
                              style={{height:'38px'}}
                             name="name__phone"
                             readonly
                             value={selectedCurrency.exchange_rate}
                             maxLength={10}
                             /> 
                        </div>
                      }
                </div>
                <div className="col-5" >
                        <div className="col-12 mb-3 d-flex " >
                            <label className="labels col-3 text-end me-3">Date</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                       value={estimate?dayjs(estimate.date):null}
                                        className='col-5'
                                         format='DD/MM/YYYY'
                                         slotProps={{ textField: { size: 'small',readOnly: true, } }}
                                         onChange={(newDate) => {
                                          const updatedDate = newDate ? dayjs(newDate).toDate() : null;
                                          SetEstimate({ ...estimate, date: updatedDate });
                                        }}
                                       
                                         />
                                         
                                    </LocalizationProvider>
                          
                        </div>
                        <div className="col-12 mb-3 d-flex">
                            <label className="labels col-3 text-end me-3">Expires on</label>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        className='col-5'
                                        value={estimate?dayjs(estimate.exp_date):null}
                                      
                                        format='DD/MM/YYYY'
                                         slotProps={{ textField: { size: 'small',readOnly: true, } }}
                                         onChange={(newDate) => {
                                          SetEstimate({...estimate,exp_date:newDate})
                                        }}
                                      />
                            </LocalizationProvider>
                        </div>
                        <div className="col-12 mb-3 d-flex">
                            <label className="labels  col-3 text-end me-3">P.O./S.O.</label>
                            <input type="text"
                             className="form-control "
                            style={{height:'38px',width:'43%'}}
                             name="purchase_order"
                             value={estimate.purchase_order?estimate.purchase_order:''}
                             onChange={(e)=>handleEstimateInputChange(e)}
                             /> 
                        </div>
                </div>
                <div className="col-4" >
                        <div className="col-12 mb-3 d-flex">
                            <label className="labels col-3 text-end me-3">Subheading</label>
                            <input type="text"
                                  className="form-control "
                                  style={{height:'38px'}}
                                  name="subheading"
                                  value={estimate.subheading?estimate.subheading:''}
                                  onChange={(e)=>handleEstimateInputChange(e)}
                                  />
                                 
                          
                        </div>
                        <div className="col-12 mb-3 d-flex">
                            <label className="labels col-3 text-end me-3">Footer</label>
                            <input type="text"
                             className="form-control"
                             style={{height:'38px'}}
                             name="footer"
                             value={estimate.footer?estimate.footer:''}
                             onChange={(e)=>handleEstimateInputChange(e)}
                             />
                             
                        </div>
                        <div className="col-12 mb-3 d-flex">
                            <label className="labels col-3 text-end me-3">Memo</label>
                                 <textarea  className="form-control" cols="30" rows="3"
                                  name='memo'
                                  value={estimate.memo?estimate.memo:''}
                                  onChange={(e)=>handleEstimateInputChange(e)}
                                  />
                                
                             
                        </div>
                </div>
        </div>
        <div className='product-section my-3'>
            <table className='table'>
                <thead>
                    <tr>
                        <th>Items<span className="importent">*</span></th>
                        <th>Description</th>
                        <th>Quantity<span className="importent">*</span></th>
                        <th>Price<span className="importent">*</span></th>
                        <th>Tax</th>
                        <th className='text-end'>Amount</th>
                        <th ></th>
                    </tr>
                </thead>
                <tbody>{
rowData.length === 0 && oldproductdetails.length===0 ? 
<tr><td colSpan={7}><p className="alert alert-warning mb-0">
    You need to <span onClick={addTableRow} style={{color:'blue',textDecoration:'underline',cursor:'pointer'}}>add</span> at least one line.</p></td></tr>

: 
(<>{
  oldproductdetails.map((olditem,index) =>{
    subtotal = isNaN(olditem.product_amount) ? 0 : parseFloat(subtotal)+ parseFloat(olditem.product_amount);

    let oldtax='';
    const oldtaxlist = olditem.product_tax.map((tax)=>{
      
      oldtax += `${tax.tax_description} ${tax.tax_percentage} \n`
      
 })


    return(
      <tr key={index}>
        <td>{olditem.product_name}</td>
          <td>{olditem.product_description}</td>
          <td>{olditem.product_quantity}</td>
          <td>{parseFloat(olditem.product_price).toFixed(2)}</td>
          <td style={{width:'100px'}} >{oldtax}</td>
          <td  className='text-end'>{selectedCurrency&&selectedCurrency.symbol}
          {(olditem.product_quantity * olditem.product_price).toFixed(2)}</td>
          <td className='text-center'>
          <span onClick={()=>{deleteoldDataRow(index)}} className='estimate-trash'><i className="bi bi-trash"></i></span>
        </td>
          </tr>
    )
  })
}
{addnewline && rowData.map( (item, index) => {
 
    const {product_id,product_name,product_quantity,product_description,product_price,product_amount,product_tax}= item;
    
    
      subtotal = isNaN(product_amount) ? 0 : parseFloat(subtotal)+ parseFloat(product_amount);
       const itemname= "product_name"+index;
       const product_quant= "product_quantity"+index;
       const prd_price= "product_price"+index;
       const prd_tax= "product_tax"+index;


    return(
        <tr key={index} >
            <td style={{width:'150px'}}>
            <Tooltip
                title={<span>This field is required</span>}
                placement="topLeft"
                color="#8B0000"
                trigger={"contextMenu"}
                open={errors[itemname]}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
                zIndex={9999}
                            >
            <Autocomplete    
                 disableClearable 
                  onChange={(event, newValue) => {
                    productonChange(newValue,index)
                  }}                  
                disablePortal
                size="small"
                ListboxProps={{ style: { maxHeight: 150 } }}
                sx={{   display: 'flex',
                width:150,
                '& input':{
                  height:13,
                },
               
                
              }}
              options={productList}
              getOptionLabel={(option) =>option.product_name?option.product_name : ''}
           //   getOptionLabel={(option) =>option.product_name}
              value={productList && productList.find((option) =>option.id === product_id) || ''}
                                 
          //    value={product_id?product_id:null}     
            isOptionEqualToValue={(option, value) => option === value}         
              renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.product_name}
                    </li>
                  );
                }}
              
              
                renderInput={(params) => (
                            <TextField {...params} size="small" placeholder='choose'    />
                            
                            
                )}
                PaperComponent={({ children }) => {
                  return (
                    <Paper>

                      {children}
                      <Button
                        color="primary"
                        fullWidth
                        style={{textTransform: 'none', display : role === 3 ? 'none' : 'block'  }}
                        sx={{  mx:'auto',py:'8px',mt:'2px',fontSize:12,borderTop:1, borderColor: 'grey.500',borderRadius:0 }}
                        onMouseDown={() => {
                         setProductPopup(true)
                         setproductIndex(index)
                         setErrors({})
                        }}
                      >
                        <i className="bi bi-plus-circle me-1" ></i> Add new product
                      </Button>
                    </Paper>
                  );
                }}
            />
            </Tooltip>
</td>
        <td  style={{width:'220px'}} ><textarea  cols="30" rows="" 
                    name="product_description"  value={product_description?product_description:''}
                    className='form-control form-control-sm item-description'
                    onChange={ (e)=>{handleChange(index,e)}}
                    />
        </td>
        <td style={{width:'100px'}}>
        <Tooltip
                title={<span>Product quantity should be greater than zero</span>}
                placement="topLeft"
                color="#8B0000"
                trigger={"contextMenu"}
                open={errors[product_quant]}
                overlayStyle={{ fontSize: "12px" }}
                autoAdjustOverflow={false}
                zIndex={9999}
                            >
          <input type="number" 
                name="product_quantity" value={product_quantity?product_quantity:''}
                size={2} className='form-control form-control-sm' 
                onChange={ (e)=>{handleChange(index,e)}} />
         </Tooltip>
        </td>
        <td style={{width:'100px'}}> 
        <Tooltip
                title={<span>Product price should be greater than zero</span>}
                placement="topLeft"
                color="#8B0000"
                trigger={"contextMenu"}
                open={errors[prd_price]}
                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                autoAdjustOverflow={false}
                zIndex={9999}>
          <input type="number"   size={2}   onChange={ (e)=>{handleChange(index,e)}}
             name="product_price"  value={product_price?product_price:''}
            className='form-control form-control-sm'/>
             </Tooltip></td>
       
        <td style={{width:'150px'}}>
        <Tooltip
                title={<span>Product tax is required</span>}
                placement="topLeft"
                color="#8B0000"
                trigger={"contextMenu"}
                open={errors[prd_tax]}
                overlayStyle={{ fontSize: "12px" }}
                autoAdjustOverflow={false}
                zIndex={9999}
                            >
        <Autocomplete  
                
                 multiple  
                 filterSelectedOptions
                 disableClearable 
                 onChange={(event, newValue) => {
                    taxOnChange(newValue,index)
                  }}          
              
                disablePortal
                size="small"
                ListboxProps={{ style: { maxHeight: 150 } }}
                sx={{   display: 'flex',
                width:150,
                '& input':{
                  height:13,
                },
                
              }}
              options={taxList}
              getOptionLabel={(option) => (option && option.tax_name) ? option.tax_name : []}

              value={product_tax}
             
                             
              renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.tax_name}
                    </li>
                  );
                }}
                renderInput={(params) => (
                            <TextField {...params} size="small"     />
                            
                            
                )}
                PaperComponent={({ children }) => {
                  return (
                    <Paper>
                       
                      {children}
                      <Button
                      style={{textTransform: 'none', display : role === 3 ? 'none' : 'block'  }}
                        color="primary"
                        fullWidth
                        sx={{  mx:'auto',py:'10px',mt:'2px',fontSize:12,borderTop:1,borderRadius:0 }}
                        onMouseDown={() => {
                         setTaxPopup(true)
                         setTaxIndex(index)
                         setErrors({})
                        }}
                      >
                       <i className="bi bi-plus-circle me-1" ></i> Add new tax
                      </Button>
                    </Paper>
                  );
                }}
            />
        
        </Tooltip>
        {allTaxList.map((item) => {
          if(product_name && product_tax.length === 0 && item.status === 0){
              toast.warning(`Tax ${item.tax_name} on this product is inactive.`, {toastId:"taxinactive"})
          }
        })}
        </td>
       
        <td className='text-end'>{selectedCurrency&&selectedCurrency.symbol}{ product_amount}</td>
        <td className='text-center'>
                <span onClick={()=>{deleteTablRows(index)}} className='estimate-trash'><i className="bi bi-trash"></i></span>
        </td>
    </tr>
    )
}) }
</>
)
                    }   
                    
                </tbody>
            </table>
            <div className="estimate-product-add-footer mb-5">
                <div className="add-icon" style={{float:'left'}} >
                    <span  onClick={addTableRow} className='add-new' ><i className="bi bi-plus-circle me-1" ></i>Add a line</span>
                   { errors.product&&<p className="alert alert-danger px-3 py-1 mt-2" >An estimate must have one or more items</p>}
               </div>
                <div style={{float:'right',marginRight:'44px'}}>
                <table className="table  table-borderless">
                    <thead>
                        <tr><td>Subtotal:</td>
                        <td className='text-end'>{selectedCurrency&&selectedCurrency.symbol}{ subtotal.toFixed(2)}</td></tr>
                    </thead>
                    <tbody>
                    {
                      taxCalc.map((tax,index)=>{
                        totaltax +=tax.taxAmount;
                      return ( 
                      <tr key={index}>
                        <td>{tax.taxName}</td><td className='text-end'>{selectedCurrency&&selectedCurrency.symbol}{Number(tax.taxAmount).toFixed(2)}</td>
                      </tr>
                      )
                       
                      })
                    }

                
                    {/* <tr><td>IGST:</td><td>$0.00</td></tr>
                    <tr><td>CGST:</td><td>$0.00</td></tr> */}
                    </tbody>
                    <tfoot>
                    <tr><td >Total ({selectedCurrency&&selectedCurrency.code}):</td><td className='text-end'>{selectedCurrency&&selectedCurrency.symbol}{ (subtotal+totaltax).toFixed(2)}</td></tr>
                    {selectedCurrency&&selectedCurrency.code !== wv_busCur_code &&
                    <tr><td>Total ({wv_busCur_code})</td><td className='text-end'>{wv_busCur_symbol}{ ((subtotal+totaltax)*selectedCurrency.exchange_rate).toFixed(2)}</td></tr>
                    
                    }
                    
                    </tfoot>
                </table>
                </div>
               
            </div>
        </div>
        <div className='text-center mt-5'>
          <button className='btn btn-outline-primary wv-outline-btn me-3'
          style={{padding:'8px 25px'}}
          onClick={()=>navigate('/estimate')}
          >Cancel</button>
                    <button className='btn btn-primary add-estimate-btn' disabled={btnDisabled}
                     onClick={()=>{ estimateId !== null && estimateId !== undefined? updateEstimate():addEstimate()}}>Save</button>
        </div>   
            </form>
            </div>
    </div>
    </div>
    <AddCustomersPopup
      onUpdatecusList={updateCustomerListfromPopup}
      onUpdateselectedcus ={updateselectdcusfrompopup}
                
        displayPopup={customerPopup}
        isOpen={customerPopup}
        onClose={() => {
          setCustomerPopup(false);
        }}
      />
       <AddProductPopup
       index={productIndex}
       onUpdateproductList={updateProductListfromPopup}
        displayPopup={productPopup}
        isOpen={productPopup}
        onClose={() => {
          setProductPopup(false);
        }}
      />
       <AddTaxPopup
       taxOnChange={taxOnChange}
        index={taxIndex}
         onUpdatetaxList={updateTaxListfromPopup}
        allTaxList={allTaxList}
        displayPopup={taxPopup}
        isOpen={taxPopup}
        onClose={() => {
          setTaxPopup(false);
        }}
      />
          <AddCurrencyPopup
                onUpdateselectedcurrency={updateSelelctedCurrencyPopup}
                displayAddCurrency={currencyPopup}
                waveCurrency={currencyList}
                isOpen={currencyPopup}
                onClose={() => {
                    setCurrencyPopup(false);
                }}
            />
        
            </>}

            <Customization_Warning_Popup
        displayPopup={customizationWarning}
        isOpen={customizationWarning}
        onClose={() => {
          setCustomizationWarning(false);
        }}
        navigateBack={true}
      />
      <Business_Warning_Popup
        displayPopup={businessWarning}
        isOpen={businessWarning}
        onClose={() => {
          setBusinessWarning(false);
        }}
        navigateBack={true}
    />
    </section>
  )
}

export default Add_Estimate