import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import AddPaymentMethod from "../Payment Method/AddPaymentMethod";
import { Tooltip } from 'antd';

export default function AddPaymentAccount(props) {
    const { displayAddPaymentAccount, isOpen, onClose, uploadAccountList } = props;
    const [_isOpen, setOpen] = useState(isOpen);
    const user = localStorage.getItem("user");
    const businessID = JSON.parse(user)[0].business.id;

    const [payment, setPayment] = useState({
        paymentmethod_name: "",
        paymentaccount: "",
        description: "",
    });

    const [paymentAccount, setPaymentAccount] = useState([]);
    const [errors, setErrors] = useState({});
    const [errorsMessage, setErrorsMessage] = useState({});
    const [addPaymentMethod, setAddPaymentMethod] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);


    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
        setPayment({ paymentmethod_name: "", paymentaccount: "", description: "" });
        setErrors(false);
    };

    useEffect(() => {
        setOpen(isOpen);

        axios.get(`https://wavedemo.cydeztechnologies.com/list-payment-method-by-businessid/${businessID}`).then((response) => {
            if(response.data !== "No data found"){
                const methodName = response.data.filter((item) => item.parent_id === null);
                setPaymentAccount(methodName);
            }
            else if(response.data === "No data found"){
                setPaymentAccount([]);
            }
        }).catch((e) => {
            console.log("Error in /list-payment-method-by-businessid:", e);
            toast.error(e.message, {toastId:"error"});
        });
    }, [isOpen, !addPaymentMethod]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setPayment((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(false);
    };

    const updateSelectMethod = (newvalue) =>{
        setPayment({ ...payment, paymentmethod_name: newvalue.paymentmethod_name });
      }  

    const handleSubmit = (event) => {
        event.preventDefault();
        const parentId = paymentAccount.find((item) => {
            if (item.paymentmethod_name === payment.paymentmethod_name) {
                return (item.id)
            }
        });

        const errors = {};
        const errorMessage = {};

        for (const [key, value] of Object.entries(payment)) {
            if (key !== 'description') {
                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = 'Payment type is required'
                } else if (key === "paymentaccount" && !/^[\w\d\s-]+$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = 'Payment type is invaild'
                }
            }
        }       
        
        if (!payment.paymentmethod_name || payment.paymentmethod_name === null) {
            errors['paymentmethod_name'] = true;
            errorMessage['paymentmethod_name'] = `Payment method is required`
        }

        setErrors(errors);
        setErrorsMessage(errorMessage);
        if (Object.keys(errors).length === 0) {
            const isExisted = uploadAccountList.some((item) => {
                return (item.paymentmethod_name === payment.paymentaccount && item.parent_id ==  parentId.id);
              });
              if (isExisted) {
                toast.error("Payment type already exists!", { toastId: "addty-1" });
              }
              else{
                setIsSubmitting(true);
            axios.post(`https://wavedemo.cydeztechnologies.com/add-payment-method`, {
                business_id: businessID,
                paymentmethod_name: payment.paymentaccount,
                description: payment.description,
                parent_id: parentId.id
            })
                .then((res) => {
                    if (res.data) {
                        toast.success('Payment account created successfully', {toastId:"addty-1"});
                        setOpen(false);
                        if (onClose) onClose();
                        setPayment({
                            paymentmethod_name: "",
                            paymentaccount: "",
                            description: "",
                        });
                        setErrors(false);
                        setIsSubmitting(false);
                    }
                    else {
                        toast.error(res.data.trim(), {toastId:"addty-1"});
                    }
                })
                .catch((e) => {
                    console.log("Error in add-payment-method:", e);
                    toast.error(e.message, {toastId:"err-api"});
                });
              }
        }
        else{
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error1"});
        }
    }

    return (
        <>
            <Dialog
                open={displayAddPaymentAccount}
                onClose={() => setOpen(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                scroll={'body'}
                disablebackdropclick="true"
                disableEscapeKeyDown={true}
                style={{ height: "auto", maxHeight: "900px" }}
            >
                {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Add Payment Method</DialogTitle> */}
                <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center ">
                    Add Payment Account
                    <i
                        className="bi bi-x-lg"
                        style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                        onClick={handleClose}
                    ></i>
                </DialogTitle>
                <hr />
                <DialogContent className="px-5 mt-2" >
                    <div className="form-group row m-4">
                        <label className="col-sm-5 fw-bold text-start pe-5 text-nowrap">Payment Method <span className="importent">*</span> </label>
                        <div className="col-sm-7">
                            <Tooltip
                                title={errorsMessage.paymentmethod_name}
                                placement="bottom"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.paymentmethod_name}
                                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                autoAdjustOverflow={false}
                                zIndex={1300}
                            >
                                <Autocomplete
                                    // disablePortal
                                    sx={{
                                        '& input': {
                                            height: 0,
                                        },
                                    }}
                                    className={errors.paymentmethod_name ? "border border-2 rounded border-danger ms-2 w-100" : "border border-2 rounded border-primary ms-2 w-100"}
                                    ListboxProps={{ style: { maxHeight: 150} }}
                                    value={payment.paymentmethod_name}
                                    onChange={(event, newValue) => {
                                        setPayment({ ...payment, paymentmethod_name: newValue });
                                        // setErrors({ ...errors, paymentmethod_name: false })
                                        setErrors(false);
                                    }}
                                    options={paymentAccount.map((item) => item.paymentmethod_name)}
                                    renderInput={(params) => <TextField {...params} />}
                                    PaperComponent={({ children }) => {
                                        return (
                                            <Paper>
                                                {children}
                                                <Button
                                                    color="primary"
                                                    fullWidth
                                                    style={{ textTransform: 'none' }}
                                                    sx={{ mx: 'auto', py: '8px', mt: '2px', fontSize: 12, borderTop: 1, borderColor: 'grey.500', borderRadius: 0 }}
                                                    onMouseDown={() => {
                                                        setAddPaymentMethod(true)
                                                    }}
                                                >
                                                    <i className="bi bi-plus-circle me-1" ></i> Add New Payment Method
                                                </Button>
                                            </Paper>
                                        );
                                    }}
                                />
                                {errors.paymentmethod_name && <div className="mb-3"></div>}
                            </Tooltip>
                        </div>
                    </div>
                    <div className="form-group row m-4">
                        <label className="col-sm-5 fw-bold pe-5 text-start text-nowrap">Payment Account <span className="importent">*</span> </label>
                        <div className="col-sm-7">
                            <Tooltip
                                title={errorsMessage.paymentaccount}
                                placement="bottom"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.paymentaccount}
                                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                autoAdjustOverflow={false}
                                zIndex={1300}
                            >
                                <input
                                    type="text"
                                    className={`${errors.paymentaccount ? "ms-2 rounded border border-2 border-danger py-1 w-100" : "ms-2 rounded border border-2 border-primary py-1 w-100"}`}
                                    name='paymentaccount'
                                    value={payment.paymentaccount}
                                    onChange={handleChange}
                                />
                                {errors.paymentaccount && <div className="mb-2"></div>}
                            </Tooltip>
                        </div>
                    </div>
                    <div className="form-group row m-4">
                        <label className="col-sm-5 fw-bold text-start text-nowrap">Description</label>
                        <div className="col-sm-7">
                            {/* <input type="text" className="ms-2 rounded border border-2 border-primary py-1 w-100"/> */}
                            <textarea
                                className="ms-2 rounded border border-2 border-primary py-1 w-100"
                                name='description'
                                value={payment.description}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>
                </DialogContent>
                <hr />
                <DialogActions className='mb-4'>
                    <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
                        Cancel
                    </button>
                    <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
                        {isSubmitting ? 'Uploading...' : 'Ok'}
                    </button>
                </DialogActions>
            </Dialog>
            <AddPaymentMethod
                uploadSelectMethod={updateSelectMethod}
                uploadMethodList={paymentAccount}
                displayAddPaymentMethod={addPaymentMethod}
                isOpen={addPaymentMethod}
                onClose={() => {
                    setAddPaymentMethod(false);
                }}
            />
        </>
    )
}




// <select
//                             className="ms-2 rounded border border-2 border-primary py-1 w-100"
//                             name='paymentmethod_name'
//                             value={payment.paymentmethod_name}
//                             onChange={handleChange}
//                         >
//                             <option value="">--Select payment method--</option>
//                             {paymentAccount.map(item => (
//                                 <option key={item.id} value={item.paymentmethod_name}>
//                                     {item.paymentmethod_name}
//                                 </option>
//                             ))}
//                         </select> 