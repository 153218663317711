import { useState, useEffect } from "react"
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

export default function UserDetails(props) {
    const { displayDetails, isOpen, onClose } = props;
    const [_isOpen, setOpen] = useState(isOpen);

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
    };

    useEffect(() => {
        setOpen(isOpen);
    }, [isOpen]);


    const handleSubmit = () => {
        setOpen(false);
        if (onClose) onClose();
    }

    return (
        <Dialog
            open={displayDetails}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll={'body'}
            disablebackdropclick="true"
            disableEscapeKeyDown={true}
            style={{ height: "auto", maxHeight: "900px" }}
        >
            {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Edit</DialogTitle> */}
            <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center ">
                Users Details
                <i
                    className="bi bi-x-lg"
                    style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                    onClick={handleClose}
                ></i>
            </DialogTitle>
            <hr />
            <DialogContent className="px-5 mt-2">
                <>
                    <div className="">
                        Wave account owners can invite trusted individuals to collaborate on their account as a Editor or Admin.
                    </div>
                    <div className="my-2">
                        To understand the level of access for each user type, and any exceptions that apply, see the details below.
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">User type</th>
                                <th scope="col">Access</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">Admin</th>
                                <td>All access</td>
                            </tr>
                            <tr>
                                <th scope="row">Editor</th>
                                <td>
                                    <ul>
                                        <li>DashBoard</li>
                                        <li>Estimate</li>
                                        <li>Invoice</li>
                                        <li>Transaction</li>
                                        <li>Expenses</li>
                                    </ul>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </>
            </DialogContent>
            <hr />
            <DialogActions className='mb-4'>
                <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
                    Cancel
                </button>
                <button onClick={handleSubmit} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
                    Ok
                </button>
            </DialogActions>
        </Dialog>
    );
}