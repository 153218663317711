import React from "react";
import ErrorIcon from '../../assets/error-icon.svg'
import WaveIogo from '../../assets/wave-logo.svg'
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';

function ErrorPage() {
    const navigate = useNavigate();

    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault(); 
            const user = localStorage.getItem("user");
            if (user !== null) {
                localStorage.removeItem("user");
                navigate(-1)
            }
            window.location.href = '/';
        };

        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, []);

    const errorPageButton = {
        listStyleType: "none",
        fontWeight: "bold",
        color: "#2046cf",
        fontSize: "16px"
    }

    const errorPageTitle = {
        fontSize: "33px",
        fontWeight: "700",
        color: "#580d4f",
        fontFamily: "cursive",
        paddingBottom: "10px"
    }

    return (
        <section>
            <div className="container">
                <div className="row">
                    <div className="my-5 d-flex align-items-center justify-content-center">
                        <img src={WaveIogo} className="img-fluid" alt="" />
                    </div>
                    <div className="col h-100 d-flex align-items-center justify-content-center">
                        <div className="error-container text-center ">
                            <img src={ErrorIcon} className="img-fluid" alt="" />
                            <p className="text-secondary fw-bold">Error code: 404</p>
                            <h3 style={errorPageTitle}>The page you tried to visit doesn't exist.</h3>
                            <h4>This is usually caused by an incorrect or outdated link.</h4>
                            <p className="my-4">Use your browser's back button and try again, or try one of the following:</p>
                            <ul className="" style={errorPageButton}>
                                <li className="errorLink" onClick={() => navigate("/user-register")}>Sign up for a free account</li>
                                <li className="errorLink" onClick={() => navigate("/")}>Sign in</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )
}
export default ErrorPage;