import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Tooltip } from 'antd';


export default function EditPaymentAccount(props) {
    const { displayEditPaymentAccount, PaymentId, parentId, isOpen, onClose, uploadAccountList } = props;
    const [_isOpen, setOpen] = useState(isOpen);
    const user = localStorage.getItem("user");
    const businessID = JSON.parse(user)[0].business.id;
    const [methodList, setMethodList] = useState([]);
    const [editpayment, setEditPayment] = useState({
        id: "",
        parent_id: "",
        paymentmethod_name: "",
        payment_name: "",
        description: "",
        business_id: ""
    });
    const [errors, setErrors] = useState({});
    const [errorsMessage, setErrorsMessage] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
        setErrors(false);
    };

    useEffect(() => {
        setOpen(isOpen);
        fetchlistmethod();
        fetchSelectedAccount();
    }, [isOpen]);


    const fetchlistmethod = () => {
        axios.get(`https://wavedemo.cydeztechnologies.com/list-payment-method-by-businessid/${businessID}`).then((response) => {
            if (response.data !== "No data found") {
                const methodName = response.data.filter((item) => item.parent_id === null);
                setMethodList(methodName);
            }
            else if (response.data === "No data found") {
                setMethodList([]);
            }
        }).catch((e) => {
            toast.error(e.message);
        });
    };

    const fetchSelectedAccount = () => {
        if (PaymentId !== undefined && parentId !== undefined) {
            axios.get(`https://wavedemo.cydeztechnologies.com/list-payment-method-by-id/${PaymentId}`).then((res) => {
                if (res.data !== "No data found") {
                    setEditPayment({
                        id: res.data[0]['id'],
                        parent_id: res.data[0]['parent_id'],
                        paymentmethod_name: res.data[0]['paymentmethod_name'],
                        payment_name: parentId,
                        description: res.data[0]['description'],
                    });
                }
                else if (res.data === "No data found") {
                    setEditPayment({ ...editpayment });
                }
            }).catch((e) => {
                toast.error(e.message, { toastId: "error" });
            });
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditPayment((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(false);
    };

    const findIdByName = (name) => {
        const foundObject = methodList.find((obj) => obj.paymentmethod_name === name);
        if (foundObject) {
            return foundObject.id;
        }
        return "";
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        const errorMessage = {};
        let errors = {};
        if (!editpayment.paymentmethod_name || editpayment.paymentmethod_name.trim() === "") {
            errors['paymentmethod_name'] = true;
            errorMessage['paymentmethod_name'] = 'Payment type is required'
        }
        if (!editpayment.payment_name || editpayment.payment_name.trim() === "") {
            errors['payment_name'] = true;
        }

        if (editpayment.paymentmethod_name && !/^[\w\d\s-]+$/.test(editpayment.paymentmethod_name)) {
            errors['paymentmethod_name'] = true;
            errorMessage['paymentmethod_name'] = 'Payment type is invaild'
        }

        setErrors(errors)
        setErrorsMessage(errorMessage);
        if (Object.keys(errors).length === 0) {
            const isExisted = uploadAccountList.some((item) => {
                return (item.id != editpayment.id && item.paymentmethod_name === editpayment.paymentmethod_name && item.parent_id == findIdByName(editpayment.payment_name));
            });
            if (isExisted) {
                toast.error("Payment type already exists!", { toastId: "editpy-1" });
            }
            else {
                setIsSubmitting(true);
                axios.put(`https://wavedemo.cydeztechnologies.com/update-payment-method/${PaymentId}`, {
                    business_id: businessID,
                    paymentmethod_name: editpayment.paymentmethod_name,
                    description: editpayment.description,
                    parent_id: findIdByName(editpayment.payment_name)
                })
                    .then((res) => {
                        if (res.data) {
                            toast.success('Payment account updated successfully', { toastId: "editpy-1" });
                            setOpen(false);
                            if (onClose) onClose();
                            setErrors(false);
                            setIsSubmitting(false);
                        }
                        else {
                            toast.error(res.data.trim(), { toastId: "editpy-1" });
                        }
                    })
                    .catch((e) => {
                        console.log("Error in update-payment-method:", e);
                        toast.error(e.message, { toastId: "err-api" });
                    });
            }
        }
        else {
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error1"});
        }
    }

    return (
        <Dialog
            open={displayEditPaymentAccount}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll={'body'}
            disablebackdropclick="true"
            disableEscapeKeyDown={true}
            style={{ height: "auto", maxHeight: "900px" }}
        >
            {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Edit Payment Method</DialogTitle> */}
            <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center ">
                Edit Payment Account
                <i
                    className="bi bi-x-lg"
                    style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                    onClick={handleClose}
                ></i>
            </DialogTitle>
            <hr />
            <DialogContent className="px-5 mt-2" >
                <div className="form-group row m-4">
                    <label className="col-sm-5 fw-bold text-start pe-5 text-nowrap">Payment Method <span className="importent">*</span> </label>
                    <div className="col-sm-7">
                        <Tooltip
                            title={<span>Payment method is invaild</span>}
                            placement="bottom"
                            color="#990000"
                            trigger={"contextMenu"}
                            open={errors.payment_name}
                            overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                            autoAdjustOverflow={false}
                            zIndex={1300}
                        >
                            <Autocomplete
                                // disablePortal
                                sx={{
                                    '& input': {
                                        height: 0,
                                    },
                                }}
                                className={`${errors.payment_name ? "border border-2 rounded border-danger ms-2 w-100" : "border border-2 rounded border-primary ms-2 w-100"}`}
                                ListboxProps={{ style: { maxHeight: 150 } }}
                                value={editpayment.payment_name}
                                onChange={(event, newValue) => {
                                    setEditPayment((prevState) => ({
                                        ...prevState,
                                        ['payment_name']: newValue,
                                    }));
                                    setErrors({ ...errors, payment_name: false })
                                }}
                                options={methodList.map((item) => item.paymentmethod_name)}
                                renderInput={(params) => <TextField {...params} />}
                            />
                            {errors.payment_name && <div className="mb-3"></div>}
                        </Tooltip>
                    </div>
                </div>
                <div className="form-group row m-4">
                    <label className="col-sm-5 pe-5 fw-bold test-wrap text-nowrap">Payment Account <span className="importent">*</span> </label>
                    <div className="col-sm-7">
                        <Tooltip
                            title={errorsMessage.paymentmethod_name}
                            placement="bottom"
                            color="#990000"
                            trigger={"contextMenu"}
                            open={errors.paymentmethod_name}
                            overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                            autoAdjustOverflow={false}
                            zIndex={1300}
                        >
                            <input
                                type="text"
                                className={`${errors.paymentmethod_name ? "ms-2 rounded border border-2 border-danger py-1 w-100" : "ms-2 rounded border border-2 border-primary py-1 w-100"}`}
                                name='paymentmethod_name'
                                value={editpayment.paymentmethod_name}
                                onChange={handleChange}
                            />
                            {errors.paymentmethod_name && <div className="mb-2"></div>}
                        </Tooltip>
                    </div>
                </div>
                <div className="form-group row m-4">
                    <label className="col-sm-5 fw-bold text-start text-nowrap">Description</label>
                    <div className="col-sm-7">
                        {/* <input type="text" className="ms-2 rounded border border-2 border-primary py-1 w-100"/> */}
                        <textarea
                            className="ms-2 rounded border border-2 border-primary py-1 w-100"
                            name='description'
                            value={editpayment.description}
                            onChange={handleChange}
                        ></textarea>
                    </div>
                </div>
            </DialogContent>
            <hr />
            <DialogActions className='mb-4'>
                <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
                    Cancel
                </button>
                <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
                    {isSubmitting ? 'Uploading...' : 'Ok'}
                </button>
            </DialogActions>
        </Dialog>
    )
}