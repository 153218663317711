import { useNavigate } from "react-router-dom";
import Sidebar from "../../Sidebar/Sidebar";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
// import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import LoadingSpinner from '../../Utils/Loader'

// import { Dropdown } from 'react-bootstrap';
import { Menu, Dropdown, Button } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
// import { FaBars } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen } from '@fortawesome/free-solid-svg-icons'


export default function Customers() {
    const navigate = useNavigate();
    const addCustomer = () => {
        navigate("/add-customers");
    }
    const user = localStorage.getItem("user");
    const businessID = user && JSON.parse(user)[0].business.id;
    const businessCurrency = user && JSON.parse(user)[0].business_currency === null ? null : JSON.parse(user)[0].business_currency.toString().split(" - ")[2];
    const [customerData, setCustomerData] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    
    function fetchData() {
        axios.get(`https://wavedemo.cydeztechnologies.com/list-customers/${businessID}`)
          .then((res) => {
            if (res.data === "No data found") {
              setCustomerData([]);
              setLoading(true);
            } else {
              const customerData = res.data;
              axios.get(`https://wavedemo.cydeztechnologies.com/invoice/${businessID}`)
                .then((res) => {
                  if (res.data === "No data found") {
                    setCustomerData(customerData);
                    setLoading(true);
                  } else {
                    const invoiceData = res.data;
                    const invoiceMap = new Map();
                    invoiceData.forEach(item => {
                      const customerID = item.customer_id.id;
                      if (invoiceMap.has(customerID)) {
                        invoiceMap.get(customerID).push(item);
                      } else {
                        invoiceMap.set(customerID, [item]);
                      }
                    });
                    const updatedCustomerData = customerData.map(customer => {
                      const customerInvoices = invoiceMap.get(customer.id);
                      let totalDueAmountInBusinessCurrency = 0;
                      if (customerInvoices) {
                        customerInvoices.forEach(invoice => {
                          if (Array.isArray(invoice.invoices)) {
                            invoice.invoices.forEach(invoiceItem => {
                              const dueAmount = Number(invoiceItem.due_amount);
                              const exchangeRate = Number(invoiceItem.currency.exchange_rate);
                              totalDueAmountInBusinessCurrency += dueAmount * exchangeRate;
                            });
                          } else {
                            const dueAmount = Number(invoice.due_amount);
                            const exchangeRate = Number(invoice.currency.exchange_rate);
                            totalDueAmountInBusinessCurrency += dueAmount * exchangeRate;
                          }
                        });
                      }
                      return {
                        ...customer,
                        totalDueAmountInBusinessCurrency: totalDueAmountInBusinessCurrency.toFixed(2)
                      };
                    });
                    console.log("updatedCustomerData: ", updatedCustomerData);
                    setCustomerData(updatedCustomerData);
                    setLoading(true);
                  }
                })
                .catch((e) => {
                  toast.error(e.message, { toastId: "err-listest" });
                });
            }
          })
          .catch((e) => {
            console.log("Error in /list-customers:", e.message);
            toast.error(e.message, { toastId: "err-api" });
          });
      }
      
      
      
    useEffect(() => {
        if(user === null){
          localStorage.removeItem("user");
          navigate('/error-page');
          }
        }, []);
    
        console.log("customerData: ",customerData)

    useEffect(() => {
        // axios.get(`https://wavedemo.cydeztechnologies.com/list-customers/${businessID}`).then((res) => {
        //     if(res.data === "No data found"){
        //         setCustomerData([])
        //         setLoading(true)
        //     }
        //     else{
        //         setCustomerData(res.data)
        //         setLoading(true)
        //     }
        // }).catch((e) => {
        //     console.log("Error in /list-customers:", e.message)
        //     toast.error(e.message, {toastId:"err-api"})
        // })
        fetchData();
    }, [])

    // const filteredData = customerData && customerData.filter(item => {
    //     // return (item.first_name !== null ? item.first_name: item.customer_company_name).toLowerCase().includes(searchQuery.toLowerCase()); //|| item.email.toLowerCase().includes(searchQuery.toLowerCase())
    //   });

    

    const filteredData = customerData && customerData !== "No data available" && customerData.filter(item => {
        return(String(item.first_name).toLowerCase().includes(searchQuery.toLowerCase()) || String(item.customer_company_name).toLowerCase().includes(searchQuery.toLowerCase())) || String(item.email).toLowerCase().includes(searchQuery.toLowerCase());
    });

    function handleView(data) {
        const customerDetails = data
        navigate("/view-customers", {
            state: { customerDetails }
        })
    }

    function handleEdit(data) {
        const customerDetails = data
        navigate("/edit-customers", {
            state: { customerDetails }
        })
    }

    const customStyles = {
        headRow: {
            style: {
                color: '#000',
                fontWeight: "bolder",
                fontSize: "16px",
                borderBottom: "1px solid black"
            },
        },
        rows: {
            style: {
                color: "#000",
                backgroundColor: "#fff",
                fontSize: "16px",
                minHeight: '55px'
            }
        },

    }

    const columns = [
        {
            name: 'Name',
            selector: row => row.first_name,
            sortable: true,
            grow: 1,
        },
        {
            name: 'Email',
            selector: row => row.email,
            grow: 2,
        },
        {
            name: 'Phone',
            selector: row => row.phone,
        },
        // {
        //     name: 'Saved',
        //     selector: row => row.saved,
        // },
        {
            name: 'Amount due',
            selector: row => row.balance,
            width: "150px",
            right: true,
        },
        {
            name: 'Action',
            selector: row => row.action,
            grow: 1,
            center: true
        },
    ];

    const rows = filteredData ? filteredData.map((item) => {
        return {
            //  (item.first_name && item.first_name.length > 0 ? item.first_name : item.customer_company_name),  first_name: (item.first_name !== null ? item.first_name : item.customer_company_name) || (item.first_name !== "" ? item.first_name : item.customer_company_name),
            first_name: item.customer_company_name, 
            email: item.email,
            phone: item.phone,
            // saved: null,
            balance: item.totalDueAmountInBusinessCurrency && item.totalDueAmountInBusinessCurrency > 0 ? `${businessCurrency}${item.totalDueAmountInBusinessCurrency}` : "-", //`${businessCurrency}${Number(item.due_amount)*Number(item.currency.exchange_rate)}`
            action: <button className="btn btn-none" onClick={() => handleEdit(item)}><FontAwesomeIcon icon={faPen} style={{ color: "#2046cf", fontSize: "18px" }} /></button>,
        }
    }) : '';
    // style={{ width: "83%", float: "right", margin: "0 auto" }}
    return (
        <section>
            <Sidebar />
            {loading ?
                <div className="container main-coloum1"> 
                    <div className="row container">
                        <div className="col-6 mt-5 fw-bold fs-3 text-capitalize">
                            Customers
                        </div>
                        <div className="col-6 form-group mt-5 text-end">
                            <button type="button" onClick={addCustomer} className="btn btn-primary rounded-5">Add a Customer</button>
                        </div>
                    </div>
                    <div className="row container mt-5">
                        <div className="col-sm-3">
                            <input type="text" className="form-control rounded border-primary" placeholder="Search by name" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} />
                        </div>
                        {searchQuery && searchQuery.length > 0 &&
                            <div className="col pt-1">
                                {filteredData.length} customer found
                            </div>}
                    </div>
                    <div className="container mt-4">
                        <DataTable
                            columns={columns}
                            data={rows}
                            fixedHeaderScrollHeight="600px"
                            customStyles={customStyles}
                            persistTableHead
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 25, 50]}
                            highlightOnHover
                        />
                    </div>
                </div>
                :
                <LoadingSpinner />}
        </section>
    )
}