import { useNavigate, useLocation, Link } from "react-router-dom";
import './Customer.css'
import { useState, useEffect, useRef } from "react";
import Sidebar from "../../Sidebar/Sidebar";
import axios from "axios";
import { toast } from 'react-toastify';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import DataTable from 'react-data-table-component';


export default function ViewCustomers() {
    const navigate = useNavigate();
    const user = localStorage.getItem("user");
    const businessID = user && JSON.parse(user)[0].business.id;
    const location = useLocation();
    const customerDetails = location.state.customerDetails;
    const customer_id = customerDetails.id;
    const [show, setShow] = useState(false);
    const target = useRef(null);

    const [invoiceData, setInvoiceData] = useState([
        {
            status: 'overdue',
            number: '111',
            invoice_date: '2023-06-10',
            total: 1000,
            balance: 0,
        },
        {
            status: 'paid',
            number: '112',
            invoice_date: '2023-06-11',
            total: 2000,
            balance: 0,
        },
        {
            status: 'unpaid',
            number: '113',
            invoice_date: '2023-06-12',
            total: 3000,
            balance: 0,
        }
    ]);

    useEffect(() => {
        if(user === null){
          localStorage.removeItem("user");
          navigate('/error-page');
          }
        else if(!location.state){
            navigate(-1);
        }
        }, []);


        const customStyles = {
            headRow: {
                style: {
                    color: '#000',
                    fontWeight: "bolder",
                    fontSize: "16px",
                    borderBottom: "1px solid black"
                },
            },
            rows: {
                style: {
                    color: "#000",
                    backgroundColor: "#fff",
                    fontSize: "16px",
                    minHeight: '55px'
                }
            },
    
        }
    
        const columns = [
            {
                name: 'Status',
                selector: row => row.status,
                sortable: true,
            },
            {
                name: 'Number',
                selector: row => row.number,
            },
            {
                name: 'Invoice Date',
                selector: row => row.invoice_date,
            },
            {
                name: 'Total',
                selector: row => row.total,
            },
            {
                name: 'Balance',
                selector: row => row.balance,
                width: "150px",
                right: true,
            },
            // {
            //     name: '',
            //     selector: row => row.action,
            //     width: "100px",
            //     cell: (row) => (
            //         <Dropdown className={`${filteredData.length === 1 ? "py-5" : ""}`}>
            //             <Dropdown.Toggle variant="primary" className='action-icon-btn'>
            //             </Dropdown.Toggle>
            //             <Dropdown.Menu>
            //                 <Dropdown.Item onClick={() => handleView(row.action)}>View</Dropdown.Item>
            //                 <Dropdown.Item onClick={() => handleEdit(row.action)}>Edit</Dropdown.Item>
            //             </Dropdown.Menu>
            //         </Dropdown>
            //     ),
            // },
        ];
    
        const rows = invoiceData ? invoiceData.map((item) => {
            return {
                status: item.status,
                number: item.number,
                invoice_date: item.invoice_date,
                total: item.total,
                balance: 0,
                // action: item,
            }
        }) : '';

    return (
        <section>
            <Sidebar />
            <div className="container p-5" style={{ width: "83%", float: "right" }}>
                <div className="row">
                        <div className="col-6 mt-2 fw-bold fs-3 text-capitalize">
                            {customerDetails.first_name? customerDetails.first_name : customerDetails.customer_company_name}
                        </div>
                        <div className="col-6 form-group mt-2 text-end d-block">
                            <button type="button" className="btn btn-white rounded-5 border border-primary text-primary fw-bold fs-6 px-3 py-1">Edit customer</button>
                        </div>
                    </div>
                    <hr />
                    <div>
                        <p className="mt-2 fw-bold fs-4 text-capitalize">Overview</p>
                        <div className="view_customer">
                            <div className="row text-center">
                                <div className="col-4 fw-bold">
                                    <p style={{color:"#68757d"}}>Paid last 12 months</p>
                                    {`${1000}`}
                                </div>
                                <div className="col-4 fw-bold">
                                    <p style={{color:"#68757d"}}>Total unpaid</p>
                                    {`${1000}`}
                                </div>
                                <div className="col-4 fw-bold">
                                    <p style={{color:"#68757d"}}>Last item sent</p>
                                    {/* {`Invoice on date`} */}
                                    <div>
                                    <Link ref={target} onClick={() => setShow(!show)} onMouseEnter={() => setShow(true)} onMouseLeave={() => setShow(false)} className="text-decoration-none fw-bold">What's this?</Link>
                                    <Overlay target={target.current} show={show} placement="bottom">
                                        {(props) => (
                                            <Tooltip id="overlay-example" {...props}>
                                                <p className="mt-2 px-4 fw-bold">View recent customer communication</p>
                                                <p>See the last item (invoice, reminder, or statement) sent to your customer.</p>
                                            </Tooltip>
                                        )}
                                    </Overlay>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="view_customer mt-4" style={{backgroundColor:"#fffbfb"}}>
                            <p className="mt-2 fw-bold fs-5 text-capitalize">Unpaid invoices</p>
                            <table className="table mx-5 fw-bold">
                                <tr className="mx-5">
                                    <td>overdue</td>
                                    <td>Invoice 8</td>
                                    <td>Due 45 days ago</td>
                                    <td>600</td>
                                    <td>icon</td>
                                </tr>
                                <tr className="mx-5">
                                    <td>overdue</td>
                                    <td>Invoice 8</td>
                                    <td>Due 45 days ago</td>
                                    <td>600</td>
                                    <td>icon</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div>
                        <p className="mt-2 fw-bold fs-4 text-capitalize">Invoice</p>
                        <div className="view_customer">
                            <div className="row text-center">
                                <div className="col-3 fw-bold">
                                    <p style={{color:"#68757d"}}>Total unpaid</p>
                                    {`${1000}`}
                                </div>
                                <div className="col-3 fw-bold">
                                    <p style={{color:"#68757d"}}>Overdue</p>
                                    {`${1000}`}
                                </div>
                                <div className="col-3 fw-bold">
                                    <p style={{color:"#68757d"}}>Not yet due</p>
                                    {`${1000}`}
                                </div>
                                <div className="col-3 fw-bold">
                                    <p style={{color:"#68757d"}}>Avg. time to pay</p>
                                    {`${0} days`}
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 view_customer" style={{backgroundColor:"#fffbfb", width:"80%", margin: "auto"}}>
                        <DataTable
                            columns={columns}
                            data={rows}
                            fixedHeaderScrollHeight="600px"
                            customStyles={customStyles}
                            persistTableHead
                            pagination
                            paginationPerPage={10}
                            paginationRowsPerPageOptions={[10, 25, 50]}
                        />
                        </div>
                    </div>
            </div>
        </section>
    )
}