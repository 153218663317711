import React, { useState,useEffect } from 'react'
import Sidebar from '../../Sidebar/Sidebar';
import { useLocation,useNavigate } from "react-router-dom";
import './Estimate.css'
import axios from 'axios';
import { toast } from 'react-toastify';
import Estimate_Preview from './Estimate_Preview';
import { format } from 'date-fns';
import Conversion_Popup from './Conversion_Popup';
import SendReceipt from '../Invoices/Send_Invoice'; 
import dayjs from 'dayjs';
import Customization_Warning_Popup from './Customization_Warning_Popup';
import Business_Warning_Popup from './Business_Warning _Popup';

function View_Estimate() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = localStorage.getItem("user");
  const business_id = user&&JSON.parse(user)[0].business.id;
  const [conversionPopup,setConversionPopup] = useState(false);
  const business_name = user&&JSON.parse(user)[0].business.business_name;
  const userName = user && JSON.parse(user)[0].first_name;
  const estimateId = location.state && location.state.estimateId;
  const [estimateDetails,setEstimateDetails]=useState();
  const [pdfDownloadUrl, setPdfDownloadUrl] = useState('');
  
  const [isUpdated, setIsUpdated] = useState(false);
  const [sendEstimate, setSendEstimate] = useState(false);
  const currentDate = dayjs(); // Get current date using dayjs
  // const [sendEmailFlag,setSendEmailFlag] = useState(false);

  const [customizationWarning, setCustomizationWarning] = useState(false);
const [businessdata, setBusinessdata] = useState({});
const [businessWarning,setBusinessWarning] = useState(false);


 //mail configure
  const transactionName = estimateDetails && estimateDetails.transaction_name;
  const transactionNumber = estimateDetails && estimateDetails.transaction_number;
  const totalAmount = estimateDetails && estimateDetails.total_amount;
  const currency = estimateDetails && estimateDetails.currency.code;
  const expiryDate = estimateDetails && format(new Date(estimateDetails.expiry_date), "MMMM d, yyyy");
  
 
  const subject = `${transactionName} #${transactionNumber} from ${business_name}`;
  const body = `Below please find a link to ${transactionName} #${transactionNumber}.\n\nAmount due: ${totalAmount} ${currency}\n\nExpires on: ${expiryDate}\n\nTo view this estimate online, please visit: ${pdfDownloadUrl}`;

  const bodyy = `Hey ${estimateDetails && estimateDetails.customer_id.id && estimateDetails.customer_id.customer_company_name},\n\nHope you're doing well! Just wanted to drop you a quick note with the details of ${transactionName} #${transactionNumber}.\n\nAmount due: ${totalAmount} ${currency}\n\nExpires on: ${expiryDate}\n\nTo view this estimate online, simply click on the link below:\n\n${pdfDownloadUrl}\n\nIf you have any questions or need further assistance, feel free to reach out. We're always here to help!\n\nThanks,\n\n${userName}`

  const gmailUrl = `https://mail.google.com/mail/?view=cm&tf=0&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(bodyy)}`;

 //mail configure : end

  useEffect(()=>{
    axios.get(`https://wavedemo.cydeztechnologies.com/estimate/${business_id}/${estimateId}`)
    .then((res)=>{
      setEstimateDetails(res.data[0]);
     
    }).catch((error)=>{toast.error(error , {toastId:"error"})});


    //estimate id encryption  
   
    const encryptionConstant = 5;
    const encryptedParts = [];
    const encryptnPart = `${estimateId}`;
    for (let i = 0; i < encryptnPart.length; i++) {
      const charCode = encryptnPart.charCodeAt(i);
      
      const encryptedCharCode = charCode * encryptionConstant;
      encryptedParts.push(encryptedCharCode);
    }
    const encryptedString = encryptedParts.join('-') + "-xyc";
    const url = `https://wavedemo.cydeztechnologies.com/estimatedownload/${business_id}/${encryptedString}`;
    setPdfDownloadUrl(url);
  },[isUpdated])

  const sendconfirm = (sendconfirm) => {
    if(sendconfirm && sendconfirm === true && estimateDetails.status === "Saved"){
      axios.put(`https://wavedemo.cydeztechnologies.com/update-status/${estimateDetails.id}`, { status: 2 })
      .then(() => {
        setIsUpdated((prevIsUpdated) => !prevIsUpdated);
      })
      .catch((error) => {
        toast.error(error.message, { toastId: "err-api" });
      });
    }
  }

  const business_data = () => {
    axios.get(`https://wavedemo.cydeztechnologies.com/list-business`)
    .then((res)=>{
      const responseData = res.data; 
      const filteredData = responseData && responseData.filter(item => item.id === business_id);
      setBusinessdata(filteredData[0]);
    }).catch(error =>{
      toast.error(error.message,{toastId: 'addest5',})  ;
  });
  }


useEffect(()=>{
  const user = localStorage.getItem("user");
  if(user === null){
      localStorage.removeItem("user");
      navigate('/user-login');
  }
  else{
      business_data();
      axios.get(`https://wavedemo.cydeztechnologies.com/get-estimate-customization-details/${business_id}`)
      .then((res) => {

        if (parseInt(res.data.estimate_number) == null || res.data.estimate_default_title == null) {
          setCustomizationWarning(true)
        }
        else if(businessdata && businessdata.business_address === null){
          setBusinessWarning(true);
        }
      }).catch(error => {
        toast.error(`Estimate Details API Error1 ${error}`, { toastId: 'addest5', });
      });
  }
},[])

 console.log('pdfDownloadUrl: ,,', estimateDetails);
  return (
    <section >
    <Sidebar/>
    <div className="main-body-container px-5">
    <div className="estimatelist-container">
    <div className="estimatelist-header d-flex justify-content-between align-items-center">
          <div className="estimatelist-title">
            <h2 className='estimate-section-title'>
              {estimateDetails&& estimateDetails.transaction_name} #{estimateDetails&&estimateDetails.transaction_number}</h2>
            </div>
            <div className="estimate-list-add-action" style={{marginTop:'-8px'}}>
             {/* Saved */}
             {currentDate > estimateDetails && dayjs(estimateDetails.expiry_date) ? <span className='status-expired fs-6'>Expired</span> : estimateDetails && estimateDetails.status == 'Saved' ? <span className='status-saved fs-6'>Saved</span> : <span className='status-sent fs-6'>Send</span>}
            </div>    
        </div>
        <div className='action-section d-flex align-items-center justify-content-between my-4'>
          <div className="left">
          <button className='btn btn-outline-primary wv-outline-btn me-3 px-4' onClick={()=>navigate('/estimate/add-estimate',{state:{estimateId}})}>Edit</button>
        <button className='btn btn-outline-primary wv-outline-btn px-4' 
          onClick={() => setConversionPopup(true)}>Convert to invoice</button>
          </div>

          <div className="right">
      
        <button className='btn btn-outline-primary wv-outline-btn  me-3 px-4'
          onClick={() => setSendEstimate(true)}>Send</button>
         <a className='btn btn-outline-primary wv-outline-btn  me-3 px-4'
         href={pdfDownloadUrl}   target="_self" >Export to PDF</a>
       
        </div>
        </div>
       {estimateDetails && <Estimate_Preview  estimate={estimateDetails}/>}
      </div>
    </div>
    <Conversion_Popup
      
      estimateDetails={estimateDetails}
        displayPopup={conversionPopup}
        isOpen={conversionPopup}
        onClose={() => {
          setConversionPopup(false);
        }}
      />
    <SendReceipt 
        opensend={sendEstimate}
        sendconfirm={sendconfirm}
        invoicedata={estimateDetails}
        isOpen={sendEstimate}
        onClose={() => {
          setSendEstimate(false);
        }}
        estimateFlag={sendEstimate}
        estimatePDF={pdfDownloadUrl}
      />

    <Customization_Warning_Popup
        displayPopup={customizationWarning}
        isOpen={customizationWarning}
        onClose={() => {
          setCustomizationWarning(false);
        }}
        navigateBack={true}
      />
      <Business_Warning_Popup
        displayPopup={businessWarning}
        isOpen={businessWarning}
        onClose={() => {
          setBusinessWarning(false);
        }}
        navigateBack={true}
    />
    </section>
  )
}

export default View_Estimate