import { TailSpin } from "react-loader-spinner";

const spinnerStyle = {
  position: "absolute",
  top: "0",
  width: "100%",
  height: "100%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

function LoadingSpinner() {
  return (
    <TailSpin
      height="80"
      width="80"
      color="blue"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={spinnerStyle}
      wrapperClass="spinner-container"
      visible={true}
    />
  );
}

export default LoadingSpinner;