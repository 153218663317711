import { useState, useEffect } from "react";
import { toast } from 'react-toastify';
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import County_Currency from "../../../Utils/County_Currency.json";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Tooltip } from 'antd';

export default function EditCurrency(props) {
    const { displayEditCurrency, CurrencyDetails, isOpen, onClose, uploadCurrenyList } = props;
    const [_isOpen, setOpen] = useState(isOpen);
    const user = localStorage.getItem("user");
    const businessID = JSON.parse(user)[0].business.id;
    const businessCurrency = JSON.parse(user)[0].business_currency.toString().split(" - ")[1];

    const [editCurrency, setEditCurrency] = useState({
        id: "",
        business_id: "",
        currency_name: "",
        symbol: "",
        exchange_rate: "",
        code: ""
    });
    const [errors, setErrors] = useState('');
    const [errorsMessage, setErrorsMessage] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [currencyList, setCurrencyList] = useState('');
    const allcurrency = County_Currency.filter((item1) => !uploadCurrenyList.some((item2) => item2.currency_name === item1.name));
    const extraObject = { cc: CurrencyDetails && CurrencyDetails.code, symbol: CurrencyDetails && CurrencyDetails.symbol, name: CurrencyDetails && CurrencyDetails.currency_name };
    const updatedAllCurrency = allcurrency.concat(extraObject);
    const currency = updatedAllCurrency.map((item) => (`${item.cc} - ${item.name}`));

    const handleClose = () => {
        setOpen(false);
        if (onClose) onClose();
        setErrors(false);
    };

    useEffect(() => {
        County_Currency.forEach((item) => {
            if (currencyList && currencyList.includes(item.name)) {
                setEditCurrency({ ...editCurrency, currency_name: item.name, symbol: item.symbol, code: item.cc })
            }
            else if (currencyList === '' || currencyList === null) {
                setEditCurrency({ ...editCurrency, currency_name: '', symbol: '', code: '' })
            }
        });
    }, [currencyList])

    useEffect(() => {
        if (CurrencyDetails !== undefined) {
            setEditCurrency(CurrencyDetails)
        }
        setOpen(isOpen);
    }, [isOpen]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setEditCurrency((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(false);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};

        for (const [key, value] of Object.entries(editCurrency)) {
            var name;
            if (key === "currency_name") { name = "Currency name" };
            if (key === "exchange_rate") { name = "Exchange rate" };
            if (key !== 'business_id') {
                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`
                }
                else if (key === "exchange_rate" && isNaN(value)) {
                    errors[key] = true;
                    errorMessage[key] = `Exchange rate is invalid. Must be a number`
                }
                else if (key === "exchange_rate" && !/^-?\d*(?:\.\d{1,5})?$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = `Exchange rate is invalid must be less then 5 decimal only accepted`
                }
            }
        }

        if (!editCurrency.currency_name || editCurrency.currency_name === null) {
            errors['currency_name'] = true;
            errorMessage['currency_name'] = `Currency name is required`
        }

        setErrors(errors);
        setErrorsMessage(errorMessage);
        if (Object.keys(errors).length === 0) {
            const isExisted = uploadCurrenyList.some((item) => {
                return (item.id != editCurrency.id && item.currency_name === editCurrency.currency_name && item.exchange_rate == editCurrency.exchange_rate);
            });
            if (isExisted) {
                toast.error("Currency Already Exists!", { toastId: "editcus-1" });
            }
            else {
                setIsSubmitting(true);
                axios.put(`https://wavedemo.cydeztechnologies.com/currencies/update/${editCurrency.id}`, {
                    business_id: businessID,
                    currency_name: editCurrency.currency_name,
                    symbol: editCurrency.symbol,
                    exchange_rate: businessCurrency.toLowerCase() === editCurrency.currency_name.toLowerCase() ? 1 : editCurrency.exchange_rate,
                    code: editCurrency.code
                })
                    .then((res) => {
                        console.log(res.data);
                        if (res.data) {
                            toast.success("Currency updated successfully", { toastId: "editcus" });
                            setOpen(false);
                            if (onClose) onClose();
                            setErrors(false);
                            setIsSubmitting(false);
                        }
                        else {
                            toast.error(res.data.trim(), { toastId: "editcus-1" });
                        }
                    })
                    .catch((e) => {
                        toast.error(e.message, { toastId: "err-api" });
                    });
            }
        }
        else {
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, { toastId: "error" });
        }
    }

    const styleName = "ms-2 rounded border border-2 border-primary w-100";
    const styleNameError = "ms-2 rounded border border-2 border-danger w-100";

    return (
        <Dialog
            open={displayEditCurrency}
            onClose={() => setOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            scroll={'body'}
            disablebackdropclick="true"
            disableEscapeKeyDown={true}
            fullWidth={true}
            maxWidth={'sm'}
            style={{ height: "auto", maxHeight: "900px" }}
        >
            {/* <DialogTitle id="alert-dialog-title" className="text-start fw-bold fs-4 mt-2 mb-3">Edit Payment Method</DialogTitle> */}
            <DialogTitle sx={{ position: 'relative' }} id="alert-dialog-title" className="fw-bold fs-4 mt-2 align-content-center ">
                Edit Currency
                <i
                    className="bi bi-x-lg"
                    style={{ position: "absolute", top: "22%", right: "5%", bottom: "0%", color: "#d5cdcd", cursor: "pointer" }}
                    onClick={handleClose}
                ></i>
            </DialogTitle>
            <hr />
            <DialogContent className="px-5 mt-2">
                <div className="form-group row m-4">
                    <label className="col-sm-4 pe-5 fw-bold test-wrap text-nowrap">Currency Name <span className="importent">*</span> </label>
                    <div className="col-sm-8">
                        <div className="">
                            <Tooltip
                                title={errorsMessage.currency_name}
                                placement="bottom"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.currency_name}
                                overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                                autoAdjustOverflow={false}
                                zIndex={9999}
                            >
                                <Autocomplete
                                    disablePortal
                                    sx={{
                                        '& input': {
                                            height: 0,
                                        },
                                    }}
                                    className={`${errors.currency_name ? styleNameError : styleName}`}
                                    ListboxProps={{ style: { maxHeight: 150 } }}
                                    value={editCurrency.currency_name}
                                    onChange={(event, newValue) => {
                                        setCurrencyList(newValue);
                                    }}
                                    options={currency}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                                {errors.currency_name && <div className="mb-3"></div>}
                            </Tooltip>
                        </div>
                    </div>
                </div>
                <div className="form-group row m-4">
                    <label className="col-sm-4 fw-bold text-start text-nowrap">Currency Symbol</label>
                    <div className="col-sm-8">
                        {/* <input type="text" className="ms-2 rounded border border-2 border-primary py-1 w-100"/> */}
                        <input
                            type="text"
                            className="ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"
                            name='symbol'
                            value={editCurrency.symbol}
                            onChange={handleChange}
                            disabled
                        />
                    </div>
                </div>
                <div className="form-group row m-4">
                    <label className="col-sm-4 fw-bold text-start text-nowrap">Currency Code</label>
                    <div className="col-sm-8">
                        <input
                            type="text"
                            className="ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"
                            name='code'
                            value={editCurrency.code}
                            onChange={handleChange}
                            disabled
                        />
                    </div>
                </div>
                <div className="form-group row m-4">
                    <label className="col-sm-4 fw-bold text-start text-nowrap">Exchange Rate <span className="importent">*</span></label>
                    <div className="col-sm-8">
                        {/* <input type="text" className="ms-2 rounded border border-2 border-primary py-1 w-100"/> */}
                        <Tooltip
                            title={errorsMessage.exchange_rate}
                            placement="bottom"
                            color="#990000"
                            trigger={"contextMenu"}
                            open={errors.exchange_rate}
                            overlayStyle={{ fontSize: "10px", fontWeight: "bold" }}
                            autoAdjustOverflow={false}
                            zIndex={9999}
                        >
                            <input
                                type="text"
                                className={`${errors.exchange_rate ? "ms-2 rounded border border-2 border-danger py-1 w-100 ps-2" : "ms-2 rounded border border-2 border-primary py-1 w-100 ps-2"}`}
                                name='exchange_rate'
                                value={editCurrency.exchange_rate}
                                onChange={handleChange}
                            />
                            {errors.exchange_rate && <><br /><br /></>}
                        </Tooltip>
                    </div>
                </div>
            </DialogContent>
            <hr />
            <DialogActions className='mb-4'>
                <button onClick={handleClose} className={`btn btn-white border rounded-5 border-primary mx-2 text-capitalize`}>
                    Cancel
                </button>
                <button onClick={handleSubmit} disabled={isSubmitting} className="btn btn-primary border rounded-5 border-primary me-4 px-4 text-capitalize">
                    {isSubmitting ? 'Uploading...' : 'Ok'}
                </button>
            </DialogActions>
        </Dialog>
    )
}