import { useNavigate } from "react-router-dom";
import Sidebar from "../../Sidebar/Sidebar";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from 'react-toastify';
import { AddSalesTax } from "../../Settings/Accounting/Sales Taxes/Add_Tax";
import { Tooltip } from 'antd';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";

export default function AddProducts() {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        product_name: "",
        description: "",
        price: "",
        tax_id: {},
    });
    const [taxData, setTaxData] = useState([]);
    const user = localStorage.getItem("user");
    const businessID = user && JSON.parse(user)[0].business.id;
    const [errors, setErrors] = useState({});
    const [errorsMessage, setErrorsMessage] = useState({});
    const [addTaxBox, setAddTaxBox] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if(user === null){
            localStorage.removeItem("user");
            navigate('/error-page');
            }

        axios.get(`https://wavedemo.cydeztechnologies.com/list-tax-by-businessid/${businessID}`).then((response) => {
            if(response.data !== 'No data found'){
                setTaxData(response.data.filter(item => item.status === 1));
            }
        }).catch((e) => {
            console.log("Error in /list-tax-by-businessid:", e);
            toast.error(e.message);
        });
    }, [!addTaxBox]);


    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setErrors(false);
    };

    const updateTaxListfromPopup  = (newvalue) =>{
        setFormData({ ...formData, tax_id: newvalue });
      }  

    const handleSubmit = (event) => {
        event.preventDefault();
        const errors = {};
        const errorMessage = {};
        for (const [key, value] of Object.entries(formData)) {
            var name;
      if(key === "product_name"){name = "Product name"};
      if(key === "price"){name = "Price"};
      if(key === "tax_id"){name = "Tax"};
            if (key !== 'description') {
                if (!String(value).trim()) {
                    errors[key] = true;
                    errorMessage[key] = `${name} is required`;
                } else if (key === "price" && !/^0*[1-9]\d*(\.\d+)?$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = "Price is invalid";
                }
                else if (key === "product_name" && !/^[\w\d\s-]{3,150}(?![\w\d\s-]*\d{2})[\w\d\s-]*$/.test(value)) {
                    errors[key] = true;
                    errorMessage[key] = "Product name must be 3-150 letters";
                }
            }
        }
        if (!formData.tax_id || Object.keys(formData.tax_id).length === 0) {
            errors.tax_id = true;
            errorMessage.tax_id = "Tax is required";
          }

        setErrors(errors);
        setErrorsMessage(errorMessage);
        if (Object.keys(errors).length === 0) {
            setIsSubmitting(true);
            axios.post("https://wavedemo.cydeztechnologies.com/add-products", {
                business_id: businessID,
                product_name: formData.product_name,
                description: formData.description,
                price: Number(formData.price).toFixed(2),
                tax_id: formData.tax_id.id,
            })
                .then((res) => {
                    if (res.data) {
                        toast.success("Product has been created", {toastId:"addPro-1"});
                        navigate("/product-&-services");
                        setIsSubmitting(false);
                    }
                })
                .catch((e) => {
                    console.log("Error in /add-products:", e);
                    toast.error(e.message, {toastId:"err-api"});
                });
        }
        else{
            toast.warning(`${errorMessage && Object.entries(errorMessage)[0][1]} please make sure to include it.`, {toastId:"error"});
        }
    }

    const styleName = "ms-2 rounded border border-2 border-primary py-1 w-100";
    const styleNameError = "ms-2 rounded border border-2 border-danger py-1 w-100";
    return (
        <section>
            <Sidebar />
            <div className="container p-5" style={{ width: "83%", float: "right" }}>
                <div className="fw-bold fs-3">
                    Add a Product or Service
                    <hr />
                </div>
                <div className="mt-2">
                    <p>Products and services that you buy from vendors are used as items on Bills to record those purchases, and the ones that you sell to customers are used as items on Invoices to record those sales.</p>
                </div>
                <div className="my-5 formCard">
                    <div className="form-group row mb-2 mt-4">
                        <label className="col-sm-4 fw-bold py-1">Name <span className="importent">*</span></label>
                        <div className="col-sm-8 text-center">
                            <Tooltip
                                title={errorsMessage.product_name}
                                placement="bottom"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.product_name}
                                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                autoAdjustOverflow={false}
                            >
                                <input
                                    className={`${errors.product_name ? styleNameError : styleName}`}
                                    type="text"
                                    name="product_name"
                                    value={formData.product_name}
                                    onChange={handleChange}
                                />
                                {errors.product_name && <div className="mb-4"></div>}
                            </Tooltip>
                            {/* {errors.product_name && <span className="mx-2 text-danger">{errors.product_name}</span>} */}
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <label className="col-sm-4 fw-bold py-1">Description</label>
                        <div className="col-sm-8">
                            <textarea
                                className="ms-2 rounded border border-2 border-primary py-1 w-100"
                                name="description"
                                value={formData.description}
                                onChange={handleChange}
                            ></textarea>
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <label className="col-sm-4 fw-bold py-1">Price <span className="importent">*</span></label>
                        <div className="col-sm-8">
                            <Tooltip
                                title={errorsMessage.price}
                                placement="bottom"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.price}
                                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                autoAdjustOverflow={false}
                            >
                                <input
                                    className={`${errors.price ? styleNameError : styleName}`}
                                    type="text"
                                    name="price"
                                    value={formData.price}
                                    onChange={handleChange}
                                />
                                {errors.price && <div className="mb-4"></div>}
                            </Tooltip>
                            {/* {errors.price && <span className="mx-2 text-danger">{errors.price}</span>} */}
                        </div>
                    </div>
                    <div className="form-group row mb-2">
                        <label className="col-sm-4 fw-bold py-1">Sales Tax <span className="importent">*</span></label>
                        <div className="col-sm-8">
                            {/* {taxData && taxData.length > 0 ?
                                <>
                                    <Tooltip
                                        title={<span>tax id is required</span>}
                                        placement="bottom"
                                        color="#990000"
                                        trigger={"contextMenu"}
                                        open={errors.tax_id}
                                        overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                        autoAdjustOverflow={false}
                                    >
                                        <select
                                            className={`${errors.tax_id ? styleNameError : styleName}`}
                                            name='tax_id'
                                            value={formData.tax_id}
                                            onChange={handleChange}
                                        >
                                            <option value="">--Select sale tax--</option>
                                            {taxData.map(item => (
                                                <option key={item.id} value={item.id}>
                                                    {item.tax_name}
                                                </option>
                                            ))}
                                        </select>
                                        {errors.tax_id && <><br /><br /><br /></>}
                                    </Tooltip>
                                </>
                                :
                                <button onClick={() => { setAddTaxBox(true) }} className="ms-2 btn btn-none text-primary fw-bold">
                                    <i className="bi bi-plus-circle me-1" ></i> Add new product
                                </button>} */}
                                <Tooltip
                                title={<span>Tax is required</span>}
                                placement="bottom"
                                color="#990000"
                                trigger={"contextMenu"}
                                open={errors.tax_id}
                                overlayStyle={{ fontSize: "10px", fontWeight:"bold" }}
                                autoAdjustOverflow={false}
                            >
                                <Autocomplete
                                    disablePortal
                                    sx={{
                                        '& input': {
                                            height: 0,
                                        },
                                    }}
                                    className={errors.tax_id ? "border border-2 rounded border-danger ms-2 w-100" : "border border-2 rounded border-primary ms-2 w-100"}
                                    ListboxProps={{ style: { maxHeight: 150 } }}
                                    value={formData.tax_id}
                                    onChange={(event, newValue) => {
                                        setFormData({ ...formData, tax_id: newValue });
                                        setErrors(false);
                                    }}
                                    options={taxData}
                                    getOptionLabel={(option) =>option.tax_name || ""}
                                    // options={taxData.map((item) => item.tax_name)}
                                    renderOption={(props, option) => {
                                        return (
                                          <li {...props} key={option.id}>
                                            {option.tax_name}
                                          </li>
                                        );
                                      }}
                                    renderInput={(params) => <TextField {...params}/>}
                                    PaperComponent={({ children }) => {
                                        return (
                                            <Paper>
                                                {children}
                                                <Button
                                                    color="primary"
                                                    fullWidth
                                                    style={{ textTransform: 'none' }}
                                                    sx={{ mx: 'auto', py: '8px', mt: '2px', fontSize: 12, borderTop: 1, borderColor: 'grey.500', borderRadius: 0 }}
                                                    onMouseDown={() => {
                                                        setAddTaxBox(true)
                                                    }}
                                                >
                                                    <i className="bi bi-plus-circle me-1" ></i> Add New Sales Tax
                                                </Button>
                                            </Paper>
                                        );
                                    }}
                                />
                                {errors.tax_id && <div className="mb-4"></div>}
                            </Tooltip>
                        </div>
                    </div>
                    <div className="form-group mt-5 mb-4 text-center">
                        <button type="button" onClick={() => navigate(-1)} className="btn btn-white border rounded-5 border-primary mx-2">Cancel</button>
                        <button type="submit" disabled={isSubmitting} onClick={handleSubmit} className="btn btn-primary rounded-5">{isSubmitting ? 'Uploading...' : 'Save'}</button>
                    </div>
                </div>

            </div>
            <AddSalesTax
                onUpdateselectedtax={updateTaxListfromPopup}
                displayAddTax={addTaxBox}
                isOpen={addTaxBox}
                onClose={() => {
                    setAddTaxBox(false);
                }}
            />
        </section>
    )
}